import { FC, Fragment } from 'react'
import useScreen from '@lib/hooks/useScreen'

import BlockLinks from '@components/common/Footer/Links/BlockLinks'
import { Social } from '@components/common/Footer'

import type { FooterLink } from '@components/common/Footer/Links/BlockLinks'

import cn from 'classnames'
import s from './Links.module.css'

export type LinksProps = {
  className?: string
  blocks?: FooterLink[]
}

const Links: FC<LinksProps> = ({ className, blocks = [] }) => {
  const screen = useScreen()
  return (
    <div className={cn(s.grid, className)}>
      <Social className={s.social} />
      {!(screen === 'xs' || screen === 'sm' || screen === 'md') &&
        blocks.map((block, i) => (
          <Fragment key={`Footer-Links-${i}`}>
            <BlockLinks key={i} className={s.blockLinks} {...block} />
          </Fragment>
        ))}
    </div>
  )
}
export default Links
