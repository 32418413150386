import React, { FC, Fragment, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '@components/ui'
import { MediaQueries } from '@components/common'

import cn from 'classnames'
import s from './ButtonsSlider.module.css'

const removeDomainInUrl = (url: string) => {
  if (!url) return null
  if (url.lastIndexOf('http', 0) !== 0) return url
  try {
    const _url = new URL(url)
    return `${_url.pathname}${_url.search}`
  } catch (err) {
    return null
  }
}
export interface ButtonsSLidersProps {
  buttons?: Array<{
    name?: string
    url?: string
  }>
}

const ButtonsSlider: FC<ButtonsSLidersProps> = ({ buttons }) => {
  const { t } = useTranslation()
  const { asPath, locale } = useRouter()

  const contentScrollREf = useRef<HTMLDivElement>(null)
  const [isScrollingRight, setIsScrollingRight] = useState(false)

  const handleScroll = () => {
    if (contentScrollREf.current.scrollLeft === 0) setIsScrollingRight(false)
    else setIsScrollingRight(true)
  }

  const buttonsFormatted = useMemo(() => {
    if (!buttons || buttons?.length < 1) return null
    return buttons.map((button) => {
      const val = Object.values(button)
      if (!val) return null
      return {
        name: val[0],
        url: val[1],
        urlWithoutDomain: removeDomainInUrl(val[1]),
      }
    })
  }, [buttons])

  if (!buttonsFormatted) return null

  return (
    <div className={cn('button_slider', s.container)}>
      <MediaQueries visible={['xs', 'sm', 'md']}>
        <div className={s.blur}>
          {!isScrollingRight && <div className={s.rightSide} />}
        </div>
      </MediaQueries>
      <div className={s.content} ref={contentScrollREf} onScroll={handleScroll}>
        {buttonsFormatted?.map((button, index: number) => (
          <Fragment key={`buttonSliders_btn_${button?.name}_${index}`}>
            <Link
              key={`blockButton_item_link_${button?.name}_${index}`}
              next={button?.url.lastIndexOf('http', 0) !== 0}
              href={button?.url}
              className={cn(s.button, s.buttonLink, {
                [s.isSelected]:
                  `/${locale}${asPath}` === button?.urlWithoutDomain,
              })}
              aria-label={`${t('layout:common.ariaNavigate')} ${button?.name}`}
            >
              {button?.name}
            </Link>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ButtonsSlider
