import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import {
  useClearRefinements,
  UseClearRefinementsProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './ClearAlgolia.module.css'

export interface ClearAlgoliaProps extends UseClearRefinementsProps {
  className?: string
}

const ClearAlgolia: FC<ClearAlgoliaProps> = (props) => {
  const { t } = useTranslation()
  const { className, ...restProps } = props
  const { refine, canRefine } = useClearRefinements(restProps)
  return (
    <div className={cn(className, s.clearRefinements)}>
      <button
        className={s.erase}
        disabled={!canRefine}
        onClick={() => refine()}
      >
        {t('block:filter.deleteAllFilters')}
      </button>
    </div>
  )
}

export default ClearAlgolia
