const defaultConfig = require('../../tailwind-config.json')
const customTypo = require('../../tailwind-typo.json')
const customClass = require('../../tailwind-class.json')

module.exports = {
  ...defaultConfig,
  plugins: [
    function ({ addComponents }) {
      addComponents({ ...customTypo, ...customClass })
    },
  ],
}
