import { FC } from 'react'

export interface PauseProps {
  className?: string
  color?: string
}

const PauseButton: FC<PauseProps> = ({
  className,
  color = 'rgb(3,4,4)',
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <g transform="matrix(1,0,0,1,-412.167,-533.532)">
      <g transform="matrix(0.0988201,0,0,0.0988201,75.8763,516.547)">
        <path
          d="M3656.13,662.789C3787.15,662.789 3894.13,555.975 3894.13,424.785C3894.13,293.764 3787.15,186.782 3656.13,186.782C3524.94,186.782 3417.96,293.764 3417.96,424.785C3417.96,555.975 3524.94,662.789 3656.13,662.789Z"
          fill={color}
        />
      </g>
      <g transform="matrix(0.0988201,0,0,0.0988201,75.8763,516.547)">
        <path
          d="M3656.13,201.678C3533.02,201.678 3432.85,301.763 3432.85,424.785C3432.85,547.806 3533.02,647.892 3656.13,647.892C3779.15,647.892 3879.24,547.806 3879.24,424.785C3879.24,301.763 3779.15,201.678 3656.13,201.678ZM3656.13,677.685C3516.59,677.685 3403.06,564.234 3403.06,424.785C3403.06,285.336 3516.59,171.885 3656.13,171.885C3723.48,171.885 3786.93,198.248 3834.8,246.117C3882.67,293.987 3909.03,357.438 3909.03,424.785C3909.03,564.234 3795.58,677.685 3656.13,677.685Z"
          fillRule="nonzero"
          fill={color}
        />
      </g>
      <g transform="matrix(0.0988201,0,0,0.0988201,75.8763,516.547)">
        <path
          d="M3582.83,322.373L3600.27,322.373C3615.67,322.373 3628.37,334.899 3628.37,350.472L3628.37,499.267C3628.37,514.672 3615.67,527.367 3600.27,527.367L3582.83,527.367C3567.43,527.367 3554.73,514.672 3554.73,499.267L3554.73,350.472C3554.73,334.899 3567.43,322.373 3582.83,322.373Z"
          fill="white"
        />
      </g>
      <g transform="matrix(0.0988201,0,0,0.0988201,75.8763,516.547)">
        <path
          d="M3711.82,322.373L3729.26,322.373C3744.66,322.373 3757.36,334.899 3757.36,350.472L3757.36,499.267C3757.36,514.672 3744.66,527.367 3729.26,527.367L3711.82,527.367C3696.42,527.367 3683.72,514.672 3683.72,499.267L3683.72,350.472C3683.72,334.899 3696.42,322.373 3711.82,322.373Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
)

export default PauseButton
