import {
  ComponentType,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useState,
  useRef,
} from 'react'

import useScreen, { Screen } from '@lib/hooks/useScreen'

import ClickOutside from '@lib/click-outside'

import cn from 'classnames'
import s from './withTooltip.module.css'

export type WithTooltipProps = {
  tooltipState?: [expanded: boolean, setExpanded: (expanded: boolean) => void]
  [key: string]: any
}

const withTooltip =
  (TooltipComponent: ComponentType<any>) =>
  (Component: ComponentType<any>) =>
  (props: WithTooltipProps) => {
    const [expanded, setExpanded] =
      props.tooltipState || useState<boolean>(false)
    const buttonRef = useRef<HTMLDivElement>(null)
    const screen = useScreen()

    const shouldHandleMouseOver =
      screen && ![Screen.xs, Screen.sm, Screen.md].includes(screen)

    const closeLocalTooltip = () => {
      setExpanded(false)
      buttonRef.current?.focus()
    }

    const onBlur = useCallback(
      (e: FocusEvent<HTMLDivElement>) => {
        if (
          !e.relatedTarget ||
          !e.currentTarget.contains(e.relatedTarget as Element)
        ) {
          setExpanded(false)
        }
      },
      [setExpanded]
    )

    const onButtonKeyDown = useCallback(
      (e: KeyboardEvent) => {
        switch (e.code) {
          case 'Enter':
          case 'Space':
            setExpanded(!expanded)
            e.preventDefault()
            break
          case 'Escape':
            setExpanded(false)
            e.preventDefault()
            break
          default:
            return
        }
      },
      [expanded, setExpanded]
    )

    const onTooltipKeyDown = useCallback(
      (e: KeyboardEvent) => {
        switch (e.code) {
          case 'Escape':
            e.preventDefault()
            setExpanded(false)
            buttonRef.current?.focus()
            break
          default:
            return
        }
      },
      [setExpanded]
    )

    return (
      <ClickOutside active={expanded} onClick={() => setExpanded(false)}>
        <div
          className={cn({ [s.toggleTooltip]: !expanded })}
          onBlur={onBlur}
          onMouseOver={
            shouldHandleMouseOver ? () => setExpanded(true) : undefined
          }
          onMouseLeave={
            shouldHandleMouseOver ? () => setExpanded(false) : undefined
          }
        >
          <div
            ref={buttonRef}
            role="button"
            tabIndex={0}
            onClick={() =>
              shouldHandleMouseOver ? setExpanded(!expanded) : undefined
            }
            onKeyDown={onButtonKeyDown}
            aria-haspopup
          >
            <Component {...props} />
          </div>
          <div
            className={s.tooltip}
            onKeyDown={onTooltipKeyDown}
            // Prevents the "blur" event to be fired to make clicks working inside the TooltipComponent
            onMouseDown={(e) => e.preventDefault()}
          >
            <TooltipComponent {...props} closeTooltip={closeLocalTooltip} />
          </div>
        </div>
      </ClickOutside>
    )
  }

export default withTooltip
