import React, { FC, useEffect, useRef, useState } from 'react'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import { getValueFromHtmlText } from '@utils/string'

import cn from 'classnames'
import s from './HomeSmallBlock.module.css'

export interface HomeSmallBlockProps {
  url?: string
  targetBlank: boolean
  isPopin: boolean
  layout: string
  description?: string
  media?: BlocksMedia
}

const HomeSmallBlock: FC<HomeSmallBlockProps> = ({
  isPopin,
  layout,
  description,
  media,
  url,
  targetBlank,
}) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [blockHeight, setBlockHeight] = useState<string>('auto')

  const title = getValueFromHtmlText(description, 'cms-home-title')

  useEffect(() => {
    const resizeListener = () => {
      if (imgRef.current) {
        setBlockHeight('auto')
        setBlockHeight(`${Math.round(imgRef.current.clientHeight * 1.5)}px`)
      }
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    if (imgRef.current) {
      setBlockHeight(`${Math.round(imgRef.current.clientHeight * 1.5)}px`)
    }
  }, [imgRef])

  return (
    <section
      className={cn('home_small_block', s.container)}
      style={{ height: blockHeight }}
    >
      <BlockLinkWrapper
        title={title}
        url={url}
        targetBlank={targetBlank}
        isPopin={isPopin}
      >
        {layout.indexOf('texte') === -1 && media?.formats?.origin ? (
          <picture className={s.media}>
            <source
              media="(min-width: 768px)"
              srcSet={`
                ${media.formats.large} 2x,
                ${media.formats.origin}
              `}
            />
            <source srcSet={media.formats.small} />
            <img
              className={cn({
                ['zoomOnHover']: url,
              })}
              src={media.formats.origin}
              alt={title}
            />
          </picture>
        ) : null}
        {layout.indexOf('image') === -1 && description ? (
          <RichText content={description} className={s.description} />
        ) : null}
      </BlockLinkWrapper>
    </section>
  )
}

export default HomeSmallBlock
