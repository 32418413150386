import { FC } from 'react'

export interface PlayProps {
  className?: string
  color?: string
  size?: string
}

const PlayButton: FC<PlayProps> = ({
  className,
  color = 'rgb(3,4,4)',
  size = '24',
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 50 50"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    className={className}
  >
    <g transform="matrix(1,0,0,1,-338.303,-533.725)">
      <g transform="matrix(0.105005,0,0,0.10504,115.849,514.105)">
        <path
          d="M2356.69,662.789C2487.71,662.789 2594.69,555.975 2594.69,424.785C2594.69,293.764 2487.71,186.782 2356.69,186.782C2225.5,186.782 2118.52,293.764 2118.52,424.785C2118.52,555.975 2225.5,662.789 2356.69,662.789Z"
          fill={color}
        />
      </g>
      <g transform="matrix(0.105005,0,0,0.10504,115.849,514.105)">
        <path
          d="M2282.55,325.251L2456.56,424.785L2282.55,524.49L2282.55,325.251Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
)

export default PlayButton
