import React, { FC } from 'react'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import cn from 'classnames'
import s from './HomeSaleBlock.module.css'

export interface HomeSaleBlockProps {
  url?: string
  targetBlank: boolean
  isPopin: boolean
  description?: string
  media?: BlocksMedia
}

const HomeSaleBlock: FC<HomeSaleBlockProps> = ({
  description,
  media,
  url,
  targetBlank,
  isPopin,
}) => (
  <>
    {description?.length ? (
      <section
        className={cn('home_sale_block', s.container)}
        style={
          media?.formats?.origin
            ? { backgroundImage: `url(${media.formats.origin})` }
            : {}
        }
      >
        <BlockLinkWrapper url={url} targetBlank={targetBlank} isPopin={isPopin}>
          <RichText content={description} className={s.description} />
        </BlockLinkWrapper>
      </section>
    ) : null}
  </>
)

export default HomeSaleBlock
