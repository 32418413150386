import React, { FC } from 'react'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import { getValueFromHtmlText } from '@utils/string'

import cn from 'classnames'
import s from './HomeMediumBlock.module.css'

export interface HomeMediumBlockProps {
  url?: string
  targetBlank: boolean
  isPopin: boolean
  layout: string
  description?: string
  media?: BlocksMedia
}

const HomeMediumBlock: FC<HomeMediumBlockProps> = ({
  layout,
  description,
  media,
  url,
  targetBlank,
  isPopin,
}) => {
  const title = getValueFromHtmlText(description, 'cms-home-title')

  return (
    <section
      className={cn(
        'home_medium_block',
        s.container,
        { [s.layout_top]: layout === 'top' },
        { [s.layout_bottom]: layout === 'bottom' }
      )}
    >
      <BlockLinkWrapper
        title={title}
        url={url}
        targetBlank={targetBlank}
        isPopin={isPopin}
      >
        {media?.formats?.origin?.length && (
          <picture className={s.media}>
            <source
              media="(min-width: 768px)"
              srcSet={`
              ${media.formats.large} 2x,
              ${media.formats.origin}
            `}
            />
            <source srcSet={media.formats.small} />
            <img
              className={cn({
                ['zoomOnHover']: url,
              })}
              src={media.formats.origin}
              alt={title}
            />
          </picture>
        )}
        {description && (
          <RichText content={description} className={s.description} />
        )}
      </BlockLinkWrapper>
    </section>
  )
}

export default HomeMediumBlock
