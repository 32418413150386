import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import { MODALS } from '@constants'

const useIfLogged = <Callback extends () => any>(
  callback: Callback
): Callback | (() => void) => {
  const user = useUserSettings()
  const { setModalView, openModal } = useUI()

  if (user.logged) return callback

  const openAccountModal = () => {
    setModalView(MODALS.ACCOUNT)
    openModal()
  }
  return openAccountModal
}

export default useIfLogged
