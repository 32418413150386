import { FC } from 'react'
import cn from 'classnames'
import Image from 'next/image'

import Link from '@components/ui/Link'

import s from './NavbarImage.module.css'

interface Props {
  imgClassName?: string
  item: NavigationItem
}

const NavbarImage: FC<Props> = ({
  imgClassName,
  item: { media, title, legend, url },
}) => (
  <Link href={url ?? ''} next>
    <div className={s.root}>
      {media?.src?.length ? (
        <Image
          className={cn('zoomOnHover', imgClassName)}
          alt={media?.alt || title}
          src={media.src}
          layout="fill"
          objectFit="cover"
          unoptimized
        />
      ) : null}
      <div className={s.legend}>
        <p className={s.title}>{title}</p>
        {legend ? <p className={s.subtitle}>{legend}</p> : null}
      </div>
    </div>
  </Link>
)

export default NavbarImage
