import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'

import { collector, clearWindowForPartners } from '@lib/eulerian'

interface TagSearchProps {
  searchEngine: string
  searchResults?: string
  searchKey: string
}

const TagSearch: FC<TagSearchProps> = ({
  searchEngine,
  searchResults,
  searchKey,
}) => {
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
  const { asPath, locale } = useRouter() || { asPath: null, locale: null }
  const { isEALoaded } = useUI()
  const {
    isFetched,
    emailMd5,
    emailSha256,
    uid,
    clientType,
    currencyCode,
    logged,
    subscribedToLoyalty,
  } = useUserSettings()

  if (!asPath || !locale) return null

  const _urlp = locale + '/catalogue/recherche'

  const searchTags = [
    ...(_urlp ? ['urlp', _urlp] : []),
    ...(emailSha256 ? ['email', emailSha256] : []),
    ...(emailMd5 ? ['email_md5', emailMd5] : []),
    ...(locale ? ['lang', locale] : []),
    ...(uid ? ['uid', uid] : []),
    ...(currencyCode ? ['currency', currencyCode] : []),
    ...(currencyCode ? ['devise', currencyCode] : []),
    ...(clientType ? ['type_client', clientType] : []),
    ...['is_logged', `${logged ? 'true' : 'false'}`],
    ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
    ...(appEnv ? ['env', appEnv] : []),
    ...(appVersion ? ['version', appVersion] : []),
    ...(searchEngine ? ['isearchengine', searchEngine] : []),
    ...(searchResults ? ['isearchresults', searchResults] : []),
    ...(searchKey ? ['isearchkey', searchKey] : []),
  ]

  useEffect(() => {
    if (isFetched && isEALoaded && searchTags?.length) {
      clearWindowForPartners(searchTags)
      collector({ tags: window.data_layer, urlp: _urlp, collectType: 'print' })
    }
  }, [asPath, isFetched, isEALoaded])

  return null
}

export default TagSearch
