import React, { FC, useState } from 'react'

import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import cn from 'classnames'
import s from './ProductMiniatures.module.css'

export interface ProductMiniaturesProps {
  selectedMediaIndex?: number
  setSelectedMediaIndex?: (value: number) => void
  productName: string
  miniatures: Array<Media>
  onModal?: boolean
}

const ProductMiniatures: FC<ProductMiniaturesProps> = ({
  selectedMediaIndex = 0,
  setSelectedMediaIndex = () => {},
  productName,
  miniatures,
  onModal = false,
}) => {
  const { t } = useTranslation()
  const screen = useScreen()

  const [sliderIndex, setSliderIndex] = useState<number>(0)

  const MAX_MINIATURES = 11

  const loopSliderIndex = (current = 0, total = 1, inc = 1) => {
    return (current + inc) % total
  }

  const handleSliderIndex = (current = 0, total = 1, inc = 1) => {
    const newIndex =
      current + inc > -1 ? loopSliderIndex(current, total, inc) : total - 1
    setSliderIndex(newIndex)
  }

  const maxMiniaturesShown =
    screen && [Screen.xs, Screen.sm].includes(screen)
      ? Infinity
      : MAX_MINIATURES

  const sliderMiniatures = [
    sliderIndex,
    ...[
      ...Array(
        miniatures.length > maxMiniaturesShown
          ? maxMiniaturesShown - 1
          : miniatures.length - 1
      ),
    ].map((e, i) => loopSliderIndex(sliderIndex, miniatures.length, i + 1)),
  ]

  return (
    <div className={s.bd_slider} aria-roledescription="carousel">
      <div className={cn(s.bd_slider_controller)}>
        {miniatures.length > maxMiniaturesShown && (
          <button
            onClick={() =>
              handleSliderIndex(sliderIndex, miniatures.length, -1)
            }
            className={cn(s.bd_slider_arrow, s.arrow_next)}
            aria-label={t('product:productMiniatures.ariaPrevious')}
            aria-hidden="true"
          ></button>
        )}
      </div>
      <div
        className={cn(s.bd_list, {
          [s.bd_list_grid]: sliderMiniatures.length > 8,
        })}
        aria-live="polite"
      >
        {sliderMiniatures.map((imageIndex, i) => (
          <button
            key={imageIndex}
            className={cn({
              [s.imgContainer]: !onModal,
              [s.selectedImg]: imageIndex === selectedMediaIndex && !onModal,
              [s.imgContainerModal]: onModal,
              [s.selectedImgModal]:
                imageIndex === selectedMediaIndex && onModal,
            })}
            onClick={() => setSelectedMediaIndex(imageIndex)}
          >
            <img
              className={s.img}
              src={miniatures[imageIndex]['76x76q80']}
              width={38}
              height={38}
              alt={t('product:productMiniatures.altProductImage', {
                productName: productName,
                id: imageIndex,
              })}
              onMouseOver={() => setSelectedMediaIndex(imageIndex)}
            />
          </button>
        ))}
      </div>
      <div className={cn(s.bd_slider_controller)}>
        {miniatures.length > maxMiniaturesShown && (
          <button
            onClick={() => handleSliderIndex(sliderIndex, miniatures.length, 1)}
            className={cn(s.bd_slider_arrow, s.arrow_previous)}
            aria-label={t('product:productMiniatures.ariaNext')}
            aria-hidden="true"
          ></button>
        )}
      </div>
    </div>
  )
}

export default ProductMiniatures
