const FacebookCircle = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <g clipPath="url(#clip0_270_3201)">
      <circle cx="15" cy="15" r="15" fill="#292929" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM16.5636 15.6589V23.8197H13.187V15.6592H11.5001V12.8469H13.187V11.1585C13.187 8.86425 14.1396 7.5 16.8458 7.5H19.0989V10.3126H17.6906C16.6371 10.3126 16.5674 10.7056 16.5674 11.4391L16.5636 12.8466H19.1148L18.8163 15.6589H16.5636Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_270_3201">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default FacebookCircle
