import React, { FC } from 'react'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import { getValueFromHtmlText } from '@utils/string'

import cn from 'classnames'
import s from './HomeLargeBlock.module.css'

export interface HomeLargeBlockProps {
  url?: string
  targetBlank: boolean
  layout: string
  description?: string
  media?: BlocksMedia
  slidesHeight?: number
}

const HomeLargeBlock: FC<HomeLargeBlockProps> = ({
  layout,
  description,
  media,
  url,
  targetBlank,
  slidesHeight,
}) => {
  const title = getValueFromHtmlText(description, 'cms-home-title')

  return (
    <section
      className={cn(
        'home_large_block',
        s.container,
        { [s.layoutLeft]: layout === 'left' },
        { [s.layoutCenter]: layout === 'center' || layout === 'right' }
      )}
    >
      <BlockLinkWrapper title={title} url={url} targetBlank={targetBlank}>
        {media?.formats?.origin && (
          <>
            <div
              className={s.media}
              style={{
                backgroundImage: `url(${
                  media?.formats?.large || media?.formats?.origin
                })`,
                height: slidesHeight ? `${slidesHeight}vh` : undefined,
              }}
            />
            <div className={s.layout} />
          </>
        )}
        {description && (
          <RichText content={description} className={s.description} />
        )}
      </BlockLinkWrapper>
    </section>
  )
}

export default HomeLargeBlock
