import { FC } from 'react'

import { Container } from '@components/ui'
import { isMobile } from 'react-device-detect'

import ProductListingLayout from 'components/blocks-cms/ProductListingLayout'
import {
  analyticsTagsAlgolia,
  formatParamsToAlgoliaFilters,
  FROM,
  getAlgoliaIndexName,
  routingConfig,
  searchClient,
} from '@lib/lib-algolia/algolia'
import {
  Configure,
  InstantSearch,
  InstantSearchSSRProvider,
} from 'react-instantsearch'
import { useUserSettings } from '@context/session'

interface ProductListingAlgoliaProps {
  algoliaListingProps?: AlgoliaProductsListingTypes
  algoliaServerInfos?: AlgoliaServerInfos
}

const ProductListingAlgolia: FC<ProductListingAlgoliaProps> = ({
  algoliaListingProps,
  algoliaServerInfos,
}) => {
  const { uid } = useUserSettings()
  const { serverUrl, serverState } = algoliaServerInfos

  const {
    countryCode: deliveryCountryCode,
    hitsPerPage,
    locale,
    parameters,
  } = algoliaListingProps.algoliaSearchParams

  const filters = formatParamsToAlgoliaFilters({
    deliveryCountryCode,
    parameters,
  })

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        searchClient={searchClient}
        indexName={getAlgoliaIndexName(
          deliveryCountryCode,
          locale,
          algoliaListingProps.algoliaSearchParams.sort || 'pertinence'
        )}
        routing={routingConfig(
          deliveryCountryCode,
          locale,
          algoliaListingProps.algoliaSearchParams.sort || 'pertinence',
          serverUrl,
          true
        )}
        future={{
          preserveSharedStateOnUnmount: false,
        }}
      >
        <Configure
          analyticsTags={analyticsTagsAlgolia({
            deliveryCountryCode,
            locale,
            isMobile,
            uid,
            from: FROM.LISTING_PRODUCT,
          })}
          clickAnalytics
          filters={filters}
          hitsPerPage={hitsPerPage}
          enablePersonalization={false}
          query={''}
          facets={['*']}
        />
        <Container>
          <ProductListingLayout isAlgoliaListing={true} />
        </Container>
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}

export default ProductListingAlgolia
