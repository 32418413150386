import { FC, useState, MutableRefObject } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { DisplaySolo, DisplayMulti } from '@components/icons'

import { scrollToTop } from '@utils/scrollTo'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import cn from 'classnames'
import s from './SortMenuMobile.module.css'

export interface SortMenuMobileProps {
  sort: string[]
  currentSort?: string
  openFilters: boolean
  setOpenFilters: (toOpen: boolean) => void
  productsPerLine: number
  setProductsPerLine: (prdPerLine: number) => void
  changeFiltersSelected: changeFiltersSelectedProps
  navigationBar: MutableRefObject<HTMLDivElement>
}

const SortMenuMobile: FC<SortMenuMobileProps> = ({
  sort,
  currentSort,
  openFilters,
  setOpenFilters,
  productsPerLine,
  setProductsPerLine,
  changeFiltersSelected,
  navigationBar,
}) => {
  const { t } = useTranslation()

  const [selectorVisibilty, setSelectorVisibilty] = useState(false)

  const handleProductPerLine = (productsPerLine: number) => {
    scrollToTop(navigationBar.current, -62)
    window?.localStorage?.setItem(
      'display_listing',
      productsPerLine === 2 ? '2' : '1'
    )
    setProductsPerLine(productsPerLine)
  }

  if (!sort?.length) return null

  return (
    <div className={s.container}>
      <button className={s.button} onClick={() => setOpenFilters(!openFilters)}>
        {t('block:filter.showFilter')}
      </button>
      <div className={s.separator} aria-hidden></div>
      <div className={s.button}>
        {t('block:sort.ariaSortBy')}
        <select
          value={currentSort}
          className={cn(s.selector, { [s.visible]: selectorVisibilty })}
          aria-label={t('block:sort.ariaSortBy')}
          onMouseDown={() => setSelectorVisibilty(true)}
          onMouseUp={() => setSelectorVisibilty(false)}
          onChange={(e) => {
            changeFiltersSelected({
              selectedValue: e.target.value,
              parent: 'sort',
              isSelected: false,
              toReplace: true,
            })
          }}
        >
          {sort.includes('rank:asc') && (
            <option value="rank:asc">{t('block:sort.bestSellers')}</option>
          )}
          {sort.includes('brand:asc') && (
            <option value="brand:asc">{t('block:sort.brands')}</option>
          )}
          <option value={'activationDate:desc'}>
            {t('block:sort.newArrivals')}
          </option>
          {sort.includes('stock:desc') && (
            <option value="stock:desc">{t('block:sort.stock')}</option>
          )}
          {sort.includes('price:asc') && (
            <option value="price:asc">{t('block:sort.priceAsc')}</option>
          )}
          {sort.includes('price:desc') && (
            <option value="price:desc">{t('block:sort.priceDesc')}</option>
          )}
        </select>
      </div>
      <div className={s.separator} aria-hidden></div>
      <div className={s.button}>
        <button
          className={cn(s.cta, { [s.selected]: productsPerLine !== 2 })}
          onClick={() => handleProductPerLine(1)}
          aria-label={t('product:displayGridSelector.single')}
        >
          <DisplaySolo width={24} height={24} fill="#000" aria-hidden />
        </button>
        <button
          className={cn(s.cta, { [s.selected]: productsPerLine === 2 })}
          onClick={() => handleProductPerLine(2)}
          aria-label={t('product:displayGridSelector.multi', { nbr: 2 })}
        >
          <DisplayMulti width={24} height={24} fill="#000" aria-hidden />
        </button>
      </div>
    </div>
  )
}

export default SortMenuMobile
