import { BOT_CACHE_CONTROL_TIME, DEFAULT_LOCALES } from '@constants'
import { IncomingMessage } from 'http'
import { GetServerSidePropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'

export interface SessionResponse {
  data: Session
  cookies?: string[] | null
}

export const fetchSession = async (
  {
    apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
    locale,
    headers,
    query,
  }: {
    apiUrl?: string
    locale: string
    headers?: Headers
    query?: string
  } = {
    apiUrl: process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
    locale: DEFAULT_LOCALES,
  }
): Promise<SessionResponse> => {
  const res = await fetch(
    `${apiUrl}/v1/session?locale=${locale}${query ? `&${query}` : ''}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers,
    }
  )
  if (!res?.ok) throw new Error(`ERROR:  API session failed`)
  const { data } = await res.json()

  const _data: Session = Array.isArray(data) ? data[0] : data
  const cookies = (res.headers as any)?.raw?.()['set-cookie'] || null
  return { data: _data, cookies }
}

export const updateSession = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale,
  headers,
  query,
  ...toUpdate
}: {
  apiUrl?: string
  locale: string
  headers?: Headers
  query?: string
  currencyCode?: string
  deliveryCountryCode?: string
}): Promise<SessionResponse> => {
  const res = await fetch(
    `${apiUrl}/v1/session?locale=${locale}${query ? `&${query}` : ''}`,
    {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: headers || { 'Content-Type': 'application/json' },
      body: JSON.stringify(toUpdate),
    }
  )
  if (!res?.ok) throw new Error(`ERROR:  API session failed`)
  const { data } = await res.json()
  const _data = Array.isArray(data) ? data[0] : data
  const cookies = (res.headers as any)?.raw?.()['set-cookie'] || null
  return { data: _data, cookies }
}

export const updateSessionCountryAndCurrency = async (
  query: ParsedUrlQuery,
  locale: string,
  queryString: string
): Promise<SessionResponse | null> => {
  try {
    if (query?.country || query?.currency) {
      return await updateSession({
        locale,
        query: queryString,
        currencyCode: query?.currency as string,
        deliveryCountryCode: query?.country as string,
      })
    }
    return null
  } catch (error) {
    console.error('Error updating session', error)
    return null
  }
}

export const forwardHeaders = (
  req: IncomingMessage,
  context: GetServerSidePropsContext
): Headers => {
  const {
    cookie,
    'cloudfront-viewer-country': cloudfrontViewerCountry,
    'smallable-new-index': useNewIndex,
    'x-amzn-waf-searchbot': amazonWafSearchBot,
    'product-listing-origin': productListingOrigin,
  } = req.headers

  const headers = new Headers({
    Cookie: cookie,
    'content-type': 'application/json',
  })

  if (cloudfrontViewerCountry) {
    const countryHeader = Array.isArray(cloudfrontViewerCountry)
      ? cloudfrontViewerCountry[0]
      : cloudfrontViewerCountry

    headers.set('X-Frontend-Viewer-Country', countryHeader)
  }

  if (useNewIndex) {
    headers.set(
      'SMALLABLE-NEW-INDEX',
      Array.isArray(useNewIndex) ? useNewIndex[0] : useNewIndex
    )
  }

  // Cache bot response
  if (amazonWafSearchBot) {
    context.res.setHeader(
      'Cache-Control',
      `public, max-age=${BOT_CACHE_CONTROL_TIME}`
    )
  }

  if (productListingOrigin) {
    headers.set(
      'product-listing-origin',
      Array.isArray(productListingOrigin)
        ? productListingOrigin[0]
        : productListingOrigin
    )
  }

  return headers
}
