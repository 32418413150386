import React, { FC, useState, useRef, ReactElement } from 'react'

import useIntersection from '@lib/hooks/useIntersection'

interface LazyLoadComponentProps {
  children: ReactElement
  width?: number
  height?: number
  seoReadable?: boolean
  placeholderHeight?: number
}

const LazyLoadComponent: FC<LazyLoadComponentProps> = ({
  children,
  width,
  height,
  seoReadable = false,
  placeholderHeight,
}) => {
  const [isInView, setIsInView] = useState<boolean>(false)
  const containerRef = useRef<HTMLImageElement>()
  useIntersection(containerRef, () => {
    setIsInView(true)
  })

  return (
    <div
      ref={containerRef}
      style={{
        paddingBottom: width && height ? `${(height / width) * 100}%` : '',
        width: '100%',
      }}
    >
      {isInView ? (
        children
      ) : (
        <div style={{ height: `${placeholderHeight}px` }}></div>
      )}
      {seoReadable ? <noscript> {children} </noscript> : null}
    </div>
  )
}

export default LazyLoadComponent
