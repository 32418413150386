import React, { FC, KeyboardEvent, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { AnimateMotion, Arrow, Link } from '@components/ui'
import RichText from '@components/blocks-cms/RichText'

import cn from 'classnames'
import s from './Brand.module.css'

export interface BrandProps {
  name?: string
  description?: string
  media?: BlocksMedia
  details: string
}

const Brand: FC<BrandProps> = ({ name, description, media, details }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const screen = useScreen()

  const [showDetails, setShowDetails] = useState<boolean>(false)

  const onKeyDownShowMore = (
    e: KeyboardEvent<HTMLAnchorElement>,
    open: boolean
  ) => {
    if (e.key === 'Enter') {
      setShowDetails(open)
    }
  }

  return (
    <div className={s.container}>
      <section className={s.brand}>
        {media?.formats?.origin?.length &&
          (typeof screen === 'undefined' ||
            ![Screen.xs, Screen.sm, Screen.md].includes(screen)) && (
            <div className={s.mediaContainer}>
              {media ? (
                <img
                  src={media.formats.origin}
                  alt={name}
                  srcSet={`
                      ${
                        media?.formats?.large?.length
                          ? `${media.formats.large} 2x,`
                          : ''
                      }
                      ${
                        media?.formats?.origin?.length
                          ? media.formats.origin
                          : ''
                      }
                    `}
                />
              ) : null}
            </div>
          )}
        <div className={s.descriptionContainer}>
          {typeof screen === 'undefined' ||
            (![Screen.xs, Screen.sm].includes(screen) && (
              <nav aria-label="breadcrumbs" className={s.breadcrumbs}>
                <ul>
                  <li>
                    <Link
                      aria-label={`${t('layout:common.ariaNavigate')} ${t(
                        'product:breadcrumb.home'
                      )}`}
                      next={false}
                      href={`/${locale}`}
                      className={s.link}
                    >
                      {t('block:brand.home')}&nbsp;/&nbsp;
                    </Link>
                  </li>
                  <li>{t('block:brand.brands')}&nbsp;/&nbsp;</li>
                  <li>{name}</li>
                </ul>
              </nav>
            ))}
          <h1 className={s.title}>{name}</h1>
          {description ? (
            <div className={s.description}>
              <RichText content={description} />
              {details?.length ? (
                <a
                  tabIndex={0}
                  className={s.readMore}
                  onClick={() => setShowDetails(!showDetails)}
                  onKeyDown={(e) => onKeyDownShowMore(e, !showDetails)}
                >
                  {showDetails
                    ? t('block:brand.close')
                    : t('block:brand.showMore')}
                  <Arrow
                    width="10"
                    height="10"
                    className={cn(
                      s.arrow,
                      showDetails ? s.arrowUp : s.arrowDown
                    )}
                    aria-hidden
                  />
                </a>
              ) : null}
            </div>
          ) : null}
        </div>
      </section>
      {details?.length ? (
        <section className={s.details}>
          <AnimateMotion initial={showDetails}>
            <>
              <RichText content={details} />
              <div className={s.separator}></div>
              <a
                className={s.readMore}
                tabIndex={0}
                onClick={() => {
                  setShowDetails(false)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                onKeyDown={(e) => {
                  onKeyDownShowMore(e, false)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              >
                {t('block:brand.close')}
              </a>
            </>
          </AnimateMotion>
        </section>
      ) : null}
    </div>
  )
}

export default Brand
