import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Loupe } from '@components/icons'
import { useUI } from '@components/ui/context'
import { MODALS } from '@constants'

const ButtonSearchBar: FC<ButtonSearchBarProps> = ({ className, id }) => {
  const { setModalView, openModal } = useUI()

  const { t } = useTranslation()

  const openSearchModal = () => {
    setModalView(MODALS.SEARCH)
    openModal()
  }

  return (
    <div id={id} className={className}>
      <button
        aria-label={t('layout:header.search.placeholder')}
        onClick={openSearchModal}
      >
        <Loupe width="1.5rem" height="1.5rem" aria-hidden />
      </button>
    </div>
  )
}

export default ButtonSearchBar
