import React, { FC } from 'react'
import { useRouter } from 'next/router'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import { getValueFromHtmlText } from '@utils/string'

import cn from 'classnames'
import s from './HomeItemBlock.module.css'

export interface HomeItemBlockProps {
  url?: string
  targetBlank: boolean
  isPopin: boolean
  columns: number
  description?: string
  media?: BlocksMedia
}

const HomeItemBlock: FC<HomeItemBlockProps> = ({
  columns,
  description,
  media,
  url,
  targetBlank,
  isPopin,
}) => {
  const { locale, asPath } = useRouter()
  const path =
    url?.charAt(0) === '?' ? `/${locale}${asPath.split('?')[0]}${url}` : url
  const title = getValueFromHtmlText(description, 'cms-home-title')

  return (
    <section
      className={cn(
        'home_item_block',
        s.container,
        { [s.col_2]: columns === 0 },
        { [s.col_4]: columns === 2 }
      )}
    >
      <BlockLinkWrapper
        title={title}
        url={path}
        targetBlank={targetBlank}
        isPopin={isPopin}
        forceScroll={true}
      >
        {media?.formats?.origin?.length && (
          <picture className={s.media}>
            <source
              media="(min-width: 768px)"
              srcSet={`
                  ${media.formats.large} 2x,
                  ${media.formats.origin}
                `}
            />
            <source srcSet={media.formats.small} />
            <img
              className={cn({
                ['zoomOnHover']: url,
              })}
              src={media.formats.origin}
              alt={title}
            />
          </picture>
        )}
        {description && (
          <RichText content={description} className={s.description} />
        )}
      </BlockLinkWrapper>
    </section>
  )
}

export default HomeItemBlock
