const ArrowLineLeft = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19 12H5"
      stroke="#121212"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      d="M8.5 8.5L5 12L8.5 15.5"
      stroke="#121212"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
)

export default ArrowLineLeft
