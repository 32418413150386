const Filters = ({ ...props }) => (
  <svg {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M2 16H22"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="16" cy="16" r="2.5" fill="white" stroke="#121212" />
    <path
      d="M2 8H22"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="8" cy="8" r="2.5" fill="white" stroke="#121212" />
  </svg>
)

export default Filters
