import {
  FC,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { paramCase } from 'param-case'
import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import cn from 'classnames'
import ClickOutside from '@lib/click-outside'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import { isMobileSafari } from 'react-device-detect'
import s from './DropdownSelect.module.css'
import { ChevronUp, Cross } from '@components/icons'

type OptionValue = string | number
type GetOptionValue<T extends {}, V = OptionValue> = (option: T) => V
type RenderOption<T = any> = FC<T>
type DefaultRenderOption<T extends {}, V = OptionValue> = (
  getValue: GetOptionValue<T, V>
) => RenderOption<T>
type DefaultRenderSelectedOption<T = any> = (
  renderOption: RenderOption<T>,
  placeholder?: string
) => RenderOption<T>
interface DropdownSelectProps<T = any, V = OptionValue> {
  className?: string
  expanded: boolean
  getOptionValue?: GetOptionValue<T, V>
  id: string
  isOptionDisabled?: (option: T) => boolean
  onChange?: (value: V) => void
  onClickOutside?: () => void
  options: T[]
  placeholder?: string
  renderOption?: RenderOption<T>
  renderSelectedOption?: (option?: T) => JSX.Element | null
  setExpanded: (value: boolean) => void
  title: string
  value?: V
  setIsDropDownOpen?: (value: boolean) => void
  isOnMobileQuickBuy?: boolean
}
const defaultGetOptionValue: GetOptionValue<any, any> = ({ value }) => value
const defaultRenderOption: DefaultRenderOption<any, any> =
  (getValue) => (option) =>
    <span>{getValue(option)}</span>
const defaultRenderSelectedOption: DefaultRenderSelectedOption<any> =
  (RenderOption, placeholder) => (option) => {
    return option ? (
      <RenderOption {...option} />
    ) : (
      <span className={s.placeholder}>{placeholder}</span>
    )
  }
const optionId = (id: string, value: OptionValue): string =>
  `${id}-${value ? paramCase(value?.toString()) : ''}`
const DropdownSelect = <T extends any, V extends OptionValue>({
  className,
  expanded,
  getOptionValue = defaultGetOptionValue,
  id,
  isOptionDisabled = () => false,
  onChange = () => {},
  onClickOutside = () => {},
  options,
  placeholder,
  renderOption,
  renderSelectedOption,
  setExpanded,
  title,
  value,
  setIsDropDownOpen,
  isOnMobileQuickBuy = false,
}: DropdownSelectProps<T, V>): ReactElement | null => {
  const { t } = useTranslation()
  const button = useRef() as React.MutableRefObject<HTMLButtonElement>
  const listbox = useRef() as React.MutableRefObject<HTMLUListElement>
  const selectedIndex = options.findIndex(
    (option) => getOptionValue(option) === value
  )
  const selectedOption = options[selectedIndex]
  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    selectedIndex < 0 ? undefined : selectedIndex
  )
  const _renderOption = renderOption || defaultRenderOption(getOptionValue)
  const _renderSelectedOption =
    renderSelectedOption ||
    defaultRenderSelectedOption(_renderOption, placeholder)
  const onOptionClick = (value: V) => {
    setExpanded(false)
    onChange(value)
    button.current.focus()
  }
  const onkeydown = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'ArrowDown':
        setActiveIndex(
          activeIndex !== undefined
            ? Math.min(activeIndex + 1, options.length - 1)
            : 0
        )
        break
      case 'ArrowUp':
        setActiveIndex(
          activeIndex !== undefined ? Math.max(activeIndex - 1, 0) : 0
        )
        break
      case 'Escape':
      case 'Tab':
        setExpanded(false)
        setActiveIndex(value !== undefined ? selectedIndex : undefined)
        button.current.focus()
        break
      case 'Enter':
        const option = options[activeIndex as number]
        if (option) {
          onOptionClick(getOptionValue(option))
        }
    }
    e.preventDefault()
  }

  useEffect(() => {
    if (expanded && listbox.current) {
      listbox.current.focus()
    }
  }, [expanded, listbox])

  const screen = useScreen()
  useEffect(() => {
    if (!isOnMobileQuickBuy) {
      if (expanded && screen && [Screen.xs, Screen.sm].includes(screen)) {
        blockScroll(isMobileSafari)
      } else if (screen && [Screen.xs, Screen.sm].includes(screen)) {
        enableScroll(isMobileSafari)
      }
    }
  }, [expanded])

  useEffect(() => {
    if (!isOnMobileQuickBuy) {
      if (expanded && screen && ![Screen.xs, Screen.sm].includes(screen)) {
        enableScroll(isMobileSafari)
      } else if (expanded) {
        blockScroll(isMobileSafari)
      }
    }
  }, [screen])

  const titleId = optionId(id, title)
  return (
    <div className={className}>
      {expanded && (
        <div
          role="button"
          aria-label={t('layout:dropdown.ariaClose')}
          className={s.backdrop}
          onClick={() => {
            setExpanded(false)
            setIsDropDownOpen && setIsDropDownOpen(false)
          }}
        />
      )}
      <ClickOutside
        active={
          expanded && !!screen && ![Screen.xs, Screen.sm].includes(screen)
        }
        onClick={(e) => {
          onClickOutside()
          setExpanded(false)
          setIsDropDownOpen && setIsDropDownOpen(false)
          e?.stopPropagation()
        }}
      >
        <div>
          <button
            role="button"
            aria-haspopup="listbox"
            aria-expanded={expanded}
            className={cn(s.selectedContent, { [s.expanded]: expanded })}
            onClick={(e) => {
              e.preventDefault()
              setExpanded(!expanded)
            }}
            ref={button}
          >
            <div className={s.buttonContent}>
              {_renderSelectedOption(selectedOption)}
            </div>
            <span className={s.selectIconContainer}>
              {expanded ? (
                <ChevronUp className={s.chevron} />
              ) : (
                <ChevronUp className={cn(s.chevron, s.chevronDown)} />
              )}
            </span>
          </button>
          <div className={cn(s.selectContainer, { hidden: !expanded })}>
            <div className={s.selectHeader}>
              <span className={s.headerTitle} id={titleId}>
                {title}
              </span>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setExpanded(false)
                  setIsDropDownOpen && setIsDropDownOpen(false)
                }}
                aria-label="Close select"
                className={s.headerIcon}
              >
                <Cross className="h-5 w-5" />
              </button>
            </div>
            <ul
              className={s.selectOptions}
              tabIndex={-1}
              role="listbox"
              aria-activedescendant={
                expanded && activeIndex !== undefined
                  ? optionId(id, getOptionValue(options[activeIndex]))
                  : undefined
              }
              aria-labelledby={titleId}
              onKeyDown={onkeydown}
              ref={listbox}
            >
              {options.map((option: any, i: number) => (
                <li
                  key={getOptionValue(option)}
                  className={cn(s.option, { [s.focused]: i === activeIndex })}
                  id={optionId(id, getOptionValue(option))}
                  onClick={
                    !isOptionDisabled(option)
                      ? () => onOptionClick(getOptionValue(option))
                      : undefined
                  }
                  onMouseEnter={() => setActiveIndex(i)}
                  role="option"
                  aria-disabled={isOptionDisabled(option)}
                >
                  {_renderOption(option)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ClickOutside>
    </div>
  )
}
export default DropdownSelect
