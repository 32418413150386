import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'

import { BRAND_PAGES } from '@constants'
import { collector, clearWindowForPartners } from '@lib/eulerian'

interface Parameters {
  [key: string]: any
}
interface TagGenericProps {
  isError?: boolean
  parameters?: Parameters
  urlp?: string
}

const getEulerianUrlp = ({
  asPath,
  isError,
  parameters,
}: {
  asPath: string
  isError: boolean
  parameters: Parameters
}) => {
  if (isError) return '/error'
  if (asPath === '/') return '/homepage'
  if (asPath.indexOf('/search') !== -1) return '/catalogue/recherche'
  if (asPath.indexOf('/page') !== -1) return asPath
  if (BRAND_PAGES.some((substr) => asPath.includes(substr))) {
    const brand = asPath.split(/.*\/([^,?]+)/)[1]
    if (asPath.split('/').length <= 2) return `/${brand}`
    return `/catalogue/marques/${brand || ''}`
  }
  if (parameters)
    return `/catalogue${parameters.new ? `-new` : ''}/${
      parameters.section ? parameters.section : ''
    }${parameters.person ? `-` + parameters.person : '-'}${
      parameters.sex ? `/` + parameters.sex : ''
    }${parameters.type ? `/` + parameters.type : ''}`
  return asPath
}

const TagGeneric: FC<TagGenericProps> = ({
  isError,
  parameters,
  urlp = null,
}) => {
  const isTranslatableLoaded =
    typeof window !== 'undefined' && window.Translatable?.initialized

  const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
  const appVersion = isTranslatableLoaded
    ? window.Translatable?.currentLang
    : process.env.NEXT_PUBLIC_APP_VERSION || ''
  const { asPath, locale } = useRouter() || { asPath: null, locale: null }
  const { isEALoaded } = useUI()
  const {
    isFetched,
    emailMd5,
    emailSha256,
    uid,
    clientType,
    currencyCode,
    logged,
    deliveryCountryCode,
    basket,
    subscribedToLoyalty,
  } = useUserSettings()

  const _urlp = urlp
    ? locale + urlp
    : locale + getEulerianUrlp({ asPath, isError, parameters })

  const genericTags =
    !asPath || !locale
      ? null
      : [
          ...(_urlp ? ['urlp', _urlp] : []),
          ...(emailSha256 ? ['email', emailSha256] : []),
          ...(emailMd5 ? ['email_md5', emailMd5] : []),
          ...(locale ? ['lang', locale] : []),
          ...(uid ? ['uid', uid] : []),
          ...(currencyCode ? ['currency', currencyCode] : []),
          ...(currencyCode ? ['devise', currencyCode] : []),
          ...(clientType ? ['type_client', clientType] : []),
          ...['is_logged', `${logged ? 'true' : 'false'}`],
          ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
          ...(appEnv ? ['env', appEnv] : []),
          ...(appVersion ? ['version', appVersion] : []),
        ]

  useEffect(() => {
    if (isFetched && isEALoaded && genericTags?.length) {
      clearWindowForPartners(genericTags)
      collector({
        tags: window.data_layer,
        urlp: _urlp,
        collectType: 'print',
        deliveryCountryCode,
        basket,
      })
    }
  }, [asPath, isFetched, isEALoaded])

  return null
}

export default TagGeneric
