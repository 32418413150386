import { FC, LegacyRef, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useKeenSlider } from 'keen-slider/react'

import ProductCard from '@components/product/ProductCard'

import 'keen-slider/keen-slider.min.css'
import cn from 'classnames'
import s from './ProductsSlider.module.css'
import { useUI } from '@components/ui/context'

export interface ProductsSliderProps {
  products: ProductListItem[]
  mobileDevice?: boolean
}

const ProductsSlider: FC<ProductsSliderProps> = ({
  products,
  mobileDevice = false,
}) => {
  const { t } = useTranslation()
  const { isSiteReverted } = useUI()

  const arrowLeftRef = useRef<HTMLButtonElement>()
  const arrowRightRef = useRef<HTMLButtonElement>()
  const [isMounted, setIsMounted] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    rtl: isSiteReverted,
    loop: true,
    defaultAnimation: {
      duration: 600,
    },
    slides: { perView: mobileDevice ? (products?.length > 1 ? 1.15 : 1) : 3 },
    created: () => setIsMounted(true),
  })

  if (!products?.length) return null

  return (
    <section
      className={s.container}
      data-cs-override-id="filterList-SimilarProduct"
    >
      <div className={s.content}>
        <h2 className={s.title}>{t('product:relatedProducts.title')}</h2>
        {!mobileDevice && instanceRef && (
          <>
            <button
              ref={arrowLeftRef as LegacyRef<HTMLButtonElement>}
              className={s.arrowLeft}
              onClick={() => instanceRef.current?.prev()}
              aria-label={t('block:slideBlock.ariaSlideNext')}
            />
            <button
              ref={arrowRightRef as LegacyRef<HTMLButtonElement>}
              className={s.arrowRight}
              onClick={() => instanceRef.current?.next()}
              aria-label={t('block:slideBlock.ariaSlidePrev')}
            />
          </>
        )}
        <div
          ref={sliderRef}
          className={cn('keen-slider', s.slider, {
            [s.jsDisabled]: !isMounted,
          })}
        >
          {products?.map((product, i) => (
            <div
              key={`productsList-${i}`}
              className={cn('keen-slider__slide', s.slide, {
                [s.jsDisabledBlocks]: !isMounted,
              })}
            >
              <ProductCard
                product={product}
                disableSwipe={mobileDevice && products?.length > 1}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ProductsSlider
