import React, { FC } from 'react'

export interface ApplePayProps {
  width?: number
  color?: string
  className?: string
}

const ApplePay: FC<ApplePayProps> = ({
  width = '452',
  color = '#121212',
  className,
  ...props
}) => (
  <svg
    width={width}
    viewBox="0 0 452 286"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M119.394 95.7916C115.456 100.453 109.155 104.129 102.854 103.604C102.067 97.3016 105.151 90.6051 108.761 86.4691C112.699 81.6765 119.59 78.2626 125.169 78C125.826 84.5652 123.266 90.999 119.394 95.7916ZM125.104 104.852C115.981 104.326 108.17 110.038 103.839 110.038C99.4413 110.038 92.8124 105.114 85.5928 105.245C76.2073 105.377 67.4782 110.695 62.687 119.164C52.8422 136.102 60.1274 161.181 69.6441 174.968C74.304 181.795 79.8828 189.28 87.2336 189.017C94.1907 188.755 96.9472 184.487 105.348 184.487C113.815 184.487 116.243 189.017 123.594 188.886C131.207 188.755 135.999 182.058 140.658 175.23C145.975 167.483 148.141 159.933 148.272 159.539C148.141 159.408 133.57 153.828 133.439 137.021C133.308 122.971 144.925 116.275 145.45 115.881C138.886 106.165 128.648 105.114 125.104 104.852ZM177.806 85.8126V188.164H193.69V153.171H215.676C235.76 153.171 249.871 139.384 249.871 119.426C249.871 99.4681 236.023 85.8126 216.202 85.8126H177.806ZM193.69 99.2055H212.001C225.784 99.2055 233.66 106.559 233.66 119.492C233.66 132.425 225.784 139.844 211.935 139.844H193.69V99.2055ZM278.881 188.951C288.857 188.951 298.111 183.896 302.311 175.887H302.64V188.164H317.341V137.218C317.341 122.446 305.527 112.927 287.347 112.927C270.48 112.927 258.009 122.578 257.55 135.839H271.858C273.039 129.537 278.881 125.401 286.888 125.401C296.601 125.401 302.049 129.931 302.049 138.268V143.914L282.228 145.096C263.785 146.212 253.809 153.762 253.809 166.892C253.809 180.154 264.113 188.951 278.881 188.951ZM283.147 176.806C274.68 176.806 269.298 172.735 269.298 166.499C269.298 160.065 274.483 156.323 284.394 155.732L302.049 154.616V160.393C302.049 169.978 293.91 176.806 283.147 176.806ZM336.965 216C352.455 216 359.74 210.091 366.106 192.168L394 113.912H377.854L359.149 174.377H358.821L340.116 113.912H323.511L350.42 188.426L348.976 192.956C346.548 200.637 342.61 203.592 335.587 203.592C334.34 203.592 331.912 203.461 330.927 203.329V215.606C331.846 215.869 335.784 216 336.965 216Z"
      fill={color}
    />
    <rect
      x="5"
      y="5"
      width="442"
      height="276"
      rx="15"
      stroke={color}
      strokeWidth="10"
    />
  </svg>
)

export default ApplePay
