const Benefits = ({ ...props }) => (
  <svg width="50" height="49" viewBox="0 0 50 49" fill="none" {...props}>
    <path
      d="M48 46H2C1.44822 46 1 46.45 1 46.9999C1 47.5499 1.44822 47.9999 2 47.9999H48C48.55 47.9999 49 47.5499 49 46.9999C49 46.45 48.55 46 48 46Z"
      fill="#292929"
      stroke="white"
    />
    <path
      d="M5.99902 44.0012H11.999C12.5508 44.0012 12.999 43.5513 12.999 43.0013V35.0019C12.999 34.4519 12.5508 34.002 11.999 34.002H5.99902C5.44724 34.002 4.99902 34.4519 4.99902 35.0019V43.0013C4.99902 43.5513 5.44724 44.0012 5.99902 44.0012ZM6.99902 36.0018H10.999V42.0014H6.99902V36.0018Z"
      fill="#292929"
      stroke="white"
    />
    <path
      d="M16.001 28.001C15.4492 28.001 15.001 28.4509 15.001 29.0009V42.9999C15.001 43.5498 15.4492 43.9998 16.001 43.9998H22.001C22.5528 43.9998 23.001 43.5498 23.001 42.9999V29.0009C23.001 28.4509 22.5528 28.001 22.001 28.001H16.001ZM21.001 42H17.001V30.0008H21.001V42Z"
      fill="#292929"
      stroke="white"
    />
    <path
      d="M26 22.0039C25.4482 22.0039 25 22.4521 25 23.0038V43.0024C25 43.5523 25.4482 44.0023 26 44.0023H32C32.55 44.0023 33 43.5523 33 43.0024V23.0038C33 22.4521 32.55 22.0039 32 22.0039H26ZM31 42.0025H27V24.0038H31V42.0025Z"
      fill="#292929"
      stroke="white"
    />
    <path
      d="M34.999 13.0029V43.0007C34.999 43.5506 35.449 44.0006 35.999 44.0006H41.999C42.549 44.0006 42.999 43.5506 42.999 43.0007V13.0029C42.999 12.4511 42.549 12.0029 41.999 12.0029H35.999C35.449 12.0029 34.999 12.4511 34.999 13.0029Z"
      fill="#292929"
      stroke="white"
    />
    <path
      d="M6.00037 30.0006C6.1285 30.0006 6.2584 29.9765 6.38252 29.9247C14.5923 26.5191 21.9608 21.5914 28.2785 15.2778C31.5526 12.0061 34.4544 8.3881 36.9883 4.52861C37.1683 5.35846 37.3383 6.20666 37.4941 7.06865C37.5821 7.55077 38.004 7.88868 38.4763 7.88868C38.5343 7.88868 38.5982 7.88467 38.6585 7.87261C39.2004 7.77441 39.5584 7.25257 39.4602 6.70881C39.1504 4.99694 38.7924 3.33317 38.4084 1.76323C38.2785 1.22711 37.7446 0.893201 37.2004 1.03115C35.6522 1.41103 34.0286 1.75699 32.3723 2.0592C31.8304 2.1574 31.4706 2.67924 31.5706 3.223C31.6585 3.70511 32.0804 4.04303 32.5527 4.04303C32.6107 4.04303 32.6746 4.03901 32.7348 4.02696C33.609 3.86716 34.4367 3.66493 35.2866 3.48281C32.8348 7.20848 30.0246 10.7024 26.8688 13.8619C20.7367 19.9896 13.5854 24.7731 5.61851 28.0746C5.10869 28.2844 4.86673 28.8705 5.07654 29.3785C5.23636 29.7687 5.60841 30.0006 6.00037 30.0006Z"
      fill="#292929"
      stroke="white"
    />
  </svg>
)

export default Benefits
