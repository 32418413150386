import React, { useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Arrow } from '@components/ui'

import {
  getSizeSorted,
  itemToSizeAndStock,
  SizeRefinement,
} from '@lib/lib-algolia/algolia'
import { RefinementListWidgetParams } from 'instantsearch.js/es/widgets/refinement-list/refinement-list'
import {
  useCurrentRefinements,
  UseCurrentRefinementsProps,
  useRefinementList,
  UseRefinementListProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './Refinement.module.css'

interface RefinementProps {
  className?: string
  title?: string
  unity?: string
  sortSize?: boolean
}

export type RefinementListProps = UseRefinementListProps &
  UseCurrentRefinementsProps &
  Pick<RefinementListWidgetParams, 'searchable' | 'searchablePlaceholder'> &
  RefinementProps

export function RefinementButtonSizeAndStock(props: RefinementListProps) {
  const { t } = useTranslation()
  const { className, title, sortSize, attribute } = props
  const { items: itemsRefinement } = useCurrentRefinements(props)

  const [openFilter, setOpenFilter] = useState(false)

  const { items, refine, searchForItems } = useRefinementList(props)

  const itemSizeAndStock: SizeRefinement[] = items.map((item) => ({
    ...itemToSizeAndStock(item.label),
    ...item,
  }))

  const itemsFiltered = itemSizeAndStock.reduce(
    (itemsFiltered: SizeRefinement[], item) => {
      if (item.isInStock) {
        itemsFiltered.push(item)
      }
      return itemsFiltered
    },
    []
  )

  const itemsSorted = sortSize ? getSizeSorted(itemsFiltered) : itemsFiltered

  const [query, setQuery] = useState('')
  const previousQueryRef = useRef(query)

  useEffect(() => {
    if (previousQueryRef.current !== query) {
      previousQueryRef.current = query
      searchForItems(query)
    }
  }, [query, searchForItems])

  useEffect(() => {
    if (
      itemsRefinement.some((item) => item.attribute === attribute) &&
      !openFilter
    ) {
      setOpenFilter(true)
    }
  }, [itemsRefinement])

  return (
    <>
      {itemsSorted && itemsSorted.length > 0 && (
        <div
          className={cn(className, s.container, {
            [s.disabled]: !openFilter,
          })}
        >
          <button className={s.cta} onClick={() => setOpenFilter(!openFilter)}>
            {title}
            <Arrow
              width="10"
              height="10"
              stroke="white"
              className={s.arrowDown}
              aria-hidden
            />
          </button>
          <ul
            className={cn(
              s.filtersList,
              s.containerCheckbox,
              s.buttonItemContainer
            )}
          >
            {itemsSorted.map((item, index) => (
              <React.Fragment key={`Filters-item-${index}`}>
                <li className={s.containerButtonCheckbox}>
                  <button
                    role="checkbox"
                    className={cn(s.titleButtonCheckbox, {
                      [s.checkedButtonCheckbox]: item.isRefined,
                      [s.disabledButtonCheckbox]:
                        (!item.count && !item.isRefined) || !item.isInStock,
                    })}
                    aria-label={t('block:filter.ariaFilterItem', {
                      filterText: item.value as string,
                    })}
                    disabled={!item.isInStock || !item.count}
                    value={item.value}
                    onClick={() => {
                      if (item.count) {
                        refine(item.value)
                        setQuery('')
                      }
                    }}
                  >
                    {item.size}
                  </button>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default RefinementButtonSizeAndStock
