import { FC } from 'react'

import {
  Facebook,
  Instagram,
  Twitter,
  Pinterest,
} from '@components/icons/social'

import cn from 'classnames'
import s from './SocialLinks.module.css'

export interface SocialLinksProps {
  className?: string
}

const SocialLinks: FC<SocialLinksProps> = ({ className }) => (
  <ul className={cn(s.list, className)}>
    <li className={s.listItem}>
      <a
        href="https://instagram.com/smallable_store/"
        className={s.link}
        title="Instagram"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Instagram />
      </a>
    </li>
    <li className={s.listItem}>
      <a
        href="https://www.pinterest.com/smallable/"
        className={s.link}
        title="Pinterest"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Pinterest />
      </a>
    </li>
    <li className={s.listItem}>
      <a
        href="https://www.facebook.com/smallable/"
        className={s.link}
        title="Facebook"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Facebook />
      </a>
    </li>
    <li className={s.listItem}>
      <a
        href="https://twitter.com/smallable"
        className={s.link}
        title="Twitter"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Twitter />
      </a>
    </li>
  </ul>
)

export default SocialLinks
