import toSlug from '@lib/toSlug'

const productUrl = ({
  attachColor = true,
  brand,
  color,
  id,
  name,
  locale,
}: {
  attachColor?: boolean
  brand: ProductBrand
  color?: string
  id: number | string
  name: string
  locale?: string
}) =>
  `${locale ? `/${locale}` : ''}/product/${toSlug(name)}${
    attachColor && color ? `-${toSlug(color)}` : ''
  }-${brand.slug}-${id}`

export const rewriteLegacyUrl = (legacyUrl: string): string | undefined => {
  const matches = /([\w\-]+).html/g.exec(legacyUrl)
  return matches && matches[1] ? `/product/${matches[1]}` : undefined
}

export default productUrl
