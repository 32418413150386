const YoutubePlayer = ({ ...props }) => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <path
      d="M31.5178 9.09533C31.3495 8.42299 31.0067 7.80697 30.5242 7.30948C30.0416 6.812 29.4363 6.45067 28.7694 6.26199C26.3328 5.66699 16.5861 5.66699 16.5861 5.66699C16.5861 5.66699 6.83943 5.66699 4.40276 6.31866C3.73586 6.50734 3.13056 6.86867 2.648 7.36615C2.16544 7.86364 1.82271 8.47966 1.65443 9.15199C1.20848 11.6249 0.990347 14.1334 1.00276 16.6462C0.986867 19.1778 1.20502 21.7055 1.65443 24.197C1.83996 24.8484 2.19036 25.441 2.6718 25.9175C3.15324 26.394 3.74943 26.7382 4.40276 26.917C6.83943 27.5687 16.5861 27.5687 16.5861 27.5687C16.5861 27.5687 26.3328 27.5687 28.7694 26.917C29.4363 26.7283 30.0416 26.367 30.5242 25.8695C31.0067 25.372 31.3495 24.756 31.5178 24.0837C31.9603 21.6294 32.1784 19.14 32.1694 16.6462C32.1853 14.1145 31.9672 11.5868 31.5178 9.09533V9.09533Z"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13 21.2787L21.1458 16.6462L13 12.0137V21.2787Z" fill="#292929" />
  </svg>
)

export default YoutubePlayer
