import React, { FC, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import LookZoomIn from './LookZoomIn'

import { fetchProductsList } from '@mw/products'
import qs from 'query-string'

import type { ShopTheLookProps } from '../ShopTheLook'

import cn from 'classnames'
import s from './ShopTheLook1.module.css'

const ShopTheLookV1: FC<ShopTheLookProps> = ({
  title,
  media,
  lookPoints,
  isInListing = false,
}) => {
  const { locale } = useRouter()
  const screen = useScreen()

  const [productsData, setProductsData] = useState<ProductListItem[]>([])
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const alreadyFetch = useRef<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (!screen) return null
      try {
        const productIdList = lookPoints.map((lookPoint) => lookPoint.productId)
        const idsString = qs.stringify(
          { id: productIdList },
          {
            arrayFormat: 'bracket',
          }
        )
        if (idsString?.length && !alreadyFetch.current) {
          const repProducts = await fetchProductsList({
            locale: locale as string,
            query: idsString,
          })
          if (repProducts?.products?.length)
            setProductsData(repProducts.products)
          alreadyFetch.current = true
        }
        setIsMobile([Screen.xs, Screen.sm, Screen.md].includes(screen))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [screen])

  if (
    !screen ||
    [Screen.xs, Screen.sm, Screen.md].includes(screen) ||
    !title?.length ||
    !media?.formats?.origin?.length ||
    !lookPoints?.length
  )
    return null

  return (
    <section
      className={cn('shop_the_look', s.container, {
        [s.isInListing]: isInListing,
      })}
    >
      {!title?.length ? <h2 className={s.title}>{title}</h2> : null}
      <picture className={s.mediaContainer}>
        <source
          media="(min-width: 768px)"
          srcSet={`
                ${media.formats.large} 2x,
                ${media.formats.large}
              `}
        />
        <source srcSet={media.formats.large} />
        <img src={media.formats.large} alt={title} />
      </picture>
      {lookPoints.map((point, i) => {
        const productAssociate =
          productsData.find(
            (product) => String(product.id) === String(point.productId)
          ) || null
        if (!productAssociate) return null
        return (
          <React.Fragment key={`Look-point-${point.productId}-${i}`}>
            <LookZoomIn
              lookPoint={point}
              product={productAssociate}
              linkOnly={isMobile}
            />
          </React.Fragment>
        )
      })}
    </section>
  )
}

export default ShopTheLookV1
