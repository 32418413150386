import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { getDiscountName } from '@utils/prices'

import cn from 'classnames'
import s from './ProductTags.module.css'

export interface ProductTagsProps {
  tags?: {
    isCharitable?: boolean
    isExclusive?: boolean
    isGreenable?: boolean
    isNew?: boolean
  }
  isATwoTagsCard?: boolean
  discountType?: any
  layout?: string
}

const tagsDef = [
  {
    key: 'isCharitable',
    name: 'charitable',
    position: 2,
    style: s.charitable,
  },
  {
    key: 'isExclusive',
    name: 'exclusive',
    position: 1,
    style: s.exclu,
  },
  {
    key: 'isGreenable',
    name: 'greenable',
    position: 2,
    style: s.greenable,
  },
  {
    key: 'isNew',
    name: 'new',
    position: 1,
    style: s.new,
  },
  {
    discount: true,
    key: 'sale',
    name: 'discount.sale',
    position: 1,
    style: s.offer,
  },
  {
    discount: true,
    key: 'promotion',
    name: 'discount.promotion',
    position: 1,
    style: s.offer,
  },
  {
    discount: true,
    key: 'outlet',
    name: 'discount.outlet',
    position: 1,
    style: s.offer,
  },
]

const ProductTags: FC<ProductTagsProps> = ({
  isATwoTagsCard,
  discountType,
  tags,
  layout,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  const tagsArr = new Map(Object.entries(tags))
  const currentTags = []
  let sum = 0
  for (let i = 0; i < tagsDef.length; i++) {
    if (
      tagsArr.get(tagsDef[i].key) ||
      getDiscountName(discountType) === tagsDef[i].key
    ) {
      if (!isATwoTagsCard) {
        currentTags.push(tagsDef[i])
        break
      } else {
        if (
          sum === 0 ||
          (sum === 1 && tagsDef[i].position === 2) ||
          (sum === 2 && tagsDef[i].position === 1)
        ) {
          currentTags.push(tagsDef[i])
          sum = tagsDef[i].position
        }
        if (currentTags.length === 2) break
      }
    }
  }
  currentTags.sort((a, b) => a.position - b.position)

  return (
    <div className={s.container} onClick={(e) => e.preventDefault()}>
      {currentTags?.map((tag, i) => (
        <React.Fragment key={`discount-tag-${tag.name}-${i}`}>
          <div className={cn(s.tag, tag.style)}>
            {tag.discount
              ? t(`product:${tag.name}`)
              : t(`product:${tag.name}.title`)}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

export default ProductTags
