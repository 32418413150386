import { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'

import { Arrow } from '@components/ui'

import { sortCountriesAlphabetically } from '@utils/countries'
import ReactCountryFlag from 'react-country-flag'

import cn from 'classnames'
import s from './CountrySelector.module.css'

export interface CountrySelectorProps {
  className?: string
  countries?: Array<Country>
}

const CountrySelector: FC<CountrySelectorProps> = ({
  className,
  countries,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { deliveryCountryCode, selectDeliveryCountry } = useUserSettings()

  const getCountryNameFromCode = (countryCode: string | undefined) => {
    const country = countries?.find(
      (country) => country.isoCode === countryCode
    )

    return country?.name
  }

  const handleSelectValue = (value: string) => {
    selectDeliveryCountry(value, locale)
  }

  return (
    <div className={cn(s.selectorContainer, className)}>
      <div
        className={s.valueSelect}
        aria-label={t('layout:header.country.countryDestination', {
          country: getCountryNameFromCode(deliveryCountryCode) || '',
        })}
      >
        <span className={s.value}>
          {getCountryNameFromCode(deliveryCountryCode)}
        </span>
        <ReactCountryFlag
          className={s.flag}
          countryCode={deliveryCountryCode}
          width="1.125rem"
          height="0.875rem"
          style={{
            height: '.875rem',
            width: 'auto',
            fontSize: '1rem',
            lineHeight: '1rem',
          }}
          alt={`flag-${deliveryCountryCode}`}
          svg
        />
        <Arrow
          width="8"
          height="8"
          stroke="white"
          className={s.arrowDown}
          aria-hidden
        />
      </div>
      <select
        className={s.selectField}
        onChange={(e) => handleSelectValue(e.target.value)}
        value={deliveryCountryCode}
        aria-label={t('layout:header.country.ariaLabel')}
      >
        {sortCountriesAlphabetically(countries)?.map((country) => (
          <option value={country.isoCode} key={country.id}>
            {country.isoCode === deliveryCountryCode
              ? t('layout:header.country.countryDestination', {
                  country: country.name,
                })
              : country.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CountrySelector
