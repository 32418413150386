import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import { MODALS } from '@constants'

import { Container, Link, Logo } from '@components/ui'
import UserSettings from '@components/common/Header/UserSettings'
import Banner from '@components/common/Header/Banner'
import NavigationButton from '@components/common/Header/NavigationButton'
import Search from '@components/common/Header/Search'
import UserNav from '@components/common/Header/UserNav'
import { changeLocaleIfTranslatable } from '@utils/locale'

import { SearchIconLight } from '@components/icons/Search'
import { Cross, Heart } from '@components/icons'

import cn from 'classnames'
import s from './Header.module.css'

export interface HeaderProps {
  className?: string
  alternates?: Array<Alternate>
  locale: string
  countries?: Array<Country>
  currencies: Array<Currency>
}

const Header: FC<HeaderProps> = ({
  className,
  alternates,
  locale,
  countries,
  currencies,
}) => {
  const { t } = useTranslation()
  const { setModalView, openModal } = useUI()
  const router = useRouter()
  const screen = useScreen()

  const [isSearchInHeader, setIsSearchInHeader] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isHomePage, setIsHomePage] = useState(true)

  const openModalSearch = (e, clear: boolean = false) => {
    e.preventDefault()
    const clearInputProps = clear || !inputValue?.length
    clear && setInputValue('')
    setModalView(MODALS.SEARCH, clearInputProps)
    openModal()
  }

  useEffect(() => {
    window?.addEventListener('scroll', () => {
      if (window?.scrollY > 30) {
        setIsSearchInHeader(true)
      } else {
        setIsSearchInHeader(false)
      }
    })
    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, [])

  useEffect(() => {
    if (router?.pathname !== '/search') setInputValue('')
    else {
      const currentQuery = router?.query?.q || null
      if (!currentQuery) return
      setInputValue(currentQuery as string)
    }
  }, [router])

  useEffect(() => {
    if (router?.asPath === '/') setIsHomePage(true)
    else setIsHomePage(false)
  }, [router])

  return (
    <div
      className={cn(s.root, className, {
        [s.expand]: !isSearchInHeader && !isHomePage,
      })}
    >
      <header className={s.header}>
        <Banner className={s.banner} />
        <Container className={s.container}>
          <div className={s.headerLeft}>
            <NavigationButton className={s.burgerMenu} />
            <UserSettings
              locale={locale}
              alternates={alternates}
              countries={countries}
              currencies={currencies}
            />
            {typeof screen === 'undefined' ||
              ([Screen.xs, Screen.sm, Screen.md].includes(screen) && (
                <Link
                  href={t('layout:header.links.wishList.href')}
                  next
                  rel="nofollow"
                  aria-label={t('layout:header.links.wishList.title')}
                >
                  <div className={s.heart}>
                    <Heart size="1.5rem" />
                  </div>
                </Link>
              ))}
          </div>
          {[Screen.xs, Screen.sm, Screen.md].includes(screen) && (
            <>
              <div
                className={cn(s.inputContainer, {
                  [s.searchInHeader]: isSearchInHeader,
                  [s.searchUnderHeader]: !isSearchInHeader,
                  [s.opacity]: isHomePage,
                })}
              >
                <div className={s.inputBox} onClick={(e) => openModalSearch(e)}>
                  <div className={s.loupe}>
                    <SearchIconLight
                      size={isSearchInHeader ? '24' : '16'}
                      color="#545454"
                    />
                  </div>

                  <input
                    className={s.input}
                    id="searchBar"
                    placeholder={t('layout:header.search.ariaSearch')}
                    value={inputValue || ''}
                  />
                  {inputValue && inputValue.length > 0 && (
                    <div
                      className={s.clearCross}
                      onClick={(e) => {
                        e.stopPropagation()
                        openModalSearch(e, true)
                      }}
                    >
                      <Cross stroke="#545454" />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <Link
            href={`/${changeLocaleIfTranslatable(locale)}`}
            next
            aria-label={t('layout:header.ariaGoToHome')}
          >
            <div
              className={cn(s.headerCenter, {
                [s.displayNone]:
                  isSearchInHeader &&
                  [Screen.xs, Screen.sm, Screen.md].includes(screen),
              })}
            >
              <Logo className={s.logo} fill="#000" />
              <p className={s.catchPhrase}>{t('layout:header.catchPhrase')}</p>
            </div>
          </Link>
          <div className={s.headerRight}>
            {![Screen.xs, Screen.sm, Screen.md].includes(screen) && (
              <Search className={s.searchBar} />
            )}
            <UserNav />
          </div>
        </Container>
      </header>
    </div>
  )
}

export default Header
