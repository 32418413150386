import React from 'react'

const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12C2 17.014 5.6895 21.1655 10.502 21.8885C10.9905 21.962 11.491 20.5 12 20.5C12.4595 20.5 12.912 21.969 13.3555 21.909C18.2375 21.2475 22 17.0635 22 12C22 6.477 17.523 2 12 2Z"
        fill="#3579EA"
      />
      <path
        opacity="0.05"
        d="M13.8535 10.5845C13.8535 9.87246 14.006 9.02396 14.732 9.02396H16.8735L16.873 6.21546L16.848 5.78946L16.425 5.73246C16.121 5.69146 15.4885 5.60596 14.322 5.60596C11.5375 5.60596 10.004 7.26346 10.004 10.273V11.522H7.53003V15.151H10.004V21.8C10.649 21.9305 11.3165 22 12 22C12.634 22 13.252 21.9345 13.8535 21.8215V15.1505H16.37L16.931 11.5215H13.8535V10.5845Z"
        fill="#121212"
      />
      <path
        opacity="0.05"
        d="M13.6035 10.5845C13.6035 9.90796 13.75 8.77396 14.732 8.77396H16.6235V6.22996L16.6105 6.00996L16.394 5.98046C16.0955 5.93996 15.475 5.85596 14.3225 5.85596C11.661 5.85596 10.2545 7.38346 10.2545 10.273V11.772H7.78003V14.901H10.254V21.838C10.822 21.938 11.403 22 12 22C12.547 22 13.08 21.944 13.6035 21.8595V14.9005H16.1555L16.639 11.7715H13.603V10.5845H13.6035Z"
        fill="#121212"
      />
      <path
        d="M13.3535 14.6505H15.9415L16.348 12.0215H13.3535V10.5845C13.3535 9.49246 13.7105 8.52396 14.732 8.52396H16.3735V6.22996C16.085 6.19096 15.475 6.10596 14.3225 6.10596C11.9155 6.10596 10.5045 7.37696 10.5045 10.273V12.022H8.03003V14.651H10.504V21.8885C10.994 21.9615 11.4905 22 12 22C12.4605 22 12.91 21.969 13.3535 21.909V14.6505Z"
        fill="white"
      />
    </svg>
  )
}

export default Facebook
