export const sortFilters = (filters: ProductsListFilters) => {
  if (typeof filters === 'object')
    return Object.entries(filters).sort((a, b) =>
      a[1].config.rank > b[1].config.rank
        ? 1
        : a[1].config.rank === b[1].config.rank
        ? a[0] > b[0]
          ? 1
          : a[0] < b[0]
          ? -1
          : 1
        : -1
    )
  return null
}

export const findSelectedChildren: (
  filters: FilterValue[],
  query: string[],
  toFind: string
) => any = (filters, query, toFind) => {
  let result = [] as Array<string>
  const children = filters?.filter((f) => f.parentSlug === toFind && f.selected)
  if (children?.length) {
    for (let i = 0; i < children.length; i++) {
      let matchingChildren = query.filter((q) => q === children[i].key)
      if (matchingChildren?.length) result = result.concat(matchingChildren)
      else
        result = result.concat(
          findSelectedChildren(filters, query, children[i].key)
        )
    }
  }
  return result
}

export const updateFiltersQuery = ({
  source,
  filters,
  selectedValue,
  parent,
  parentSlug,
  isSelected,
  toReplace,
  levelDepth,
}: {
  source: { [key: string]: any }
  filters: ProductsListFilters
  selectedValue: string
  parent: string
  parentSlug?: string
  isSelected?: boolean
  toReplace?: boolean
  levelDepth?: number
}) => {
  const s = { ...source }
  const newKey = `_${parent}`

  //REPLACE PARAMS
  if (toReplace) return { ...s, [newKey]: [selectedValue] }
  //REMOVE PARAMS
  else if (isSelected) {
    //IF SELECTED VALUE HAS CHILDREN
    if (parentSlug) {
      if (!Object.values(s[newKey]).includes(selectedValue)) {
        //IF SEVERAL LEVELS ARE REMOVE
        const activeChildren = findSelectedChildren(
          filters?.[parent]?.values,
          s[newKey],
          selectedValue
        )
        //REMOVE ACTIVE CHILDREN
        if (activeChildren)
          s[newKey] = s[newKey].filter(
            (value: string) => !activeChildren.includes(value)
          )
      } else {
        //REMOVE CURRENT LEVEL
        s[newKey] = s[newKey].filter((value: string) => value !== selectedValue)
      }
      const activeSibling = filters?.[parent]?.values.some(
        (filter) =>
          filter.parentSlug === parentSlug &&
          filter.key !== selectedValue &&
          filter.selected
      )
      if (levelDepth === 1 || activeSibling) return s
      return {
        ...s,
        [newKey]: s[newKey] ? [...s[newKey], parentSlug] : [parentSlug],
      }
    } else {
      //IF SELECTED VALUE HAS NO CHILDREN
      if (s[newKey]?.length === 1) delete s[newKey]
      else
        s[newKey] = s[newKey].filter((value: string) => value !== selectedValue)
      return s
    }
  } else {
    //ADD PARAMS
    //IF SELECTED VALUE IS A CHILD REMOVE HIS PARENT
    if (parentSlug && s[newKey])
      s[newKey] = s[newKey].filter((value: string) => value !== parentSlug)
    //ADD SELECTED VALUE
    return {
      ...s,
      [newKey]: s[newKey] ? [...s[newKey], selectedValue] : [selectedValue],
    }
  }
}
