const DisplayMulti = ({ ...props }) => (
  <svg {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M11 2H2V11H11V2Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 2H13V11H22V2Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 13H13V22H22V13Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 13H2V22H11V13Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DisplayMulti
