const Remote = ({ ...props }) => (
  <svg width="70" height="48" viewBox="0 0 70 48" fill="none" {...props}>
    <path
      d="M61.6438 39.7888H7.25586V6.13477H61.6438V39.7888Z"
      fill="#292929"
    />
    <path
      d="M3.9937 5.39225L3.99316 43.0387L65.1159 43.0387L65.117 5.39227C65.117 2.12157 62.565 1.08927 61.1883 1L7.89513 1.0006C6.54572 1.08986 3.9937 2.12163 3.9937 5.39225Z"
      stroke="#292929"
    />
    <path
      d="M35.3426 4.15189C35.3426 4.63958 34.9884 5.0356 34.5522 5.0356C34.1154 5.0356 33.7617 4.63958 33.7617 4.15189C33.7617 3.6636 34.1154 3.26758 34.5522 3.26758C34.9884 3.26758 35.3426 3.6636 35.3426 4.15189Z"
      fill="#292929"
      stroke="#292929"
      strokeWidth="0.4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.02148 47.9994H3.87771H66.1224H66.9786C67.4588 47.9994 68.1003 47.3397 68.8849 46.0397C69.1464 45.6053 70.0001 44.1272 70.0001 43.3011C70.0001 43.101 69.9535 42.9302 69.8602 42.7942C69.7402 42.6193 69.5591 42.5234 69.3512 42.5234H68.531H1.46912H0.648968C0.441041 42.5234 0.259908 42.6193 0.139866 42.7942C0.046623 42.9302 0 43.101 0 43.3011C0 44.1272 0.853697 45.6053 1.11521 46.0397C1.89983 47.3397 2.54131 47.9994 3.02148 47.9994ZM35.0001 43.55L0.952159 43.5503C1.19171 44.4718 2.55071 46.742 3.05241 46.972H3.87969H66.1204H66.9477C67.4494 46.742 68.8084 44.4718 69.048 43.5503L35.0001 43.55Z"
      fill="#292929"
    />
  </svg>
)

export default Remote
