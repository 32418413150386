import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import {
  fetchProductReviewSummary,
  fetchProductReviewComments,
} from '@mw/reviews'
import { Button } from '@components/ui'
import TrustSummary from '@components/product/TrustReview/TrustSummary'
import TrustComment from '@components/product/TrustReview/TrustComment'

import { MODALS } from '@constants'

import s from './TrustReview.module.css'

interface TrustReviewProps {
  declinations: Declination[]
}

const TrustReview: FC<TrustReviewProps> = ({ declinations }) => {
  const { t } = useTranslation()
  const { setModalView, openModal } = useUI()

  const [reviewData, setReviewData] = useState<{
    summary: ReviewSummary
    comments: ReviewComments
    bestComment: DetailReviewComments
  }>({
    summary: null,
    comments: null,
    bestComment: null,
  })

  useEffect(() => {
    ;(async () => {
      if (!declinations.length) return
      try {
        const sku = Array.from(declinations, (d) => d.sku)?.toString()
        if (!sku?.length) return
        const [repSummary, repComments] = await Promise.allSettled([
          fetchProductReviewSummary({ sku }),
          fetchProductReviewComments({ sku }),
        ])
        if (repSummary.status === 'rejected')
          throw new Error('Product review notation not found')

        let bestComment = null
        if (repComments.status === 'fulfilled') {
          const review = repComments.value?.productReviews
          for (let i = 0; i < review.length; i++) {
            if (review[i].stars >= 4) {
              bestComment = review[i]
              break
            }
          }
        }

        if (repSummary.value.starsAverage >= 4) {
          setReviewData({
            summary: repSummary.value,
            comments:
              repComments.status === 'fulfilled' ? repComments.value : null,
            bestComment,
          })
        }
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  if (!reviewData?.summary || reviewData?.summary?.starsAverage < 4) return null

  return (
    <div className={s.container}>
      <TrustSummary summary={reviewData.summary} />
      {reviewData.bestComment && (
        <>
          <TrustComment comments={reviewData.bestComment} />
          <Button
            className={s.action}
            onClick={() => {
              setModalView(MODALS.REVIEWS, { reviewData })
              openModal()
            }}
          >
            {t('product:review.readAll')}
          </Button>
        </>
      )}
      <p
        className={s.notice}
        onClick={() => {
          setModalView(MODALS.REVIEW_NOTICE)
          openModal()
        }}
      >
        {t('product:review.notice.title')}
      </p>
    </div>
  )
}

export default TrustReview
