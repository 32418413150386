import React, { FC } from 'react'

import dynamic from 'next/dynamic'

const FooterPaymentsMethods = dynamic(() => import('./FooterPaymentsMethods'))

export interface FooterComponentProps {
  component: string
}

const FooterComponent: FC<FooterComponentProps> = ({ component }) => {
  switch (component) {
    case 'FooterPaymentsMethods':
      return <FooterPaymentsMethods />
    default:
      return null
  }
}

export default FooterComponent
