import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { LogoTrustpilot, Star } from '@components/icons'

import s from './TrustSummary.module.css'

interface TrustSummaryProps {
  summary: ReviewSummary
}

const TrustSummary: FC<TrustSummaryProps> = ({ summary }) => {
  const { t } = useTranslation()

  if (!summary) return null

  return (
    <div className={s.summary}>
      <div className={s.evaluation}>
        <p className={s.total}>
          {t('product:review.title')}&nbsp;({summary.numberOfReviews?.total})
        </p>
        <p className={s.average}>
          {summary.starsAverage}
          <span className={s.scale}>/5</span>
        </p>
      </div>
      <div className={s.symbol}>
        <LogoTrustpilot height="20" />
        <div className={s.stars}>
          {Array.from(Array(5).keys()).map((i) => (
            <React.Fragment key={`average-stars-key-${i}`}>
              <Star
                height="20"
                fill={
                  i + 1 <= Math.round(summary.starsAverage)
                    ? '#000'
                    : 'transparent'
                }
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TrustSummary
