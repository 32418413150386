import React, { FC, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useKeenSlider } from 'keen-slider/react'
import useScreen from '@lib/hooks/useScreen'

import HomeLargeBlock from '@components/blocks-cms/HomeLargeBlock'
import { ArrowRight } from '@components/icons'

import 'keen-slider/keen-slider.min.css'
import cn from 'classnames'
import s from './SlideBlock.module.css'
import { useUI } from '@components/ui/context'

export interface SlideBlockProps {
  slides: Array<HomeLargeBlockTypes>
  slidesPerView?: number
  slidesHeight?: number
  isInListing?: boolean
}

const SlideBlock: FC<SlideBlockProps> = ({
  slides,
  slidesPerView,
  slidesHeight,
  isInListing = false,
}) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const { isSiteReverted } = useUI()

  const containerRef = useRef<HTMLDivElement>()
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [pause, setPause] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState(false)

  const _slidesPerView =
    screen === 'xs' || screen === 'sm' || !slidesPerView ? 1 : slidesPerView
  const toSlide = slides?.length > _slidesPerView

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    rtl: isSiteReverted,
    loop: toSlide,
    defaultAnimation: {
      duration: 600,
    },
    slides: { perView: _slidesPerView },
    created: () => setIsMounted(true),
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    dragStarted: () => {
      setPause(true)
    },
    dragEnded: () => {
      setPause(false)
    },
  })

  useEffect(() => {
    let timer = setTimeout(() => {
      if (!pause && slides.length > 0) instanceRef?.current?.next()
    }, 4500)

    return () => {
      clearTimeout(timer)
    }
  }, [instanceRef, currentSlide, pause])

  return (
    <div
      ref={containerRef}
      className={cn('slide_block', s.container, {
        [s.isInListing]: isInListing,
      })}
    >
      <div
        ref={sliderRef}
        className={cn('keen-slider', { [s.jsDisabled]: !isMounted })}
      >
        {slides?.map((slide: HomeLargeBlockTypes, index: number) => (
          <div
            key={`blockContainer-${index}`}
            className={cn('keen-slider__slide', s.blockContainer, {
              [s.jsDisabledBlocks]: !isMounted,
            })}
            data-cs-override-id={`slider_${index + 1}`}
          >
            <HomeLargeBlock
              layout={slide.layout}
              description={slide.description}
              media={slide.media}
              url={slide.url}
              targetBlank={slide.targetBlank}
              slidesHeight={
                !slidesHeight || slidesHeight === 100 ? undefined : slidesHeight
              }
            />
          </div>
        ))}
      </div>
      {isMounted && instanceRef.current && toSlide && (
        <div className={s.control}>
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => (
            <button
              key={idx}
              className={cn(s.dot, { [s.active]: currentSlide === idx })}
              aria-label={`${t('layout:common.ariaNavigate')} slide ${idx + 1}`}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx)
              }}
            >
              <span />
            </button>
          ))}
        </div>
      )}
      <button
        className={cn('slideBlock_scrollTo', s.scrollTo)}
        aria-label={t('block:loadMore.seeNext')}
        onClick={() => {
          window.scrollTo({
            top:
              containerRef.current?.clientHeight +
              (screen === 'xl' || screen === '2xl' ? 154 : 0),
            behavior: 'smooth',
          })
        }}
      >
        <ArrowRight
          width="24"
          height="24"
          className={s.arrowDown}
          aria-hidden
        />
      </button>
    </div>
  )
}

export default SlideBlock
