export const sortValueOnTop = (arr, val) => {
  let _arr = [...arr]
  let bestElem = 0
  let bestValue = 0
  for (var i = 0; i < _arr.length; i++) {
    if (_arr[i][val] > bestValue) {
      bestValue = _arr[i][val]
      bestElem = i
    }
  }
  _arr.unshift(_arr.splice(bestElem, 1)[0])
  return _arr
}

export const arrayToObject = (arr) => {
  if (!arr?.length) return null
  const obj = {}
  for (let i = 0; i < arr.length; i += 2) {
    obj[arr[i]] = arr[i + 1]
  }
  return obj
}
