export enum MODALS {
  ACCOUNT = 'ACCOUNT',
  LOGIN_VIEW = 'LOGIN_VIEW',
  FAQ = 'FAQ',
  NEWSLETTER = 'NEWSLETTER',
  PRODUCT_MEDIA = 'PRODUCT_MEDIA',
  PRODUCT_VIDEO = 'PRODUCT_VIDEO',
  RETURN_REFUND = 'RETURN_REFUND',
  SHOES_SIZE_GUIDE = 'SHOES_SIZE_GUIDE',
  SIZE_GUIDE = 'SIZE_GUIDE',
  NOTIFY_ME = 'NOTIFY_ME',
  NOTIFICATION = 'NOTIFICATION',
  PAGE_POPIN = 'PAGE_POPIN',
  TELESALES = 'TELESALES',
  NAVIGATION_OPTIONS = 'NAVIGATION_OPTIONS',
  SEARCH = 'SEARCH',
  REVIEW_NOTICE = 'REVIEW_NOTICE',
  REVIEWS = 'REVIEWS',
  ADD_TO_BASKET = 'ADD_TO_BASKET',
  PRIVATE_SALES = 'PRIVATE_SALES',
  EQUIVALENCE_LOYALTY = 'EQUIVALENCE_LOYALTY',
  EXIT_LOYALTY = 'EXIT_LOYALTY',
  COUPONS_LOYALTY = 'COUPONS_LOYALTY',
  ALERT_LOYALTY = 'ALERT_LOYALTY',
  RESUBSCRIBE_LOYALTY = 'RESUBSCRIBE_LOYALTY',
  SUBSCRIBE_LOYALTY = 'SUBSCRIBE_LOYALTY',
  LOYALTY_ERROR = 'LOYALTY_ERROR',
  BASKET_ERROR = 'BASKET_ERROR',
}

export enum TOOLTIPS {
  BASKET = 'TOOLTIP_BASKET',
}

export const EMAIL_REGEXP =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/

export const FRANCE_ISO_CODE = 'FR'

export const DEFAULT_COUNTRY = 'FR'

export const DEFAULT_LOCALES = 'fr'

export const LOCALES = [
  {
    id: 1,
    locale: 'en',
  },
  {
    id: 2,
    locale: 'fr',
  },
  {
    id: 3,
    locale: 'es',
  },
  {
    id: 4,
    locale: 'de',
  },
  {
    id: 5,
    locale: 'it',
  },
]

export const BOT_CACHE_CONTROL_TIME =
  process.env.SMALLABLE_BOT_CACHE_CONTROL_TIME || 3600

export const BOT_CACHED_URLS = [
  '/smallable-and-me',
  '/loyalty',
  '/loyalty-register',
]

export const NEWSLETTER_DISCOUNT = {
  EUR: '15€',
  USD: '20$US',
  GBP: '15£GB',
  CHF: '20CHF',
  CNY: '150CNY',
  HKD: '150HKD',
  SGD: '25SGD',
  CAD: '25CAD',
  AUD: '25AUD',
  AED: '100AED',
  SAR: '100SAR',
  QAR: '100QAR',
  KRW: '20000KRW',
  JPY: '2000JPY',
  KWD: '10KWD',
}

// Data comes from smallable2 : table welcomepack
export const NEWSLETTER_MINIMUM_ORDER = {
  EUR: '150€',
  USD: '200$US',
  GBP: '150£GB',
  CHF: '200CHF',
  CNY: '1500CNY',
  HKD: '1500HKD',
  SGD: '250SGD',
  CAD: '250CAD',
  AUD: '250AUD',
  AED: '1000AED',
  SAR: '1000SAR',
  QAR: '1000QAR',
  KRW: '200000KRW',
  JPY: '20000JPY',
  KWD: '100KWD',
}

export const AVAILABLE_LANGUAGES = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  de: 'Deutsch',
  it: 'Italiano',
  ar: 'Arabic',
}

export enum DiscountType {
  Private = 'private',
  Public = 'public',
}

export const ENABLE_TRUSTPILOT = false

export const ENABLE_VP_MODAL = true

export const NUMBER_PAGE_MODAL_NL = 50
export const URL_STATIC_SMALLABLE = '//static.smallable.com/'
export const URL_STATIC_V3_SMALLABLE = '//staticv3.smallable.com/'
