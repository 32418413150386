import { FC, useEffect, useRef, useState } from 'react'
import router, { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import useStats, { UseStatsProps } from '@lib/hooks/useStats'
import useTranslation from 'next-translate/useTranslation'

import ClearRefinements from '@components/common/Algolia/ClearRefinements'
import RefinementButtonCheckbox from '@components/common/Algolia/RefinementButtonCheckbox'
import RefinementButtonSizeAndStock from '@components/common/Algolia/RefinementButtonSizeAndStock'
import RefinementCheckbox from '@components/common/Algolia/RefinementCheckbox'
import RefinementHierarchie from '@components/common/Algolia/RefinementHierarchie'
import RefinementRangeInput from '@components/common/Algolia/RefinementRangeInput'
import RefinementToggleButton from '@components/common/Algolia/RefinementToggleButton'
import SortMenuAlgolia from '@components/blocks-cms/ProductListingLayout/SortMenuAlgolia'
import SortMenuMobileAlgolia from '@components/blocks-cms/ProductListingLayout/SortMenuMobileAlgolia'
import { TagSearch } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'
import NoResults from '@components/common/Header/Search/Algolia/Stats/NoResults'
import ProductsGridAlgolia from '@components/product/ProductsGridAlgolia'
import { LoadingDots } from '@components/ui'

import {
  DESKTOP_LISTING_PRODUCTS_PER_LINE,
  MOBILE_LISTING_PRODUCTS_PER_LINE,
} from '@constants'

import {
  ages,
  brandName,
  closingType,
  collections,
  colorGroup,
  discountsAll,
  hasCookieInHits,
  isGreenable,
  isInStock,
  isOutlet,
  lowestPrice,
  lowestPriceVP,
  sexs,
  shoeSizeGroups,
  shoeSizeSizeAndStock,
  sizeGroups,
  sizeSizeAndStock,
  types0,
  types1,
  types2,
  types3,
} from '@lib/lib-algolia/algolia'
import { restoreScrollManually } from '@lib/scrollRestoration/scrollRestoration'
import { scrollToTop } from '@utils/scrollTo'

import {
  DynamicWidgets,
  useCurrentRefinements,
  UseCurrentRefinementsProps,
  useHits,
  UseHitsProps,
  usePagination,
  UsePaginationProps,
  useSearchBox,
  UseSearchBoxProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './ProductListingLayout.module.css'

export interface QueryAlgoliaProps {
  querySearch?: string
  pageParams?: { [key: string]: string }
  isAlgoliaListing?: boolean
}

export type ProductListingLayoutProps = UseSearchBoxProps &
  UseCurrentRefinementsProps &
  UseHitsProps &
  QueryAlgoliaProps &
  UseStatsProps &
  UsePaginationProps

const ProductListingLayout: FC<ProductListingLayoutProps> = (props) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const { querySearch } = props
  const { q } = props.isAlgoliaListing ? { q: querySearch } : useRouter().query // to avoid useRouter being undefined in SSR
  const { deliveryCountryCode } = useUserSettings()

  const { query, refine } = useSearchBox(props)
  const { hits } = useHits(props)
  const { nbHits, processingTimeMS } = useStats()
  const { items } = useCurrentRefinements(props)
  const { currentRefinement, nbPages } = usePagination(props)

  const [hasCookie, setHasCookie] = useState(false)

  const isLoaded = useRef(false)
  const isRender = useRef(false)

  const navigationBar = useRef() as React.MutableRefObject<HTMLDivElement>

  const productsPerListing = Number(
    process.env.NEXT_PUBLIC_PRODUCTS_PER_LISTING
  )

  const [openFilters, setOpenFilters] = useState(true)
  const [productsPerLine, setProductsPerLine] = useState(
    [Screen.xs, Screen.sm, Screen.md].includes(screen)
      ? MOBILE_LISTING_PRODUCTS_PER_LINE
      : DESKTOP_LISTING_PRODUCTS_PER_LINE
  )

  const [value, setValue] = useState(query || querySearch)

  const [refinementIsInStock, setRefinementIsInStock] = useState(false)

  useEffect(() => {
    if (!hits?.length) return null
    const _hasCookie = hasCookieInHits(hits)
    setHasCookie(_hasCookie)
  }, [])

  useEffect(() => {
    setRefinementIsInStock(
      items.some((item) =>
        item.refinements.find(
          (refinement) =>
            refinement.attribute === isInStock && refinement.value === 'true'
        )
      )
    )
  }, [items])

  useEffect(() => {
    if (processingTimeMS) {
      if (isLoaded.current) {
        scrollToTop(
          navigationBar.current,
          screen && ![Screen.xs, Screen.sm, Screen.md].includes(screen)
            ? 0
            : -62
        )
      } else {
        isLoaded.current = true
        restoreScrollManually()
      }
    }
  }, [processingTimeMS])

  useEffect(() => {
    if (q !== query) {
      if (Array.isArray(q)) {
        setValue(q[0])
      } else {
        setValue(q)
      }
    }
  }, [q])

  useEffect(() => {
    if (refine && value && query !== value) refine(value)
  }, [value])

  useEffect(() => {
    if (query !== value) setValue(query)
  }, [query])

  useEffect(() => {
    if ([Screen.xs, Screen.sm, Screen.md].includes(screen)) {
      const userDisplayChoice = window?.localStorage?.getItem('display_listing')
      setProductsPerLine(
        userDisplayChoice
          ? Number(userDisplayChoice)
          : MOBILE_LISTING_PRODUCTS_PER_LINE
      )
      setOpenFilters(false)
    } else {
      setProductsPerLine(DESKTOP_LISTING_PRODUCTS_PER_LINE)
      setOpenFilters(true)
    }
  }, [screen])

  useEffect(() => {
    if (isRender.current) router.reload()
  }, [deliveryCountryCode])

  useEffect(() => {
    isRender.current = true
  }, [])

  if (!processingTimeMS)
    return (
      <section className={s.container}>
        <div className={s.content}>
          <LoadingDots />
        </div>
      </section>
    )

  return (
    <>
      <>
        <TagSearch
          searchEngine="ALGOLIA"
          searchResults={`${nbHits}`}
          searchKey={querySearch}
        />
        <TagPageCategory pageCategory="catalogue" levelOne="recherche" />
      </>

      <section
        ref={navigationBar}
        id="products-listing"
        className={s.container}
      >
        {value && ![Screen.xs, Screen.sm, Screen.md].includes(screen) && (
          <div className={s.header}>
            {t('layout:header.search.searchResultsFor')}{' '}
            <span className={s.query}>{value}</span>
          </div>
        )}

        <div className={s.content}>
          {[Screen.xs, Screen.sm, Screen.md].includes(screen) ? (
            <SortMenuMobileAlgolia
              openFilters={openFilters}
              setOpenFilters={setOpenFilters}
              productsPerLine={productsPerLine}
              setProductsPerLine={setProductsPerLine}
              navigationBar={navigationBar}
              isVP={hasCookie}
            />
          ) : (
            <SortMenuAlgolia
              openFilters={openFilters}
              setOpenFilters={setOpenFilters}
              total={nbHits}
              isVP={hasCookie}
            />
          )}
          <div className={s.row}>
            {openFilters &&
              [Screen.xs, Screen.sm, Screen.md].includes(screen) && (
                <div
                  className={s.layout}
                  onClick={() => setOpenFilters(!openFilters)}
                />
              )}

            <div className={cn(s.filters, { [s.open]: openFilters })}>
              <div className={s.filtersContainer}>
                <div className={s.filtersList}>
                  {/* to force re-render and get facets ready */}
                  <DynamicWidgets key={isLoaded?.current?.toString()}>
                    <RefinementToggleButton
                      attribute={isInStock}
                      title={t('block:filter.inStock')}
                    />
                    <RefinementToggleButton
                      attribute={isOutlet}
                      title={t('block:filter.outlet')}
                    />
                    <RefinementHierarchie
                      title={t('block:filter.typeProduct')}
                      attributes={[types0, types1, types2, types3]}
                    />
                    <RefinementCheckbox
                      title={t('block:brand.brand')}
                      attribute={brandName}
                      limit={999}
                      searchable={true}
                    />
                    <RefinementCheckbox
                      attribute={sexs}
                      title={t('block:filter.sex')}
                    />
                    <RefinementCheckbox
                      attribute={colorGroup}
                      title={t('block:filter.color')}
                    />
                    <RefinementButtonSizeAndStock
                      className={cn({ ['hidden']: !refinementIsInStock })}
                      title={t('block:filter.size')}
                      attribute={sizeSizeAndStock}
                      limit={999}
                      sortSize={true}
                    />
                    <RefinementButtonSizeAndStock
                      className={cn({ ['hidden']: !refinementIsInStock })}
                      title={t('block:filter.shoeSize')}
                      attribute={shoeSizeSizeAndStock}
                      limit={999}
                      sortBy={['name:asc']}
                    />
                    <RefinementButtonCheckbox
                      className={cn({ ['hidden']: refinementIsInStock })}
                      title={t('block:filter.size')}
                      attribute={sizeGroups}
                      limit={999}
                      sortSize={true}
                    />
                    <RefinementButtonCheckbox
                      className={cn({ ['hidden']: refinementIsInStock })}
                      title={t('block:filter.shoeSize')}
                      attribute={shoeSizeGroups}
                      limit={999}
                      sortBy={['name:asc']}
                    />
                    <RefinementRangeInput
                      title={t('block:filter.ages')}
                      attribute={ages}
                    />
                    <RefinementCheckbox
                      title={t('block:filter.closingType')}
                      attribute={closingType}
                      limit={999}
                    />
                    <RefinementRangeInput
                      attribute={
                        hasCookie
                          ? lowestPriceVP(deliveryCountryCode)
                          : lowestPrice(deliveryCountryCode)
                      }
                      title={t('block:filter.price')}
                      isCurrency={true}
                    />
                    <RefinementCheckbox
                      attribute={discountsAll}
                      title={t('block:filter.discount')}
                      unity={' %'}
                    />
                    <RefinementCheckbox
                      attribute={collections}
                      title={t('block:filter.collection')}
                    />
                    <RefinementToggleButton
                      attribute={isGreenable}
                      title={t('product:greenable.title')}
                    />
                  </DynamicWidgets>
                </div>
                <div className={s.filterAction}>
                  <ClearRefinements />
                  <div className={s.separatorAction} aria-hidden></div>
                  <button
                    className={s.buttonAction}
                    onClick={() => setOpenFilters(false)}
                  >
                    {t('block:filter.applyFilters', { filterText: nbHits })}
                  </button>
                </div>
              </div>
            </div>

            <div className={s.cards}>
              <div className={cn({ ['hidden']: hits?.length || nbHits !== 0 })}>
                <NoResults query={query} layout="listing" />
              </div>
              <ProductsGridAlgolia
                currentPage={currentRefinement + 1}
                nbPages={nbPages}
                products={hits}
                productsTotal={nbHits}
                productsParameters={null}
                productsPerLine={productsPerLine}
                chunk={productsPerListing}
                layout="listing"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductListingLayout
