import { FC, useEffect } from 'react'

import cn from 'classnames'
import { useRouter } from 'next/router'
import s from './ProductSiblingSelector.module.css'
import { DropdownSelect } from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import productUrl from '@utils/url/product'
import { changeLocaleIfTranslatable } from '@utils/locale'

export interface ProductSiblingSelectorProps {
  id: number
  className?: string
  name: string
  brand: ProductBrand
  expanded: boolean
  onExpand: (expanded: boolean) => void
  siblings: Array<Sibling>
  selectedSibling: number
  onSiblingSelected: (value: number) => void
}

const SiblingOption: FC<Sibling> = ({ color }) => {
  return <span className={s.textSelected}>{color}</span>
}

const renderSelectedOption =
  (siblings: Array<Sibling>) => (sibling?: Sibling) => {
    const { t } = useTranslation()
    const count = siblings?.length - 1
    const msg =
      count > 1
        ? t('product:siblingSelector.moreColors', { nbr: count })
        : t('product:siblingSelector.moreColor', { nbr: count })
    return (
      <div className={s.placeholderContainer}>
        <span className={s.textSelected}>{sibling?.color}</span>
        <span className={s.countColors}>{msg}</span>
      </div>
    )
  }

const getOptionValue = (declination: Sibling) => declination.id

const ProductSiblingSelector: FC<ProductSiblingSelectorProps> = ({
  id,
  className,
  name,
  brand,
  expanded,
  onExpand,
  siblings = [],
  selectedSibling,
  onSiblingSelected,
}) => {
  const { locale, ...router } = useRouter()
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedSibling !== id) {
      const sibling = siblings?.find(
        (sibling) => sibling?.id === selectedSibling
      )

      router.push(
        productUrl({
          attachColor: sibling?.attachColor,
          brand,
          color: sibling?.color,
          id: selectedSibling,
          name,
        }),
        undefined,
        {
          locale: changeLocaleIfTranslatable(locale),
        }
      )
    }
  }, [selectedSibling])

  if (siblings?.length === 1) {
    return (
      <div className={cn(s.singleColorContainer, className)}>
        <span className={s.singleColor}>{siblings[0]?.color} </span>
        <span className={s.label}>
          {t('product:siblingSelector.singleColor')}
        </span>
      </div>
    )
  }

  return (
    <DropdownSelect
      className={cn(s.customSelectContainer, className)}
      expanded={expanded}
      getOptionValue={getOptionValue}
      id="siblingSelector"
      onChange={onSiblingSelected}
      options={siblings}
      renderOption={SiblingOption}
      renderSelectedOption={renderSelectedOption(siblings)}
      setExpanded={onExpand}
      title={t('product:siblingSelector.title')}
      value={selectedSibling}
    />
  )
}

export default ProductSiblingSelector
