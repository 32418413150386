export const deleteItemFromBasket = async ({
  sku,
  locale,
  quantity,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  sku: string
  quantity?: number
  locale: string
  apiUrl?: string
}): Promise<Basket> => {
  const res = await fetch(`${apiUrl}/v1/basket`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, locale, quantity }),
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const addItemToBasket = async ({
  sku,
  locale,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  sku: string
  locale: string
  apiUrl?: string
}): Promise<Basket> => {
  const res = await fetch(`${apiUrl}/v1/basket`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, locale }),
  })
  if (!res?.ok) {
    const { messageKey } = await res.json()
    throw messageKey
  }
  const { data } = await res.json()
  return data
}
