import React, { FC } from 'react'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import { getValueFromHtmlText } from '@utils/string'

import cn from 'classnames'
import s from './LandingItem.module.css'

export interface LandingItemProps {
  url: string
  title: string
  media?: BlocksMedia
}

const LandingItem: FC<LandingItemProps> = ({ title, media, url }) => {
  const alt = getValueFromHtmlText(title, 'cms-home-title')
  return (
    <div className={cn('landing_item', s.container)}>
      <BlockLinkWrapper title={title} url={url}>
        {media?.formats?.origin && (
          <div className={s.media}>
            <picture className={s.media}>
              <source
                media="(min-width: 768px)"
                srcSet={`
                ${media.formats.large} 2x,
                ${media.formats.origin}
              `}
              />
              <source srcSet={media.formats.small} />
              <img
                className={cn({
                  ['zoomOnHover']: url,
                })}
                src={media.formats.origin}
                alt={alt}
              />
            </picture>
          </div>
        )}
        <RichText content={title} />
      </BlockLinkWrapper>
    </div>
  )
}

export default LandingItem
