import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { SortByAlgolia } from '.'
import { Filters } from '@components/icons'
import { useUserSettings } from '@context/session'
import CurrentRefinement from './CurrentRefinement'

import cn from 'classnames'
import s from './SortMenuAlgolia.module.css'

export interface SortMenuProps {
  openFilters: boolean
  setOpenFilters: (openFilters: boolean) => void
  total: number
  layout?: string
  isVP?: boolean
}

const SortMenuAlgolia: FC<SortMenuProps> = ({
  openFilters,
  setOpenFilters,
  total,
  layout,
  isVP = false,
}) => {
  const { t } = useTranslation()
  const { deliveryCountryCode } = useUserSettings()
  const { locale } = useRouter()

  return (
    <div className={cn(s.container, { [s.layoutBottom]: layout === 'bottom' })}>
      <div className={s.colFilters}>
        <button
          className={s.toggleFilters}
          onClick={() => setOpenFilters(!openFilters)}
        >
          <Filters
            width={20}
            height={20}
            fill="#000"
            className={s.icon}
            aria-hidden
          />
          {!openFilters
            ? t('block:filter.ariaShowFilter')
            : t('block:filter.ariaCloseFilter')}
        </button>
        <div className={s.separator}>|</div>
        <p className={s.total}>
          {t('block:nbProductsResults', {
            nbProducts: total,
          })}
        </p>
      </div>

      <CurrentRefinement />

      <div className={s.colSort}>
        <div className={s.selectorContainer}>
          <SortByAlgolia
            deliveryCountryCode={deliveryCountryCode}
            locale={locale}
            isVP={isVP}
            items={[
              {
                label: t('block:sort.pertinence'),
                value: `pertinence`,
              },
              {
                label: t('block:sort.newArrivals'),
                value: `activationDate:desc`,
              },
              {
                label: t('block:sort.stock'),
                value: `stock:desc`,
              },
              {
                label: t('block:sort.priceAsc'),
                value: `price:asc`,
              },
              {
                label: t('block:sort.priceDesc'),
                value: `price:desc`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default SortMenuAlgolia
