const Basket = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4.34326 6.5H19.6567C19.9156 6.5 20.1317 6.69765 20.1548 6.95553L21.4048 20.9555C21.4309 21.2481 21.2004 21.5 20.9067 21.5H3.09326C2.79957 21.5 2.56913 21.2481 2.59524 20.9555L3.84525 6.95553C3.86827 6.69765 4.08435 6.5 4.34326 6.5Z"
      stroke="#1F1F1F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11C8 14.1882 9.79086 17 12 17C14.2091 17 16 14.1882 16 11"
      stroke="#1F1F1F"
      strokeLinecap="round"
    />
    <path
      d="M6.2933 6.5L10.5 2.67573L14.7067 6.5L6.2933 6.5Z"
      stroke="#1F1F1F"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 6.5L13.5 2.5L12.5 3.5"
      stroke="#1F1F1F"
      strokeLinejoin="round"
    />
  </svg>
)

export default Basket
