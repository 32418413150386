import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Cross } from '@components/icons'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import s from './FiltersSelected.module.css'

export interface FiltersSelectedProps {
  filters: ProductsListFilters
  changeFiltersSelected: changeFiltersSelectedProps
  resetFilters: () => void
}

const FiltersSelected: FC<FiltersSelectedProps> = ({
  filters,
  changeFiltersSelected,
  resetFilters,
}) => {
  const { t } = useTranslation()

  const selectedFiltersCategory = filters
    ? Object.entries(filters).filter(([parent, filter]) =>
        filter.values.some((value) => value.selected === true)
      )
    : null
  if (!selectedFiltersCategory?.length) return null

  return (
    <div className={s.container}>
      <p className={s.heading}>{t('block:filter.selectedTitle')}</p>
      <div className={s.list}>
        {selectedFiltersCategory.map(([parent, filters], index) => (
          <React.Fragment key={`FiltersControl-${parent}-${index}`}>
            {filters.values.map(
              (filter, index) =>
                filter.selected && (
                  <button
                    key={`FiltersControl-${filter.key}-${index}`}
                    className={s.filter}
                    aria-label={t('block:filter.deleteSomeFilters', {
                      filterText: filter.title || filter.key,
                    })}
                    onClick={() => {
                      changeFiltersSelected({
                        selectedValue: filter.key,
                        parent,
                        isSelected: filter.selected,
                        parentSlug: filter.levelDepth
                          ? filter.levelDepth > 1
                            ? filter.parentSlug
                            : filter.key
                          : undefined,
                        levelDepth: filter.levelDepth || 0,
                      })
                    }}
                  >
                    {filter.title || filter.key}
                    <Cross className={s.cross} aria-hidden />
                  </button>
                )
            )}
          </React.Fragment>
        ))}
        <button className={s.erase} onClick={() => resetFilters()}>
          {t('block:filter.deleteAllFilters')}
        </button>
      </div>
    </div>
  )
}

export default FiltersSelected
