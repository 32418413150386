export const deleteItemFromWishlist = async ({
  sku,
  productId,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale,
}: {
  sku?: string
  productId?: number
  apiUrl?: string
  locale: string
}): Promise<Wishlist> => {
  const res = await fetch(`${apiUrl}/v1/wishlist`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, productId, locale }),
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const addItemToWishlist = async ({
  sku,
  productId,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale,
}: {
  sku?: string
  productId?: number
  apiUrl?: string
  locale: string
}): Promise<Wishlist> => {
  const res = await fetch(`${apiUrl}/v1/wishlist`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, productId, locale }),
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const fetchWishlistProducts = async ({
  locale,
  query,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  headers,
}: {
  locale: string
  query?: string
  apiUrl?: string
  headers?: Headers
}): Promise<ListingFilters[]> => {
  const res = await fetch(
    `${apiUrl}/v1/wishlist/products?locale=${locale}${
      query ? `&${query}` : ''
    }`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers,
    }
  )
  if (!res?.ok) throw { status: res.status }
  const { data } = await res.json()
  return data
}
