import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useUserSettings } from '@context/session'
import { useRouter } from 'next/router'

import { getCurrencySymbol } from '@utils/prices'
import { Arrow } from '@components/ui'

import { useRange, UseRangeProps } from 'react-instantsearch-core'
import {
  useCurrentRefinements,
  UseCurrentRefinementsProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './Refinement.module.css'
import r from './RefinementRangeInput.module.css'

export interface RangeInputProps {
  isCurrency?: boolean
}

export type RangeProps = React.ComponentProps<'div'> &
  UseCurrentRefinementsProps &
  UseRangeProps &
  RangeInputProps

export function RefinementRangeInput(props: RangeProps) {
  const { title, attribute, isCurrency } = props
  const { currencyCode, currencyRate } = useUserSettings()
  const { locale } = useRouter()

  const valueWithRate = (value: number) => {
    if (!isCurrency) return value
    return parseFloat((value * currencyRate).toFixed(2))
  }

  const valueWithoutRate = (value: number) => {
    if (!isCurrency) return value
    return parseFloat((value / currencyRate).toFixed(2))
  }

  const { items: itemsRefinement } = useCurrentRefinements(props)

  const [openFilter, setOpenFilter] = useState(false)
  const [submitRange, setSubmitRange] = useState<boolean>(false)

  const {
    start: [minValue, maxValue], // The current value for the refinement, with start[0] as the minimum value and start[1] as the maximum value.
    range: { min, max }, // The current available value for the range.
    canRefine,
    refine,
  } = useRange(props)

  const [{ minDisplay, maxDisplay }, setMinMaxDisplay] = useState({
    minDisplay: valueWithRate(min),
    maxDisplay: valueWithRate(max),
  })

  const [{ fromInput, toInput }, setFormInput] = useState({
    fromInput: 0,
    toInput: 0,
  })
  useEffect(() => {
    if (max) {
      setFormInput({
        fromInput: valueWithRate(
          minValue !== -Infinity && minValue !== min ? minValue : min
        ),
        toInput: valueWithRate(
          maxValue !== Infinity && maxValue !== max ? maxValue : max
        ),
      })
      setMinMaxDisplay({
        minDisplay: valueWithRate(min),
        maxDisplay: valueWithRate(max),
      })
    }
  }, [min, max, currencyRate])

  useEffect(() => {
    if (!submitRange || (!fromInput && !toInput)) return null
    refine([valueWithoutRate(fromInput), valueWithoutRate(toInput)])
    setSubmitRange(false)
  }, [submitRange])

  // Range Slider
  const minValueRef = useRef(null)
  const maxValueRef = useRef(null)
  const range = useRef(null)

  const getPercent = useCallback(
    (value: number) =>
      Math.round(((valueWithoutRate(value) - min) / (max - min)) * 100),
    [min, max, currencyRate]
  )

  useEffect(() => {
    if (maxValueRef.current) {
      const minPercent = getPercent(fromInput)
      const maxPercent = getPercent(toInput)

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [fromInput, getPercent])

  useEffect(() => {
    if (minValueRef.current) {
      const minPercent = getPercent(fromInput)
      const maxPercent = getPercent(toInput)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [toInput, getPercent, openFilter])

  useEffect(() => {
    if (
      itemsRefinement.some((item) => item.attribute === attribute) &&
      !openFilter
    ) {
      setOpenFilter(true)
    }
  }, [itemsRefinement])

  if (!min && !max) return null

  return (
    <div
      className={cn(s.container, {
        [s.disabled]: !openFilter,
      })}
    >
      <button className={s.cta} onClick={() => setOpenFilter(!openFilter)}>
        {title}
        <Arrow
          width="10"
          height="10"
          stroke="white"
          className={s.arrowDown}
          aria-hidden
        />
      </button>
      <ul className={cn(s.filtersList, s.buttonItemContainer)}>
        <li className={cn(s.containerCheckbox)}>
          <div className={r.inputs}>
            <div className={r.currencyWrap}>
              <input
                className={r.inputRange}
                type="number"
                min={minDisplay}
                max={maxDisplay}
                value={fromInput || ''}
                placeholder={minDisplay.toString()}
                disabled={!canRefine}
                onChange={(event) => {
                  setFormInput({
                    fromInput: Number(event.target.value),
                    toInput,
                  })
                }}
                onBlur={(event) => {
                  const val = Number(event.target.value)
                  const valRound =
                    val > minDisplay && val < (toInput || maxDisplay)
                      ? val
                      : minDisplay + 1
                  setFormInput({ fromInput: valRound, toInput })
                  setSubmitRange(true)
                }}
              />
              {isCurrency && (
                <span className={r.currencyCode}>
                  {getCurrencySymbol(locale, currencyCode)}
                </span>
              )}
            </div>
            <div className={r.currencyWrap}>
              <input
                className={r.inputRange}
                type="number"
                min={minDisplay}
                max={maxDisplay}
                value={toInput || ''}
                placeholder={maxDisplay.toString()}
                disabled={!canRefine}
                onChange={(event) => {
                  setFormInput({
                    fromInput,
                    toInput: Number(event.target.value),
                  })
                }}
                onBlur={(event) => {
                  const val = Number(event.target.value)
                  const valRound =
                    val < maxDisplay && val > (fromInput || minDisplay)
                      ? val
                      : maxDisplay - 1
                  setFormInput({ fromInput, toInput: valRound })
                  setSubmitRange(true)
                }}
              />
              {isCurrency && (
                <span className={r.currencyCode}>
                  {getCurrencySymbol(locale, currencyCode)}
                </span>
              )}
            </div>
          </div>
          <div className={r.container}>
            <input
              type="range"
              min={minDisplay}
              max={maxDisplay}
              value={fromInput}
              ref={minValueRef}
              onInput={(event: ChangeEvent<HTMLInputElement>) => {
                setFormInput({
                  fromInput: Number(event.target.value),
                  toInput,
                })
              }}
              onMouseUp={() => {
                let value = Math.max(+fromInput, minDisplay)
                if (value >= toInput) value = toInput - 1
                setFormInput({ fromInput: Number(value), toInput })
                setSubmitRange(true)
              }}
              onTouchEnd={() => {
                let value = Math.max(+fromInput, minDisplay)
                if (value >= toInput) value = toInput - 1
                setFormInput({ fromInput: Number(value), toInput })
                setSubmitRange(true)
              }}
              className={cn(r.thumb, r.thumb__zindex_3, {
                [r.thumb__zindex_5]: fromInput > max - 100,
              })}
            />
            <input
              type="range"
              min={minDisplay}
              max={maxDisplay}
              value={toInput}
              ref={maxValueRef}
              onInput={(event: ChangeEvent<HTMLInputElement>) => {
                setFormInput({
                  fromInput,
                  toInput: Number(event.target.value),
                })
              }}
              onMouseUp={() => {
                let value = Math.min(+toInput, maxDisplay)
                if (value <= fromInput) value = fromInput + 1
                setFormInput({ fromInput, toInput: Number(value) })
                setSubmitRange(true)
              }}
              onTouchEnd={() => {
                let value = Math.min(+toInput, maxDisplay)
                if (value <= fromInput) value = fromInput + 1
                setFormInput({ fromInput, toInput: Number(value) })
                setSubmitRange(true)
              }}
              className={cn(r.thumb, r.thumb__zindex_4)}
            />
            <div className={r.slider}>
              <div className={r.slider__track}></div>
              <div ref={range} className={r.slider__range}></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default RefinementRangeInput
