import { FC, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import {
  DESKTOP_LISTING_PRODUCTS_PER_LINE,
  MOBILE_LISTING_PRODUCTS_PER_LINE,
} from '@constants'

import {
  FiltersMenu,
  SortMenu,
  SortMenuMobile,
} from '@components/blocks-cms/ProductsListing'
import { ProductsGrid } from '@components/product'
import { Link } from '@components/ui'

import { fetchProductsList } from '@mw/products'
import { updateFiltersQuery } from '@utils/filters'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import { formatParams, formatQueryParams } from '@utils/url/query'
import { scrollToTop } from '@utils/scrollTo'
import { disableHtml100vh, enableHtml100vh } from '@utils/html100vh'
import { changeLocaleIfTranslatable } from '@utils/locale'
import { isMobile, isMobileSafari } from 'react-device-detect'
import qs from 'query-string'

import cn from 'classnames'
import s from './ProductsListing.module.css'

export type changeFiltersSelectedProps = (opt: {
  selectedValue: string
  parent: string
  isSelected?: boolean
  toReplace?: boolean
  parentSlug?: string
  levelDepth?: number
  returnTop?: boolean
}) => void

const ProductsListing: FC<ListingFilters> = ({
  products,
  filters,
  parameters,
  total,
  outletCount,
  sort,
  isBrandExcluded = false,
  customInserts,
  showOutlet = true,
}) => {
  const { t } = useTranslation()
  const { currencyCode, deliveryCountryCode } = useUserSettings()
  const { locale, query, asPath, ...router } = useRouter()
  const { pages, ...queryParams } = query
  const screen = useScreen()

  const currentSort =
    (query?._sort as string) || parameters?._sort || 'activationDate:desc'
  const currentPage = useRef(
    parseInt(query?._page as string) > 1 ? query._page : '1'
  )
  const userSetting = useRef({ currencyCode, deliveryCountryCode })
  const navigationBar = useRef() as React.MutableRefObject<HTMLDivElement>

  const queries = useRef<{
    parameters: ProductsParameters
    vanity: { [key: string]: string[] }
  }>({
    parameters: { ...parameters },
    vanity: formatQueryParams(queryParams),
  })
  const [openFilters, setOpenFilters] = useState<boolean>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [productsPerLine, setProductsPerLine] = useState(
    [Screen.xs, Screen.sm, Screen.md].includes(screen)
      ? MOBILE_LISTING_PRODUCTS_PER_LINE
      : DESKTOP_LISTING_PRODUCTS_PER_LINE
  )
  const [dataListing, setDataListing] = useState({
    products: [...products],
    filters: { ...filters },
    total,
    isBrandExcluded,
    gridDisplay: queries.current.vanity?._infinite?.[0] === '1' || false,
  })

  const productsPerListing =
    parameters?.limit || Number(process.env.NEXT_PUBLIC_PRODUCTS_PER_LISTING)
  const showFilters = parameters.showFilters && dataListing.filters

  const updateDataListing = async ({
    shouldRouterUpdate = true,
    _callback,
  }: {
    shouldRouterUpdate: boolean
    _callback?: () => void
  }) => {
    setIsLoading(true)
    try {
      const vanityUrlString = qs
        .stringify(queries.current.vanity, {
          arrayFormat: 'separator',
          arrayFormatSeparator: '+',
        })
        .split('+')
        .join('%2B')

      const params = {
        ...queries.current.parameters,
        country: deliveryCountryCode,
      }
      const parametersUrlString = qs
        .stringify(params, {
          arrayFormat: 'separator',
          arrayFormatSeparator: '+',
        })
        .split('+')
        .join('%2B')

      const newDataListing = await fetchProductsList({
        query: `&${vanityUrlString}&${parametersUrlString}`,
        locale: locale as string,
      })
      if (newDataListing) {
        queries.current.parameters = formatParams(
          newDataListing.parameters,
          queries.current.vanity
        )
        setDataListing({
          products: [...newDataListing.products],
          filters: { ...newDataListing.filters },
          total: newDataListing.total,
          isBrandExcluded: newDataListing.isBrandExcluded,
          gridDisplay: queries.current.vanity?._infinite?.[0] === '1' || false,
        })
      }
      if (shouldRouterUpdate) {
        router.push(`${asPath.split('?')[0]}?${vanityUrlString}`, undefined, {
          shallow: true,
          locale: changeLocaleIfTranslatable(locale),
        })
      }
      if (_callback) _callback()
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }

  const changePagination = (pageToGo: number) => {
    scrollToTop(
      navigationBar.current,
      screen && ![Screen.xs, Screen.sm, Screen.md].includes(screen) ? 0 : -62,
      false
    )
    const pageNumber = Number.isInteger(pageToGo) && pageToGo > 0 ? pageToGo : 1
    queries.current.vanity._page = [`${pageNumber}`]
    queries.current.parameters.offset = [
      `${productsPerListing * (pageNumber - 1)}`,
    ]
    currentPage.current = `${pageToGo}`
    updateDataListing({ shouldRouterUpdate: false })
  }

  const resetFilters = () => {
    queries.current.vanity = {}
    queries.current.parameters.offset = 0
    updateDataListing({
      shouldRouterUpdate: true,
      _callback: () => {
        if ([Screen.xs, Screen.sm, Screen.md].includes(screen))
          setOpenFilters(false)
      },
    })
  }

  const changeFiltersSelected: changeFiltersSelectedProps = ({
    selectedValue,
    parent,
    isSelected,
    toReplace,
    parentSlug,
    levelDepth,
    returnTop = true,
  }) => {
    if (isLoading) return false
    queries.current.vanity = updateFiltersQuery({
      source: queries.current.vanity,
      filters: dataListing.filters,
      selectedValue,
      parent,
      parentSlug,
      isSelected,
      toReplace,
      levelDepth,
    })
    queries.current.parameters.offset = ['0']
    delete queries.current.vanity._page
    delete queries.current.vanity._scroll
    if (parent === 'sort' && queries.current.parameters?._sort)
      delete queries.current.parameters._sort
    currentPage.current = '1'
    if (returnTop)
      scrollToTop(
        navigationBar.current,
        screen && ![Screen.xs, Screen.sm, Screen.md].includes(screen) ? 0 : -62
      )
    updateDataListing({ shouldRouterUpdate: true })
  }

  useEffect(() => {
    const formattedQueryParams = formatQueryParams(queryParams)
    const formattedParams = formatParams(parameters, formattedQueryParams)
    queries.current = {
      parameters: formattedParams,
      vanity: formattedQueryParams,
    }
    setDataListing({
      products,
      filters,
      total,
      isBrandExcluded,
      gridDisplay: queries.current.vanity?._infinite?.[0] === '1' || false,
    })
  }, [products, filters, parameters, total, isBrandExcluded])

  useEffect(() => {
    const { _page } = query
    const initialPage = (_page as string) || null
    if (initialPage && initialPage !== currentPage.current)
      changePagination(parseInt(initialPage))
  }, [query])

  useEffect(() => {
    if (
      userSetting.current.currencyCode !== currencyCode ||
      userSetting.current.deliveryCountryCode !== deliveryCountryCode
    ) {
      updateDataListing({ shouldRouterUpdate: true })
      userSetting.current = { currencyCode, deliveryCountryCode }
    }
  }, [currencyCode, deliveryCountryCode])

  useEffect(() => {
    if (!openFilters || ![Screen.xs, Screen.sm, Screen.md].includes(screen))
      enableScroll(isMobileSafari)
    else blockScroll(isMobileSafari)
  }, [openFilters, screen])

  useEffect(() => {
    if (isMobile) {
      if (openFilters) enableHtml100vh()
      else disableHtml100vh()
    }
  }, [openFilters])

  useEffect(() => {
    if (typeof screen === 'undefined') return null
    if (![Screen.xs, Screen.sm, Screen.md].includes(screen)) {
      setProductsPerLine(DESKTOP_LISTING_PRODUCTS_PER_LINE)
      setOpenFilters(true)
    } else {
      const userDisplayChoice = window?.localStorage?.getItem('display_listing')
      setProductsPerLine(
        userDisplayChoice
          ? Number(userDisplayChoice)
          : MOBILE_LISTING_PRODUCTS_PER_LINE
      )
      setOpenFilters(false)
    }
  }, [screen])

  if (dataListing.isBrandExcluded) {
    return (
      <div className={s.container}>
        <p className={s.msg}>{t('block:brandExcluded')}</p>
      </div>
    )
  }

  if (outletCount > 0 && total === 0 && Object.keys(queryParams).length === 0) {
    return (
      <div className={s.container}>
        <p className={s.msg}>
          <Link
            next
            href={`/${locale}${asPath.split('?')[0]}?show_outlet=true`}
            className={s.outletButton}
          >
            {t('block:msgPush.seeOutlet')}
          </Link>
        </p>
      </div>
    )
  }

  return (
    <section ref={navigationBar} id="products-listing" className={s.container}>
      {isLoading && <div className={s.loading} />}
      <div className={s.content}>
        {showFilters && (
          <>
            {[Screen.xs, Screen.sm, Screen.md].includes(screen) ? (
              <SortMenuMobile
                sort={sort}
                currentSort={currentSort}
                openFilters={openFilters}
                setOpenFilters={setOpenFilters}
                productsPerLine={productsPerLine}
                setProductsPerLine={setProductsPerLine}
                changeFiltersSelected={changeFiltersSelected}
                navigationBar={navigationBar}
              />
            ) : (
              <SortMenu
                filters={dataListing?.filters}
                resetFilters={resetFilters}
                openFilters={openFilters}
                setOpenFilters={setOpenFilters}
                sort={sort}
                currentSort={currentSort}
                total={dataListing?.total || dataListing?.products?.length}
                chunk={productsPerListing}
                changeFiltersSelected={changeFiltersSelected}
              />
            )}
          </>
        )}
        <div
          className={cn(
            s.row,
            { 'pt-7': !showFilters },
            { [s.openFilters]: openFilters }
          )}
        >
          {openFilters &&
            [Screen.xs, Screen.sm, Screen.md].includes(screen) && (
              <div
                className={s.layout}
                onClick={() => setOpenFilters(!openFilters)}
              />
            )}
          {showFilters && openFilters !== null && (
            <div className={s.filters}>
              <FiltersMenu
                filters={dataListing?.filters}
                total={dataListing?.total || dataListing?.products?.length}
                changeFiltersSelected={changeFiltersSelected}
                setOpenFilters={setOpenFilters}
                resetFilters={resetFilters}
              />
            </div>
          )}
          <div className={cn(s.cards, { [s.fadeIn]: isLoading })}>
            <ProductsGrid
              products={dataListing.products}
              productsTotal={dataListing.total}
              productsParameters={queries.current.parameters}
              productsPerLine={productsPerLine}
              urlParams={queries.current.vanity}
              currentPage={queries?.current?.vanity?._page?.[0]}
              chunk={productsPerListing}
              isPaginate={!dataListing?.gridDisplay}
              changeFiltersSelected={changeFiltersSelected}
              layout="listing"
              customInserts={customInserts}
            />
            {showOutlet &&
            outletCount > 0 &&
            queryParams?.show_outlet !== 'true' ? (
              <p className={s.msgLite}>
                <Link
                  next
                  href={`/${locale}${asPath.split('?')[0]}?show_outlet=true`}
                  className={s.outletButton}
                >
                  {t('block:msgPush.seeOutlet')}
                </Link>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsListing
