import React, { FC, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

import { Button, Link } from '@components/ui'
import BasketDetail from './BasketDetail'
import { tagEventRemoveFromBasket } from '@components/common/Eulerian/EulerianEvents'

import { formattedPrice } from '@utils/prices'
import { PayPalScriptProvider, PayPalMessages } from '@paypal/react-paypal-js'

import cn from 'classnames'
import s from './BasketSlider.module.css'

export interface Props {
  basket: Basket
  itemsCount: number
}

const BasketSlider: FC<Props> = ({ basket, itemsCount }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const sessionData = useUserSettings()
  const { removeFromBasket, currencyCode, deliveryCountryCode } = sessionData

  const PAYPAL_APIKEY = process.env.NEXT_PUBLIC_PAYPAL_APIKEY || ''
  const displayPaypalDisclaimer =
    locale === 'fr' && currencyCode === 'EUR' && deliveryCountryCode === 'FR'
  const stylePaypal = {
    layout: 'text',
    logo: {
      type: 'inline',
    },
    text: {
      align: 'center',
      size: '13',
    },
  }

  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const [sliderHidden, setSliderHidden] = useState<boolean>(false)

  const basketItems = [...basket.items].reverse()

  const loopSliderIndex = (current = 0, total = 1) => {
    if (current + total === 2) return current + total
    return (current + total) % total
  }
  const handleSliderIndex = (current = 0, total = 1, inc = 1) => {
    setSliderIndex(loopSliderIndex(current + inc, total))
  }

  const handleRemoveItems = async (sku: string) => {
    setSliderHidden(true)
    try {
      if (!sku)
        throw new Error('SKU missing when trying to remove item from basket')
      const newBasket = await removeFromBasket(sku, locale)
      if (newBasket) {
        if (sliderIndex) handleSliderIndex(sliderIndex, basketItems?.length, -1)
        tagEventRemoveFromBasket({
          urlp: '/basket/remove/page_basket_summary',
          locale,
          sessionData,
          productData: basket,
          skuRemove: sku,
        })
      }
    } catch (err) {
      console.error(err)
    }
    setSliderHidden(false)
  }

  return (
    <div className={cn(s.container, { [s.hidden]: sliderHidden })}>
      <div className={s.title}>
        <Link
          href={t('layout:header.links.basket.href') as string}
          className={s.title_link}
        >
          {t('layout:header.links.basket.title')}&nbsp;({itemsCount})
        </Link>
      </div>
      <div className={s.slider} aria-roledescription="carousel">
        <div className={cn(s.controller, s.arrow_up)}>
          {basketItems?.length > 2 && (
            <div
              onClick={() =>
                handleSliderIndex(sliderIndex, basketItems.length, -1)
              }
              role="button"
              aria-label="Previous"
            >
              <button
                className={cn(s.arrow, s.arrow_up)}
                aria-hidden="true"
              ></button>
            </div>
          )}
        </div>
        <div className={s.list} aria-live="polite" aria-controls="IDREF">
          {sliderIndex !== undefined &&
            [
              sliderIndex,
              loopSliderIndex(sliderIndex + 1, basketItems?.length),
            ].map(
              (current) =>
                basket?.items[current] && (
                  <BasketDetail
                    key={basketItems[current].sku}
                    attachColor={basketItems[current].attachColor}
                    id={basketItems[current].id}
                    sku={basketItems[current].sku}
                    color={basketItems[current].color}
                    image={basketItems[current].image}
                    name={basketItems[current].name}
                    brand={basketItems[current].brand}
                    size={basketItems[current].size}
                    quantity={basketItems[current].quantity}
                    price={basketItems[current].priceTaxIncluded}
                    removeHandler={handleRemoveItems}
                  />
                )
            )}
        </div>
        <div className={cn(s.controller, s.arrow_down)}>
          {basketItems?.length > 2 && (
            <div
              onClick={() =>
                handleSliderIndex(sliderIndex, basketItems?.length, 1)
              }
              role="button"
              aria-label="Next"
            >
              <button
                className={cn(s.arrow, s.arrow_down)}
                aria-hidden="true"
              ></button>
            </div>
          )}
        </div>
      </div>
      <p className={s.total}>
        {basket?.totalPriceTaxIncluded && currencyCode && (
          <strong>
            <small>{t('layout:header.links.basket.total')} :</small>{' '}
            {formattedPrice(basket.totalPriceTaxIncluded, currencyCode, locale)}
          </strong>
        )}
      </p>
      <div className={s.order}>
        <Button
          className={s.cta}
          href={t('layout:header.links.basket.href') as string}
        >
          {t('layout:header.links.basket.purchase')}
        </Button>
        {PAYPAL_APIKEY?.length && displayPaypalDisclaimer ? (
          <PayPalScriptProvider
            options={{
              'client-id': PAYPAL_APIKEY,
              components: 'messages',
            }}
          >
            <PayPalMessages
              className={s.paypalDisclaimer}
              style={stylePaypal as any}
              forceReRender={[stylePaypal]}
              amount={basket?.totalPriceTaxIncluded}
            />
          </PayPalScriptProvider>
        ) : null}
      </div>
    </div>
  )
}

export default BasketSlider
