import React, { FC, useState, useRef, useEffect } from 'react'

import { PlayButton, PauseButton, Mute, UnMute } from '@components/icons'
import { ProgressBar, Link } from '@components/ui'

import ReactPlayer from 'react-player/lazy'
import { isMobile, isIOS } from 'react-device-detect'

import cn from 'classnames'
import s from './VideoPlayer.module.css'

const playerTheme = '#c39b55'

const secondsToMinutesAndSeconds = (totalSeconds: number) => {
  let minutes: number = Math.floor(totalSeconds / 60)
  let seconds: number = Math.floor(totalSeconds % 60)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

const targetSelectedTime = (progression, duration) => {
  return Math.round(progression * duration)
}

export interface VideoPlayerProps {
  url: string
  isControllerVisible?: boolean
  description?: string
  link?: string
  linkText?: string
  placeholderImage?: string
  isMobileMinimalistDisplay?: boolean
  autoPlay?: boolean
  loop?: boolean
  mute?: boolean
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  url,
  isControllerVisible = true,
  placeholderImage,
  description,
  link,
  linkText,
  isMobileMinimalistDisplay = false,
  autoPlay = false,
  loop = false,
  mute = false,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState<boolean>(autoPlay)
  const [isMuted, setIsMuted] = useState<boolean>(mute)
  const [isPlayedSeconds, setIsPlayedSeconds] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [isHover, setIsHover] = useState<boolean>(false)
  const [volume, setVolume] = useState<number>(0.2)
  const playerRef = useRef(null)
  const controllerRef = useRef(null)

  const handleProgress = (e) => {
    setIsPlayedSeconds(e.playedSeconds)
  }

  const handleDuration = (e) => {
    setDuration(Math.floor(e))
  }

  const handlePlaceHolder = (autoPlay: boolean) => {
    if (autoPlay) return null
    setIsPlaying(true)
    setIsLoading(false)
    setIsMuted(false)
  }

  const handleReady = () => {
    autoPlay && setIsLoading(false)
  }

  const handleProgressBar = (e) => {
    const progression =
      e.nativeEvent.offsetX / controllerRef.current.offsetWidth
    const selectTime = targetSelectedTime(progression, duration)
    playerRef.current.seekTo(selectTime)
  }

  const handleMute = () => {
    if (volume == 0) {
      setVolume(0.1)
      setIsMuted(false)
    } else setIsMuted(!isMuted)
  }

  const handleVolumeChange = (e) => {
    if (isMuted) setIsMuted(false)
    setVolume(Number(e.target.value))
  }

  useEffect(() => {
    if (volume == 0) setIsMuted(true)
  }, [volume])

  return (
    <div
      className={cn(
        s.container,
        { [s.mobile]: isMobile },
        { [s.minimalist]: isMobile && isMobileMinimalistDisplay },
        { ['my-0']: !description?.length }
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={isControllerVisible ? () => setIsPlaying(!isPlaying) : undefined}
      // onFocus={() => setIsHover(true)}
      // onBlur={() => setIsHover(false)}
    >
      <ReactPlayer
        ref={playerRef}
        className={s.player}
        width="100%"
        height="100%"
        url={url}
        config={{
          youtube: {
            playerVars: {
              autoplay: autoPlay ? 1 : 0,
              showinfo: 0,
              controls: 0,
              modestbranding: 1,
              fs: 0,
              iv_load_policy: 3,
              rel: 0,
            },
          },
        }}
        onReady={handleReady}
        onProgress={handleProgress}
        onDuration={handleDuration}
        loop={loop}
        volume={volume}
        playing={isPlaying}
        muted={isMuted}
      />
      {!isLoading ? (
        <div className={s.layout}>
          {(isHover || isMobile) && (
            <>
              {link?.length && linkText?.length ? (
                <Link
                  aria-label={linkText}
                  next={false}
                  href={link}
                  className={s.link}
                >
                  {linkText}
                </Link>
              ) : null}
              <div
                className={cn(s.controllers, {
                  [s.noControls]: !isControllerVisible,
                })}
                onClick={
                  isControllerVisible ? (e) => e?.stopPropagation() : undefined
                }
              >
                {isControllerVisible ? (
                  <>
                    <div className={s.top}>
                      <div className={s.leftSide}>
                        <div
                          className={s.play}
                          onClick={() => setIsPlaying(!isPlaying)}
                        >
                          {isPlaying ? (
                            <PauseButton color={playerTheme} />
                          ) : (
                            <PlayButton color={playerTheme} />
                          )}
                        </div>
                        <div className={s.time}>
                          {secondsToMinutesAndSeconds(isPlayedSeconds)} /{' '}
                          {secondsToMinutesAndSeconds(duration)}
                        </div>
                      </div>
                      <div className={s.rightSide}>
                        {!isIOS ? (
                          <input
                            className={s.volumeBar}
                            type="range"
                            min={0}
                            max={1}
                            step={0.05}
                            value={volume}
                            onChange={(e) => handleVolumeChange(e)}
                          />
                        ) : null}
                        <div className={s.mute} onClick={() => handleMute()}>
                          {isMuted ? (
                            <UnMute color={playerTheme} />
                          ) : (
                            <Mute color={playerTheme} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      ref={controllerRef}
                      onClick={(e) => handleProgressBar(e)}
                      className={s.progress}
                    >
                      <ProgressBar
                        className={s.bar}
                        total={duration}
                        part={isPlayedSeconds}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {description?.length ? (
                      <div className={s.description}>{description}</div>
                    ) : null}
                    <div className={s.mute} onClick={() => handleMute()}>
                      {isMuted ? (
                        <UnMute color={playerTheme} />
                      ) : (
                        <Mute color={playerTheme} />
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={cn(s.layout, { [s.cta]: !autoPlay })}
          onClick={() => handlePlaceHolder(autoPlay)}
        >
          {placeholderImage?.length ? (
            <img
              className={cn(s.loadingImg, { [s.fade]: autoPlay })}
              src={placeholderImage}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
