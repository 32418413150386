const getRoundDecade = (num: number, scale = 10): number => {
  return Math.floor(num / scale) * scale
}

const arrayRange = (start: number, end: number, step: number): number[] => {
  start = Math.max(start, 1) // Ensure the start is at least 1
  return Array.from(
    { length: (end - start) / step + 1 },
    (_, i) => i * step + start
  )
}
export const generatePaginationLinks = ({
  currentPage,
  nbPages,
}: {
  currentPage: number
  nbPages: number
}): number[] => {
  // Calculate the start of the current decade and century ex : page 24 => 20, page 237 => 200
  const startOfCurrentDecade = getRoundDecade(currentPage, 10)
  const startOfCurrentCentury = getRoundDecade(currentPage, 100)

  const links = new Set([
    1,
    // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    ...arrayRange(
      startOfCurrentDecade,
      Math.min(nbPages, startOfCurrentDecade + 10),
      1
    ),
    // [10, 20, 30, 40, 50, 60, 70, 80, 90]
    ...arrayRange(
      startOfCurrentDecade + 10,
      Math.min(nbPages, startOfCurrentCentury + 100),
      10
    ),
    // [200,300,400,500,600,700,800,900]
    ...arrayRange(
      startOfCurrentCentury + 100,
      Math.min(nbPages, startOfCurrentCentury + 1000),
      100
    ),
    nbPages,
  ])

  return Array.from(links)
}
