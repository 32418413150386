import { FC, ReactNode, Component, LegacyRef } from 'react'

import { Tooltip, withTooltip } from 'components/ui'
import s from './SubUserNav.module.css'

interface Props {
  children?: ReactNode[] | Component[] | any[]
  className?: string
  title?: string
  href?: string
  id?: string
  position?: string
  linkRef: LegacyRef<HTMLAnchorElement>
  icon?: Component
  ariaLabel?: string
}

const SubUserNav: FC<Props> = ({
  className,
  title,
  href,
  icon,
  id,
  linkRef,
  ariaLabel,
}) => (
  <a
    className={className}
    href={href}
    rel="nofollow"
    aria-describedby={id}
    aria-label={ariaLabel}
    ref={linkRef}
    tabIndex={-1}
  >
    {icon}
    {title}
  </a>
)

const ProductListTooltip: FC<Props> = ({
  children,
  id,
  position = 'center',
  linkRef,
}) => (
  <Tooltip
    className={position && s[position]}
    id={id}
    position={position}
    linkRef={linkRef}
  >
    {children}
  </Tooltip>
)

export default withTooltip(ProductListTooltip)(SubUserNav)
