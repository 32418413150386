import React, { FC, useState, useEffect, LegacyRef } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import useScreen from '@lib/hooks/useScreen'

import { Link } from '@components/ui'

import { getRowCount } from '@utils/brands'

import cn from 'classnames'
import s from './BrandsLetterBlock.module.css'

export interface BrandsLetterBlockProps {
  letter: string
  brands: Brand[]
  brandsContainerRef: LegacyRef<HTMLOListElement> | null
}

const BrandsLetterBlock: FC<BrandsLetterBlockProps> = ({
  letter,
  brands,
  brandsContainerRef,
}) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const { locale } = useRouter()

  const [styleRowCount, setStyleRowCount] = useState({
    gridTemplateRows: `repeat(${getRowCount(brands, screen)}, 24px)`,
  })

  useEffect(() => {
    setStyleRowCount({
      gridTemplateRows: `repeat(${getRowCount(brands, screen)}, 24px)`,
    })
  }, [screen, brands])

  return (
    <div className={cn(s.letterBlock, { [s.displayNone]: !brands.length })}>
      <div
        className={s.anchor}
        id={letter === '#' ? 'letter-num' : `letter-${letter.toLowerCase()}`}
      />
      <div className={s.letter}>{letter}</div>
      <ol
        className={s.brandsBlock}
        style={styleRowCount}
        ref={brandsContainerRef}
      >
        {brands.map((brand, index) => (
          <li key={`brand-${index}`} className={s.brand}>
            <Link
              aria-label={`${t('layout:common.ariaNavigate')} brands ${
                brand.name
              }`}
              next
              href={`/${locale}/${t('block:brand.brandsUrl')}/${brand.slug}`}
            >
              {brand.name}
              {brand.isNew && (
                <span className={cn(s.brandSuffix, s.brandIsNew)}>New</span>
              )}
              {brand.discount ? (
                <span className={cn(s.brandSuffix, s.brandDiscount)}>
                  -{brand.discount}%
                </span>
              ) : null}
            </Link>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default BrandsLetterBlock
