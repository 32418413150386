import React, { FC, ReactChild, useState, useEffect } from 'react'

import { VideoPlayer } from '@components/blocks-cms'

import { blockScroll, enableScroll } from '@utils/scrollLock'
import { isMobileSafari } from 'react-device-detect'

import s from './ModuleVideo.module.css'

interface ModuleVideoProps {
  className?: string
  url: string
  text?: string
  date?: string
  type?: string
  title?: string
  children?: ReactChild
}

const ModuleVideo: FC<ModuleVideoProps> = ({
  className,
  url,
  text,
  date,
  type,
  title,
  children,
}) => {
  const [displayVideo, setDisplayVideo] = useState<boolean>(false)

  useEffect(() => {
    if (displayVideo) blockScroll(isMobileSafari)
    else enableScroll(isMobileSafari)
  }, [displayVideo])

  return (
    <>
      <div className={className} onClick={() => setDisplayVideo(true)}>
        {children}
      </div>
      {displayVideo ? (
        <>
          <div
            className={s.videoBackground}
            onClick={() => setDisplayVideo(false)}
          />
          <div className={s.videoContainer}>
            <VideoPlayer url={url} />
            <div className={s.desc}>
              <div className={s.inner}>
                <div className={s.date}>{date}</div>
                <div className={s.type}>{type}</div>
                <div className={s.title}>{title}</div>
              </div>
            </div>
            {text && (
              <p
                className={s.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </div>
        </>
      ) : null}
    </>
  )
}

export default ModuleVideo
