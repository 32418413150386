import { FC, useEffect, useState } from 'react'

import cn from 'classnames'
import s from './Banner.module.css'
import { fetchBannerConfig } from '@mw/query/banner'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

export interface BannerProps {
  className?: string
}

const Banner: FC<BannerProps> = ({ className }) => {
  const [currentBannerAnimated, setCurrentBannerAnimated] = useState(0)
  const { locale, query } = useRouter()
  const { deliveryCountryCode } = useUserSettings()
  const [bannerConfig, setBannerConfig] = useState<BannerConfig | null>(null)

  useEffect(() => {
    const removeCache = query.hasOwnProperty('no-cache')
    const key = `bannerConfig-${locale}-${deliveryCountryCode}`
    if (!removeCache && sessionStorage.getItem(key)) {
      const { data, expiration } = JSON.parse(sessionStorage.getItem(key))
      if (expiration > Date.now()) {
        setBannerConfig(data)
        return
      }
    }
    fetchBannerConfig(locale, deliveryCountryCode, removeCache)
      .then((data) => {
        sessionStorage.setItem(
          key,
          JSON.stringify(
            { data, expiration: Date.now() + 1000 * 60 * 60 } // 1h
          )
        )
        setBannerConfig(data)
      })
      .catch(console.error)
  }, [locale, deliveryCountryCode])

  if (!bannerConfig || !bannerConfig.banners.length) return null
  return (
    <div className={cn(s.container, className)}>
      <div
        className={s.background}
        style={{
          backgroundColor: bannerConfig.backgroundColor,
        }}
      />
      {bannerConfig.banners.map((banner, index) => (
        <p
          key={index}
          className={cn(s.text, s.fade, {
            [s.hide]: index !== currentBannerAnimated,
          })}
          style={{ color: bannerConfig.textColor }}
          onAnimationIteration={() =>
            setCurrentBannerAnimated((index + 1) % bannerConfig.banners.length)
          }
        >
          {banner.startText}
          <a
            className={s.redirection}
            style={{ color: bannerConfig.textColor }}
            href={banner.href}
            target={banner.target}
            rel="nofollow noreferrer"
          >
            {banner.linkText}
          </a>
          {banner.endText}
        </p>
      ))}
    </div>
  )
}

export default Banner
