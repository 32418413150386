import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import cn from 'classnames'
import s from './ButtonItem.module.css'

interface ButtonItemProps {
  filter: FilterValue
  parent: string
  changeFiltersSelected: changeFiltersSelectedProps
}

const ButtonItem: FC<ButtonItemProps> = ({
  filter,
  parent,
  changeFiltersSelected,
}) => {
  const { t } = useTranslation()

  return (
    <li className={s.container}>
      <button
        role="checkbox"
        className={cn(
          s.title,
          { [s.checked]: filter.selected },
          { [s.disabled]: !filter.count }
        )}
        aria-label={t('block:filter.ariaFilterItem', {
          filterText: filter.key as string,
        })}
        aria-checked={filter.selected}
        onClick={() => {
          if (filter.count) {
            changeFiltersSelected({
              selectedValue: filter.key,
              parent,
              isSelected: filter.selected,
            })
          }
        }}
      >
        {filter.key}
      </button>
    </li>
  )
}
export default ButtonItem
