import { FC } from 'react'

import CountrySelector from '@components/common/Header/UserSettings/CountrySelector'
import CurrencySelector from '@components/common/Header/UserSettings/CurrencySelector'
import LocaleSelector from '@components/common/Header/UserSettings/LocaleSelector'

import cn from 'classnames'
import s from './NavbarUserSetting.module.css'

interface Props {
  alternates?: Array<Alternate>
  currencies: Array<Currency>
  countries?: Array<Country>
}

const NavbarUserSetting: FC<Props> = ({
  alternates,
  currencies,
  countries,
}) => (
  <ul className={s.items}>
    <li className={s.item}>
      <LocaleSelector className={s.buttonContainer} alternates={alternates} />
    </li>
    <li className={cn(s.item, s.lite)}>
      <CurrencySelector className={s.buttonContainer} currencies={currencies} />
    </li>
    <li className={s.item}>
      <CountrySelector className={s.buttonContainer} countries={countries} />
    </li>
  </ul>
)

export default NavbarUserSetting
