import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import { Link } from '@components/ui'
import FooterComponent from './FooterComponent'

import cn from 'classnames'
import s from './BlockLinks.module.css'
import footerStyle from '../../Footer.module.css'

interface Components {
  name: string
}

export interface FooterLink {
  title: string
  links?: Array<{
    title: string
    href: string
    next?: boolean
    target?: string
    rel?: string
    modal?: string
    id?: string
  }>
  components?: Array<Components>
}

interface BlockLinksProps extends FooterLink {
  className?: string
}

const BlockLinks: FC<BlockLinksProps> = ({
  className,
  title,
  links = [],
  components = [],
}) => {
  const { t } = useTranslation()

  const { setModalView, openModal } = useUI()
  const openPageModal = async (modal: string) => {
    setModalView(modal)
    openModal()
  }

  return (
    <div className={cn(s.root, className)}>
      {title?.length && (
        <h2 className={cn(footerStyle.title, s.title)}>
          {t(`layout:${title}`)}
        </h2>
      )}
      <ul className={s.list}>
        {links.map(({ title, href, next, modal, target, rel }, i: number) => (
          <li key={i}>
            <Link
              next={!!next}
              href={t(`layout:${href}`) as string}
              className={s.link}
              rel={rel}
              target={target}
              onClick={
                modal
                  ? (e) => {
                      openPageModal(modal)
                      e.preventDefault()
                    }
                  : undefined
              }
            >
              {t(`layout:${title}`)}
            </Link>
          </li>
        ))}
        {components.map(({ name }: Components, i: number) => (
          <li key={i}>
            <FooterComponent component={name} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BlockLinks
