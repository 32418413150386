import { FC, useMemo, useRef, useEffect, useState } from 'react'
import Portal from '@reach/portal'
import { Cross } from '@components/icons'
import onClickOutside from 'react-onclickoutside'
import FocusLock from 'react-focus-lock'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import { isMobileSafari } from 'react-device-detect'
import cn from 'classnames'
import s from './Modal.module.css'
import { useUI } from '@components/ui/context'

export interface ModalProps {
  className?: string
  children?: any
  open?: boolean
  showHeader?: boolean
  onClose: () => void
  layout?: 'dark' | 'white'
  detectClickOutside?: boolean
}

const ModalContent: FC<ModalProps> = ({
  children,
  onClose,
  className,
  showHeader = true,
  layout = 'white',
  open,
}) => {
  return (
    <div
      className={cn(
        s.modal,
        className,
        { [s.opened]: open },
        { [s.dark]: layout === 'dark' }
      )}
    >
      <div className={cn(s.modalHeader, { hidden: !showHeader })}>
        <button
          onClick={() => onClose()}
          aria-label="Close panel"
          className={s.close}
        >
          <Cross
            className={cn('h-5', 'w-5', { ['opacity-25']: layout === 'white' })}
            stroke={layout === 'white' ? 'currentColor' : 'white'}
          />
        </button>
      </div>
      {children}
    </div>
  )
}

const Modal: FC<ModalProps> = ({
  children,
  open,
  onClose,
  className,
  showHeader = true,
  layout = 'white',
  detectClickOutside = true,
}) => {
  const { isSiteReverted } = useUI()
  const isMounted = useRef<boolean>(false)
  const ModalContentOnClickOutside = useMemo(() => {
    return onClickOutside(ModalContent, {
      handleClickOutside: () => (detectClickOutside ? onClose : () => null),
    })
  }, [onClose])

  useEffect(() => {
    if (isMounted.current || open) {
      if (open) blockScroll(isMobileSafari)
      else enableScroll(isMobileSafari)
    }
  }, [open])

  useEffect(() => {
    isMounted.current = true
  }, [])

  return (
    <Portal>
      <div dir={isSiteReverted ? 'rtl' : 'ltr'}>
        {open ? (
          <FocusLock>
            <div className={cn(s.root, { hidden: !open })}>
              <ModalContentOnClickOutside
                onClose={onClose}
                open={open}
                className={className}
                showHeader={showHeader}
                layout={layout}
              >
                {children}
              </ModalContentOnClickOutside>
            </div>
          </FocusLock>
        ) : null}
      </div>
    </Portal>
  )
}

export default Modal
