import { FC } from 'react'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { ProductMediaSlider, ProductMediaGrid } from '@components/product'

import s from './ProductMedias.module.css'

export interface ProductMediasProps {
  productMedias: Array<Media>
  productName: string
  productVideo?: string
}

const ProductMedias: FC<ProductMediasProps> = ({
  productMedias,
  productName,
  productVideo,
}) => {
  const screen = useScreen()

  return (
    <div id="productMediaGrid" className={s.mediasContainer}>
      {![Screen.xs, Screen.sm, Screen.md].includes(screen) ? (
        <ProductMediaGrid
          productMedias={productMedias}
          productName={productName}
          productVideo={productVideo}
        />
      ) : (
        <div className={s.sliderContainer}>
          <ProductMediaSlider
            productMedias={productMedias}
            productName={productName}
            productVideo={productVideo}
          />
        </div>
      )}
    </div>
  )
}

export default ProductMedias
