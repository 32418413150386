import React, { useEffect, useState } from 'react'

import { Check } from '@components/icons'
import { Arrow } from '@components/ui'
import normalize from '@lib/normalize'

import {
  useCurrentRefinements,
  UseCurrentRefinementsProps,
  useHierarchicalMenu,
  UseHierarchicalMenuProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './Refinement.module.css'

interface RefinementHierarchieProps {
  title: string
}

export type HierarchicalListProps = UseHierarchicalMenuProps &
  UseCurrentRefinementsProps &
  RefinementHierarchieProps

export function RefinementHierarchie(props: HierarchicalListProps) {
  const { title, attributes } = props
  const { items: itemsRefinement } = useCurrentRefinements(props)
  const { items, refine, createURL } = useHierarchicalMenu(props)

  const [openFilter, setOpenFilter] = useState(false)

  useEffect(() => {
    if (
      itemsRefinement.some(
        (item) =>
          attributes.some((attribute) => attribute === item.attribute) &&
          item.refinements.some((refinement) => !!refinement.value)
      ) &&
      !openFilter
    ) {
      setOpenFilter(true)
    }
  }, [itemsRefinement])

  return (
    <div
      className={cn(s.container, {
        [s.disabled]: !openFilter,
      })}
    >
      <button className={s.cta} onClick={() => setOpenFilter(!openFilter)}>
        {title}
        <Arrow
          width="10"
          height="10"
          stroke="white"
          className={s.arrowDown}
          aria-hidden
        />
      </button>
      <HierarchicalList items={items} refine={refine} createURL={createURL} />
    </div>
  )
}

function HierarchicalList({
  items,
  refine,
  createURL,
}: Pick<
  ReturnType<typeof useHierarchicalMenu>,
  'items' | 'refine' | 'createURL'
>) {
  return (
    <ul className={cn(s.filtersList, s.buttonItemContainer)}>
      {items.map((item, index) => (
        <React.Fragment key={`Filters-item-${index}`}>
          <li
            key={item.value}
            className={cn(s.containerCheckbox, {
              [s.disabled]: !item.count,
            })}
          >
            <div className={s.contentCheckbox}>
              <input
                id={`checkbox_${normalize(item.label)}`}
                className={cn(s.boxCheckbox, {
                  [s.checked]: item.isRefined,
                })}
                type="checkbox"
                disabled={!item.count && !item.isRefined}
                value={item.value}
                checked={item.isRefined}
                onChange={(event) => {
                  event.preventDefault()
                  createURL(item.value)
                  refine(item.value)
                }}
              />

              <label
                htmlFor={`checkbox_${normalize(item.label)}`}
                className={s.titleCheckbox}
              >
                {item.isRefined && <Check aria-hidden />}
                {item.label || item.value}
                {item.count ? (
                  <span className={s.totalCheckbox}>{item.count}</span>
                ) : null}
              </label>
            </div>
            {Boolean(item.data) && (
              <div className={s.childHirerachicalList}>
                <HierarchicalList
                  items={item.data!}
                  refine={refine}
                  createURL={createURL}
                />
              </div>
            )}
          </li>
        </React.Fragment>
      ))}
    </ul>
  )
}

export default RefinementHierarchie
