const Facebook = ({ ...props }) => (
  <svg width="14" height="24" viewBox="0 0 14 24" fill="none" {...props}>
    <path
      d="M1.32509 12.7819H3.95734V23.6129C3.95734 23.8297 4.12767 24 4.34444 24H8.8038C9.02057 24 9.19089 23.8297 9.19089 23.6129V12.8361H12.218C12.4115 12.8361 12.5819 12.689 12.6051 12.4955L13.0619 8.50839C13.0773 8.4 13.0386 8.29161 12.969 8.20645C12.8993 8.12129 12.7909 8.07484 12.6825 8.07484H9.19863V5.57419C9.19863 4.82323 9.60122 4.43613 10.4064 4.43613C10.5225 4.43613 12.6825 4.43613 12.6825 4.43613C12.8993 4.43613 13.0696 4.26581 13.0696 4.04903V0.387097C13.0696 0.170323 12.8993 0 12.6825 0H9.54702C9.5238 0 9.47734 0 9.39993 0C8.85799 0 6.96121 0.108387 5.46702 1.48645C3.81025 3.01161 4.04251 4.83871 4.0967 5.15613V8.08258H1.32509C1.10831 8.08258 0.937988 8.2529 0.937988 8.46968V12.4026C0.937988 12.6116 1.10831 12.7819 1.32509 12.7819Z"
      fill="white"
    />
  </svg>
)

export default Facebook
