import { FC } from 'react'
import { useRouter } from 'next/router'

import { TagPageCategory } from '@components/common/GoogleTagManager'

import i18n from 'i18n.json'
import NotFound from '../NotFound/NotFound'
import Head from './Head'
import { TagGeneric } from '../Eulerian'

const { defaultLocale } = i18n

const HeadError: FC = () => {
  const { locale = defaultLocale } = useRouter()

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <TagGeneric isError />
      <TagPageCategory pageCategory="erreur" />
      <NotFound />
    </>
  )
}

export default HeadError
