const Tick = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="8" fill="#757575" />
    <path
      d="M11.3333 5.5L6.74999 10.0833L4.66666 8"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Tick
