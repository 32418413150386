const MutualAssurance = ({ ...props }) => (
  <svg width="49" height="37" viewBox="0 0 49 37" fill="none" {...props}>
    <path
      d="M1 28.9681C1.96351 28.459 4.64205 27.7462 7.64819 28.9681C11.4059 30.4954 23.8351 35.6884 26.7256 35.9939C29.6161 36.2994 46.0921 24.997 47.5373 23.7751C48.5603 22.9102 47.8455 20.9741 45.803 20.5664M21.8877 22.7358C22.8497 22.9583 23.821 23.2193 24.4132 23.4696C25.5694 23.9584 27.9782 26.5243 29.038 27.7462C30.2302 27.1163 32.013 26.1879 33.9519 25.2014M21.8877 22.7358C20.9713 22.524 20.0633 22.3471 19.4993 22.2477L21.8877 22.7358ZM21.8877 22.7358L33.9519 25.2014M33.9519 25.2014C34.238 25.0558 34.5275 24.909 34.819 24.7616M34.819 24.7616C37.6612 23.3251 40.6939 21.8415 42.6234 21.0258C43.8903 20.4903 44.9598 20.3981 45.803 20.5664M34.819 24.7616C35.8789 23.4145 37.0158 20.9647 33.0847 19.4985C28.1709 17.6657 16.3197 14 13.7183 14C11.6371 14 5.33577 16.2401 2.44526 17.3602M45.803 20.5664C45.9957 19.5995 45.7452 17.7267 43.2015 17.9711C40.6579 18.2155 37.7096 19.8031 36.5534 20.5664"
      stroke="#292929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 3.09377C34 7.38677 28.0002 11 28.0002 11C28.0002 11 22 7.33335 22 3.10247C22 1.37502 23.3333 1.85348e-05 25 1.85348e-05C26.6667 1.85348e-05 28 2.06252 28 2.06252C28 2.06252 29.3333 1.85348e-05 31 1.85348e-05C32.6667 1.85348e-05 34 1.37502 34 3.09377Z"
      fill="#292929"
    />
  </svg>
)

export default MutualAssurance
