const Rainbow = ({ ...props }) => (
  <svg height="75" width="75" viewBox="0 0 595.28 595.28" {...props}>
    <g>
      <path
        d="M297.34,332.52c-44.04,0-79.88,35.85-79.88,79.88v24.12l45.96-7.03v-17.09c0-18.7,15.22-33.92,33.92-33.92
      c18.72,0,33.94,15.22,33.94,33.92v17.09l45.96,7.03v-24.12C377.24,368.37,341.39,332.52,297.34,332.52z"
      />
      <path
        d="M297.34,231.62c-102,0-184.99,82.99-184.99,184.99v35.99l45.96-7.03v-28.96c0-76.67,62.36-139.03,139.03-139.03
      c76.67,0,139.05,62.36,139.05,139.03v28.96l45.96,7.03v-35.99C482.35,314.61,399.34,231.62,297.34,231.62z"
      />
      <path
        d="M60.03,410.91c0-130.87,106.45-237.33,237.31-237.33c130.87,0,237.33,106.45,237.33,237.33v49.69l45.96,7.03v-56.72
      c0-156.21-127.08-283.29-283.29-283.29c-156.19,0-283.27,127.08-283.27,283.29v56.72l45.96-7.03V410.91z"
      />
    </g>
  </svg>
)

export default Rainbow
