import { FC } from 'react'

interface Props {
  title?: string
}

const Attention: FC<Props> = ({ title, ...props }) => {
  return (
    <svg viewBox="0 0 24 24" version="1.1" {...props}>
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-4.000000, -4.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <path d="M16,4 C22.627417,4 28,9.372583 28,16 C28,22.627417 22.627417,28 16,28 C9.372583,28 4,22.627417 4,16 C4,9.372583 9.372583,4 16,4 Z M16,19 C15.1715729,19 14.5,19.6715729 14.5,20.5 C14.5,21.3284271 15.1715729,22 16,22 C16.8284271,22 17.5,21.3284271 17.5,20.5 C17.5,19.6715729 16.8284271,19 16,19 Z M17,9 L15,9 L15,17.5 L17,17.5 L17,9 Z"></path>
        </g>
      </g>
    </svg>
  )
}

export default Attention
