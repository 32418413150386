export const fetchPageLegacy = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale = null,
  path,
  query = '',
  headers,
}: {
  locale?: string
  path: string
  query?: string
  apiUrl?: string
  headers?: Headers
}): Promise<Page> => {
  const encodedPath = encodeURIComponent(locale ? `/${locale}${path}` : path)
  const url = `${apiUrl}/v1/pages?path=${encodedPath}${
    query ? `&${query}` : ''
  }${locale ? `&locale=${locale}` : ''}`

  const res = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers,
  })

  if (!res?.ok) throw { status: res.status }
  const { data } = await res.json()
  const page = Array.isArray(data) ? data[0] : data
  return page
}
