import React, { FC, useRef, useEffect, useState } from 'react'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { More, Less } from '@components/icons/Sign'

import { scrollToTop } from '@utils/scrollTo'

import cn from 'classnames'
import s from './ProductDescriptionTab.module.css'

export type ProductDescriptionTabProps = {
  descriptionBlock: string[]
}

const ProductDescriptionTab: FC<ProductDescriptionTabProps> = ({
  descriptionBlock,
}) => {
  const screen = useScreen()

  const isRender = useRef(false)
  const [toggleTab, setToggleTab] = useState<number>(0)

  useEffect(() => {
    if (isRender.current && screen && [Screen.xs, Screen.sm].includes(screen))
      scrollToTop(
        document?.getElementById(`description-attr-${toggleTab}`),
        -72,
        true
      )
  }, [toggleTab])

  useEffect(() => {
    isRender.current = true
  }, [])

  return (
    <div className={s.container}>
      {descriptionBlock?.map((description, i) =>
        description.lastIndexOf('<div class="title">', 0) !== 0 ? (
          <div
            key={`product-description-concat-${i}`}
            className={cn(s.content, s.newDescription, 'bg-grey50')}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : (
          <button
            key={`product-description-concat-${i}`}
            id={`description-attr-${i}`}
            className={cn(s.descriptionCta, {
              [s.open]: toggleTab === i,
            })}
            onClick={() => setToggleTab(i === toggleTab ? null : i)}
          >
            <div
              className={cn(s.content, s.newDescription)}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {toggleTab === i ? (
              <Less className={s.tabIcon} />
            ) : (
              <More className={s.tabIcon} />
            )}
          </button>
        )
      )}
    </div>
  )
}

export default ProductDescriptionTab
