const Party = ({ ...props }) => (
  <svg width="47" height="40" viewBox="0 0 47 40" fill="none" {...props}>
    <path
      d="M23.2637 15.0156H24.2236V39.9994H23.2637V15.0156Z"
      fill="#292929"
    />
    <path
      d="M8.34219 18.963L7.68359 19.6774C12.4618 24.287 13.8439 28.8452 13.8439 40.0006H14.8038C14.8038 28.5173 13.3541 23.798 8.34213 18.9629L8.34219 18.963Z"
      fill="#292929"
    />
    <path
      d="M32.6836 40.0004H33.6435C33.6435 28.8451 35.0253 24.287 39.8042 19.6773L39.1453 18.9629C34.1328 23.7982 32.6836 28.5173 32.6836 40.0006L32.6836 40.0004Z"
      fill="#292929"
    />
    <path
      d="M9.01624 12.5315C8.99025 12.4725 8.99434 12.4045 9.02724 12.349L10.8336 9.3009C10.9178 9.1589 10.8034 8.98183 10.6394 9.00018L7.1873 9.38616C7.12092 9.39358 7.05522 9.36738 7.01216 9.31631L4.74795 6.63048C4.64018 6.50264 4.43242 6.55564 4.39906 6.71949L3.69081 10.1979C3.67773 10.2621 3.63399 10.3159 3.57375 10.3417L0.364055 11.7202C0.213266 11.7849 0.200341 11.9937 0.341991 12.0766L3.36541 13.8452C3.42175 13.8782 3.45846 13.9365 3.46376 14.0016L3.75262 17.5443C3.76619 17.7107 3.96568 17.7882 4.08805 17.6746L6.65463 15.2913C6.70371 15.2457 6.77234 15.2277 6.83746 15.2434L10.2164 16.0555C10.3773 16.0942 10.5129 15.9319 10.4462 15.7805L9.01624 12.5315ZM6.56632 14.1694C6.50119 14.1537 6.43253 14.1717 6.38344 14.2173L4.88331 15.6102C4.76095 15.7238 4.56146 15.6464 4.54789 15.4799L4.37859 13.405C4.37328 13.3399 4.33657 13.2816 4.28024 13.2486L2.50735 12.2115C2.36569 12.1287 2.37862 11.9199 2.52943 11.8551L4.41116 11.0472C4.47141 11.0214 4.51516 10.9676 4.52824 10.9034L4.94284 8.86742C4.97621 8.70358 5.18395 8.65058 5.29173 8.77842L6.61542 10.3485C6.65847 10.3995 6.72416 10.4258 6.79054 10.4183L8.81056 10.1926C8.97462 10.1743 9.08899 10.3513 9.00482 10.4933L7.94479 12.2815C7.91188 12.337 7.90778 12.405 7.93377 12.4641L8.77243 14.37C8.83907 14.5215 8.70345 14.6837 8.54257 14.645L6.56632 14.1694Z"
      fill="#292929"
    />
    <path
      d="M43.4254 10.3414C43.3652 10.3155 43.3215 10.2618 43.3084 10.1976L42.5995 6.71935C42.5662 6.55553 42.3584 6.50255 42.2507 6.63037L39.9864 9.31597C39.9433 9.36703 39.8776 9.39323 39.8113 9.38581L36.3599 8.99983C36.1958 8.98149 36.0814 9.15855 36.1656 9.30056L37.972 12.3487C38.0049 12.4042 38.009 12.4721 37.983 12.5312L36.5528 15.7805C36.4861 15.9319 36.6217 16.0942 36.7826 16.0555L40.1615 15.2434C40.2266 15.2277 40.2952 15.2457 40.3443 15.2912L42.9116 17.6747C43.034 17.7883 43.2335 17.7108 43.247 17.5444L43.5362 14.0016C43.5415 13.9365 43.5782 13.8782 43.6345 13.8452L46.6575 12.0766C46.7991 11.9937 46.7862 11.7849 46.6354 11.7202L43.4254 10.3414ZM42.7191 13.2489C42.6627 13.2818 42.626 13.3402 42.6207 13.4052L42.4514 15.4802C42.4378 15.6466 42.2384 15.7241 42.116 15.6105L40.6158 14.2175C40.5668 14.172 40.4981 14.154 40.433 14.1696L38.4566 14.6448C38.2958 14.6834 38.1602 14.5212 38.2268 14.3697L39.0658 12.4637C39.0918 12.4046 39.0877 12.3367 39.0548 12.2811L37.9948 10.493C37.9106 10.351 38.025 10.1739 38.189 10.1922L40.209 10.418C40.2754 10.4254 40.3411 10.3992 40.3842 10.3481L41.7082 8.77772C41.816 8.64988 42.0237 8.70288 42.0571 8.86674L42.4717 10.9034C42.4848 10.9676 42.5285 11.0214 42.5888 11.0472L44.4705 11.8551C44.6213 11.9199 44.6342 12.1287 44.4926 12.2115L42.7191 13.2489Z"
      fill="#292929"
    />
    <path
      d="M25.7614 3.97893C25.6959 3.9692 25.6394 3.92766 25.6106 3.868L23.9249 0.373401C23.8522 0.2226 23.6374 0.222592 23.5647 0.373388L21.8786 3.86801C21.8498 3.92766 21.7934 3.9692 21.7279 3.97893L17.9493 4.54018C17.7867 4.56434 17.721 4.76354 17.8375 4.87964L20.577 7.61101C20.6229 7.65674 20.6437 7.72184 20.633 7.78572L19.987 11.6383C19.9595 11.8022 20.1325 11.9262 20.279 11.8475L23.6498 10.0351C23.7089 10.0034 23.7801 10.0034 23.8392 10.0351L27.21 11.8475C27.3565 11.9262 27.5295 11.8022 27.502 11.6383L26.856 7.78571C26.8453 7.72184 26.8662 7.65674 26.912 7.61101L29.6512 4.87963C29.7677 4.76353 29.7021 4.56434 29.5394 4.54017L25.7614 3.97893Z"
      fill="#292929"
    />
  </svg>
)

export default Party
