
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { FC, useState, useEffect } from 'react'
import type { AppProps } from 'next/app'
import Router from 'next/router'

import { ManagedUIContext } from '@components/ui/context'
import { UserSettingsProvider } from '@context/session'
import { Head } from '@components/common'
import { LayoutLoading } from '@components/ui'

import 'focus-visible'
import scrollRestoration from '@lib/scrollRestoration'

import '@assets/main.css'
import 'keen-slider/keen-slider.min.css'

import smoothscroll from 'smoothscroll-polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'

const Noop: FC = ({ children }) => <>{children}</>

const __Page_Next_Translate__ = function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop
  scrollRestoration()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    Router.events.on('routeChangeStart', () => setIsLoading(true))
    Router.events.on('routeChangeComplete', () => setIsLoading(false))
    Router.events.on('routeChangeError', () => setIsLoading(false))
    smoothscroll.polyfill()
  }, [])

  return (
    <UserSettingsProvider sessionData={pageProps.session || null}>
      <ManagedUIContext>
        <Head />
        <Layout pageProps={pageProps}>
          {isLoading && <LayoutLoading />}
          <Component {...pageProps} />
        </Layout>
      </ManagedUIContext>
    </UserSettingsProvider>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  