import React, { useEffect, useRef, FC } from 'react'
import { useRouter } from 'next/router'

import Trustpilot from '../Trustpilot'
import cn from 'classnames'
import s from './TrustBox.module.css'

// TrustMultiSource seems to be the same component
const TrustBox = () => {
  const { locale } = useRouter()
  const trustpilotRef = useRef<HTMLDivElement>(null)

  const dataLocale = (locale: string) => {
    switch (locale) {
      case 'fr':
        return 'fr-FR'
      case 'en':
        return 'en-GB'
      case 'de':
        return 'de-DE'
      case 'es':
        return 'es-ES'
      case 'it':
        return 'it-IT'
      default:
        return 'fr-FR'
    }
  }

  const truspilotUrl = `https://${
    locale === 'en' ? 'uk' : locale
  }.trustpilot.com/review/smallable.com`

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustpilotRef.current, true)
    }
  }, [])

  return (
    <>
      <Trustpilot />
      <div className={s.root}>
        <div
          ref={trustpilotRef}
          className={cn('trustpilot-widget', s.container)}
          data-locale={dataLocale(locale)}
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="4f560fdd0000640005136c1a"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-review-languages={locale}
          data-font-family="Lato"
        >
          <a href={truspilotUrl} target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div>
    </>
  )
}

export default TrustBox
