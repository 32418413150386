const Matrice = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M6.66667 2H2V6.66667H6.66667V2Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9997 2H9.33301V6.66667H13.9997V2Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9997 9.33301H9.33301V13.9997H13.9997V9.33301Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 9.33301H2V13.9997H6.66667V9.33301Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Matrice
