import { FC, useEffect, useState, FormEvent } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'
import { useRouter } from 'next/router'

import { Arrow } from '@components/icons'
import { tagEventCS } from '@components/common/ContentSquare'
import { getCookie, setCookie } from '@utils/cookies'

import { MODALS } from '@constants'

import cn from 'classnames'
import s from './Newsletter.module.css'
import { useNewsletterContext } from '@context/newsletter'

export interface NewsletterProps {
  className?: string
}

const Newsletter: FC<NewsletterProps> = ({ className }) => {
  const { t } = useTranslation()
  const { newsletterSubscription } = useUserSettings()
  const { getNewsletterDiscount, getNewsletterMinimumOrder } =
    useNewsletterContext()

  const { setModalView, openModal } = useUI()
  const { asPath } = useRouter()

  const [value, setValue] = useState('')

  const openNewsletterModal = async ({
    e,
    autoDisplay,
  }: {
    e?: FormEvent
    autoDisplay: boolean
  }) => {
    e?.preventDefault()

    const props = {
      email: value,
      autoDisplay,
    }
    setModalView(MODALS.NEWSLETTER, props)
    openModal()
  }

  useEffect(() => {
    if (
      newsletterSubscription === false &&
      asPath !== '/newsletter-subscription'
    ) {
      const alreadyView = getCookie('_newsletter_proposal', document.cookie)
      if (alreadyView !== 'view') {
        setCookie('_newsletter_proposal', 'view', 5)
        tagEventCS({ eventName: 'newsletter_proposal_showed' })
        openNewsletterModal({ autoDisplay: true })
      }
    }
  }, [newsletterSubscription])

  return (
    <section className={cn(s.container, className)}>
      <h2 className={s.title}>
        {t('layout:footer.newsletter.label', {
          discount: getNewsletterDiscount(),
        })}
      </h2>
      <div>
        <p className={s.text}>
          {t('layout:footer.newsletter.text', {
            discount: getNewsletterDiscount(),
          })}
        </p>
        <p className={s.miniText}>
          {t('layout:footer.newsletter.annotation', {
            minimumOrder: getNewsletterMinimumOrder(),
          })}
        </p>
      </div>
      <form
        className={s.form}
        action="#"
        method="get"
        onSubmit={(e) => openNewsletterModal({ e, autoDisplay: false })}
      >
        <input
          className={s.input}
          type="email"
          name="email"
          value={value}
          placeholder="E-mail*"
          onChange={(e) => setValue(e.target.value)}
          autoCorrect="off"
          data-cs-mask
        />
        <button
          className={s.button}
          onClick={(e) => openNewsletterModal({ e, autoDisplay: false })}
          aria-label={t('newsletter:submit')}
        >
          <Arrow
            width="1.5rem"
            height="1.5rem"
            stroke="#121212"
            strokeWidth={2}
          />
        </button>
      </form>
    </section>
  )
}

export default Newsletter
