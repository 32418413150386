const Confirm = ({ size = '56', ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_101_1111)">
        <path
          d="M45.1682 19.6647L45.6278 20.1243L45.1682 19.6647L23.7976 41.0366C23.7976 41.0366 23.7976 41.0366 23.7976 41.0366C23.5565 41.2778 23.2418 41.3981 22.9249 41.3981C22.608 41.3981 22.2932 41.2777 22.0521 41.0366L21.5925 41.4963L22.0521 41.0366L12.1013 31.0858C11.6193 30.6038 11.6193 29.8236 12.1013 29.3416C12.5833 28.8596 13.3636 28.8596 13.8456 29.3416L22.4653 37.9614L22.9249 38.421L23.3845 37.9614L43.4241 17.9218L43.4245 17.9214C43.9065 17.4386 44.6863 17.4386 45.1682 17.9205C45.6502 18.4025 45.6502 19.1827 45.1682 19.6647Z"
          fill="#1F1F1F"
          stroke="white"
          strokeWidth="1.3"
        />
        <circle cx="28" cy="28" r="27.5" stroke="#1F1F1F" />
      </g>
      <defs>
        <clipPath id="clip0_101_1111">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Confirm
