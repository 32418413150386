import deburr from 'lodash.deburr'
import { paramCase } from 'param-case'

const COCUR_RULES = {
  '°': '0',
  '¹': '1',
  '²': '2',
  '³': '3',
  '⁴': '4',
  '⁵': '5',
  '⁶': '6',
  '⁷': '7',
  '⁸': '8',
  '⁹': '9',
  '₀': '0',
  '₁': '1',
  '₂': '2',
  '₃': '3',
  '₄': '4',
  '₅': '5',
  '₆': '6',
  '₇': '7',
  '₈': '8',
  '₉': '9',
  Ä: 'AE',
  Ö: 'OE',
  Ü: 'UE',
  ß: 'ss',
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
}

const COCUR_REGEXP = new RegExp(Object.keys(COCUR_RULES).join('|'), 'g') // Or /(ß|ä|ö|ü)|([°-⁹])/gi

const toSlug = (str: string) => {
  const s = str.replace(COCUR_REGEXP, (c) => COCUR_RULES[c] || c)
  return paramCase(deburr(s))
}

export default toSlug
