import { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '@components/ui'
import { ChevronUp } from '@components/icons'
import { MediaQueries } from '@components/common'

import cn from 'classnames'
import s from './BreadCrumb.module.css'

export interface BreadCrumbItem {
  href?: string
  title: string
}

export interface BreadCrumbProps {
  displayLastItemOnMobile?: boolean
  paths: Array<BreadCrumbItem>
  productName?: string
}

const getTitle = (title: string) => {
  return title?.trim().replace(/(^\/\s)|(\s\/$)/g, '')
}

const BreadCrumbElement = ({
  href,
  title,
  withSlash = false,
}: BreadCrumbItem & { withChevron?: boolean; withSlash?: boolean }) => {
  const children = (
    <>
      <span className={s.chevron} aria-hidden>
        <ChevronUp />
      </span>
      <span className={s.title}>{getTitle(title)}</span>
      {withSlash && <span className={s.slash}>&nbsp;/&nbsp;</span>}
    </>
  )

  if (href) {
    return (
      <Link next href={href} className={cn(s.element, s.link)}>
        {children}
      </Link>
    )
  }

  return <span className={s.element}>{children}</span>
}

const BreadCrumb: FC<BreadCrumbProps> = ({
  paths = [],
  displayLastItemOnMobile = false,
  productName = '',
}) => {
  const { asPath, locale } = useRouter()
  const { t } = useTranslation()

  if (!paths.length) {
    return null
  }

  return (
    <nav
      className={cn(s.breadcrumb, {
        [s.breadcrumbLastItem]: displayLastItemOnMobile,
      })}
    >
      <BreadCrumbElement
        href={`/${locale}`}
        title={t('product:breadcrumb.home')}
        withSlash
      />
      {paths.map((link, i) => (
        <BreadCrumbElement
          key={`link-${i}`}
          href={link.href !== `/${locale}${asPath}` ? link.href : undefined}
          title={link.title}
          withSlash={i + 1 < paths.length}
        />
      ))}
      <MediaQueries hidden={['xs', 'sm']}>
        <span className={cn(s.title, s.element, s.productName)}>
          &nbsp;/&nbsp;{productName}
        </span>
      </MediaQueries>
    </nav>
  )
}

export default BreadCrumb
