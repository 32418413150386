import React, { FC } from 'react'

import { getValueFromHtmlText } from '@utils/string'

import cn from 'classnames'
import s from './GuideBlock.module.css'

export interface GuideBlockProps {
  layout: string
  media?: BlocksMedia
  description?: string
}

const GuideBlock: FC<GuideBlockProps> = ({ layout, media, description }) => {
  const title = getValueFromHtmlText(description, 'cms-home-title')
  return (
    <section
      className={cn(
        'guide_block',
        s.container,
        { [s.layout_right]: layout === 'right' },
        { [s.layout_left]: layout === 'left' }
      )}
    >
      {media?.formats?.origin ? (
        <picture className={s.mediaContainer}>
          <source
            media="(min-width: 768px)"
            srcSet={`
                ${media.formats.large} 2x,
                ${media.formats.origin}
              `}
          />
          <source srcSet={media.formats.small} />
          <img src={media.formats.origin} alt={title} />
        </picture>
      ) : null}
      {description && (
        <div className={s.descriptionContainer}>
          <div
            className={cn('rich-text', s.description)}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      )}
    </section>
  )
}

export default GuideBlock
