import React, { FC } from 'react'
import NextHead from 'next/head'
import TagUserInformations from './TagUserInformations'

declare global {
  interface Window {
    dataLayer: { [key: string]: any }
  }
}

const GoogleTagManager: FC<{}> = () => {
  const GTM_ACCOUNT = process.env.NEXT_PUBLIC_GTM_ACCOUNT || ''
  if (!GTM_ACCOUNT?.length) return null

  const gtmScript = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ACCOUNT}');
`

  return (
    <>
      <NextHead>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: gtmScript }}
        />
      </NextHead>
      <TagUserInformations />
    </>
  )
}

export default GoogleTagManager
