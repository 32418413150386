import { FC } from 'react'
import s from './ProductDesigner.module.css'
import RichText from '@components/blocks-cms/RichText'
import useTranslation from 'next-translate/useTranslation'
import { MediaQueries } from '@components/common'

export interface ProductDesignerProps {
  designer: ProductDesigner
}

const ProductDesigner: FC<ProductDesignerProps> = ({ designer }) => {
  const { t } = useTranslation()

  return (
    <section className={s.container}>
      <div className={s.content}>
        <h2 className={s.title}>{t('product:designer')}</h2>
        <section className={s.designer}>
          {designer.imageUrl && (
            <MediaQueries hidden={['xs', 'sm', 'md']}>
              <div className={s.mediaContainer}>
                <img src={designer.imageUrl} alt="Image du designer" />
              </div>
            </MediaQueries>
          )}
          <div className={s.descriptionContainer}>
            <h1>{designer.name}</h1>
            <div className={s.description}>
              <RichText content={designer.shortDescription} />
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

export default ProductDesigner
