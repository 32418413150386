const CustomerService = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M2 2H22V6.176L21.71 6.118C21.71 3.75933 17.7162 2.58 13.0375 2.58L13.0376 15.446C13.0376 18.3073 13.2019 20.0473 13.5306 20.666C13.6466 20.898 13.8496 21.101 14.1396 21.275C14.4296 21.449 14.7389 21.5553 15.0676 21.594C15.3962 21.6327 15.8506 21.652 16.4306 21.652L16.4886 22H7.55657L7.61457 21.652C8.19457 21.652 8.6489 21.6423 8.97757 21.623C9.32557 21.5843 9.6349 21.4683 9.90557 21.275C10.1956 21.0817 10.3986 20.8787 10.5146 20.666C10.6499 20.4533 10.7659 20.0763 10.8626 19.535C10.9592 18.8777 11.0076 17.5147 11.0076 15.446L11.0075 2.58C6.32888 2.58 2.348 3.75933 2.348 6.118L2 6.176V2Z"
      fill="#1F1F1F"
    />
  </svg>
)

export default CustomerService
