export const StarTruspilot = ({ color = 'white', ...props }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M10.6389 7.44424H15.9441L11.6536 10.5558L8.99619 12.4788L12.024 11.7L13.2867 15.6L8.99619 12.4788L4.70571 15.6L6.34846 10.5558L2.05798 7.43457L7.3631 7.44424L9.00585 2.40002L10.6389 7.44424Z"
      fill={color}
    />
  </svg>
)

export default StarTruspilot
