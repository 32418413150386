const StarTruspilotFull = ({ ...props }) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M54.5988 22.1069H34.5037L28.3177 3L22.0952 22.1069L2 22.0703L18.2518 33.8931L12.0293 53L28.2811 41.1772L44.5329 53L38.347 33.8931L54.5988 22.1069Z"
      fill="#00B67A"
    />
    <path
      d="M39.7379 38.2124L38.347 33.8932L28.3177 41.1772L39.7379 38.2124Z"
      fill="#005128"
    />
  </svg>
)

export default StarTruspilotFull
