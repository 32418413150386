const Contract = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12.0003 2H4.00033C3.26395 2 2.66699 2.56711 2.66699 3.26667V13.4C2.66699 14.0996 3.26395 14.6667 4.00033 14.6667H12.0003C12.7367 14.6667 13.3337 14.0996 13.3337 13.4V3.26667C13.3337 2.56711 12.7367 2 12.0003 2Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4.5"
      y="1.16699"
      width="7"
      height="1.66667"
      rx="0.5"
      fill="#FAFAFA"
      stroke="#555555"
    />
  </svg>
)

export default Contract
