import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import CheckboxItem from './CheckboxItem'
import ButtonItem from './ButtonItem'
import ToggleItem from './ToggleItem'
import { Arrow } from '@components/ui'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import cn from 'classnames'
import s from './FiltersList.module.css'

interface FiltersListProps {
  filter: [string, ProductListFilter]
  changeFiltersSelected: changeFiltersSelectedProps
}

const FiltersList: FC<FiltersListProps> = ({
  filter,
  changeFiltersSelected,
}) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const [openFilter, setOpenFilter] = useState(false)
  const [currentFiIter, setCurrentFiIter] = useState([...filter?.[1]?.values])
  const [searchField, setSearchField] = useState('')

  const normalizeString = (str: string) =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  useEffect(() => {
    if (!searchField.length) setCurrentFiIter([...filter?.[1]?.values])
    let textSearch = normalizeString(searchField)
    const results = [...filter?.[1]?.values]?.filter(
      (elem) =>
        normalizeString(elem.title || elem.key).indexOf(textSearch) != -1
    )
    setCurrentFiIter(results)
  }, [searchField])

  useEffect(() => {
    const newFilters = [...filter?.[1]?.values]
    setCurrentFiIter(newFilters)
    if (newFilters?.some((f) => f.selected)) setOpenFilter(true)
  }, [filter])

  useEffect(() => {
    setSearchField('')
  }, [openFilter])

  return (
    <div
      id={filter?.[0]}
      className={cn(s.container, {
        [s.disabled]:
          !openFilter &&
          ((filter?.[0] !== 'in_stock' && filter?.[0] !== 'greenable') ||
            [Screen.xs, Screen.sm, Screen.md].includes(screen)),
      })}
      data-cs-override-id={`filterList-${filter?.[1]?.label || filter?.[0]}`}
    >
      {(filter?.[0] === 'in_stock' || filter?.[0] === 'greenable') &&
      ![Screen.xs, Screen.sm, Screen.md].includes(screen) ? (
        <ul className={s.filtersList}>
          {currentFiIter?.map((elem, index) => (
            <ToggleItem
              key={`Filters-item-${elem.key}-${index}`}
              filter={elem}
              parent={filter?.[0]}
              changeFiltersSelected={changeFiltersSelected}
            />
          ))}
        </ul>
      ) : (
        <>
          <button className={s.cta} onClick={() => setOpenFilter(!openFilter)}>
            {filter?.[1]?.label}
            <Arrow
              width="10"
              height="10"
              stroke="white"
              className={s.arrowDown}
              aria-hidden
            />
          </button>
          {filter?.[1]?.config?.search && (
            <input
              className={s.inputSearch}
              aria-label={t('block:filter.ariaSearchBrand')}
              type="text"
              placeholder={t('block:filter.searchBrand')}
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            />
          )}
          <ul
            className={cn(
              s.filtersList,
              { [s.buttonItemContainer]: filter?.[1]?.config?.inline },
              { [s.searchable]: filter?.[1]?.config?.search }
            )}
          >
            {currentFiIter?.map((elem, index) => (
              <React.Fragment key={`Filters-item-${elem.key}-${index}`}>
                {filter?.[1]?.config?.inline ? (
                  <ButtonItem
                    filter={elem}
                    parent={filter?.[0]}
                    changeFiltersSelected={changeFiltersSelected}
                  />
                ) : (
                  <CheckboxItem
                    filter={elem}
                    parent={filter?.[0]}
                    showCount={filter?.[1]?.config?.show_count}
                    changeFiltersSelected={changeFiltersSelected}
                  />
                )}
              </React.Fragment>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default FiltersList
