import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { Link } from '@components/ui'
import { Chevron } from '@components/icons'

import { removeParameterFromUrl } from '@utils/url/query'
import { generatePaginationLinks } from '@utils/pagination'

import cn from 'classnames'
import s from './NextPagination.module.css'

export interface NextPaginationProps {
  currentPage: string | number
  total: number
  chunk: number
  isPaginate: boolean
  shallowNavigation?: boolean
  anchor?: string
}

const NextPagination: FC<NextPaginationProps> = ({
  currentPage,
  total,
  chunk,
  isPaginate,
  shallowNavigation = true,
  anchor,
}) => {
  const { t } = useTranslation()
  const { locale, asPath } = useRouter()

  const currentPageInt =
    typeof currentPage === 'string' ? Number(currentPage) : currentPage
  const nbPages = Math.ceil(total / chunk)

  const pageNumbers = generatePaginationLinks({
    currentPage: currentPageInt,
    nbPages,
  })

  const pathWithoutPage = `/${locale + removeParameterFromUrl(asPath, '_page')}`
  const basePath = `${pathWithoutPage}${
    pathWithoutPage.indexOf('?') === -1 ? '?' : '&'
  }`
  const withAnchor = anchor ? `#${anchor}` : ''

  return (
    <div className={s.container}>
      {nbPages > 1 && isPaginate && (
        <ol className={s.pagination}>
          {currentPageInt > 1 && (
            <li>
              <Link
                next
                shallowNavigation={shallowNavigation}
                className={cn(s.paginationItem, s.chevron)}
                href={`${basePath}_page=${currentPageInt - 1}${withAnchor}`}
                tabIndex={0}
                aria-label={`${t('layout:common.ariaNavigate')} ${t(
                  'block:pagination.previousPage'
                )}`}
              >
                <Chevron width={8} height={20} fill="#000" />
              </Link>
            </li>
          )}

          {pageNumbers.map((nbr, i) => (
            <li key={`pagination-item-${i}`}>
              <Link
                next
                shallowNavigation={shallowNavigation}
                className={cn(s.paginationItem, {
                  [s.selected]: nbr === currentPageInt,
                })}
                href={`${basePath}_page=${nbr}${withAnchor}`}
                tabIndex={0}
                aria-label={`${t('layout:common.ariaNavigate')} ${t(
                  'block:pagination.page',
                  { numPage: nbr }
                )}`}
              >
                {nbr}
              </Link>
            </li>
          ))}

          {currentPageInt < nbPages && (
            <li>
              <Link
                next
                shallowNavigation={shallowNavigation}
                className={cn(s.paginationItem)}
                href={`${basePath}_page=${currentPageInt + 1}${withAnchor}`}
                tabIndex={0}
                aria-label={`${t('layout:common.ariaNavigate')} ${t(
                  'block:pagination.nextPage'
                )}`}
              >
                <Chevron width={8} height={20} fill="#000" />
              </Link>
            </li>
          )}
        </ol>
      )}
    </div>
  )
}

export default NextPagination
