import useTranslation from 'next-translate/useTranslation'
import { ClearRefinementsProps, useClearRefinements } from 'react-instantsearch'

import s from './Refinement.module.css'

export default function ClearRefinements(props: ClearRefinementsProps) {
  const { t } = useTranslation()
  const { refine } = useClearRefinements(props)

  return (
    <button className={s.buttonAction} onClick={() => refine()}>
      {t('block:filter.deleteFilters')}
    </button>
  )
}
