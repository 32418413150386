export const enableHtml100vh = () => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined')
    document.body.style.height = '100vh'
}

export const disableHtml100vh = () => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined')
    document.body.style.height = '100%'
}

export const disableTouchAction = () => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined')
    document.body.style.touchAction = 'none'
}

export const enableTouchAction = () => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined')
    document.body.style.touchAction = 'auto'
}
