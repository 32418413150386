import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'
import useWindowSize from './useWindowSize'

const fullConfig = resolveConfig(tailwindConfig)

const getValue = (str: string): number | undefined => {
  const matches = (str || '').match(/^(\d)+/)
  const value = matches && matches[0]
  return value ? +value : undefined
}

export enum Screen {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

const SCREEN_WIDTH = {
  sm: getValue(fullConfig.theme.screens.sm) as number,
  md: getValue(fullConfig.theme.screens.md) as number,
  lg: getValue(fullConfig.theme.screens.lg) as number,
  xl: getValue(fullConfig.theme.screens.xl) as number,
  '2xl': getValue(fullConfig.theme.screens['2xl']) as number,
}

const getScreenFromWidth = (width?: number): Screen | undefined => {
  if (!width) {
    return undefined
  }

  if (width && width >= SCREEN_WIDTH['2xl']) {
    return Screen['2xl']
  }

  if (width && width >= SCREEN_WIDTH.xl) {
    return Screen.xl
  }

  if (width && width >= SCREEN_WIDTH.lg) {
    return Screen.lg
  }

  if (width && width >= SCREEN_WIDTH.md) {
    return Screen.md
  }
  if (width && width >= SCREEN_WIDTH.sm) {
    return Screen.sm
  }

  return Screen.xs
}

const useScreen = (): Screen | undefined => {
  const { width } = useWindowSize()
  return getScreenFromWidth(width)
}

export default useScreen
