import React, { FC } from 'react'

import { useSortBy, UseSortByProps } from 'react-instantsearch'

import s from './SortMenuAlgolia.module.css'
import { getAlgoliaIndexName, indexToSort } from '@lib/lib-algolia/algolia'

const SortByAlgolia: FC<
  UseSortByProps & {
    deliveryCountryCode: string
    locale: string
    isVP: boolean
  }
> = ({ deliveryCountryCode, locale, isVP, ...props }) => {
  const { currentRefinement, options, refine } = useSortBy(props)

  return (
    <select
      className={s.selector}
      onChange={(event) => {
        const params = new URLSearchParams(window.location.search)
        params.set('_sort', event.target.value)
        // add query param _sort in url egale a event.target.value
        history.pushState({}, '', `${window.location.pathname}?${params}`)
        refine(
          getAlgoliaIndexName(
            deliveryCountryCode,
            locale,
            event.target.value,
            isVP
          )
        )
      }}
      defaultValue={indexToSort(currentRefinement)}
    >
      {options.map(({ label, value }) => (
        <option aria-labelledby="sort_selector" key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}

export default SortByAlgolia
