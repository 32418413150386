const SearchIcon = ({ ...props }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49996 0.166687C1.65901 0.166687 0.166626 1.65907 0.166626 3.50002C0.166626 5.34097 1.65901 6.83335 3.49996 6.83335C4.24005 6.83335 4.92381 6.59216 5.47692 6.18408L6.97974 7.68691C7.175 7.88217 7.49158 7.88217 7.68685 7.68691C7.88211 7.49165 7.88211 7.17506 7.68685 6.9798L6.18402 5.47698C6.5921 4.92387 6.83329 4.24011 6.83329 3.50002C6.83329 1.65907 5.34091 0.166687 3.49996 0.166687ZM5.25368 5.0392C5.61451 4.6284 5.83329 4.08976 5.83329 3.50002C5.83329 2.21136 4.78862 1.16669 3.49996 1.16669C2.21129 1.16669 1.16663 2.21136 1.16663 3.50002C1.16663 4.78868 2.21129 5.83335 3.49996 5.83335C4.0897 5.83335 4.62833 5.61457 5.03913 5.25374L5.25368 5.0392Z"
      fill="#121212"
    />
  </svg>
)

export default SearchIcon
