const Pinterest = ({ ...props }) => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" {...props}>
    <path
      d="M16.4759 1.76395C18.0063 2.88926 19.0866 4.55471 19.3567 6.62527C19.4917 7.48051 19.4917 8.33574 19.4467 9.14596C19.3116 11.1265 18.6365 13.062 17.5562 14.5474C16.4759 16.0328 14.9455 17.1131 13.0549 17.3382C12.6048 17.3832 12.1097 17.4282 11.6146 17.3832C10.4892 17.2932 9.85908 16.8431 9.09387 16.3479C8.95883 16.2579 8.82379 16.1679 8.64375 16.0328C8.37367 17.5182 8.01357 19.0036 7.51844 20.264C6.97829 21.7044 6.16807 22.9197 5.04277 23.7299L4.68267 24L4.63766 23.5949C4.14252 19.9489 4.90773 17.0231 5.67294 14.1423C5.94301 13.152 6.21308 12.1618 6.39313 11.2165C5.94301 10.4063 5.80797 9.32601 5.98802 8.29073C6.07805 7.57053 6.34812 6.89535 6.70822 6.31019C7.06832 5.72503 7.60846 5.31992 8.19362 5.13987C8.64375 5.00483 9.18389 5.00483 9.76905 5.22989C11.6596 5.9951 10.9844 8.02065 10.3542 10.0912C10.1291 10.7214 9.9491 11.3966 9.81406 11.9817C9.589 13.1971 9.72404 14.1873 11.0744 14.4574C11.8396 14.5924 12.5148 14.4574 13.1 14.0973C13.9102 13.6022 14.5403 12.7019 14.9905 11.6666C15.4406 10.5864 15.6657 9.32601 15.7107 8.11068C15.7107 6.49024 15.3056 5.00483 14.4503 4.10459C13.4601 3.11432 12.2447 2.61918 11.0294 2.52916C9.81406 2.43913 8.59873 2.70921 7.47343 3.29437C6.39313 3.87953 5.40286 4.68975 4.77269 5.77004C4.0525 6.89535 3.73741 8.29073 3.96247 9.77613C4.0525 10.2263 4.23255 10.5864 4.45761 10.9464C4.90773 11.7117 5.35785 12.3868 4.54763 13.8272L4.45761 14.0073L4.27756 13.9623C3.01721 13.6922 2.11697 13.062 1.57682 12.2068C1.03667 11.3516 0.811614 10.2713 0.766602 9.01092C0.811614 6.62527 1.66685 4.5097 3.10724 2.93427C4.54763 1.35884 6.57318 0.278547 8.95883 0.0534855C9.18389 0.00847322 9.40895 0.00847305 9.67903 0.00847305C9.90409 0.00847305 10.1292 0.00847305 10.3992 0.00847305C12.6498 -0.0815514 14.8104 0.54862 16.4759 1.76395Z"
      fill="white"
    />
  </svg>
)

export default Pinterest
