import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { AnchorHTMLAttributes, FC } from 'react'

interface Props extends NextLinkProps {
  next?: boolean
  shallowNavigation?: boolean
  forceScroll?: boolean
}

interface HtmlProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  next?: boolean
  shallowNavigation?: boolean
  forceScroll?: boolean
}

declare global {
  interface Window {
    Translatable: {
      initialized: boolean
      currentLang: string
    }
  }
}

export type LinkProps = Props | HtmlProps

const Link: FC<LinkProps> = ({
  next,
  shallowNavigation = false,
  forceScroll = false,
  children,
  ...props
}) => {
  const isTranslatableLoaded =
    typeof window !== 'undefined' && window.Translatable?.initialized

  if (isTranslatableLoaded && props.href) {
    props.href = props.href
      .toString()
      .replace(/\/en\//g, `/${window.Translatable.currentLang}/`)
  }

  return next && !isTranslatableLoaded ? (
    <NextLink
      {...(props as NextLinkProps)}
      shallow={shallowNavigation}
      scroll={forceScroll || null}
      passHref={true}
    >
      <a {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}>{children}</a>
    </NextLink>
  ) : (
    <a {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}>{children}</a>
  )
}

export default Link
