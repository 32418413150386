import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Alert } from '@components/common'
import { Status } from '@components/common/Alert/Alert'

import { ProductStocks } from '@utils/stocks'

export interface ProductDeliveryInfoProps {
  className?: string
  stocks: ProductStocks | null
  sku: string
}

const ProductDeliveryInfo: FC<ProductDeliveryInfoProps> = ({
  className,
  sku,
  stocks,
}) => {
  const { t } = useTranslation()

  const quantity = stocks ? stocks.declinations[sku]?.quantity : 0
  const isOnDemand = stocks?.availability?.onDemand

  if (
    quantity <= 0 &&
    stocks?.isSalableOutOfStock &&
    stocks?.availability?.onDemand
  ) {
    const { from, to } = stocks?.availability

    return (
      <div>
        <Alert
          status={Status.Info}
          message={
            isOnDemand
              ? t('product:delivery.isOnDemand', {
                  from: Math.floor(from / 7),
                  to: Math.ceil(to / 7),
                })
              : t('product:delivery.isSalableOutOfStock', {
                  from: Math.floor(from / 7),
                  to: Math.ceil(to / 7),
                })
          }
        />
      </div>
    )
  }
  return null
}

export default ProductDeliveryInfo
