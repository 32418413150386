import React, { FC, useEffect, useState } from 'react'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import useTranslation from 'next-translate/useTranslation'

import ProductMediaVideo from '@components/product/ProductMediaVideo'

import { useKeenSlider } from 'keen-slider/react'
import { useUI } from '@components/ui/context'
import { MODALS } from '@constants'

import cn from 'classnames'
import s from './ProductMediaSlider.module.css'

export interface ProductMediaSliderProps {
  productMedias: Array<Media>
  productName: string
  productVideo?: string
}

const ProductMediaSlider: FC<ProductMediaSliderProps> = ({
  productMedias,
  productName,
  productVideo,
}) => {
  const { t } = useTranslation()
  const { isSiteReverted } = useUI()

  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    rtl: isSiteReverted,
    defaultAnimation: {
      duration: 600,
    },
    loop: true,
    slides: { perView: 1 },
    created: () => setIsMounted(true),
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
  })

  useEffect(() => {
    if (currentSlide !== selectedMediaIndex) setSelectedMediaIndex(currentSlide)
  }, [currentSlide])

  useEffect(() => {
    if (instanceRef && currentSlide !== selectedMediaIndex)
      instanceRef.current?.moveToIdx(selectedMediaIndex)
  }, [selectedMediaIndex])

  const screen = useScreen()
  const isDesktopDevice = screen && ![Screen.xs, Screen.sm].includes(screen)
  const [mediaPosition, setMediaPosition] = useState('0% 0%')
  const handleMouseMove = (e: React.MouseEvent) => {
    const node = e.target as HTMLElement
    const { left, top, width, height } = node.getBoundingClientRect()
    const x = ((e.pageX - left - window.pageXOffset) / width) * 100
    const y = ((e.pageY - top - window.pageYOffset) / height) * 100
    setMediaPosition(`${x}% ${y}%`)
  }

  const { setModalView, openModal, closeModal } = useUI()
  const openMediaModal = (indexMedia: number) => {
    const props = {
      productName: productName,
      productMedias: productMedias,
      currentIndexMedia: indexMedia,
    }
    setModalView(MODALS.PRODUCT_MEDIA, props)
    openModal()
  }

  useEffect(() => {
    closeModal()
  }, [])

  return (
    <div className={s.root}>
      <div
        ref={sliderRef}
        className="keen-slider h-full transition-opacity duration-150"
        style={{ opacity: isMounted ? 1 : 0 }}
      >
        {productMedias?.map((image, i) => (
          <div key={`productMedias-slide-${i}`} className="keen-slider__slide">
            <div
              onMouseMove={
                isDesktopDevice ? (e) => handleMouseMove(e) : undefined
              }
              className={s.figureImg}
              style={{
                backgroundImage:
                  isDesktopDevice && mediaPosition !== '0% 0%'
                    ? `url(${image?.thickbox})`
                    : '',
                backgroundPosition: mediaPosition,
              }}
            >
              <img
                className={cn(s.img, { [s.mobile]: !isDesktopDevice })}
                src={image?.thickbox}
                srcSet={`
                  ${image?.['622x622q80']},
                  ${image?.['720x720q80']} 2x,"
                  ${image?.thickbox} 3x,
                `}
                width="100%"
                height="100%"
                alt={t('product:productMediaSlider.altProductImage', {
                  productName: productName,
                  id: i,
                })}
                aria-label={t('product:productMediaSlider.ariaProductImage', {
                  productName: productName,
                  id: i,
                })}
                onClick={() => openMediaModal(i)}
              />
            </div>
          </div>
        ))}
      </div>
      {instanceRef && (
        <div className={cn(s.positionIndicatorsContainer)}>
          {[
            ...Array(
              instanceRef.current?.track?.details?.slides?.length
            ).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                role="tab"
                className={cn(s.positionIndicator, {
                  [s.positionIndicatorActive]: selectedMediaIndex === idx,
                })}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                aria-label={t(
                  'product:productMediaSlider.ariaPositionIndicator'
                )}
                aria-labelledby={`product-media-${idx}`}
                aria-controls={`product-media-${idx}`}
                aria-selected={selectedMediaIndex === idx}
              >
                <div className={s.dot} id={`product-media-${idx}`} />
              </button>
            )
          })}
        </div>
      )}
      {productVideo?.length && productMedias?.length ? (
        <ProductMediaVideo
          productVideo={productVideo}
          productName={productName}
          placeholder={productMedias[0]}
          displayLite={!isDesktopDevice}
        />
      ) : null}
    </div>
  )
}

export default ProductMediaSlider
