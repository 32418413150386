import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import ProductMediaVideo from '@components/product/ProductMediaVideo'

import { MODALS } from '@constants'

import cn from 'classnames'
import s from './ProductMediaGrid.module.css'

export interface ProductMediaGridProps {
  productMedias: Array<Media>
  productName: string
  productVideo?: string
}

const ProductMediaGrid: FC<ProductMediaGridProps> = ({
  productMedias,
  productName,
  productVideo,
}) => {
  const { t } = useTranslation()
  const { setModalView, openModal } = useUI()

  const productMediaLength =
    (productMedias?.length || 0) + (productVideo?.length ? 1 : 0)

  const openMediaModal = (indexMedia: number) => {
    const props = {
      productName: productName,
      productMedias: productMedias,
      currentIndexMedia: indexMedia,
    }
    setModalView(MODALS.PRODUCT_MEDIA, props)
    openModal()
  }

  return (
    <div className={s.container}>
      {productMedias?.map((image, i) => (
        <figure
          key={`productMedias-${i}`}
          className={cn(s.media, {
            [s.full]: productMediaLength <= 2,
            [s.half]: productMediaLength > 2,
          })}
        >
          <img
            className={s.img}
            src={image?.['720x720q80']}
            alt={t('product:productMediaSlider.altProductImage', {
              productName: productName,
              id: i,
            })}
            aria-label={t('product:productMediaSlider.ariaProductImage', {
              productName: productName,
              id: i,
            })}
            onClick={() => openMediaModal(i)}
          />
        </figure>
      ))}
      {productVideo?.length && productMedias?.length ? (
        <ProductMediaVideo
          productVideo={productVideo}
          productName={productName}
          placeholder={productMedias[0]}
          classname={productMediaLength <= 2 ? 'w-full' : 'w-1/2'}
        />
      ) : null}
    </div>
  )
}

export default ProductMediaGrid
