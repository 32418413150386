import { FC } from 'react'
import cn from 'classnames'
import s from './NavbarColumn.module.css'
import NavbarImage from '../NavbarImage'
import NavbarSection from '../NavbarSection'

const IMAGE_TEMPLATE = 'depth-2-image.html.twig'

interface Props {
  expandParent: () => void
  expandedItem?: string
  imgClassName?: string
  isParentItemExpanded?: boolean
  item: NavigationItem
  setExpandedItem: (expandedItem?: string) => void
}

const hasImage = (item: NavigationItem): boolean =>
  (item.children || []).some(
    ({ template }: NavigationItem) => template === IMAGE_TEMPLATE
  )

const NavbarColumn: FC<Props> = ({
  expandParent,
  expandedItem,
  imgClassName,
  isParentItemExpanded,
  item,
  setExpandedItem,
}) => (
  <ul className={cn({ [s.imageCol]: hasImage(item) })}>
    {(item.children || []).map((navItem: NavigationItem, index: number) => (
      <li className={s.col} key={index}>
        {navItem.template === IMAGE_TEMPLATE ? (
          <NavbarImage imgClassName={imgClassName} item={navItem} />
        ) : (
          <NavbarSection
            collapse={() => setExpandedItem(undefined)}
            expand={() => setExpandedItem(navItem.title)}
            expandParent={() => expandParent()}
            isExpanded={expandedItem === navItem.title}
            isParentItemExpanded={isParentItemExpanded}
            item={navItem}
          />
        )}
      </li>
    ))}
  </ul>
)

export default NavbarColumn
