export const addGiftCardToBasket = async ({
  amount,
  sku,
  locale,
}: {
  amount: number
  sku: string
  locale: string
}): Promise<{ success: boolean }> => {
  const res = await fetch(`/${locale}/basket/add/${sku}?price=${amount}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (!res?.ok) throw Error
  const data = await res.json()
  return data
}
