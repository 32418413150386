import { FC } from 'react'

interface Props {
  stroke?: string
  isLogged?: boolean
  width?: string
  height?: string
}

const ProfilLogged: FC<Props> = ({
  stroke = 'black',
  isLogged = true,
  width = '20',
  height = '20',
  ...props
}) => (
  <svg viewBox="0 0 28 20" fill="none" width={width} height={height} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 1.66666C13.222 1.66666 15.8337 4.27833 15.8337 7.49999C15.8337 10.7217 13.222 13.3333 10.0003 13.3333C6.77866 13.3333 4.16699 10.7217 4.16699 7.49999C4.16699 4.27833 6.77866 1.66666 10.0003 1.66666Z"
      stroke="#121212"
      strokeLinecap="round"
    />
    <path
      d="M26.3891 2.91656L21.0419 8.26379L18.6113 5.83323"
      stroke="#121212"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 18.3333C17.5 14.4673 14.1421 13.3333 10 13.3333C5.85786 13.3333 2.5 14.4673 2.5 18.3333"
      stroke="#121212"
      strokeLinecap="round"
    />
  </svg>
)

export default ProfilLogged
