import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useUI } from '@components/ui/context'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import {
  nbCharactersTriggerSearch,
  setRecentSearchesToLocalStorage,
} from '@lib/lib-algolia/algolia'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-core'

import { Cross } from '@components/icons'
import { SearchIconLight } from '@components/icons/Search'
import MediaQueries from '@components/common/MediaQueries'

import debounce from 'lodash.debounce'
import { stringify } from 'query-string'
import { replaceAt } from '@utils/string'
import { changeLocaleIfTranslatable } from '@utils/locale'

import s from './SearchBox.module.css'
import cn from 'classnames'

const firstSuggestionToDisplay = (
  suggestions: string[],
  inputValue: string
) => {
  if (!suggestions) return null
  let firstSuggestion: string
  for (const suggestion of suggestions) {
    if (suggestion?.toLowerCase().startsWith(inputValue?.toLowerCase())) {
      firstSuggestion = suggestion?.replace(
        inputValue.toLowerCase(),
        inputValue
      )
      break
    }
  }
  return firstSuggestion || null
}

const mixFirstSuggestionAndInput = (suggestion: string, input: string) => {
  return replaceAt(suggestion, 0, input)
}

type SearchBoxProps = React.ComponentProps<'div'> &
  UseSearchBoxProps &
  SearchBoxAlgoliaProps
const SearchBox = (props: SearchBoxProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { closeModal } = useUI()
  const screen = useScreen()
  const { locale } = router

  const {
    inputRef,
    formRef,
    className,
    placeholder,
    open,
    clearInput,
    onClose,
    autoFocus,
    autocompleteSuggestions,
    resultView,
    ...restProps
  } = props

  const { query, refine } = useSearchBox(restProps)

  const [value, setValue] = useState<string>('')
  const [ghostValue, setGhostValue] = useState<string>('')
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false)

  const currentQuery = router?.query?.q || null

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setValue(event.currentTarget.value)
  }

  const handleReset = (event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setValue('')
    if (inputRef.current) inputRef.current.focus()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (value && value.length > 1) {
        setRecentSearchesToLocalStorage(value)
        closeModal()
        if (router.asPath.includes('/search')) {
          window.location.href = `/${locale}/search/?${stringify({ q: value })}`
        } else {
          router.push(
            `/search/?${stringify({
              q: value,
            })}`,
            undefined,
            {
              locale: changeLocaleIfTranslatable(locale),
            }
          )
        }
      }
    }
    if (event.key === 'ArrowRight' && showSuggestion) {
      event.preventDefault()
      setValue(ghostValue)
    }
  }

  const handRefine = (value) => refine(value)
  const debounceChange = useCallback(debounce(handRefine, 500), [])

  useEffect(() => {
    if (refine && query !== value) {
      if (value.length === 0) {
        handRefine(value)
      }
      if (value.length >= nbCharactersTriggerSearch) {
        debounceChange(value)
      }
    }
  }, [value])

  useEffect(() => {
    const firstSuggestion = firstSuggestionToDisplay(
      autocompleteSuggestions,
      value
    )
    if (value?.length < nbCharactersTriggerSearch || firstSuggestion === null) {
      setGhostValue('')
      setShowSuggestion(false)
    } else {
      setGhostValue(mixFirstSuggestionAndInput(firstSuggestion, value))
      setShowSuggestion(true)
    }
  }, [value])

  useEffect(() => {
    if (autoFocus && inputRef.current) inputRef.current.focus()
  }, [inputRef])

  useEffect(() => {
    if (!open) setValue('')
  }, [open])

  useEffect(() => {
    if (clearInput || !currentQuery) {
      setValue('')
      return
    }
    setValue(currentQuery as string)
  }, [currentQuery])

  return (
    <>
      <div className={className}>
        <form className={s.form} onReset={handleReset} ref={formRef} action=".">
          <div className={s.inputContainer}>
            <div className={s.loupe}>
              <SearchIconLight
                className={s.icon}
                color={
                  [Screen.xs, Screen.sm, Screen.md].includes(screen)
                    ? '#545454'
                    : '#121212'
                }
                size={
                  [Screen.xs, Screen.sm, Screen.md].includes(screen)
                    ? '24'
                    : '14'
                }
              />
            </div>

            <div className={s.inputSearch}>
              <div className={s.inputBox}>
                <MediaQueries hidden={['xs', 'sm']}>
                  <input
                    tabIndex={-1}
                    disabled
                    value={ghostValue}
                    className={cn(s.input, s.ghostSuggestion)}
                  ></input>
                </MediaQueries>
                <input
                  ref={inputRef}
                  className={s.input}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  placeholder={placeholder}
                  aria-label={placeholder}
                  spellCheck={false}
                  maxLength={80}
                  type="search"
                  name="search"
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            {value && (
              <button className={s.erase} onClick={(e) => handleReset(e)}>
                {t('block:filter.reset')}
              </button>
            )}
          </div>

          <button
            className={s.close}
            aria-label={t('layout:header.search.ariaClose')}
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
          >
            <Cross className={s.cross} />
            <span className={s.closeLabel}>
              {t('layout:header.search.cancel')}
            </span>
          </button>
        </form>
      </div>
    </>
  )
}

export default SearchBox
