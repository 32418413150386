const Logo = ({ className = '', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
    viewBox="0 0 230 24"
  >
    <g>
      <path d="M230 2.98096V0H217.375V23.1499H230V20.169H220.732V13.0015H229.057V10.024H220.732V2.98096H230Z" />
      <path d="M39.5749 17.8994L31.5612 0.0136719H28.2832V23.1325H31.6372V8.01355L38.4143 23.1325H40.7666L47.5403 7.96174V23.1325H50.8977V0.0136719H47.5886L39.5749 17.8994Z" />
      <path d="M96.5372 0.0175781H93.1797V23.1329H104.423V20.1865H96.5372V0.0175781Z" />
      <path d="M118.82 0.0175781H115.463V23.1329H126.706V20.1865H118.82V0.0175781Z" />
      <path d="M147.811 0.0136719H144.523L136.233 22.4658L135.991 23.1567H139.511L140.972 19.2707C141.35 18.3235 141.953 17.4825 142.729 16.8204C143.504 16.1584 144.43 15.6953 145.425 15.4711C146.115 15.3364 148.032 14.7803 149.335 13.7233L149.68 14.6352L150.395 16.6007L151.476 19.5505L152.823 23.1429H156.347L147.811 0.0136719ZM144.62 12.5489C144.14 12.6625 143.676 12.8341 143.238 13.0601L146.164 5.04641L148.084 10.314C147.991 11.865 145.984 12.2449 144.62 12.5489Z" />
      <path d="M73.7225 0.0136719H70.4342L62.1441 22.4658L61.9023 23.1567H65.4221L66.8867 19.2707C67.2635 18.3228 67.8661 17.4812 68.642 16.8191C69.4179 16.1569 70.3438 15.6942 71.3392 15.4711C72.03 15.3364 73.9436 14.7803 75.2458 13.7233L75.5913 14.6352L76.3063 16.6007L77.384 19.5505L78.7311 23.1429H82.2544L73.7225 0.0136719ZM70.5274 12.5489C70.0478 12.6625 69.5838 12.8341 69.1458 13.0601L72.0714 5.04641L73.9954 10.314C73.8987 11.865 71.8918 12.2449 70.5274 12.5489Z" />
      <path d="M198.443 0.0449219H195.089V23.1637H206.332V20.2139H198.443V0.0449219Z" />
      <path d="M16.8322 14.2243C16.4788 13.4638 15.9594 12.7922 15.3124 12.2589C14.6442 11.7165 13.8992 11.2765 13.1017 10.9532C12.2934 10.6078 11.4575 10.3211 10.6147 10.0585C9.77187 9.79603 8.94287 9.55078 8.13114 9.31935C7.402 9.11949 6.69221 8.85477 6.01027 8.52835C5.47986 8.28748 5.00835 7.93385 4.6286 7.4921C4.32283 7.07175 4.17707 6.55609 4.21755 6.03788C4.21755 3.61995 6.70456 3.10183 7.25723 3.01547C10.1346 2.54916 12.6423 5.24342 12.6423 5.24342L14.8253 3.27799C13.6958 1.66144 9.64407 -0.538871 6.33842 0.151965C5.39705 0.33428 4.49319 0.674106 3.66488 1.15713C2.85817 1.63878 2.17183 2.29795 1.65801 3.08456C1.11275 3.96456 0.80124 4.96923 0.753011 6.00335C0.657456 6.91281 0.811503 7.83112 1.1986 8.65961C1.39894 9.0603 2.38684 10.5629 4.81513 11.6233C6.41441 12.3141 7.86862 12.5974 10.7425 13.4955C12.2969 13.979 13.1604 14.6906 13.5818 15.378C13.9253 15.9632 14.0363 16.6561 13.8927 17.3192C13.5473 18.7009 12.3832 19.6715 10.853 20.0826C5.61304 21.4642 2.37647 16.7631 2.37647 16.7631L0 18.7493C0 18.7493 3.65107 24.1447 11.157 23.0255C12.1615 22.8758 13.1319 22.5508 14.024 22.0653C14.8944 21.5912 15.6484 20.9292 16.2312 20.1275C16.7931 19.2646 17.1407 18.28 17.2453 17.2556C17.3498 16.2312 17.2082 15.1967 16.8322 14.2381V14.2243Z" />
      <path d="M180.896 11.72C180.288 11.0707 178.865 9.91696 177.732 9.64754C178.043 9.55082 180.15 8.06897 180.15 4.91531C180.15 3.11568 178.229 0.0449219 173.715 0.0449219H167.072V23.1707H173.981C181.58 23.1672 184.91 15.9894 180.896 11.72ZM173.798 3.06041C178.229 3.06041 177.297 6.76675 176.323 7.48522C175.038 8.42821 174.157 8.36258 172.381 8.55602C171.661 8.62703 170.98 8.91762 170.43 9.38847V3.05351L173.798 3.06041ZM174.022 20.1621H170.43V12.4109C171.269 11.6786 173.1 10.7529 176.174 11.7753C177.701 12.2865 178.982 13.5784 179.314 14.4247C180.26 16.8219 178.73 20.1552 174.022 20.1552V20.1621Z" />
    </g>
  </svg>
)

export default Logo
