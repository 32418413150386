import React, { FC, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'

import { tagEventAddToBasket } from '@components/common/Eulerian/EulerianEvents'
import { Cross } from '@components/icons'

import { hasDeclinationStocks, getAvailabilityLabel } from '@utils/stocks'
import { MODALS } from '@constants'
import { isIOS, isChrome } from 'react-device-detect'

import cn from 'classnames'
import s from './DropdownQuickBuy.module.css'

export interface DropdownQuickBuyProps {
  declinations: Array<Declination>
  stocks: Stocks
  prices: Prices
  setIsDropDownOpen: (isDropDownOpen: boolean) => void
  displayAddToBasketMsg: () => void
  layout?: string
}

const DropdownCTA: FC<any> = ({
  declination,
  stocks,
  prices,
  handleSelectSize,
}) => {
  const { t } = useTranslation()
  const sku = declination?.sku
  const noStock = !hasDeclinationStocks(stocks, sku)
  const lastChance =
    !noStock &&
    stocks?.declinations[sku]?.quantity > 0 &&
    stocks?.declinations[sku]?.quantity <= 3
  const availabilityLabel = getAvailabilityLabel(stocks, sku)

  if (!sku) return null

  return (
    <div
      key={`declination_quickbuy_mobile-{i}`}
      className={cn(
        s.sizeValue,
        { [s.inStock]: !lastChance && !noStock },
        { [s.lastChance]: lastChance },
        { [s.noStock]: noStock }
      )}
      data-testid={
        !noStock ? 'DropdownQuickBuy_available' : 'DropdownQuickBuy_unavailable'
      }
      onClick={() => handleSelectSize(sku)}
    >
      <p>{declination?.size}</p>
      <div className={s.details}>
        {prices?.[sku] && (
          <p>
            {prices[sku].baseAmount &&
            prices[sku].baseAmount !== prices[sku].amount ? (
              <>
                <span className={s.salePrice}>{prices[sku].baseAmount}</span>
                &nbsp;
                <span className={s.initialPrice}>{prices[sku].amount}</span>
              </>
            ) : (
              prices[sku].amount
            )}
          </p>
        )}
        <p>
          {noStock
            ? t('product:isUnavailable')
            : lastChance
            ? stocks?.declinations?.[sku].quantity === 0
              ? t('product:quickBuy.declination.lastChance')
              : t('product:quickBuy.declination.lastChances')
            : t(availabilityLabel.message, availabilityLabel.params)}
        </p>
      </div>
    </div>
  )
}

const DropdownQuickBuy: FC<DropdownQuickBuyProps> = ({
  declinations,
  stocks,
  prices,
  setIsDropDownOpen,
  displayAddToBasketMsg,
  layout,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { setModalView, openModal } = useUI()
  const sessionData = useUserSettings()
  const { addToBasket } = sessionData

  const [altAnim, setAltAnim] = useState<boolean>(false)

  const handleAddToBasket = async (sku: string) => {
    let newBasket
    try {
      newBasket = await addToBasket(sku, locale)
      displayAddToBasketMsg()
    } catch (err) {
      console.error(err)
      const errMsg =
        typeof err === 'string'
          ? t(`${'product:error.' + err}`)
          : t(`${'product:error.defaultMessage'}`)
      setModalView(MODALS.NOTIFICATION, {
        message: errMsg,
      })
      openModal()
    }
    if (newBasket) {
      tagEventAddToBasket({
        urlp:
          layout === 'shopTheLook'
            ? '/basket/add/shop_the_look'
            : '/basket/add/page_listing',
        locale,
        sessionData,
        productData: newBasket,
      })
    }
  }

  const handleAlertDisponibility = async (sku?: string) => {
    if (sku) {
      setModalView(MODALS.NOTIFY_ME, { sku })
      openModal()
    }
  }

  const handleSelectSize = (sku: string) => {
    setIsDropDownOpen(false)
    hasDeclinationStocks(stocks, sku)
      ? handleAddToBasket(sku)
      : handleAlertDisponibility(sku)
  }

  useEffect(() => {
    if (isIOS && isChrome) setAltAnim(true)
  }, [])

  return (
    <>
      <div
        role="button"
        aria-label={t('layout:dropdown.ariaClose')}
        className={s.backdrop}
        onClick={() => setIsDropDownOpen(false)}
      />
      <div className={cn(s.dropdown, { [s.dropdownAlt]: altAnim })}>
        <div className={s.head}>
          <h2 className={s.title}>{t('product:addToShoppingBag')}</h2>
          <Cross className={s.close} onClick={() => setIsDropDownOpen(false)} />
        </div>
        <div className={s.sizeContainer}>
          <div className={s.sizeContent}>
            {declinations?.map((declination, index) => (
              <React.Fragment
                key={`DropdownCTA-list-${declination?.sku}-${index}`}
              >
                <DropdownCTA
                  declination={declination}
                  stocks={stocks}
                  prices={prices}
                  handleSelectSize={handleSelectSize}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default DropdownQuickBuy
