import { FC, MutableRefObject } from 'react'

import { PageMenu } from '@components/common'
import { Blocks } from '@components/blocks-cms'
import TrustBox from '@components/common/TrustBox'
import { ENABLE_TRUSTPILOT } from '@constants'

import cn from 'classnames'
import s from './PageContent.module.css'

interface PageContentProps {
  id?: string
  className?: string
  ref?: MutableRefObject<HTMLDivElement>
  blocks?: PageBlockTypes[]
  menu?: PageMenuType
  onMenuItemClick?: (path: string) => void
  withTrustBox?: boolean
  algoliaServerInfos?: AlgoliaServerInfos
}

const PageContent: FC<PageContentProps> = ({
  id,
  className,
  ref,
  blocks,
  menu,
  onMenuItemClick,
  withTrustBox = true,
  algoliaServerInfos,
}) => {
  const enableTrustPilot = ENABLE_TRUSTPILOT

  return (
    <div ref={ref} id={id} className={cn(s.container, className)}>
      {menu ? (
        <div className={s.menuContainer}>
          <PageMenu
            className={s.menu}
            onMenuItemClick={onMenuItemClick}
            items={menu.pageMenuItems}
            title={menu.title}
          />
        </div>
      ) : null}
      {blocks?.length ? (
        <Blocks
          className={cn('page_content', s.blocks)}
          blocks={blocks}
          algoliaServerInfos={algoliaServerInfos}
        />
      ) : null}
      {enableTrustPilot && withTrustBox && <TrustBox />}
    </div>
  )
}

export default PageContent
