import React, { FC } from 'react'

import cn from 'classnames'
import s from './SeparatorBlock.module.css'

export interface SeparatorBlockProps {
  size?: string
}

const SeparatorBlock: FC<SeparatorBlockProps> = ({ size = 'small' }) => (
  <div className={s.container}>
    <div
      className={cn(s.separator, {
        [s.small]: size === 'small',
        [s.large]: size === 'large',
      })}
    />
  </div>
)

export default SeparatorBlock
