import { FC, useRef, useState } from 'react'
import { useUI } from '@components/ui/context'

import { ProductMiniatures } from '@components/product'

import s from './ProductMediaModal.module.css'

export interface ProductMediaModalProps {
  productName: string
  productMedias: Array<Media>
  currentIndexMedia: number
}

const ProductMediaModal: FC<ProductMediaModalProps> = ({
  productName,
  productMedias,
  currentIndexMedia,
}) => {
  const { closeModal } = useUI()
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  const [selectedMediaIndex, setSelectedMediaIndex] =
    useState(currentIndexMedia)

  const centerPositionView = (e: React.SyntheticEvent) => {
    if (ref.current) {
      const node = e.target as HTMLElement
      ref.current.scrollTo({
        top: 0,
        left: node.offsetWidth / 2 - ref.current.offsetWidth / 2,
      })
    }
  }

  return (
    <>
      <div className={s.miniaturesContainer}>
        <ProductMiniatures
          selectedMediaIndex={selectedMediaIndex}
          setSelectedMediaIndex={(idx: number) => setSelectedMediaIndex(idx)}
          productName={productName}
          miniatures={productMedias}
          onModal
        />
      </div>
      <div className={s.container} ref={ref} onClick={() => closeModal()}>
        <div className={s.imgContainer}>
          <img
            src={productMedias?.[selectedMediaIndex]?.thickbox}
            onLoad={(e) => centerPositionView(e)}
            alt={productName}
          />
        </div>
      </div>
    </>
  )
}

export default ProductMediaModal
