import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '@components/ui'

import cn from 'classnames'
import s from './NavbarSection.module.css'

interface Props {
  collapse?: () => void
  expand?: () => void
  expandParent?: () => void
  isExpanded: boolean
  isParentItemExpanded?: boolean
  item: NavigationItem
}

const itemClassName = (template?: string): string | undefined => {
  if (template === 'depth-3-link-inline.html.twig') return s.inlineItem
}

const NavbarSection: FC<Props> = ({
  collapse = () => {},
  expand = () => {},
  expandParent = () => {},
  isExpanded,
  isParentItemExpanded,
  item,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn(s.section, {
        [s.expanded]: isExpanded,
        [s.parentItemExpanded]: isParentItemExpanded,
      })}
    >
      <div
        className={s.toggleButtonWrapper}
        data-cs-override-id={`navbarSection-${item.title}`}
      >
        {item.url ? (
          <Link
            next
            className={s.title}
            href={item.url}
            rel={item.rel}
            style={item.color ? { color: item.color } : {}}
            onFocus={() => expandParent()}
          >
            <strong>{item.title}</strong>
            {item.children?.length ? (
              <span className={s.plusIcon} aria-hidden />
            ) : null}
          </Link>
        ) : (
          <span
            className={s.title}
            style={item.color ? { color: item.color } : {}}
          >
            <strong>{item.title}</strong>
            {item.children?.length ? (
              <span className={s.plusIcon} aria-hidden />
            ) : null}
          </span>
        )}

        {item.children?.length ? (
          <button
            className={s.toggleButton}
            onClick={() => (isExpanded ? collapse() : expand())}
            aria-label={t(
              isExpanded
                ? 'layout:navigation.ariaCollapseMenu'
                : 'layout:navigation.ariaExpandMenu',
              { title: item.title || '' }
            )}
          />
        ) : null}
      </div>

      {item.children?.length ? (
        <ul className={s.items}>
          {item?.url?.length ? (
            <li className={cn(s.seeAll, s.item)}>
              <Link next className={cn(s.link, s.seeAlllink)} href={item.url}>
                {t('layout:header.search.viewAll')}
              </Link>
            </li>
          ) : null}
          {(item.children || []).map(
            (navItem: NavigationItem, index: number) => (
              <li
                className={cn(s.item, itemClassName(navItem.template))}
                key={index}
                data-cs-override-id={`navbarSection-${navItem.title}`}
              >
                {navItem?.url && (
                  <Link
                    next
                    className={s.link}
                    href={navItem.url}
                    rel={navItem.rel}
                    style={navItem.color ? { color: navItem.color } : {}}
                    onFocus={() => {
                      if (!isExpanded) {
                        expand()
                        expandParent()
                      }
                    }}
                  >
                    {navItem.title}
                  </Link>
                )}
              </li>
            )
          )}
        </ul>
      ) : null}
    </div>
  )
}

export default NavbarSection
