import React, { FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import { Button } from '@components/ui'

import cn from 'classnames'
import s from './ProductMsgAddToBasket.module.css'

export interface ProductMsgAddToBasketProps {
  open?: boolean
}

const ProductMsgAddToBasket: FC<ProductMsgAddToBasketProps> = ({ open }) => {
  const { t } = useTranslation()
  const { closeModal } = useUI()

  useEffect(() => {
    if (!open) return null
    let timer = setTimeout(() => {
      closeModal()
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [open])

  return (
    <div className={cn(s.container, { [s.enable]: open })} aria-hidden={!open}>
      <div className={s.content}>
        <p>{t('product:addToBasket.productAdded')}</p>
        <Button
          className={s.link}
          href={t('layout:header.links.basket.href')}
          aria-label={`${t('layout:common.ariaNavigate')} ${t(
            'product:addToBasket.basket'
          )}`}
          tabIndex={open ? 0 : -1}
        >
          {t('product:addToBasket.order')}
        </Button>
      </div>
    </div>
  )
}

export default ProductMsgAddToBasket
