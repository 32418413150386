import React, { FC, MouseEvent } from 'react'
import useTranslation from 'next-translate/useTranslation'
import cn from 'classnames'

import { useUI } from '@components/ui/context'
import { Link } from '@components/ui'

import { MODALS } from '@constants'

import s from './BlockLinkWrapper.module.css'

interface BlockLinkWrapper {
  title?: string
  url?: string
  targetBlank?: boolean
  isPopin?: boolean
  forceScroll?: boolean
  className?: string
}

const BlockLinkWrapper: FC<BlockLinkWrapper> = ({
  title = '',
  url,
  targetBlank,
  isPopin,
  forceScroll = false,
  className = '',
  children,
}) => {
  const { t } = useTranslation()
  const { setModalView, openModal } = useUI()

  const openPageModal = async (e: MouseEvent, modalUrl: string) => {
    e.preventDefault()
    setModalView(MODALS.PAGE_POPIN, { url: modalUrl })
    openModal()
  }

  return (
    <>
      {url?.length ? (
        <Link
          next={url?.lastIndexOf('http', 0) !== 0}
          className={cn(s.wrapper, className)}
          href={url}
          forceScroll={forceScroll}
          rel={targetBlank ? 'nofollow noopener noreferrer' : ''}
          target={targetBlank ? '_blank' : '_self'}
          onClick={(e) => {
            if (isPopin) openPageModal(e, url)
          }}
          aria-label={`${t('layout:common.ariaNavigate')} ${title}`}
        >
          {children}
        </Link>
      ) : (
        <div className={s.content}>{children}</div>
      )}
    </>
  )
}

export default BlockLinkWrapper
