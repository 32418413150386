import React, { createContext, useCallback } from 'react'
import { useUserSettings } from './session'
import { NEWSLETTER_DISCOUNT, NEWSLETTER_MINIMUM_ORDER } from '@constants'

interface NewsletterContextTypes {
  getNewsletterDiscount: () => Promise<string>
  getNewsletterMinimumOrder: () => Promise<string>
}

const defaultNewsletterContext: NewsletterContextTypes = {
  getNewsletterDiscount: async () => {
    return 'EUR'
  },
  getNewsletterMinimumOrder: async () => {
    return 'EUR'
  },
}

export const NewsletterContext = createContext<NewsletterContextTypes>(
  defaultNewsletterContext
)

export const NewsletterProvider = ({
  children,
}: {
  children: any | undefined
}) => {
  const userProfile = useUserSettings()

  const getNewsletterDiscount = useCallback(
    () => NEWSLETTER_DISCOUNT[userProfile.currencyCode],
    [userProfile.currencyCode]
  )

  const getNewsletterMinimumOrder = useCallback(
    () => NEWSLETTER_MINIMUM_ORDER[userProfile.currencyCode],
    [userProfile.currencyCode]
  )

  return (
    <NewsletterContext.Provider
      value={{
        getNewsletterDiscount,
        getNewsletterMinimumOrder,
      }}
    >
      {children}
    </NewsletterContext.Provider>
  )
}

export const useNewsletterContext = () => {
  const context = React.useContext(NewsletterContext)
  if (context === undefined) throw new Error(`Newsletter Context is undefined`)
  return context
}
