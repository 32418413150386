import { FC } from 'react'

import { SocialLinks } from 'components/common'
import { Logo } from '@components/ui'

import cn from 'classnames'
import s from './Social.module.css'

export interface SocialProps {
  className?: string
}

const Social: FC<SocialProps> = ({ className }) => (
  <section className={cn(s.root, className)}>
    <Logo fill="#fff" className={s.logo} />
    <SocialLinks className={s.socialLinks} />
  </section>
)

export default Social
