import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'

import BasketDetail from '@components/common/Header/UserNav/BasketModal/BasketDetail'
import { Button, Link } from '@components/ui'
import { Cross } from '@components/icons'
import {
  tagEventAddToBasket,
  tagEventRemoveFromBasket,
} from '@components/common/Eulerian/EulerianEvents'

import { formattedPrice } from '@utils/prices'
import { PayPalScriptProvider, PayPalMessages } from '@paypal/react-paypal-js'
import { isChrome, isIOS } from 'react-device-detect'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import Div100vh from 'react-div-100vh'
import { MODALS } from '@constants'

import cn from 'classnames'
import s from './BasketModal.module.css'

export interface Props {
  basket: Basket
  itemsCount: number
  basketOpen: boolean
  setBasketOpen: (sliderOpen: boolean) => void
}

const stylePaypal = {
  layout: 'text',
  logo: {
    type: 'inline',
  },
  text: {
    align: 'center',
    size: '13',
  },
}

const BasketModal: FC<Props> = ({
  basket,
  itemsCount,
  basketOpen,
  setBasketOpen,
}) => {
  const { t } = useTranslation()
  const { locale, asPath } = useRouter()
  const { setModalView, openModal, tooltipProps } = useUI()
  const sessionData = useUserSettings()
  const { addToBasket, removeFromBasket, currencyCode, deliveryCountryCode } =
    sessionData

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [altAnim, setAltAnim] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)

  const displayPaypalDisclaimer =
    locale === 'fr' && currencyCode === 'EUR' && deliveryCountryCode === 'FR'
  const PAYPAL_APIKEY = process.env.NEXT_PUBLIC_PAYPAL_APIKEY || ''
  const isPaypal = PAYPAL_APIKEY?.length && displayPaypalDisclaimer
  const basketItems = basket?.items ? [...basket.items].reverse() : []

  const handleRemoveItems = async (sku: string, quantity?: number) => {
    setIsLoading(true)
    try {
      if (!sku)
        throw new Error('SKU missing when trying to remove item from basket')
      const newBasket = await removeFromBasket(sku, locale, quantity)
      if (newBasket) {
        tagEventRemoveFromBasket({
          urlp: '/basket/remove/page_basket_summary',
          locale,
          sessionData,
          productData: basket,
          skuRemove: sku,
        })
      }
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }

  const handleAddItems = async (sku: string) => {
    setIsLoading(true)
    try {
      if (!sku)
        throw new Error('SKU missing when trying to add item from basket')
      const newBasket = await addToBasket(sku, locale)
      if (newBasket) {
        tagEventAddToBasket({
          urlp: '/basket/add/page_basket_summary',
          locale,
          sessionData,
          productData: newBasket,
        })
      }
    } catch (err) {
      const errMsg =
        typeof err === 'string'
          ? t(`${'product:error.' + err}`)
          : t(`${'product:error.defaultMessage'}`)
      setModalView(MODALS.NOTIFICATION, {
        message: errMsg,
      })
      openModal()
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (isIOS && isChrome) setAltAnim(true)
  }, [])

  useEffect(() => {
    if (basketOpen) blockScroll(false)
    else enableScroll(false)
  }, [basketOpen])

  useEffect(() => {
    if (!tooltipProps?.withTimer || isHover) return null

    let timer = setTimeout(() => {
      setBasketOpen(false)
    }, 1500)

    return () => timer && clearTimeout(timer)
  }, [tooltipProps?.withTimer, isHover])

  useEffect(() => {
    setBasketOpen(false)
  }, [asPath])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null

  return (
    <>
      {basketOpen && (
        <div
          role="button"
          className={s.backdrop}
          onClick={() => setBasketOpen(false)}
          aria-label={t('layout:dropdown.ariaClose')}
        />
      )}
      <Div100vh
        className={cn(
          s.container,
          { [s.open]: basketOpen },
          { [s.altAnim]: altAnim },
          { [s.paypal]: isPaypal }
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Link href={t('layout:header.links.basket.href')} className={s.link}>
          {t('layout:header.links.basket.title')}
          <span>{itemsCount}</span>
        </Link>
        <div className={cn(s.list, { [s.hidden]: isLoading })}>
          {basketItems.map((item) => (
            <BasketDetail
              key={item.sku}
              attachColor={item.attachColor}
              id={item.id}
              sku={item.sku}
              color={item.color}
              image={item.image}
              name={item.name}
              brand={item.brand}
              size={item.size}
              quantity={item.quantity}
              price={item.priceTaxIncluded}
              addHandler={handleAddItems}
              removeHandler={handleRemoveItems}
            />
          ))}
        </div>
        <div className={s.order}>
          <Button className={s.cta} href={t('layout:header.links.basket.href')}>
            {t('layout:header.language.additional.cta_accepted')}
            &nbsp;—&nbsp;
            {formattedPrice(basket.totalPriceTaxIncluded, currencyCode, locale)}
          </Button>
          {isPaypal && (
            <PayPalScriptProvider
              options={{
                'client-id': PAYPAL_APIKEY,
                components: 'messages',
              }}
            >
              <PayPalMessages
                className={s.paypalDisclaimer}
                style={stylePaypal as any}
                forceReRender={[stylePaypal]}
                amount={basket?.totalPriceTaxIncluded}
              />
            </PayPalScriptProvider>
          )}
        </div>
        {basketOpen && (
          <button
            className={s.close}
            onClick={() => setBasketOpen(false)}
            onBlur={() => setBasketOpen(false)}
            aria-label={t('layout:dropdown.ariaClose')}
          >
            <Cross className={s.cross} aria-hidden />
          </button>
        )}
      </Div100vh>
    </>
  )
}

export default BasketModal
