const Check = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M15 6.25L8.125 13.125L5 10"
      stroke="#ffffff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Check
