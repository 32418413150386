export const enableScroll = (isMobileSafari: boolean) => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined') {
    if (isMobileSafari) {
      let YOffset = document.body.style.top
        ? parseInt(document.body.style.top, 10)
        : 0
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('width')
      window.scrollTo(0, -YOffset)
    } else {
      document.body.style.removeProperty('overflow')
    }
  }
}

export const blockScroll = (isMobileSafari: boolean) => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined') {
    if (isMobileSafari) {
      document.body.style.overflow = 'hidden'
      document.body.style.top = `-${window.pageYOffset}px`
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }
}
