import React, { FC } from 'react'

import s from './FormRadioInput.module.css'
import cn from 'classnames'

interface FormRadioInputProps {
  className?: string
  label?: string
  name?: string
  required?: boolean
  isDarkTheme?: boolean
  defaultSelected?: string | number
  options: { option: string; value: string | number }[]
  setData?: (...args: any) => any
}

const FormRadioInput: FC<FormRadioInputProps> = ({
  className,
  label,
  name,
  isDarkTheme = false,
  required = false,
  defaultSelected,
  options,
  setData,
}) => (
  <fieldset className={className}>
    <legend className={s.title}>{`${label}${required ? '*' : ''}`}</legend>
    <div className={s.content}>
      {options.map((option, index) => (
        <div
          key={`formRadioInput-${option.option}-${index}`}
          className={s.inputBox}
        >
          <input
            type="radio"
            name={name}
            id={`formRadioInput-${name}-${option.option}-${index}`}
            className={cn(s.input, { [s.darkTheme]: isDarkTheme })}
            value={option.value}
            defaultChecked={defaultSelected === option.value}
            onChange={(e) => setData({ name, data: e.target.value })}
          />
          <label
            htmlFor={`formRadioInput-${name}-${option.option}-${index}`}
            className={s.label}
          >
            {option.option}
          </label>
        </div>
      ))}
    </div>
  </fieldset>
)

export default FormRadioInput
