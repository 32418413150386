export const PlayVideo = ({ fill = 'white', stroke = 'white', ...props }) => (
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" {...props}>
    <path
      d="M18.3335 14.5503C18.3335 11.4086 21.7893 9.49319 24.4535 11.1583L71.573 40.608C74.0797 42.1747 74.0796 45.8253 71.573 47.392L24.4535 76.8416C21.7893 78.5068 18.3335 76.5914 18.3335 73.4497V14.5503Z"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
