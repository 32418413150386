import { FC } from 'react'

import cn from 'classnames'
import s from './PageMenu.module.css'

interface PageMenuProps {
  className?: string
  items: Array<MenuItems>
  onMenuItemClick?: (path: string) => void
  title: string
}

const PageMenu: FC<PageMenuProps> = ({
  className,
  items,
  onMenuItemClick,
  title,
}) => (
  <div className={cn(s.menu, className)}>
    <p className={s.title}>{title}</p>

    <div className={s.items}>
      {items.map((item) => (
        <a
          key={item.title}
          href={item.path}
          onClick={
            onMenuItemClick
              ? (e) => {
                  e.preventDefault()
                  onMenuItemClick(item.path)
                }
              : undefined
          }
          className={s.item}
        >
          {item.title}
        </a>
      ))}
    </div>
  </div>
)

export default PageMenu
