import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '@components/ui'
import { Chevron } from '@components/icons'
import { usePagination, UsePaginationProps } from 'react-instantsearch'

import cn from 'classnames'
import s from './PaginationAlgolia.module.css'
import { generatePaginationLinks } from '@utils/pagination'

const PaginationAlgolia: FC<UsePaginationProps> = (props) => {
  const { t } = useTranslation()

  const { currentRefinement, nbPages, refine } = usePagination(props)

  const currentPage = currentRefinement + 1

  const pageNumbers = generatePaginationLinks({
    currentPage,
    nbPages,
  })

  return (
    <div className={s.container}>
      {nbPages > 1 && (
        <ol className={s.pagination}>
          {currentPage > 1 && (
            <li>
              <Link
                href="#"
                className={cn(s.paginationItem, s.chevron)}
                tabIndex={0}
                aria-label={`${t('layout:common.ariaNavigate')} ${t(
                  'block:pagination.previousPage'
                )}`}
                onClick={(event) => {
                  event.preventDefault()
                  refine(currentPage - 2) // decreased 2 because the refine range start to 0 for page 1
                }}
              >
                <Chevron width={8} height={20} fill="#000" />
              </Link>
            </li>
          )}

          {pageNumbers.map((nbr, i) => (
            <li key={`pagination-item-${i}`}>
              <Link
                href="#"
                className={cn(s.paginationItem, {
                  [s.selected]: nbr == currentPage,
                })}
                tabIndex={0}
                aria-label={`${t('layout:common.ariaNavigate')} ${t(
                  'block:pagination.page',
                  { numPage: nbr }
                )}`}
                onClick={(event) => {
                  event.preventDefault()
                  refine(nbr - 1)
                }}
              >
                {nbr}
              </Link>
            </li>
          ))}

          {currentPage < nbPages && (
            <li>
              <Link
                href="#"
                className={s.paginationItem}
                tabIndex={0}
                aria-label={`${t('layout:common.ariaNavigate')} ${t(
                  'block:pagination.nextPage'
                )}`}
                onClick={(event) => {
                  event.preventDefault()
                  refine(currentPage)
                }}
              >
                <Chevron width={8} height={20} fill="#000" />
              </Link>
            </li>
          )}
        </ol>
      )}
    </div>
  )
}

export default PaginationAlgolia
