import * as Sentry from '@sentry/nextjs'
import { CaptureConsole } from '@sentry/integrations'

const SENTRY_DSN = process.env.NEXT_PUBLIC_CATALOG_SENTRY_DSN
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://examplePublicKey@test.smallable.com/0',
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 0.2,
    environment: SENTRY_ENV || 'dev',
  })
}
