import React, { FC } from 'react'

import cn from 'classnames'
import s from './ProgressBar.module.css'

export interface BarProps {
  className?: string
  total: number
  part: number
}

const ProgressBar: FC<BarProps> = ({ className, total, part }) => {
  const progress = Math.round((part / total) * 100)
  const progressWidth = {
    width: `${progress}%`,
  }

  return (
    <div className={cn(s.container, className)}>
      <div style={progressWidth} className={s.bar}></div>
    </div>
  )
}
export default ProgressBar
