import { FC, useState } from 'react'
import { useUserSettings } from '@context/session'

import NavbarFooterSection from '@components/common/Navbar/NavbarFooterSection'

import navigation from './navigation.json'

import cn from 'classnames'
import s from './NavbarFooter.module.css'

interface Props {
  className?: string
}

interface Navigation {
  footer: Array<NavigationItem>
  user: Array<NavigationItem>
  logout: NavigationItem
}

const NavbarFooter: FC<Props> = ({ className }) => {
  const { logged } = useUserSettings()

  const [expandedItem, setExpandedItem] = useState<number>(undefined)

  const {
    footer: footerNavigation,
    user: userNavigation,
    logout: logoutNavigationItem,
  }: Navigation = navigation

  return (
    <ul className={cn(s.root, className)}>
      {userNavigation.map((item: NavigationItem, index: number) => (
        <NavbarFooterSection
          key={index}
          item={item}
          isExpanded={expandedItem === index}
          expand={() => setExpandedItem(index)}
          collapse={() => setExpandedItem(undefined)}
        />
      ))}
      {logged && (
        <NavbarFooterSection
          isExpanded={false}
          item={logoutNavigationItem}
          collapse={() => setExpandedItem(undefined)}
        />
      )}
      {footerNavigation.map((item: NavigationItem, index: number) => (
        <NavbarFooterSection
          key={index}
          item={item}
          isExpanded={expandedItem === index + navigation.user.length}
          expand={() => setExpandedItem(index + navigation.user.length)}
          collapse={() => setExpandedItem(undefined)}
        />
      ))}
    </ul>
  )
}

export default NavbarFooter
