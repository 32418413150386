export const formatQueryParams = (queryParams: {
  [key: string]: string | string[] | undefined
}) => {
  let q = { ...queryParams }
  for (let p in q)
    if (typeof q[p] === 'string') q[p] = (q[p] as string)?.split('+')
  return q as { [key: string]: string[] }
}

export const formatParams = (params, queryParams) => {
  const f = {}
  Object.keys({ ...params }).forEach((k) => {
    if (typeof queryParams[k] === 'undefined') f[k] = params[k]
  })
  return f as ProductsParameters
}

export const removeParameterFromUrl = (url, parameter) => {
  let _url = url
  if (_url.indexOf('#') !== -1)
    _url = _url.replace(new RegExp('(?=#)(.*?)(?=&|$)'), '')
  if (_url.indexOf(parameter) === -1) return _url
  return _url
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1')
}
