export const COUNTRY_WITH_REPLICA = [
  'US',
  'CA',
  'QA',
  'KW',
  'SA',
  'AE',
  'CN',
  'HK',
  'SG',
  'JP',
  'KR',
  'AU',
  'AT',
  'BE',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'GB',
]

export const MOBILE_LISTING_PRODUCTS_PER_LINE = 2
export const DESKTOP_LISTING_PRODUCTS_PER_LINE = 3

export const REVIEWS_TRUSTPILOT_PER_PAGE = 5
export const PATH_REVIEWS = {
  fr: 'avis-smallable',
  en: 'smallable-reviews',
  de: 'bewertung-smallable',
  es: 'opiniones-smallable',
  it: 'recensioni-smallable',
  nl: 'smallable-reviews',
}

export const BRAND_PAGES = [
  '/marques',
  '/brands',
  '/marcas',
  '/marken',
  '/marche',
]

export const GIFTCARD_AMOUNTS = [30, 50, 100, 150, 200, 500, 1000, 0]
