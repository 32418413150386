import React, { FC, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'

import { Button, DropdownSelect } from '@components/ui'
import { useUI } from '@components/ui/context'

import { addGiftCardToBasket } from '@mw/giftCard'
import { formattedPrice } from '@utils/prices'
import { GIFTCARD_AMOUNTS, TOOLTIPS } from '@constants'

import s from './ProductGiftCard.module.css'

export interface ProductGiftCardProps {
  productName: string
  productSku: string
  medias: Media
}

export interface GiftCardOptionProps {
  option: number
  currencyCode: string
  locale: string
  labelCustom: string
  placeholder: string
  selected?: boolean
}

const GiftCardOption: FC<GiftCardOptionProps> = ({
  option,
  currencyCode,
  locale,
  labelCustom,
  placeholder,
  selected = false,
}) => (
  <>
    {option !== undefined ? (
      <span className={selected ? s.optSel : s.opt}>
        {option > 0
          ? formattedPrice(option, currencyCode, locale)
          : labelCustom}
      </span>
    ) : (
      <span className={s.opt}>{placeholder}</span>
    )}
  </>
)

const ProductGiftCard: FC<ProductGiftCardProps> = ({
  productName,
  productSku,
  medias,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { refreshSession, currencyCode } = useUserSettings()
  const { openTooltip } = useUI()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [amount, setAmount] = useState<number>(0)
  const [selectedGiftCard, setSelectedGiftCard] = useState<number>(null)

  const handleAddToBasket = async (
    amount: number,
    productSku: string,
    locale: string
  ) => {
    if (!amount || !productSku) return null
    try {
      const customersData = await addGiftCardToBasket({
        amount,
        sku: productSku,
        locale: locale as string,
      })
      if (customersData?.success) {
        await refreshSession()
        openTooltip(TOOLTIPS.BASKET)
      } else throw Error
    } catch (err) {
      console.error(err)
    }
  }

  const handleInput = (val) => {
    let input = Math.round(Number(val))
    if (input < 0) input = Math.abs(amount)
    if (typeof input === 'number') setAmount(input)
  }

  useEffect(() => {
    setAmount(selectedGiftCard || 0)
  }, [selectedGiftCard])

  return (
    <div className={s.container}>
      {medias?.['622x622q80'] && (
        <img
          className={s.img}
          src={medias?.['622x622q80']}
          srcSet={`
            ${medias?.['622x622q80']},
            ${medias?.['720x720q80']} 2x,"
            ${medias?.thickbox} 3x,
          `}
          width="100%"
          height="100%"
          alt={productName}
        />
      )}
      <div className={s.details}>
        <p className={s.subtitle}>{t('product:giftcard.subtitle')}</p>
        <h1 className={s.title}>{t('product:giftcard.title')}</h1>
        <p className={s.text}>
          {t('product:giftcard.text_sale_1')}
          <br />
          {t('product:giftcard.text_sale_2')}
        </p>
        <DropdownSelect
          id="giftcardSelector"
          className={s.selectOpt}
          title={t('product:giftcard.selectTitle')}
          expanded={isExpanded}
          value={selectedGiftCard}
          options={GIFTCARD_AMOUNTS}
          getOptionValue={(opt: number) => opt}
          renderOption={(opt: number) => (
            <GiftCardOption
              option={opt}
              currencyCode={currencyCode}
              locale={locale}
              labelCustom={t('product:giftcard.customLabel')}
              placeholder={t('product:giftcard.selectTitle')}
            />
          )}
          renderSelectedOption={(opt: number) => (
            <GiftCardOption
              option={opt}
              currencyCode={currencyCode}
              locale={locale}
              labelCustom={t('product:giftcard.customLabel')}
              placeholder={t('product:giftcard.selectTitle')}
              selected
            />
          )}
          setExpanded={() => setIsExpanded(!isExpanded)}
          onChange={(value) => setSelectedGiftCard(value)}
        />
        {selectedGiftCard === 0 && (
          <input
            className={s.input}
            name="amount"
            type="number"
            placeholder={t('product:giftcard.selectTitle')}
            value={amount || ''}
            min={1}
            max={99999999}
            aria-label={t('product:giftcard.selectTitle')}
            onChange={(e) => handleInput(e.target.value)}
            required
            autoComplete="off"
            data-cs-mask
          />
        )}
        <Button
          className={s.add}
          onClick={() => handleAddToBasket(amount, productSku, locale)}
        >
          {t('product:addToShoppingBag')}
        </Button>
        <p className={s.text}>
          {t('product:giftcard.text_exp_1')}
          <br />
          {t('product:giftcard.text_exp_2')}
        </p>
      </div>
    </div>
  )
}

export default ProductGiftCard
