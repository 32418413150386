import React, { FC, ReactChild } from 'react'

import { motion } from 'framer-motion'

import cn from 'classnames'
import s from './AnimateMotionScroll.module.css'

interface AnimateMotionScrollProps {
  children: ReactChild
  className?: string
  from?: any
  to?: any
  duration?: number
  delay?: number
  replay?: boolean
  offset?: number
}

const AnimateMotionScroll: FC<AnimateMotionScrollProps> = ({
  children,
  className,
  from = { opacity: 0, transform: 'translateY(-10%)' },
  to = { opacity: 1, transform: 'translateY(0%)' },
  replay = false,
  duration = 0.3,
  delay = 0.4,
  offset = 0.1,
}) => (
  <motion.div
    className={cn(s.container, className)}
    initial={from}
    whileInView={to}
    viewport={{ once: !replay, amount: offset }}
    transition={{ duration, delay }}
  >
    {children}
  </motion.div>
)

export default AnimateMotionScroll
