import React, { FC } from 'react'

import ShopTheLookV1 from './ShopTheLookV1'
import ShopTheLookV2 from './ShopTheLookV2'

export interface ShopTheLookProps {
  title?: string
  media: BlocksMedia
  lookPoints: Array<{
    positionX: number
    positionY: number
    productId: number
  }>
  isInListing?: boolean
}

interface ShopTheLookVersionProps extends ShopTheLookProps {
  type?: string
}

const ShopTheLook: FC<ShopTheLookVersionProps> = (props) => {
  const { type, ...restProps } = props

  if (type === 'buy') return <ShopTheLookV2 {...restProps} />
  return <ShopTheLookV1 {...restProps} />
}

export default ShopTheLook
