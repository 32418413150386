const LinkedinCircle = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <g clipPath="url(#clip0_270_3198)">
      <circle cx="15" cy="15" r="15" fill="#292929" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM7.20089 12.4237H10.6002V22.6373H7.20089V12.4237ZM10.8243 9.26419C10.8022 8.26275 10.0861 7.5 8.92323 7.5C7.76034 7.5 7.00008 8.26275 7.00008 9.26419C7.00008 10.2449 7.73787 11.0296 8.8791 11.0296H8.90082C10.0861 11.0296 10.8243 10.2449 10.8243 9.26419ZM18.9457 12.1838C21.1826 12.1838 22.8596 13.6439 22.8596 16.7811L22.8594 22.6372H19.4602V17.1729C19.4602 15.8004 18.9683 14.8639 17.7378 14.8639C16.7986 14.8639 16.2392 15.4953 15.9936 16.1051C15.9037 16.3236 15.8816 16.6281 15.8816 16.9334V22.6375H12.4819C12.4819 22.6375 12.5267 13.3823 12.4819 12.4239H15.8816V13.8706C16.3328 13.1751 17.1408 12.1838 18.9457 12.1838Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_270_3198">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LinkedinCircle
