import React, { FC } from 'react'
import s from './Media.module.css'

interface MediaProps {
  media: BlocksMedia
  title?: string
}

const Media: FC<MediaProps> = ({ media, title = '' }) => (
  <div className={s.container}>
    <img src={media.formats.origin} alt={title} aria-hidden />
  </div>
)

export default Media
