const Eye = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }}
    {...props}
  >
    <path
      d="M3 12s3.273-5 9-5 9 5 9 5-3.273 5-9 5-9-5-9-5Z"
      style={{
        fill: 'none',
        fillRule: 'nonzero',
        stroke: '#121212',
        strokeWidth: 1,
      }}
    />
    <path
      d="M12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      style={{
        fill: '#121212',
        fillRule: 'nonzero',
        stroke: '#121212',
        strokeWidth: 1,
      }}
    />
    <path
      d="M10 11a2 2 0 0 1 2-2"
      style={{
        fill: 'none',
        fillRule: 'nonzero',
        stroke: '#fff',
        strokeWidth: 1,
      }}
    />
  </svg>
)

export default Eye
