import React, { FC } from 'react'

const ArrowRight: FC<any> = ({
  strokeW = '0.5',
  stroke = '#121212',
  ...props
}) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.75 13.5L7.25 7L0.749999 0.5"
      stroke={stroke}
      strokeWidth={strokeW}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowRight
