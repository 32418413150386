import React, { FC } from 'react'

export interface PaypalProps {
  width?: number
  className?: string
}

const Paypal: FC<PaypalProps> = ({ width = '567', className, ...props }) => (
  <svg
    width={width}
    viewBox="0 0 567 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <g clipPath="url(#clip0_3423_18219)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.47 32.33H88.21C109.01 32.33 116.84 42.86 115.63 58.33C113.63 83.87 98.19 98 77.71 98H67.37C64.56 98 62.67 99.86 61.91 104.9L57.52 134.2C57.23 136.1 56.2301 137.2 54.7301 137.35H30.39C28.1 137.35 27.29 135.6 27.89 131.81L42.7301 37.88C43.3101 34.12 45.37 32.33 49.47 32.33Z"
        fill="#009EE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.66 30.59C230.73 30.59 242.79 37.68 241.14 55.35C239.14 76.35 227.89 87.97 210.14 88.02H194.63C192.4 88.02 191.32 89.84 190.74 93.57L187.74 112.64C187.29 115.52 185.81 116.94 183.63 116.94H169.2C166.9 116.94 166.1 115.47 166.61 112.18L178.52 35.75C179.11 31.99 180.52 30.59 183.09 30.59H217.63H217.66ZM194.16 71.51H205.91C213.26 71.23 218.14 66.14 218.63 56.96C218.93 51.29 215.1 47.23 209.01 47.26L197.95 47.31L194.16 71.51ZM280.37 111.09C281.69 109.89 283.03 109.27 282.84 110.75L282.37 114.29C282.13 116.14 282.86 117.12 284.58 117.12H297.4C299.56 117.12 300.61 116.25 301.14 112.91L309.04 63.33C309.44 60.84 308.83 59.62 306.94 59.62H292.84C291.57 59.62 290.95 60.33 290.62 62.27L290.1 65.32C289.83 66.91 289.1 67.19 288.42 65.59C286.03 59.93 279.93 57.39 271.42 57.59C251.65 58 238.32 73.01 236.89 92.25C235.79 107.13 246.45 118.82 260.51 118.82C270.71 118.82 275.27 115.82 280.41 111.12L280.37 111.09ZM269.63 103.46C261.12 103.46 255.19 96.67 256.42 88.35C257.65 80.03 265.61 73.24 274.12 73.24C282.63 73.24 288.56 80.03 287.33 88.35C286.1 96.67 278.15 103.46 269.63 103.46ZM334.13 59.46H321.13C318.45 59.46 317.36 61.46 318.21 63.92L334.35 111.18L318.52 133.67C317.19 135.55 318.22 137.26 320.09 137.26H334.7C335.551 137.358 336.413 137.21 337.183 136.832C337.952 136.455 338.597 135.864 339.04 135.13L388.68 63.93C390.21 61.74 389.49 59.44 386.98 59.44H373.15C370.78 59.44 369.83 60.38 368.47 62.35L347.77 92.35L338.52 62.28C337.98 60.46 336.63 59.46 334.14 59.46H334.13Z"
        fill="#113984"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M440.32 30.59C453.39 30.59 465.45 37.68 463.8 55.35C461.8 76.35 450.55 87.97 432.8 88.02H417.3C415.07 88.02 413.99 89.84 413.41 93.57L410.41 112.64C409.96 115.52 408.48 116.94 406.3 116.94H391.87C389.57 116.94 388.77 115.47 389.28 112.18L401.21 35.73C401.8 31.97 403.21 30.57 405.78 30.57H440.32V30.59ZM416.82 71.51H428.57C435.92 71.23 440.8 66.14 441.29 56.96C441.59 51.29 437.76 47.23 431.67 47.26L420.61 47.31L416.82 71.51ZM503.03 111.09C504.35 109.89 505.69 109.27 505.5 110.75L505.03 114.29C504.79 116.14 505.52 117.12 507.24 117.12H520.06C522.22 117.12 523.27 116.25 523.8 112.91L531.7 63.33C532.1 60.84 531.49 59.62 529.6 59.62H515.52C514.25 59.62 513.63 60.33 513.3 62.27L512.78 65.32C512.51 66.91 511.78 67.19 511.1 65.59C508.71 59.93 502.61 57.39 494.1 57.59C474.33 58 461 73.01 459.57 92.25C458.47 107.13 469.13 118.82 483.19 118.82C493.39 118.82 497.95 115.82 503.09 111.12L503.03 111.09ZM492.3 103.46C483.79 103.46 477.86 96.67 479.09 88.35C480.32 80.03 488.28 73.24 496.79 73.24C505.3 73.24 511.23 80.03 510 88.35C508.77 96.67 500.81 103.46 492.3 103.46ZM551.43 117.2H536.63C536.372 117.211 536.116 117.166 535.878 117.067C535.64 116.968 535.427 116.817 535.254 116.626C535.081 116.435 534.953 116.208 534.878 115.962C534.803 115.715 534.783 115.455 534.82 115.2L547.82 32.84C547.944 32.2779 548.255 31.7743 548.701 31.4112C549.148 31.0481 549.704 30.8468 550.28 30.84H565.08C565.337 30.8285 565.594 30.874 565.832 30.9732C566.07 31.0724 566.283 31.2229 566.456 31.414C566.628 31.605 566.757 31.8319 566.832 32.0784C566.907 32.3249 566.927 32.585 566.89 32.84L553.89 115.2C553.77 115.766 553.461 116.274 553.014 116.641C552.567 117.008 552.008 117.212 551.43 117.22V117.2Z"
        fill="#009EE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2398 0H64.0198C74.9398 0 87.8998 0.35 96.5598 8C102.35 13.11 105.39 21.24 104.69 30C102.31 59.61 84.5998 76.2 60.8398 76.2H41.7198C38.4598 76.2 36.3098 78.36 35.3898 84.2L30.0498 118.2C29.6998 120.4 28.7498 121.7 27.0498 121.86H3.11978C0.469784 121.86 -0.470224 119.86 0.219776 115.44L17.4198 6.45999C18.1098 2.07999 20.5198 0 25.2398 0Z"
        fill="#113984"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9502 80.75L42.7202 37.88C43.3102 34.12 45.3702 32.32 49.4702 32.32H88.2102C94.6202 32.32 99.8102 33.32 103.87 35.17C99.9802 61.53 82.9302 76.17 60.6102 76.17H41.5202C38.9602 76.18 37.0802 77.46 35.9502 80.75Z"
        fill="#172C70"
      />
    </g>
    <defs>
      <clipPath id="clip0_3423_18219">
        <rect width="566.93" height="137.35" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Paypal
