import { URL_STATIC_SMALLABLE, URL_STATIC_V3_SMALLABLE } from '../../constants'

export const mediaSrcProduct = (
  //productImage: ProductImage,
  hit: HitAlgolia,
  sizes: string[]
): string[] => {
  const hasImageV2 = !!hit?.imagesV2?.length
  const url = hasImageV2 ? hit?.imagesV2?.[0]?.url : hit?.images?.[0]?.url ?? ''

  const static_url = hasImageV2
    ? process.env.NEXT_PUBLIC_STATIC_V3_URL || URL_STATIC_V3_SMALLABLE
    : process.env.NEXT_PUBLIC_STATIC_URL || URL_STATIC_SMALLABLE

  let images: string[] = []
  // objectif : '//staticv3.smallable.com/1228145-648x648q80/t-shirt-uni-collection-adulte-.webp'
  for (let size of sizes) {
    images[size] = url ? static_url + url.replace('%FORMAT%', size) : ''
  }
  return images
}

export const mediaSrcCard = (hit: HitAlgolia): MediaCard[] => {
  const hasImageV2 = !!hit?.imagesV2?.length
  const productImages = hasImageV2 ? hit?.imagesV2 : hit?.images

  if (!productImages?.length) {
    return null
  }

  let images: MediaCard[] = []

  const static_url = hasImageV2
    ? process.env.NEXT_PUBLIC_STATIC_V3_URL || URL_STATIC_V3_SMALLABLE
    : process.env.NEXT_PUBLIC_STATIC_URL || URL_STATIC_SMALLABLE

  for (let productImage of productImages) {
    let image: MediaCard = {
      ['324x324q80']: '',
      ['330x330q80']: '',
      ['648x648q80']: '',
    }

    image['324x324q80'] = productImage.url
      ? static_url + productImage.url.replace('%FORMAT%', '324x324q80')
      : ''
    image['330x330q80'] = productImage.url
      ? static_url + productImage.url.replace('%FORMAT%', '330x330q80')
      : ''
    image['648x648q80'] = productImage.url
      ? static_url + productImage.url.replace('%FORMAT%', '648x648q80')
      : ''
    images.push(image)
  }

  return images
}
