import React, { FC } from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

import productUrl from '@utils/url/product'
import { Link } from '@components/ui'
import { getItemsName } from '@utils/basket/items'
import { formattedPrice } from '@utils/prices'
import { Less, More } from '@components/icons/Sign'

import cn from 'classnames'
import s from './BasketDetail.module.css'

export interface BasketDetailProps {
  attachColor: boolean
  brand: ProductBrand
  color: string
  id: number
  image: {
    src: string
    alt?: string
    title?: string
  }
  name: string
  quantity: number
  sku: string
  size: string
  price: number
  removeHandler: (sku: string, quantity?: number) => void
  addHandler: (sku: string) => void
}

const BasketDetail: FC<BasketDetailProps> = ({
  attachColor,
  brand,
  color,
  id,
  image,
  name,
  quantity,
  removeHandler,
  addHandler,
  sku,
  size,
  price,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { currencyCode } = useUserSettings()

  const productName = getItemsName({ attachColor, name, color })

  return (
    <div className={s.container}>
      <Link
        href={productUrl({
          attachColor,
          brand,
          color,
          id,
          name,
          locale,
        })}
        next
        aria-label={`${t('layout:common.ariaNavigate')} ${name}`}
      >
        <div className={s.content}>
          <div className={s.media}>
            {image?.src && (
              <Image
                className={s.productImage}
                src={image.src}
                width={90}
                height={90}
                alt={productName}
                aria-label={image?.title}
                unoptimized
              />
            )}
          </div>
          <div className={s.desc}>
            {brand?.name && (
              <p className={s.brand}>
                <strong>{brand?.name}</strong>
              </p>
            )}
            <ul role="listbox">
              {productName && <li>{productName}</li>}
              {size && (
                <li className={cn(s.row, 'py-1')}>
                  <div>
                    {t('layout:header.links.basket.size')}&nbsp;: {size}
                  </div>
                  {price && currencyCode && (
                    <p className={s.price}>
                      {formattedPrice(price, currencyCode, locale)}
                    </p>
                  )}
                </li>
              )}
            </ul>
            {quantity && (
              <div className={cn(s.row, 'my-3.5')}>
                <div className={s.quantityHandler}>
                  <div className={s.quantity}>
                    <button
                      className={cn(s.quantityCta, s.less)}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        removeHandler(sku, 1)
                      }}
                      aria-label="-"
                    >
                      <Less />
                    </button>
                    <span>{quantity}</span>
                    <button
                      className={cn(s.quantityCta, s.more)}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        addHandler(sku)
                      }}
                      aria-label="+"
                    >
                      <More />
                    </button>
                  </div>
                </div>
                <button
                  className={s.quantityRemove}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    removeHandler(sku)
                  }}
                >
                  {t('layout:header.links.basket.remove')}&nbsp;<span>x</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BasketDetail
