import React, { FC, FormEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'
import { Button, DynamicInput } from '@components/ui'
import Alert, { Status } from '@components/common/Alert/Alert'

import { tagEventCS } from '@components/common/ContentSquare'
import { EMAIL_REGEXP } from '@constants'
import { fetchLogin } from '@mw/login'

import cn from 'classnames'
import s from './FormLogin.module.css'
import { changeLocaleIfTranslatable } from '@utils/locale'

enum Error {
  InvalidEmail = 'invalid email',
  EmptyField = 'empty field',
  CredentialsError = 'credentials error',
  DefaultError = 'default error',
}

export interface FormLoginProps {
  referer?: string
  isModal?: boolean
  handleReset: (param: boolean) => void
}

const FormLogin: FC<FormLoginProps> = ({
  referer,
  isModal = false,
  handleReset,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { closeModal } = useUI()
  const { refreshSession, tokens, deliveryCountryCode } = useUserSettings()
  const anonymousToken = tokens?.['X-ANONYMOUS-TOKEN'] || ''

  const [isLoading, setIsloading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{
    email: {
      status: Status
      message: string
      type: Error | null
    }
    password: {
      status: Status
      message: string
      type: Error | null
    }
    global: {
      status: Status
      message: string
      type: Error | null
    }
  }>(null)

  const [dataInput, setDataInput] = useState<{
    mail: string
    password: string
  }>({ mail: '', password: '' })

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    mail: string | undefined
  ) => {
    let isValid = true
    if (dataInput?.password?.length === 0) {
      e.preventDefault()
      setErrors({
        ...errors,
        password: {
          status: Status.FormError,
          message: t('layout:header.modalLogin.message.error_emptyField'),
          type: Error.EmptyField,
        },
      })
      tagEventCS({ eventName: 'login_emptyPassword' })
      isValid = false
    }
    if (mail && !mail.trim().match(EMAIL_REGEXP)) {
      e.preventDefault()
      setErrors({
        ...errors,
        email: {
          status: Status.FormError,
          message: t('layout:header.modalLogin.message.error_invalidEmail'),
          type: Error.InvalidEmail,
        },
      })
      tagEventCS({ eventName: 'login_invalidMail' })
      isValid = false
    }

    if (mail?.length === 0) {
      e.preventDefault()
      setErrors({
        ...errors,
        email: {
          status: Status.FormError,
          message: t('layout:header.modalLogin.message.error_emptyField'),
          type: Error.EmptyField,
        },
      })
      tagEventCS({ eventName: 'login_emptyMail' })
      isValid = false
    }
    if (isValid) {
      setIsloading(true)
      e.preventDefault()
      const formData = {
        username: mail?.trim().toLocaleLowerCase(),
        password: dataInput?.password,
        type: 'username_password',
      }
      try {
        const repLogin = await fetchLogin({
          formData,
          anonymousToken: anonymousToken,
          country: deliveryCountryCode,
        })
        if (repLogin.status === 200) {
          tagEventCS({ eventName: 'login_success' })
          if (!isModal) {
            router.back()
          } else {
            refreshSession()
            closeModal()
          }
        }
        if (
          repLogin?.status === 400 ||
          repLogin?.status === 401 ||
          repLogin?.status === 405
        ) {
          setErrors({
            ...errors,
            global: {
              status: Status.FormError,
              message: t(
                'layout:header.modalLogin.message.error_badCredentials'
              ),
              type: Error.CredentialsError,
            },
          })
          tagEventCS({ eventName: 'login_badCredentials' })
        }
        if (repLogin?.status === 500) {
          setErrors({
            ...errors,
            global: {
              status: Status.FormError,
              message: t(
                'layout:header.modalLogin.message.error_defaultMessage'
              ),
              type: Error.DefaultError,
            },
          })
        }
      } catch (err) {
        console.error(err)
      }
      setIsloading(false)
    }
  }

  const handleOnBlur = (e: any) => {
    const mail = dataInput?.mail.trim()
    const password = dataInput?.password

    if (e.target.id === 'login-username') {
      if (mail?.length === 0) {
        setErrors({
          ...errors,
          email: {
            status: Status.FormError,
            message: t('layout:header.modalLogin.message.error_emptyField'),
            type: Error.EmptyField,
          },
        })
      }
      if (mail && !mail.match(EMAIL_REGEXP)) {
        setErrors({
          ...errors,
          email: {
            status: Status.FormError,
            message: t('layout:header.modalLogin.message.error_invalidEmail'),
            type: Error.InvalidEmail,
          },
        })
      }
    }
    if (e.target.id === 'password' && password?.length === 0) {
      setErrors({
        ...errors,
        password: {
          status: Status.FormError,
          message: t('layout:header.modalLogin.message.error_emptyField'),
          type: Error.EmptyField,
        },
      })
    }
  }

  useEffect(() => {
    setErrors({
      ...errors,
      email: { ...errors?.email, message: '', type: null },
      global: { ...errors?.global, message: '', type: null },
    })
  }, [dataInput.mail])

  useEffect(() => {
    setErrors({
      ...errors,
      password: { ...errors?.password, message: '', type: null },
      global: { ...errors?.global, message: '', type: null },
    })
  }, [dataInput.password])

  return (
    <div className={cn(s.formContainer, { [s.fadeIn]: isLoading })}>
      {errors?.global && errors?.global.status && errors?.global.message && (
        <Alert
          status={errors?.global.status}
          message={errors?.global.message}
          className={s.alertMessage}
        />
      )}
      <h1 className={s.title}>{t('layout:header.modalLogin.login.title')}</h1>
      <p className={s.info}>{t('layout:header.modalLogin.login.text')}</p>
      <div className={s.formContent}>
        <form onSubmit={(e) => handleSubmit(e, dataInput?.mail)}>
          <DynamicInput
            id="login-username"
            data-testid="FormLogin_username"
            type="text"
            inputName="_username"
            label={t('layout:header.modalLogin.input.mail')}
            className={cn({
              [s.errorInput]: errors?.email?.type === Error.InvalidEmail,
            })}
            ariaLabel={t('layout:header.modalLogin.input.mail')}
            onChange={(e) =>
              setDataInput({ ...dataInput, mail: e.target.value })
            }
            onBlur={(e) => handleOnBlur(e)}
            errorState={errors?.email}
          />

          <DynamicInput
            id="password"
            data-testid="FormLogin_password"
            type="password"
            inputName="_password"
            label={t('layout:header.modalLogin.input.password')}
            className={cn({
              [s.errorInput]: errors?.password?.type === Error.EmptyField,
            })}
            ariaLabel={t('layout:header.modalLogin.input.password')}
            onChange={(e) =>
              setDataInput({ ...dataInput, password: e.target.value })
            }
            onBlur={(e) => handleOnBlur(e)}
            errorState={errors?.password}
          />

          <div className={s.formAction}>
            <Button>{t('layout:header.modalLogin.login.cta')}</Button>
            <div className={s.formComment}>
              <button className={s.button} onClick={() => handleReset(true)}>
                {t('layout:header.modalLogin.login.switcher')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FormLogin
