import { FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { Cross } from '@components/icons'
import { useUserSettings } from '@context/session'
import toSlug from '@lib/toSlug'
import { getCurrencySymbol } from '@utils/prices'

import {
  discounts,
  isGreenable,
  isInStock,
  isOutlet,
  itemToSizeAndStock,
  lowestPrice,
  shoeSizeGroups,
  shoeSizeSizeAndStock,
  sizeGroups,
  sizeSizeAndStock,
  tagAlgoliaClickedFilters,
} from '@lib/lib-algolia/algolia'
import {
  useCurrentRefinements,
  UseCurrentRefinementsProps,
} from 'react-instantsearch'
import ClearAlgolia from '../ClearAlgolia'

import cn from 'classnames'
import s from './CurrentRefinement.module.css'

const CurrentRefinement: FC<UseCurrentRefinementsProps> = (props) => {
  const { t } = useTranslation()
  const { currencyCode, deliveryCountryCode } = useUserSettings()
  const { locale } = useRouter()
  const { items, refine } = useCurrentRefinements(props)

  if (items?.length) {
    for (const item of items) {
      for (const refinement of item.refinements) {
        if (refinement.label === '') {
          item.refinements.splice(item.refinements.indexOf(refinement), 1)
        }
      }
      if (item.refinements.length === 0) {
        items.splice(items.indexOf(item), 1)
      }
    }
  }

  const label = (refinement) => {
    switch (refinement.attribute) {
      case isInStock:
        return t('block:filter.inStock')
      case isOutlet:
        return t('block:filter.outlet')
      case isGreenable:
        return t('product:greenable.title')
      case sizeSizeAndStock:
      case shoeSizeSizeAndStock:
        return itemToSizeAndStock(refinement.label).size
      case sizeGroups:
      case shoeSizeGroups:
        return refinement.value
      case discounts:
        return refinement.label + ' %'
      case lowestPrice(deliveryCountryCode):
        return `${refinement.label} ${getCurrencySymbol(locale, currencyCode)}`
      default:
        return refinement.label
    }
  }

  useEffect(() => {
    if (items?.length > 0) {
      let filters = []
      for (const item of items) {
        for (const refinement of item.refinements) {
          filters.push(`${refinement.attribute}:${refinement.value}`)
        }
      }
      tagAlgoliaClickedFilters({
        filters: filters,
        deliveryCountryCode,
        locale,
      })
    }
  }, [items])

  return (
    <div className={cn(s.container, { [s.enable]: items?.length > 0 })}>
      <p className={s.heading}>{t('block:filter.selectedTitle')}</p>
      <div className={s.list}>
        {items?.map((item, index) => (
          <div key={index} className={s.buttons}>
            {item.refinements.map((refinement, index) => (
              <div key={toSlug(refinement.label)}>
                <button
                  className={s.filter}
                  aria-label={t('block:filter.deleteSomeFilters', {
                    filterText: refinement.label,
                  })}
                  onClick={(event) => {
                    event.preventDefault()
                    refine(refinement)
                  }}
                >
                  {label(refinement)}
                  <Cross className={s.cross} aria-hidden />
                </button>
              </div>
            ))}
          </div>
        ))}
        <ClearAlgolia
          className={cn(s.clear, { [s.enable]: items?.length > 0 })}
        />
      </div>
    </div>
  )
}

export default CurrentRefinement
