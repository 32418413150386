import { FC } from 'react'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { Arrow } from '@components/ui'
import s from './NavbarFooterSection.module.css'

interface Props {
  collapse?: () => void
  expand?: () => void
  isExpanded: boolean
  item: NavigationItem
}

const NavbarFooterSection: FC<Props> = ({
  collapse = () => {},
  expand = () => {},
  isExpanded,
  item,
}) => {
  const { t } = useTranslation()

  return (
    <li className={cn(s.item, { [s.expanded]: isExpanded })}>
      <div className={s.buttonContainer}>
        {item.url ? (
          <a
            className={s.link}
            href={t(item.url || '')}
            rel={item.rel}
            style={item.color ? { color: item.color } : {}}
          >
            <strong className={s.title}>{t(item.title || '')}</strong>
            {item.children?.length ? <Arrow className={s.arrow} /> : null}
          </a>
        ) : (
          <span
            className={s.link}
            style={item.color ? { color: item.color } : {}}
          >
            <strong className={s.title}>{t(item.title || '')}</strong>
            {item.children?.length ? <Arrow className={s.arrow} /> : null}
          </span>
        )}

        {item.children?.length ? (
          <button
            className={s.button}
            aria-label={t(
              isExpanded
                ? 'layout:navigation.ariaCollapseMenu'
                : 'layout:navigation.ariaExpandMenu',
              { title: t(item.title || '') }
            )}
            onClick={() => {
              isExpanded ? collapse() : expand()
            }}
          />
        ) : null}
      </div>

      {item.children?.length ? (
        <ul className={s.items}>
          {item.children.map((navItem: NavigationItem, index: number) => (
            <li className={s.subItem} key={index}>
              <a
                href={t(navItem.url || '')}
                rel={item.rel}
                style={navItem.color ? { color: navItem.color } : {}}
                onFocus={() => {
                  if (!isExpanded) {
                    expand()
                  }
                }}
              >
                {t(navItem.title || '')}
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  )
}

export default NavbarFooterSection
