import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { MediaQueries } from '@components/common'
import accountMenu from './accountMenu.json'

import s from './AccountMenu.module.css'

const AccountMenu: FC<{}> = () => {
  const { t } = useTranslation()

  if (!accountMenu?.length) return null

  return (
    <MediaQueries hidden={['xs', 'sm', 'md']}>
      <div className={s.menu}>
        <h1 className={s.title}>
          <a href={t('account:menu.href')} className={s.title}>
            {t('account:menu.title')}
          </a>
        </h1>
        <ul>
          {accountMenu?.map((cat, i) => (
            <li key={`menu_account_${s.catTitle}_${i}`} className={s.catTitle}>
              <a href={t(`account:${cat.href}`)} className={s.catTitle}>
                {t(`account:${cat.title}`)}
              </a>
              {cat?.subCategories?.length ? (
                <ul>
                  {cat.subCategories.map((subCat, i) => (
                    <li
                      key={`subMenu_account_${s.subCatTitle}_${i}`}
                      className={s.subCatTitle}
                    >
                      <a href={t(`account:${subCat.href}`)}>
                        {t(`account:${subCat.title}`)}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </MediaQueries>
  )
}

export default AccountMenu
