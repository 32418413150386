import { FC } from 'react'
import cn from 'classnames'

import s from './PriceLine.module.css'
import {
  formattedObjPrices,
  getLowestPriceDeclination,
  isSinglePrice,
} from '@utils/prices'

import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

interface Props {
  className?: string
  product: Product
  selectedSize?: string
}

const PriceLine: FC<Props> = ({ className, product, selectedSize }) => {
  const { locale } = useRouter()
  const { t } = useTranslation()

  const prices = formattedObjPrices(product.prices, locale as string) as Prices
  const lowestPrice = getLowestPriceDeclination(prices)
  const singlePrice = isSinglePrice(prices)

  if (!prices || (!lowestPrice && !selectedSize)) return null
  if (!lowestPrice) return null

  const { amount, baseAmount, discount } = selectedSize
    ? prices[selectedSize]
    : prices[lowestPrice]

  return (
    <p className={cn(s.priceLineContainer, className)}>
      {!selectedSize && !singlePrice && (
        <span className={s.labelFrom}>{t('product:price.from')}</span>
      )}
      <span className={cn(s.priceLine, 'notranslate')}>
        {baseAmount && baseAmount !== amount ? (
          <>
            <span className={s.price}>{baseAmount}</span>
            <span className={s.basePrice}>{amount}</span>
            <span className={s.discount}>{`-${discount}`}</span>
          </>
        ) : (
          <>
            <span>{amount}</span>
          </>
        )}
      </span>
    </p>
  )
}

export default PriceLine
