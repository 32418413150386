import { FC } from 'react'

interface Props {
  className?: string
  fill?: string
  stroke?: string
  size?: string
}

const Gift: FC<Props> = ({
  fill = 'none',
  stroke = '#1F1F1F',
  className,
  size = '24',
  ...props
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M3.5 21.5V11.5H20.5V21.5H3.5Z"
      stroke={stroke}
      strokeLinejoin="round"
    />
    <path d="M12 11.5L12 21" stroke={stroke} />
    <path
      d="M2.5 11.5V7.5H21.5V11.5H2.5Z"
      stroke={stroke}
      strokeLinejoin="round"
    />
    <path
      d="M11.2802 7.20687C11.3119 7.31194 11.34 7.4101 11.3647 7.5H6.85714C6.24008 7.5 5.64305 7.24284 5.19891 6.77649C4.75398 6.30931 4.5 5.6707 4.5 5C4.5 4.3293 4.75398 3.69069 5.19891 3.22351C5.64305 2.75716 6.24008 2.5 6.85714 2.5C8.56657 2.5 9.72348 3.77694 10.4857 5.232C10.8594 5.94557 11.1165 6.66394 11.2802 7.20687Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1428 7.5L12.6353 7.5C12.6599 7.4101 12.688 7.31194 12.7197 7.20687C12.8835 6.66394 13.1405 5.94557 13.5143 5.232C14.2765 3.77694 15.4334 2.5 17.1428 2.5C17.7599 2.5 18.3569 2.75716 18.801 3.22351C19.246 3.69069 19.4999 4.3293 19.4999 5C19.4999 5.6707 19.246 6.30931 18.801 6.77649C18.3569 7.24284 17.7599 7.5 17.1428 7.5Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Gift
