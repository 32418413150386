import { FC } from 'react'

import { VideoPlayer } from '@components/blocks-cms'

import s from './ProductVideoModal.module.css'

export interface ProductVideoModalProps {
  src: string
}

const ProductVideoModal: FC<ProductVideoModalProps> = ({ src }) => (
  <div className={s.container}>
    <VideoPlayer url={src} autoPlay={true} />
  </div>
)

export default ProductVideoModal
