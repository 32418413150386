import { FC } from 'react'

import { SideMedia } from '@components/ui'
import { MediaQueries, SigninModul } from '@components/common'
import { TagGeneric } from '@components/common/Eulerian'

import s from './AccountModal.module.css'

const AccountModal: FC<{}> = () => (
  <div className={s.container}>
    <TagGeneric urlp="/login_modal" />
    <MediaQueries hidden={['xs', 'sm']}>
      <SideMedia url="/img/store-entrance-light.jpg" />
    </MediaQueries>
    <SigninModul className={s.account} isModal={true} />
  </div>
)

export default AccountModal
