import normalize from '@lib/normalize'
import useTranslation from 'next-translate/useTranslation'
import { KeyboardEvent } from 'react'

import {
  useToggleRefinement,
  UseToggleRefinementProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './RefinementToggleButton.module.css'

interface RefinementToggleButtonProps {
  title: string
}

export function RefinementToggleButton(
  props: UseToggleRefinementProps & RefinementToggleButtonProps
) {
  const { t } = useTranslation()
  const { title } = props
  const { refine, value } = useToggleRefinement(props)

  const titleNormalize = normalize(value.name)

  const onClickHandler = (check: boolean) => {
    if (value.count) {
      refine({ isRefined: check })
    }
  }

  const onKeyDownHandler = (
    e: KeyboardEvent<HTMLInputElement>,
    check: boolean
  ) => {
    if (e.key === 'Enter') {
      onClickHandler(check)
    }
  }

  if (!value) return null
  return (
    <div className={s.container}>
      <ul className={s.filtersList}>
        <li
          className={cn(s.container, { [s.disabled]: !value.count })}
          data-cs-override-id={`ToggleItem-${value.name}`}
        >
          <div className={s.content}>
            <input
              type="checkbox"
              id={`checkbox_${titleNormalize}`}
              className={cn(
                s.box,
                { [s.checked]: value.isRefined },
                { [s.disabled]: !value.count }
              )}
              value={titleNormalize}
              disabled={!value.count}
              checked={value.isRefined}
              aria-checked={value.isRefined}
              aria-label={t('block:filter.ariaFilterItem', {
                filterText: titleNormalize,
              })}
              onChange={(e) => onClickHandler(!e.target.checked)}
              onKeyDown={(e) => onKeyDownHandler(e, !value.isRefined)}
            />
            <label htmlFor={`checkbox_${titleNormalize}`} className={s.title}>
              {title}
              <span
                className={cn(
                  s.switch,
                  { [s.checked]: value.isRefined },
                  { [s.disabled]: !value.count }
                )}
                aria-hidden
              />
            </label>
          </div>
        </li>
      </ul>
    </div>
  )
}
export default RefinementToggleButton
