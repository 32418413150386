import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import s from './SearchBox.module.css'

export default function ClearSearchRefinements(props: ClearSearchBoxProps) {
  const { t } = useTranslation()
  const { focusInputSearch } = props

  return (
    <button
      onClick={() => {
        focusInputSearch()
      }}
      className={s.newSearchCenter}
    >
      {t('layout:header.search.newSearch')}
    </button>
  )
}
