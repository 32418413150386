import { useState } from 'react'
import { useUI } from '@components/ui/context'
import { TOOLTIPS } from '@constants'

type tooltipState = [
  expanded: boolean,
  setExpanded: (expanded: boolean) => void
]

const useTooltip = (id: TOOLTIPS) => {
  const { displayTooltip, openTooltip, closeTooltip } = useUI()
  const [expanded, setExpanded] =
    displayTooltip[id] !== undefined
      ? [
          displayTooltip[id],
          (bool: boolean) => (bool ? openTooltip(id) : closeTooltip(id)),
        ]
      : useState<boolean>(false)
  return [expanded, setExpanded] as tooltipState
}

export default useTooltip
