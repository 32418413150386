import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import s from './ReviewNoticeModal.module.css'

const ReviewNoticeModal: FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <div className={s.container}>
      <h2 className={s.title}> {t('product:review.notice.title')}</h2>
      <p className={s.text}>{t('product:review.notice.paragraph_1')}</p>
      <p className={s.text}>{t('product:review.notice.paragraph_2')}</p>
    </div>
  )
}

export default ReviewNoticeModal
