export const sortCountriesAlphabetically = (countries?: Array<Country>) => {
  if (!countries) return undefined
  return [...countries].sort((a, b) => a.name.localeCompare(b.name))
}

export const postCodeFormatToRegex = (
  postCodeFormat?: string,
  isoCode?: string
) => {
  if (!postCodeFormat?.length || !isoCode?.length) return null
  let reg: RegExp
  if (isoCode === 'GB') {
    reg = new RegExp(postCodeFormat.replaceAll('/', ''))
    return reg
  }

  let postCodeRegex = `^${postCodeFormat}$`
  postCodeRegex = postCodeRegex
    .replaceAll(' ', '( ||-)')
    .replaceAll('-', '( ||-)')
    .replaceAll('W', 'w')
    .replaceAll('N', '[0-9]')
    .replaceAll('L', '[a-zA-z]')
    .replaceAll('C', isoCode)

  reg = new RegExp(postCodeRegex, 'gi')
  return reg
}

export const getStore = ({
  locale,
  deliveryCountryCode,
}: {
  locale: string
  deliveryCountryCode: string
}) => {
  return `${locale}-${deliveryCountryCode}` as any
}
