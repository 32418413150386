import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { useUI } from '@components/ui/context'
import { MODALS } from '@constants'
import { PlayBar, PlayCircle } from '@components/icons'
import { tagEventCS } from '@components/common/ContentSquare'

import cn from 'classnames'
import s from './ProductMediaVideo.module.css'

export interface ProductMediaVideoProps {
  productVideo: string
  productName: string
  placeholder: Media
  displayLite?: boolean
  classname?: string
}

const ProductMediaVideo: FC<ProductMediaVideoProps> = ({
  productVideo,
  productName,
  placeholder,
  displayLite = false,
  classname,
}) => {
  const { t } = useTranslation()
  const { setModalView, openModal } = useUI()

  const openVideoModal = () => {
    setModalView(MODALS.PRODUCT_VIDEO, { src: productVideo })
    openModal()
    tagEventCS({ eventName: 'video_product_player_open' })
  }

  if (!productVideo?.length) return null

  return (
    <button
      className={cn(s.container, { ['bg-grey200']: !displayLite }, classname)}
      aria-label={t('product:productMiniatures.ariaProductVideo', {
        productName: productName,
      })}
      onClick={() => openVideoModal()}
    >
      {!displayLite ? (
        <>
          <img
            className={s.img}
            src={placeholder?.thickbox}
            srcSet={`
                ${placeholder?.['622x622q80']},
                ${placeholder?.['720x720q80']} 2x,"
                ${placeholder?.thickbox} 3x,
              `}
            width="100%"
            height="100%"
            alt={t('product:productMiniatures.altProductVideo', {
              productName: productName,
            })}
          />
          <PlayBar className={s.icon} />
        </>
      ) : (
        <div className={s.cta}>
          <PlayCircle />
          <p className={s.ctaLabel}>
            {t('product:productMiniatures.ctaProductVideo')}
          </p>
        </div>
      )}
    </button>
  )
}

export default ProductMediaVideo
