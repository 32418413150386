import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import { useUI } from '@components/ui/context'
import { Footer, Header, Navbar } from '@components/common'
import { LoadingDots, Modal, ScrollToTop, Separator } from '@components/ui'
import { ProductMsgAddToBasket } from '@components/product'
import { MODALS, NUMBER_PAGE_MODAL_NL } from '@constants'
import cn from 'classnames'
import s from './Layout.module.css'
import { useRouter } from 'next/router'
import { getCookie, setCookie } from '@utils/cookies'

const Loading = () => (
  <div className="w-80 h-80 m-auto flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
)

const AccountModal = dynamic(() => import('@components/common/AccountModal'), {
  loading: () => <Loading />,
})
const NewsletterModal = dynamic(
  () => import('@components/common/NewsletterModal'),
  { loading: () => <Loading /> }
)
const PrivateSalesModal = dynamic(
  () => import('@components/common/PrivateSalesModal'),
  { loading: () => <Loading /> }
)
const NotifyMeModal = dynamic(
  () => import('@components/product/ProductDetails/NotifyMeModal'),
  { loading: () => <Loading /> }
)
const PageModal = dynamic(() => import('@components/common/PageModal'), {
  loading: () => <Loading />,
})
const NotificationModal = dynamic(
  () => import('@components/common/NotificationModal'),
  { loading: () => <Loading /> }
)
const TelesalesModal = dynamic(
  () => import('@components/common/TelesalesModal'),
  { loading: () => <Loading /> }
)
const SearchModal = dynamic(
  () => import('@components/common/Header/Search/SearchModal/SearchModal')
)
const ReviewNoticeModal = dynamic(
  () => import('@components/product/ReviewNoticeModal')
)
const ReviewsModal = dynamic(() => import('@components/product/ReviewsModal'), {
  loading: () => <Loading />,
})

interface Props {
  pageProps: {
    alternates?: Array<Alternate>
    countries?: Array<Country>
    currencies: Array<Currency>
    locale: string
    navigation?: NavigationItem[]
    page?: Page
    topSeparator?: boolean
    withoutScrollToToTop?: boolean
    layerStyle?: string
  }
}

const Layout: FC<Props> = ({ children, pageProps }) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const {
    locale,
    events,
    query: { revert },
  } = useRouter()
  const {
    displayModal,
    closeModal,
    modalView,
    modalViewProps,
    isSiteReverted,
    enableSiteReverted,
    setModalView,
    openModal,
  } = useUI()

  const [isPrivateSalesModalOpen, setIsPrivateSalesModalOpen] = useState(true)
  const [autoDisplayNewsletter, setAutoDisplayNewsletter] = useState(false)

  enableSiteReverted(
    typeof revert !== 'undefined'
      ? true
      : typeof window !== 'undefined'
      ? window?.Translatable?.currentLang
      : locale
  )

  useEffect(() => {
    const alreadyView = getCookie('_newsletter_proposal', document.cookie)
    if (
      !isPrivateSalesModalOpen &&
      !autoDisplayNewsletter &&
      alreadyView !== 'view'
    ) {
      setCookie('_newsletter_proposal', 'view', 5)
      setAutoDisplayNewsletter(true)
      setModalView(MODALS.NEWSLETTER)
      openModal()
    }
  }, [isPrivateSalesModalOpen, autoDisplayNewsletter, setModalView, openModal])

  useEffect(() => {
    const handleRouteChange = () => {
      const pageCount =
        parseInt(localStorage.getItem('pageVisitCount') || '0') + 1
      localStorage.setItem('pageVisitCount', pageCount.toString())

      if (pageCount >= NUMBER_PAGE_MODAL_NL) {
        setCookie('_newsletter_proposal', 'view', 5)
        localStorage.setItem('pageVisitCount', '0')
        setAutoDisplayNewsletter(true)
        setModalView(MODALS.NEWSLETTER)
        openModal()
      }
    }
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  const isDesktop = useMemo(
    () => ![Screen.xs, Screen.sm, Screen.md, Screen.lg].includes(screen),
    [screen]
  )

  const handleClosePrivateSalesModal = useCallback(() => {
    closeModal()
    setIsPrivateSalesModalOpen(false)
  }, [closeModal])

  return (
    <div className={s.root} dir={isSiteReverted ? 'rtl' : 'ltr'}>
      <div className={s.head}>
        <Header
          alternates={pageProps.alternates}
          locale={pageProps.locale}
          countries={pageProps.countries}
          currencies={pageProps.currencies}
        />
        {pageProps.navigation ? (
          <Navbar
            navigation={pageProps.navigation}
            alternates={pageProps.alternates}
            locale={pageProps.locale}
            currencies={pageProps.currencies}
            countries={pageProps.countries}
          />
        ) : null}
      </div>
      {pageProps.topSeparator ? <Separator /> : null}
      <main className="fit">{children}</main>
      <Footer
        content={pageProps.page?.footer}
        className={
          pageProps.layerStyle === 'no_margin' ? s.no_margin : undefined
        }
      />
      {!pageProps.withoutScrollToToTop ? <ScrollToTop /> : null}
      <ProductMsgAddToBasket
        open={displayModal && modalView === MODALS.ADD_TO_BASKET}
      />
      <SearchModal
        open={displayModal && modalView === MODALS.SEARCH}
        clearInput={modalViewProps}
      />

      <PrivateSalesModal
        open={displayModal && modalView === MODALS.PRIVATE_SALES}
        closeModal={handleClosePrivateSalesModal}
      />

      <NewsletterModal
        open={displayModal && modalView === MODALS.NEWSLETTER}
        closeModal={closeModal}
        countries={pageProps.countries}
        emailProp={modalViewProps?.email || ''}
        autoDisplay={
          modalViewProps?.autoDisplay || autoDisplayNewsletter || false
        }
        isDesktop={isDesktop}
      />

      <Modal
        open={
          displayModal &&
          [
            MODALS.ACCOUNT,
            MODALS.FAQ,
            MODALS.RETURN_REFUND,
            MODALS.SHOES_SIZE_GUIDE,
            MODALS.NOTIFY_ME,
            MODALS.SIZE_GUIDE,
            MODALS.NOTIFICATION,
            MODALS.PAGE_POPIN,
            MODALS.TELESALES,
            MODALS.REVIEW_NOTICE,
            MODALS.REVIEWS,
          ].includes(modalView)
        }
        onClose={closeModal}
        className={cn(
          { [s.no_overflow]: modalView === MODALS.TELESALES },
          { [s.no_border]: modalView === MODALS.SEARCH },
          { [s.full_width]: modalView === MODALS.SEARCH },
          { [s.animation]: modalView === MODALS.SEARCH },
          { [s.height_auto]: modalView === MODALS.SEARCH }
        )}
        showHeader={modalView !== MODALS.SEARCH}
      >
        {modalView === MODALS.RETURN_REFUND && (
          <PageModal
            url={
              modalViewProps?.url || `${t(`layout:footer.help.returns.modal`)}`
            }
            id="return_refund"
          />
        )}
        {modalView === MODALS.SIZE_GUIDE && (
          <PageModal
            url={
              modalViewProps?.url ||
              `${t(`layout:footer.help.sizeGuide.modal`)}`
            }
            id="size_guide"
            withMenu={false}
          />
        )}
        {modalView === MODALS.SHOES_SIZE_GUIDE && (
          <PageModal
            url={
              modalViewProps?.url ||
              `${t(`layout:footer.help.shoeSizeGuide.modal`)}`
            }
            id="shoe_size_guide"
            withMenu={false}
          />
        )}
        {modalView === MODALS.NOTIFY_ME && (
          <NotifyMeModal sku={modalViewProps.sku} onclose={closeModal} />
        )}
        {modalView === MODALS.FAQ && (
          <PageModal url={`${t(`layout:footer.help.faq.modal`)}`} />
        )}
        {modalView === MODALS.PAGE_POPIN && (
          <PageModal url={modalViewProps?.url || ''} id="popin_page" />
        )}
        {modalView === MODALS.ACCOUNT && <AccountModal />}
        {modalView === MODALS.NOTIFICATION && (
          <NotificationModal message={modalViewProps?.message || ''} />
        )}
        {modalView === MODALS.TELESALES && (
          <TelesalesModal countries={pageProps.countries} />
        )}
        {modalView === MODALS.REVIEW_NOTICE && <ReviewNoticeModal />}
        {modalView === MODALS.REVIEWS && (
          <ReviewsModal reviewData={modalViewProps?.reviewData} />
        )}
      </Modal>
    </div>
  )
}

export default Layout
