import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { useUserSettings } from '@context/session'

import ProductCard from '@components/product/ProductCard'
import NextPagination from '@components/common/NextPagination'
import { Button, Link, LoadingDots, ProgressBar } from '@components/ui'
import { Block } from '@components/blocks-cms/Blocks/Blocks'

import qs from 'query-string'
import { fetchProductsList } from '@mw/products'
import { changeLocaleIfTranslatable } from '@utils/locale'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import cn from 'classnames'
import s from './ProductsGrid.module.css'

const enrichCustomInsert = ({
  customInserts,
  isPaginate,
  currentPage,
  productsPerLine,
}: {
  customInserts: CustomListingInsertTypes[]
  isPaginate: boolean
  currentPage: string
  productsPerLine: number
}) => {
  const productsPerListing =
    Number(process.env.NEXT_PUBLIC_PRODUCTS_PER_LISTING) || null
  if (
    !customInserts?.length ||
    !currentPage?.length ||
    !productsPerLine ||
    !productsPerListing
  )
    return null
  return customInserts.reduce((acc, insert) => {
    const lineToInsert =
      insert.toInsert.line <= 0 ? 0 : insert.toInsert.line - 1
    const pageToInsert = insert.toInsert.page <= 0 ? 1 : insert.toInsert.page
    if (isPaginate) {
      insert.indexToInsert =
        Number(currentPage) === pageToInsert
          ? productsPerLine * lineToInsert
          : null
    } else {
      insert.indexToInsert =
        productsPerListing * (pageToInsert - 1) + productsPerLine * lineToInsert
    }
    if (insert.indexToInsert >= productsPerListing)
      insert.indexToInsert = productsPerListing - productsPerLine
    acc.push(insert)
    return acc
  }, [])
}

export interface ProductsGridProps {
  products: ProductListItem[]
  productsTitle?: string
  productsTotal: number
  productsParameters: ProductsParameters
  productsPerLine: number
  urlParams?: any
  currentPage?: string
  chunk?: number
  isPaginate?: boolean
  changeFiltersSelected?: changeFiltersSelectedProps
  layout?: string
  categoryUrl?: string
  customInserts?: CustomListingInsertTypes[]
}

const ProductsGrid: FC<ProductsGridProps> = ({
  products = [],
  productsTitle,
  productsTotal,
  productsParameters,
  productsPerLine,
  urlParams = {},
  currentPage = '1',
  chunk = 36,
  isPaginate = false,
  changeFiltersSelected,
  layout,
  categoryUrl,
  customInserts,
}) => {
  const { t } = useTranslation()
  const { locale, query, asPath, ...router } = useRouter()
  const { currencyCode, deliveryCountryCode } = useUserSettings()

  const [isLoading, setIsLoading] = useState(false)
  const [gridData, setGridData] = useState<ProductListItem[]>(products)
  const [insertElements, setInsertElements] = useState(
    enrichCustomInsert({
      customInserts,
      isPaginate,
      currentPage,
      productsPerLine,
    })
  )
  const userSetting = useRef({ currencyCode, deliveryCountryCode })
  const isMounted = useRef(false)

  const loadMore = async ({
    gridData,
    productsParameters,
    locale,
    urlParams,
    chunk,
    batch,
  }: {
    gridData: ProductListItem[]
    productsParameters: ProductsParameters
    locale: string
    urlParams: Record<string, any>
    chunk: number
    batch?: number
  }) => {
    setIsLoading(true)
    try {
      const currentPage = batch || Math.round(gridData.length / chunk)
      productsParameters.offset = batch ? chunk : chunk * currentPage
      productsParameters.limit = batch ? chunk * (currentPage - 1) : chunk
      urlParams._scroll = batch || currentPage + 1
      const vanityUrlString = qs
        .stringify(urlParams, {
          arrayFormat: 'separator',
          arrayFormatSeparator: '+',
        })
        .split('+')
        .join('%2B')
      delete urlParams._scroll
      const urlToRequest = qs
        .stringify(
          { ...productsParameters, ...urlParams },
          {
            arrayFormat: 'separator',
            arrayFormatSeparator: '+',
          }
        )
        .split('+')
        .join('%2B')
      const newProducts = await fetchProductsList({
        query: urlToRequest,
        locale,
      })
      if (newProducts?.products.length) {
        setGridData([...gridData.concat(newProducts.products)])
        router.push(`${asPath.split('?')[0]}?${vanityUrlString}`, undefined, {
          shallow: true,
          locale: changeLocaleIfTranslatable(locale),
        })
      }
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setInsertElements(
      enrichCustomInsert({
        customInserts,
        isPaginate,
        currentPage,
        productsPerLine,
      })
    )
  }, [customInserts, currentPage, isPaginate, productsPerLine])

  useEffect(() => {
    if (layout === 'listing') {
      return null
    }
    ;(async () => {
      try {
        if (
          userSetting.current.currencyCode !== currencyCode ||
          userSetting.current.deliveryCountryCode !== deliveryCountryCode
        ) {
          const urlToRequest = qs
            .stringify(productsParameters, {
              arrayFormat: 'separator',
              arrayFormatSeparator: '+',
            })
            .split('+')
            .join('%2B')
          const newProducts = await fetchProductsList({
            query: urlToRequest,
            locale: locale as string,
          })
          if (newProducts?.products?.length && isMounted?.current) {
            setGridData(newProducts.products)
            userSetting.current = { currencyCode, deliveryCountryCode }
          }
        }
      } catch (err) {
        console.error(err)
      }
    })()
  }, [currencyCode, deliveryCountryCode])

  useEffect(() => {
    setGridData(products)
  }, [products])

  useEffect(() => {
    if (isPaginate || layout !== 'listing' || Number(query?._scroll) <= 1)
      return null
    const initialPage = (query._scroll as string) || null
    if (initialPage) {
      loadMore({
        gridData,
        productsParameters,
        locale,
        urlParams,
        chunk,
        batch: parseInt(initialPage, 10),
      })
    }
  }, [])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!gridData?.length) return null

  return (
    <section
      className={cn(s.container, { [s.noPadding]: layout === 'listing' })}
    >
      <div className={cn(s.content, { [s.titleBorder]: productsTitle })}>
        {productsTitle && (
          <h2 className={s.title}>
            {categoryUrl ? (
              <Link href={categoryUrl} next>
                {productsTitle}
              </Link>
            ) : (
              productsTitle
            )}
          </h2>
        )}
        <div className={s.row}>
          {gridData?.map((current, index) => {
            let pushComponent = !!insertElements?.length
              ? insertElements.find((insert) => insert.indexToInsert === index)
              : null
            return (
              <Fragment key={`ProductsGrid-${current?.id}-${index}`}>
                {pushComponent && (
                  <div className={s.customInsertRow}>
                    <Block block={pushComponent.content} />
                  </div>
                )}
                {current && index < gridData.length && (
                  <div
                    className={cn(s.col, { [s.half]: productsPerLine === 2 })}
                  >
                    <ProductCard
                      product={current}
                      productsPerLine={productsPerLine}
                      priority={index === 0 && layout === 'listing'}
                    />
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
        {productsTotal && (
          <>
            {gridData?.length < productsTotal ? (
              <div className={s.navigationContainer}>
                {isPaginate ? (
                  <NextPagination
                    currentPage={currentPage}
                    total={productsTotal}
                    chunk={chunk}
                    isPaginate={isPaginate}
                  />
                ) : (
                  <>
                    {!isLoading ? (
                      <div className={s.loadMoreContainer}>
                        <Trans
                          i18nKey="product:sameCategoryProduct.productsSeen"
                          components={[
                            <p className={s.loadmore} />,
                            <mark aria-live="polite">{gridData?.length}</mark>,
                            <mark>{productsTotal}</mark>,
                          ]}
                        />
                        <ProgressBar
                          total={productsTotal}
                          part={gridData.length}
                        />
                        <Button
                          className={s.seemoreButton}
                          onClick={() =>
                            loadMore({
                              gridData,
                              productsParameters,
                              locale,
                              urlParams,
                              chunk,
                            })
                          }
                        >
                          {t('product:sameCategoryProduct.loadMore_cta')}
                        </Button>
                      </div>
                    ) : (
                      <LoadingDots className={s.loading} />
                    )}
                  </>
                )}

                {layout === 'listing' && (
                  <div className={s.paginationTypeContainer}>
                    <label
                      htmlFor="pagination_selector"
                      className={s.labelSelector}
                    >
                      {t('block:displayProducts.show')} :
                    </label>
                    <select
                      className={s.selector}
                      value={isPaginate ? '0' : '1'}
                      onChange={(e) =>
                        changeFiltersSelected({
                          selectedValue: e.target.value,
                          parent: 'infinite',
                          isSelected: false,
                          toReplace: true,
                          returnTop: false,
                        })
                      }
                    >
                      <option value="0" aria-labelledby="pagination_selector">
                        {t('block:displayProducts.perPage')}
                      </option>
                      <option value="1" aria-labelledby="pagination_selector">
                        {t('block:displayProducts.allProducts')}
                      </option>
                    </select>
                  </div>
                )}
              </div>
            ) : (
              <Trans
                i18nKey="product:sameCategoryProduct.productsSeen"
                components={[
                  <p className={s.loadmore} />,
                  <mark aria-live="polite">{gridData?.length}</mark>,
                  <mark>{productsTotal}</mark>,
                ]}
              />
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default ProductsGrid
