import React, { FC, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'
import { MODALS, TOOLTIPS } from '@constants'

export interface State {
  displayModal: boolean
  displayNavigation: boolean
  displaySearchbar: boolean
  modalView: MODALS
  modalViewProps: any
  displayTooltip: { [val: string]: boolean }
  isEALoaded: boolean
  isSiteReverted: boolean
}

export interface UIType extends State {
  enableSiteReverted?: (locale: string | boolean) => void
  openModal?: () => void
  closeModal?: () => void
  setModalView?: (view: string, viewProps?: any) => void
  openNavigation?: () => void
  closeNavigation?: () => void
  openSearchbar?: () => void
  closeSearchbar?: () => void
  openTooltip?: (view: TOOLTIPS, viewProps?: any) => void
  closeTooltip?: (view: TOOLTIPS) => void
  setisEALoaded?: () => void
  tooltipProps?: Record<any, any>
}

const reversedLocales = ['ar']

const initialState: State = {
  displayModal: false,
  displayNavigation: false,
  displaySearchbar: false,
  modalView: MODALS.LOGIN_VIEW,
  modalViewProps: {},
  displayTooltip: (() => {
    const initialTooltip: { [val: string]: boolean } = {}
    Object.values(TOOLTIPS).forEach((value) => {
      initialTooltip[value] = false
    })
    return initialTooltip
  })(),
  isEALoaded: false,
  isSiteReverted: false,
}

type Action =
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODALS
      viewProps: any
    }
  | {
      type: 'OPEN_NAVIGATION'
    }
  | {
      type: 'CLOSE_NAVIGATION'
    }
  | {
      type: 'OPEN_SEARCHBAR'
    }
  | {
      type: 'CLOSE_SEARCHBAR'
    }
  | {
      type: 'OPEN_TOOLTIP'
      view: TOOLTIPS
      viewProps: any
    }
  | {
      type: 'CLOSE_TOOLTIP'
      view: TOOLTIPS
    }
  | {
      type: 'SET_EA_IS_LOADED'
    }
  | {
      type: 'SET_SITE_IS_REVERSED'
    }

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
        modalViewProps: action.viewProps,
      }
    }
    case 'OPEN_NAVIGATION': {
      return {
        ...state,
        displayNavigation: true,
        displaySearchbar: false,
      }
    }
    case 'CLOSE_NAVIGATION': {
      return {
        ...state,
        displayNavigation: false,
      }
    }
    case 'OPEN_SEARCHBAR': {
      return {
        ...state,
        displayNavigation: false,
        displaySearchbar: true,
      }
    }
    case 'CLOSE_SEARCHBAR': {
      return {
        ...state,
        displaySearchbar: false,
      }
    }
    case 'OPEN_TOOLTIP': {
      return {
        ...state,
        displayNavigation: false,
        displayTooltip: { ...state.displayTooltip, [action.view]: true },
        tooltipProps: action.viewProps || {},
      }
    }
    case 'CLOSE_TOOLTIP': {
      return {
        ...state,
        displayTooltip: { ...state.displayTooltip, [action.view]: false },
        tooltipProps: {},
      }
    }
    case 'SET_EA_IS_LOADED': {
      return {
        ...state,
        isEALoaded: true,
      }
    }
    case 'SET_SITE_IS_REVERSED': {
      return {
        ...state,
        isSiteReverted: true,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openModal = () => dispatch({ type: 'OPEN_MODAL' })
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' })
  const setModalView = (view: MODALS, viewProps?: any) => {
    dispatch({ type: 'SET_MODAL_VIEW', view, viewProps })
  }

  const openNavigation = () => dispatch({ type: 'OPEN_NAVIGATION' })
  const closeNavigation = () => dispatch({ type: 'CLOSE_NAVIGATION' })

  const openSearchbar = () => dispatch({ type: 'OPEN_SEARCHBAR' })
  const closeSearchbar = () => dispatch({ type: 'CLOSE_SEARCHBAR' })

  const openTooltip = (view: TOOLTIPS, viewProps?: any) => {
    dispatch({ type: 'OPEN_TOOLTIP', view, viewProps })
  }
  const closeTooltip = (view: TOOLTIPS) => {
    dispatch({ type: 'CLOSE_TOOLTIP', view })
  }

  const setisEALoaded = () => dispatch({ type: 'SET_EA_IS_LOADED' })

  const enableSiteReverted = (locale: string | boolean) => {
    if (
      !state.isSiteReverted &&
      (typeof locale === 'boolean' || // forced reversed
        reversedLocales.includes(locale))
    ) {
      dispatch({ type: 'SET_SITE_IS_REVERSED' })
    }
  }

  const value = useMemo(
    () => ({
      ...state,
      openModal,
      closeModal,
      setModalView,
      openNavigation,
      closeNavigation,
      openSearchbar,
      closeSearchbar,
      openTooltip,
      closeTooltip,
      setisEALoaded,
      enableSiteReverted,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = (): UIType => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
