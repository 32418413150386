import { FC } from 'react'

import LocaleSelector from '@components/common/Header/UserSettings/LocaleSelector'
import CountrySelector from '@components/common/Header/UserSettings/CountrySelector'
import CurrencySelector from '@components/common/Header/UserSettings/CurrencySelector'

import s from './UserSettings.module.css'

interface UserSettingsProps {
  locale: string
  alternates?: Array<Alternate>
  countries?: Array<Country>
  currencies: Array<Currency>
}

const UserSettings: FC<UserSettingsProps> = ({
  locale,
  alternates,
  countries,
  currencies,
}) => (
  <div className={s.userSettings}>
    <LocaleSelector alternates={alternates} />
    <CurrencySelector currencies={currencies} />
    <CountrySelector countries={countries} />
  </div>
)

export default UserSettings
