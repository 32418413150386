import { FC, useState, FormEvent, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Button, DynamicInput } from '@components/ui'
import { Status } from '@components/common/Alert/Alert'

import { EMAIL_REGEXP } from '@constants'
import { fetchResetPassword } from '@mw/login'
import { tagEventCS } from '@components/common/ContentSquare'

import cn from 'classnames'
import s from './FormResetPwd.module.css'

enum Error {
  InvalidEmail = 'invalid email',
  EmptyField = 'empty field',
  DefaultError = 'default error',
}

export interface ResetPwdProps {
  handleResetSuccess: (param: boolean) => void
}

const FormResetPwd: FC<ResetPwdProps> = ({ handleResetSuccess }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [mailInput, setMailInput] = useState<string>('')
  const [state, setState] = useState<{
    status: Status
    message: string
    type: Error | null
  } | null>(null)

  const handleSubmit = async (e: FormEvent, mail: string) => {
    e.preventDefault()
    let isValid = true

    if (mail && !mail.trim().match(EMAIL_REGEXP)) {
      e.preventDefault()
      setState({
        status: Status.FormError,
        message: t('layout:header.modalLogin.message.error_invalidEmail'),
        type: Error.InvalidEmail,
      })
      tagEventCS({ eventName: 'reset_invalidMail' })
      isValid = false
    }

    if (mail?.length === 0) {
      e.preventDefault()
      setState({
        status: Status.FormError,
        message: t('layout:header.modalLogin.message.error_emptyField'),
        type: Error.EmptyField,
      })
      tagEventCS({ eventName: 'reset_emptyMail' })
      isValid = false
    }

    if (isValid) {
      setIsLoading(true)
      e.preventDefault()
      try {
        const repReset = await fetchResetPassword({
          email: mail?.trim().toLocaleLowerCase(),
        })
        if (repReset?.status === 500) {
          setState({
            status: Status.FormError,
            message: t('layout:header.modalLogin.message.error_defaultMessage'),
            type: Error.DefaultError,
          })
        } else {
          tagEventCS({ eventName: 'reset_requestSend' })
          handleResetSuccess(true)
        }
      } catch (err) {
        console.error(err)
      }
      setIsLoading(false)
    }
  }

  const handleOnBlur = (e: any) => {
    const email: string | undefined = mailInput.trim()
    if (
      e.target.id === 'passwd-reset-username' &&
      email &&
      !email.match(EMAIL_REGEXP)
    ) {
      setState({
        status: Status.FormError,
        message: t('layout:header.modalLogin.message.error_invalidEmail'),
        type: Error.InvalidEmail,
      })
    }
  }

  useEffect(() => {
    setState({ ...state, message: '', type: null })
  }, [mailInput])

  return (
    <div className={cn(s.formContainer, { [s.fadeIn]: isLoading })}>
      <h1 className={s.title}>{t('layout:header.modalLogin.reset.title')}</h1>
      <div className={s.formContent}>
        {state?.status !== Status.Success ? (
          <>
            <form onSubmit={(e) => handleSubmit(e, mailInput)}>
              <DynamicInput
                id="passwd-reset-username"
                type="text"
                inputName="_username"
                label={t('layout:header.modalLogin.input.mail')}
                onChange={(e) => setMailInput(e.target.value)}
                ariaLabel={t('layout:header.modalLogin.input.mail')}
                className={cn(s.formInput, {
                  [s.errorInput]: state?.type === Error.InvalidEmail,
                })}
                onBlur={(e) => handleOnBlur(e)}
                errorState={state}
              />

              <div className={s.formAction}>
                <Button>{t('layout:header.modalLogin.reset.cta')}</Button>
              </div>
            </form>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default FormResetPwd
