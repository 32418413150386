import { FC, useEffect, useState } from 'react'
import Trans from 'next-translate/Trans'

import ProductCardAlgolia from '../ProductCardAlgolia'
import PaginationAlgolia from './PaginationAlgolia'

import cn from 'classnames'
import s from './ProductsGridAlgolia.module.css'

const ProductsGridAlgolia: FC<ProductsGridAlgoliaProps> = ({
  currentPage,
  nbPages,
  products = [],
  productsTitle,
  productsTotal,
  productsPerLine,
  layout,
}) => {
  const [gridData, setGridData] = useState(products)

  const seenProducts =
    nbPages === currentPage ? productsTotal : gridData?.length * currentPage

  useEffect(() => {
    setGridData(products)
  }, [products])

  return (
    <>
      {gridData?.length ? (
        <section
          className={cn(
            s.container,
            { [s.noPadding]: layout === 'listing' },
            { [s.titleBorder]: productsTitle }
          )}
        >
          {productsTitle && <h2 className={s.title}>{productsTitle}</h2>}
          <div className={s.row}>
            {gridData?.map(
              (hit, index) =>
                hit &&
                index < gridData.length && (
                  <div
                    key={`ProductsGrid-${hit?.id}-${index}`}
                    className={cn(s.col, { [s.half]: productsPerLine === 2 })}
                  >
                    <ProductCardAlgolia
                      hit={hit}
                      productsPerLine={productsPerLine}
                      layout={layout}
                      priority={index === 0 && layout === 'listing'}
                    />
                  </div>
                )
            )}
          </div>

          {productsTotal && (
            <>
              <div
                className={cn(s.navigationContainer, {
                  ['hidden']: gridData?.length >= productsTotal,
                })}
              >
                <PaginationAlgolia />
              </div>
              <div>
                <Trans
                  i18nKey="product:sameCategoryProduct.productsSeen"
                  components={[
                    <p className={s.loadmore} />,
                    <mark aria-live="polite">{seenProducts}</mark>,
                    <mark>{productsTotal}</mark>,
                  ]}
                />
              </div>
            </>
          )}
        </section>
      ) : null}
    </>
  )
}

export default ProductsGridAlgolia
