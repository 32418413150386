const DisplaySolo = ({ ...props }) => (
  <svg {...props} viewBox="0 0 22 22" fill="none">
    <path
      d="M18.7778 1H11H3.22222C2.63285 1 2.06762 1.23413 1.65087 1.65087C1.23413 2.06762 1 2.63285 1 3.22222V18.7778C1 19.3671 1.23413 19.9324 1.65087 20.3491C2.06762 20.7659 2.63285 21 3.22222 21H11H18.7778C19.3671 21 19.9324 20.7659 20.3491 20.3491C20.7659 19.9324 21 19.3671 21 18.7778V3.22222C21 2.63285 20.7659 2.06762 20.3491 1.65087C19.9324 1.23413 19.3671 1 18.7778 1Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DisplaySolo
