import React, {
  FC,
  useState,
  useEffect,
  useRef,
  KeyboardEventHandler,
  KeyboardEvent,
} from 'react'
import useTranslation from 'next-translate/useTranslation'
import useScreen from '@lib/hooks/useScreen'
import useIsomorphicEffect from '@lib/hooks/useIsomorphicEffect'

import BrandsLetterBlock from '@components/blocks-cms/BrandsListing/BrandsLetterBlock'
import BrandsLetterBlockMedia from '@components/blocks-cms/BrandsListing/BrandsLetterBlockMedia'

import { scrollToTop } from '@utils/scrollTo'

import cn from 'classnames'
import s from './BrandsListMedia.module.css'

export interface BrandsListMediaProps {
  searchResults: FormatedBrands
  searchEnable: boolean
  listingMedia: boolean
}

const BrandsListMedia: FC<BrandsListMediaProps> = ({
  searchResults,
  searchEnable,
  listingMedia,
}) => {
  const { t } = useTranslation()
  const screen = useScreen()
  const isomorphicEffect = useIsomorphicEffect()

  const [indexIsOpen, setIndexIsOpen] = useState<boolean>(false)
  const [letterFilter, setLetterFilter] = useState<string>(null)
  const brandsContainersEls = useRef<Array<HTMLOListElement>>(new Array())
  const indexLetterEls = useRef<Array<HTMLElement>>(new Array())
  const navIndexRef = useRef<HTMLDivElement>()

  const resultsToDisplay = letterFilter?.length
    ? Object.keys(searchResults).filter((letter) => letter === letterFilter)
    : Object.keys(searchResults)

  const findNextLetterIndex = (actualIndex: number): number => {
    let i = actualIndex
    const len = resultsToDisplay.length
    while (
      i <= len &&
      indexLetterEls?.current[i]?.classList?.contains(s.indexLetterGray)
    ) {
      i += 1
    }
    return i
  }

  const findPrevLetterIndex = (actualIndex: number): number => {
    let i = actualIndex
    while (
      i >= 0 &&
      indexLetterEls?.current[i]?.classList?.contains(s.indexLetterGray)
    ) {
      i -= 1
    }
    return i
  }

  const onKeyDownIndex: KeyboardEventHandler<HTMLElement> = (e) => {
    if (e.key === 'Enter') {
      setIndexIsOpen(true)
      indexLetterEls?.current[findNextLetterIndex(0)]?.focus()
    } else {
      setIndexIsOpen(false)
    }
  }

  const onKeyDownLetter = (
    e: KeyboardEvent<HTMLAnchorElement>,
    letterIndex: number
  ) => {
    e.stopPropagation()
    switch (e.code) {
      case 'Enter':
        const el = brandsContainersEls?.current[letterIndex]?.children[0]
          ?.children[0] as HTMLElement
        el?.focus()
        break
      case 'ArrowLeft':
        indexLetterEls?.current[findPrevLetterIndex(letterIndex - 1)]?.focus()
        break
      case 'ArrowRight':
        indexLetterEls?.current[findNextLetterIndex(letterIndex + 1)]?.focus()
        break
      default:
        return
    }
  }

  isomorphicEffect(() => {
    const offset =
      screen === 'sm' || screen === 'xs' ? -48 : screen === 'md' ? -65 : 0
    if (letterFilter !== null) scrollToTop(navIndexRef.current, offset, true)
  }, [letterFilter])

  useEffect(() => {
    const hash = window.location.hash
    const currentLetter = hash.substring(1).toUpperCase()
    if (currentLetter?.length) {
      let letter
      if (currentLetter === 'ALL') letter = ''
      else if (currentLetter === 'NUM') letter = '...'
      else letter = currentLetter

      setLetterFilter(letter)
    }
  }, [])

  return (
    <div ref={navIndexRef}>
      <nav
        className={s.brandsIndex}
        tabIndex={0}
        aria-label={t('block:brands.ariaNavigateToIndex')}
        onKeyDown={(e) => onKeyDownIndex(e)}
      >
        <ol>
          <li>
            <a
              ref={(element) => {
                element ? indexLetterEls.current.push(element) : null
              }}
              href="#all"
              className={cn(s.indexLetter, {
                [s.indexSelected]: '' === letterFilter,
              })}
              onClick={() => setLetterFilter('')}
              onKeyDown={(e) => onKeyDownLetter(e, 0)}
              tabIndex={indexIsOpen ? 0 : -1}
              aria-label={t('block:brands.scrollToLetter', { letter: 'all' })}
            >
              All
            </a>
          </li>
          {Object.keys(searchResults).map((letter, index) => (
            <li key={`index-letter-${index}`}>
              <a
                ref={(element) => {
                  element ? indexLetterEls.current.push(element) : null
                }}
                href={letter === '...' ? '#num' : `#${letter.toLowerCase()}`}
                className={cn(
                  s.indexLetter,
                  { [s.indexLetterGray]: !searchResults[letter].length },
                  { [s.indexSelected]: letter === letterFilter }
                )}
                onClick={() => setLetterFilter(letter)}
                onKeyDown={(e) => onKeyDownLetter(e, index + 1)}
                tabIndex={indexIsOpen && searchResults[letter].length ? 0 : -1}
                aria-label={
                  letter === '...'
                    ? t('block:brands.scrollToNumber')
                    : t('block:brands.scrollToLetter', { letter: letter })
                }
              >
                {letter}
              </a>
            </li>
          ))}
        </ol>
      </nav>
      <div>
        {listingMedia
          ? resultsToDisplay?.map((letter, index) => (
              <BrandsLetterBlockMedia
                key={`letterBlock-${index}`}
                letter={letter}
                brands={searchResults[letter]}
                brandsContainerRef={(element) => {
                  element ? brandsContainersEls?.current?.push(element) : null
                }}
                searchEnable={searchEnable}
              />
            ))
          : resultsToDisplay?.map((letter, index) => (
              <BrandsLetterBlock
                key={`letterBlock-${index}`}
                letter={letter}
                brands={searchResults[letter]}
                brandsContainerRef={(element) => {
                  element ? brandsContainersEls?.current?.push(element) : null
                }}
              />
            ))}
      </div>
    </div>
  )
}

export default BrandsListMedia
