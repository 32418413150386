import React, { FC } from 'react'

import cn from 'classnames'
import s from './SideMedia.module.css'

interface SideMediaProps {
  className?: string
  url: string
}

const SideMedia: FC<SideMediaProps> = ({ className, url }) => (
  <div
    className={cn(s.container, className)}
    style={{ backgroundImage: `url(${url || ''})` }}
  />
)

export default SideMedia
