import { FC } from 'react'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'
import s from './NavigationButton.module.css'

export interface NavigationButtonProps {
  className?: string
}

const NavigationButton: FC<NavigationButtonProps> = ({ className }) => {
  const { displayNavigation, closeNavigation, openNavigation } = useUI()
  const { t } = useTranslation()

  return (
    <button
      className={cn(s.button, className, { [s.opened]: displayNavigation })}
      aria-label={t(
        displayNavigation
          ? 'layout:navigation.ariaCloseMenu'
          : 'layout:navigation.ariaOpenMenu'
      )}
      onClick={() => (displayNavigation ? closeNavigation() : openNavigation())}
    >
      <span className={s.bar} aria-hidden />
    </button>
  )
}

export default NavigationButton
