import React, { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { useUserSettings } from '@context/session'
import { useRouter } from 'next/router'

import FormLogIn from '@components/common/AccountForm/FormLogin'
import FormResetPwd from '@components/common/AccountForm/FormResetPwd'
import WithFacebook from '@components/common/WithFacebook'
import { Button, Link } from '@components/ui'
import { changeLocaleIfTranslatable } from '@utils/locale'

import cn from 'classnames'
import s from './SigninModul.module.css'

export interface SigninModulProps {
  className?: string
  isModal?: boolean
}

const SigninModul: FC<SigninModulProps> = ({ className, isModal = false }) => {
  const { t } = useTranslation()
  const { refreshSession } = useUserSettings()
  const { locale } = useRouter()

  const [resetWanted, setResetWanted] = useState<boolean>(false)
  const [resetSuccessful, setResetSuccessFul] = useState<boolean>(false)
  const [header, setHeader] = useState<{ title; text?; span }>({
    title: t('layout:header.modalLogin.login.header.title'),
    text: t('layout:header.modalLogin.login.header.text'),
    span: t('layout:header.modalLogin.login.header.createAccount'),
  })

  const handleBackToConnection = () => {
    setResetSuccessFul(false)
    setResetWanted(false)
  }

  useEffect(() => {
    if (!resetSuccessful && !resetWanted) {
      setHeader({
        title: t('layout:header.modalLogin.login.header.title'),
        text: t('layout:header.modalLogin.login.header.text'),
        span: t('layout:header.modalLogin.login.header.createAccount'),
      })
    }
    if (resetWanted) {
      setHeader({
        title: t('layout:header.modalLogin.reset.header.title'),
        text: t('layout:header.modalLogin.reset.text'),
        span: t('layout:header.modalLogin.reset.text2'),
      })
    }
    if (resetSuccessful) {
      setHeader({
        title: t('layout:header.modalLogin.reset.done'),
        text: t('layout:header.modalLogin.reset.text'),
        span: t('layout:header.modalLogin.reset.text2'),
      })
    }
  }, [resetWanted, resetSuccessful])

  useEffect(() => {
    isModal && refreshSession()
  }, [])

  return (
    <>
      <div className={cn(s.container, className)}>
        <div className={s.header}>
          <h1 className={s.title}>{header.title}</h1>
          <div className={s.text}>
            {!resetWanted && !resetSuccessful ? (
              <>
                <p>{header?.text}</p>
                <span className={s.link}>
                  <Link
                    href={`/${changeLocaleIfTranslatable(locale)}/register`}
                    passHref
                  >
                    {header.span}
                  </Link>
                </span>{' '}
                <span>.</span>
              </>
            ) : (
              <>
                <p>{header?.text}</p>
                <span className={s.link} onClick={handleBackToConnection}>
                  {header?.span}
                </span>
                <span>.</span>
              </>
            )}
          </div>
        </div>
        <div className={s.modalContent}>
          {!resetSuccessful ? (
            !resetWanted ? (
              <>
                <div className={s.formContent}>
                  <div className={s.login}>
                    <FormLogIn handleReset={setResetWanted} isModal={isModal} />
                  </div>
                </div>

                <div className={s.modalRow}>
                  <WithFacebook isModal />
                </div>
              </>
            ) : (
              <FormResetPwd handleResetSuccess={setResetSuccessFul} />
            )
          ) : (
            <div className={s.resetSuccesPage}>
              <p className={s.resetSuccessInfo}>
                {t('layout:header.modalLogin.reset.confirm')}
              </p>
              <Button
                className={s.ctaBackConnection}
                onClick={handleBackToConnection}
              >
                {t('layout:header.modalLogin.reset.switcher')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SigninModul
