import { FC } from 'react'

interface Props {
  stroke?: string
  size?: string
}

const ProfilNotLogged: FC<Props> = ({
  stroke = 'black',
  size = 24,
  ...props
}) => (
  <svg viewBox="0 0 24 24" fill="none" width={size} height={size} {...props}>
    <path
      d="M12 2.5C15.0376 2.5 17.5 4.96243 17.5 8C17.5 11.0376 15.0376 13.5 12 13.5C8.96243 13.5 6.5 11.0376 6.5 8C6.5 4.96243 8.96243 2.5 12 2.5Z"
      stroke="#1F1F1F"
      strokeLinecap="round"
    />
    <path
      d="M12 15.5C14.5523 15.5 16.8123 15.8455 18.4821 16.7148C20.1199 17.5675 21.2055 18.9288 21.4484 21.0581C21.4727 21.271 21.2945 21.5 21 21.5H3C2.7055 21.5 2.52726 21.271 2.55156 21.0581C2.79449 18.9288 3.8801 17.5675 5.51789 16.7148C7.18767 15.8455 9.44771 15.5 12 15.5Z"
      stroke="#1F1F1F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ProfilNotLogged
