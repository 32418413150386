import NextHead from 'next/head'

declare global {
  interface Window {
    Trustpilot: any
  }
}

const Truspilot = () => (
  <NextHead>
    <script
      type="text/javascript"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      async
    />
  </NextHead>
)

export default Truspilot
