import { FC } from 'react'

import { Container } from '@components/ui'
import { Newsletter, Links, Copyright } from '@components/common/Footer'
import RichText from '@components/blocks-cms/RichText'
import MediaQueries from '@components/common/MediaQueries'
import { NewsletterProvider } from '@context/newsletter'
import { ENABLE_TRUSTPILOT } from '@constants'

import cn from 'classnames'
import s from './Footer.module.css'

import footerLinks from './footerLinks.json'
import { FooterLink } from './Links/BlockLinks'

export interface FooterProps {
  content?: string
  className?: string
  children?: any
  pages?: Page[]
}

const Footer: FC<FooterProps> = ({ content, className }) => {
  const links = ENABLE_TRUSTPILOT
    ? footerLinks
    : (footerLinks as FooterLink[]).map((columnLinks) => ({
        ...columnLinks,
        links: columnLinks.links?.filter(
          (link) => !('id' in link) || link.id !== 'trustpilot'
        ),
      }))

  return (
    <footer className={cn(s.footer, className)} data-testid="Footer_footer">
      <Container>
        <div className={s.line}>
          <NewsletterProvider>
            <Newsletter className={s.newsletter} />
          </NewsletterProvider>
        </div>
        {content?.length && content !== '<p>...</p>' ? (
          <MediaQueries hidden={['xs', 'sm']}>
            <div className={cn('footer-rich-text', s.line)}>
              <RichText content={content} />
            </div>
          </MediaQueries>
        ) : null}
        <div className={s.line}>
          <Links blocks={links} />
        </div>
        <div className={s.line}>
          <Copyright />
        </div>
      </Container>
    </footer>
  )
}

export default Footer
