const Twitter = ({ ...props }) => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" {...props}>
    <path
      d="M23.4583 2.44375C23.0789 2.61408 22.6918 2.75343 22.297 2.86956C22.7693 2.34311 23.1254 1.71601 23.3422 1.03472C23.3886 0.879881 23.3422 0.7173 23.2183 0.616654C23.0944 0.516009 22.9164 0.508267 22.777 0.585687C21.9331 1.08117 21.0273 1.44504 20.0751 1.65408C19.1151 0.7173 17.8144 0.183105 16.4673 0.183105C13.626 0.183105 11.319 2.49795 11.319 5.3315C11.319 5.55602 11.3344 5.78053 11.3577 5.99731C7.83509 5.68763 4.55251 3.95343 2.30736 1.1973C2.22994 1.09665 2.10607 1.04246 1.97445 1.05795C1.85058 1.06569 1.73445 1.13536 1.67252 1.25149C1.21575 2.03343 0.975746 2.9315 0.975746 3.84505C0.975746 5.08376 1.41704 6.26053 2.19897 7.18182C1.95897 7.09666 1.72671 6.99602 1.50994 6.87215C1.39381 6.80247 1.24671 6.81021 1.13058 6.87215C1.01446 6.94182 0.937037 7.0657 0.937037 7.19731C0.937037 7.22053 0.937037 7.24376 0.937037 7.26699C0.937037 9.11731 1.93574 10.7896 3.4609 11.6954C3.32929 11.6799 3.19768 11.6644 3.06606 11.6412C2.93445 11.618 2.7951 11.6644 2.70219 11.7651C2.60929 11.8657 2.57832 12.0128 2.62478 12.1367C3.18994 13.9018 4.64542 15.1947 6.39509 15.5896C4.93961 16.5031 3.2751 16.9831 1.52542 16.9831C1.16155 16.9831 0.78994 16.9599 0.433812 16.9212C0.255747 16.898 0.0854251 17.0064 0.0234897 17.1767C-0.0384457 17.347 0.0234897 17.5328 0.178328 17.6335C2.42349 19.0735 5.02477 19.8322 7.68799 19.8322C12.937 19.8322 16.2118 17.3547 18.0467 15.2799C20.3306 12.6941 21.6389 9.26441 21.6389 5.88118C21.6389 5.74182 21.6389 5.59473 21.6312 5.45537C22.5293 4.77408 23.3034 3.95343 23.9383 3.01666C24.0312 2.8773 24.0234 2.68375 23.9151 2.55214C23.7989 2.41279 23.6131 2.37408 23.4583 2.44375Z"
      fill="white"
    />
  </svg>
)

export default Twitter
