const Padlock = ({ ...props }) => (
  <svg x="0px" y="0px" viewBox="0 0 72 90" {...props}>
    <path
      fill="#000000"
      d="M55,27.7V20C55,9.7,47,0.9,36.7,0.5C25.9,0.1,17,8.8,17,19.5v8.2c0,1.1-0.9,1.9-1.9,1.9h-2.7  c-1.1,0-1.9,0.9-1.9,1.9v38c0,1.1,0.9,1.9,1.9,1.9h47.4c1.1,0,1.9-0.9,1.9-1.9v-38c0-1.1-0.9-1.9-1.9-1.9H57  C55.9,29.6,55,28.8,55,27.7z M20.9,19.5c0-8.6,7.2-15.5,15.9-15.1c8.1,0.4,14.4,7.4,14.4,15.6v7.7c0,1.1-0.9,1.9-1.9,1.9H22.8  c-1.1,0-1.9-0.9-1.9-1.9V19.5z"
    />
  </svg>
)

export default Padlock
