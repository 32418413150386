import React, { FC, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import BreadCrumb from '@components/product/BreadCrumb'
import RichText from '@components/blocks-cms/RichText'
import { AnimateMotion } from '@components/ui'

import cn from 'classnames'
import s from './DescriptionListingHeader.module.css'

export interface DescriptionListingHeaderProps {
  title?: string
  description?: string
  showMore?: string
  breadcrumb: Array<{
    href?: string
    title: string
  }>
}

const DescriptionListingHeader: FC<DescriptionListingHeaderProps> = ({
  title,
  breadcrumb,
  description,
  showMore,
}) => {
  const { t } = useTranslation()

  const [isShowMoreOpen, setIsShowMoreOpen] = useState(false)

  if (!title?.length) return null

  return (
    <div className={cn('description_listing_header', s.container)}>
      <div className={s.content}>
        {breadcrumb?.length && <BreadCrumb paths={breadcrumb} />}
        {!!title?.length && <h2 className={s.title}>{title}</h2>}
        {!!description?.length && (
          <div className={s.description}>
            {!!description?.length && (
              <RichText content={description} className={s.text} />
            )}
            {!!showMore?.length && (
              <div className="w-full">
                <AnimateMotion initial={isShowMoreOpen}>
                  <RichText
                    content={showMore}
                    className={cn(s.text, s.additionalText)}
                  />
                </AnimateMotion>
              </div>
            )}
            {!isShowMoreOpen && !!showMore?.length && (
              <button className={s.cta} onClick={() => setIsShowMoreOpen(true)}>
                {t('block:footerDescription.showMore')}
              </button>
            )}
            {isShowMoreOpen && !!showMore?.length && (
              <button
                className={s.cta}
                onClick={() => setIsShowMoreOpen(false)}
              >
                {t('block:footerDescription.close')}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default DescriptionListingHeader
