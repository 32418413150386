import { FC, useState, useEffect, FormEvent } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'

import { Alert } from '@components/common'
import { ProductModalBlock } from '@components/product'
import { tagEventList } from '@components/common/Eulerian/EulerianEvents'
import { Notification, Status } from '@components/common/Alert/Alert'
import { Button, Modal } from '@components/ui'
import { ChevronUp } from '@components/icons'

import s from './GiftListModal.module.css'
import cn from 'classnames'

export interface GiftListModalProps {
  isOpen: boolean
  onClose: () => void
  product: Product | ProductListItem
  selectedSize?: string
  eventName?: string
}

interface GiftListModalContentProps {
  product: Product | ProductListItem
  selectedSize?: string
  eventName?: string
}

const isInList = (list: CustomerProductList, sku: string | undefined) =>
  !!sku && (list?.products || []).includes(sku)

const GiftListModalContent: FC<GiftListModalContentProps> = ({
  product,
  selectedSize: initialSelectedSize,
  eventName = '/giftlist/add/page_product',
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const sessionData = useUserSettings()
  const { customerProductLists, addToCustomerProductList } = sessionData

  const [selectedSize, setSelectedSize] = useState<string>(
    initialSelectedSize || ''
  )
  const [selectedList, setSelectedList] = useState<number>(0)
  const [notification, setNotification] = useState<Notification>(null)
  const [isAddingToCustomerProductList, setIsAddingToCustomerProductList] =
    useState<boolean>(false)

  const handleAddToList = async (e: FormEvent) => {
    e.preventDefault()
    if (!selectedSize?.length && !selectedList) {
      setNotification({
        status: Status.Error,
        message: t(`product:addToList.error_chooseSizeAndList`),
      })
      return false
    }
    if (!selectedSize?.length) {
      setNotification({
        status: Status.Error,
        message: t(`product:addToList.error_chooseSize`),
      })
      return false
    }
    if (!selectedList) {
      setNotification({
        status: Status.Error,
        message: t(`product:addToList.error_chooseList`),
      })
      return false
    }
    setNotification(null)
    setIsAddingToCustomerProductList(true)
    try {
      await addToCustomerProductList(selectedList, selectedSize, locale)
      const list = (customerProductLists || []).find(
        ({ id }: CustomerProductList) => id === selectedList
      )
      setSelectedList(undefined)
      setNotification({
        status: Status.Success,
        message: t(`product:addToList.success_addToGiftlist`, {
          list: list?.title || '',
        }),
      })
      tagEventList({
        urlp: eventName,
        locale,
        sessionData,
        productData: product,
        selectedSize,
        type: 'type: addgiftlist - page_product',
      })
    } catch (err) {
      console.error(err)
      setNotification({
        status: Status.Error,
        message: t('product:error.defaultMessage'),
      })
    }
    setIsAddingToCustomerProductList(false)
  }

  const onListSelected = (selectedListId: number) => {
    const list = (customerProductLists || []).find(
      ({ id }: CustomerProductList) => id === selectedListId
    )
    if (!list) {
      setNotification({
        status: Status.Error,
        message: t('product:error.defaultMessage'),
      })
      return false
    }
    if (isInList(list, selectedSize)) {
      setSelectedList(undefined)
      setNotification({
        status: Status.Error,
        message: t(`product:addToList.error_productAlreadyInList`, {
          list: list?.title || '',
        }),
      })
      return false
    }
    setNotification(null)
    setSelectedList(selectedListId)
  }

  const onSizeSelected = (size: string) => {
    setNotification(null)
    setSelectedSize(size)
  }

  useEffect(() => {
    setSelectedSize(initialSelectedSize)
  }, [initialSelectedSize])

  return (
    <div className={s.modalContent}>
      <h2>{t(`product:addToList.addToAList`)}</h2>
      {customerProductLists && customerProductLists?.length > 0 ? (
        <>
          <div className={s.messageContainer}>
            {notification && notification.status && notification.message ? (
              <Alert
                status={notification.status}
                message={notification.message}
              />
            ) : (
              <p className={s.messageInfo}>
                {t(`product:addToList.chooseSizeAndList`)}
              </p>
            )}
          </div>
          <ProductModalBlock
            product={product as Product}
            className={s.productModalBlock}
            selectedSize={selectedSize}
          />
          <form className={s.form} onSubmit={(e) => handleAddToList(e)}>
            <div>
              <div className={s.selectContainer}>
                <select
                  className={s.select}
                  onChange={(e) => onSizeSelected(e.target.value)}
                  value={selectedSize}
                  aria-label={t('product:sizeSelector.title')}
                >
                  <option value="" disabled>
                    {t('product:sizeSelector.title')}
                  </option>
                  {product?.declinations?.map((declination) => (
                    <option key={declination.sku} value={declination.sku}>
                      {declination.size}
                    </option>
                  ))}
                </select>
                <ChevronUp className={s.chevron} />
              </div>
              <div className={s.selectContainer}>
                <select
                  className={s.select}
                  onChange={(e) => onListSelected(Number(e.target.value))}
                  value={selectedList}
                  aria-label={t('product:addToList.chooseAList_2')}
                >
                  <option value={0} disabled>
                    {t('product:addToList.chooseAList_2')}
                  </option>
                  {customerProductLists?.map((list) => (
                    <option key={list.id} value={list.id}>
                      {list.title}
                    </option>
                  ))}
                </select>
                <ChevronUp className={s.chevron} />
              </div>
            </div>
            <div className={s.buttonsContainer}>
              <Button
                className={cn(s.button, {
                  [s.buttonDisabled]: !(selectedSize && selectedList),
                })}
                loading={isAddingToCustomerProductList}
              >
                {t(`product:addToList.addToMyList`)}
              </Button>

              <Button
                className={s.createList}
                href={`/${locale}/productlist/account/create`}
                next={false}
              >
                <span className={s.createListIcon}>+ </span>
                <span className={s.createListLabel}>
                  {t('product:addToList.createAList')}
                </span>
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className={s.messageContainer}>
            <p className={s.messageInfo}>
              {t(`product:addToList.createAListToAddProduct`)}
            </p>
          </div>
          <div className={cn(s.createListButtonContainer)}>
            <Button
              className={s.button}
              href={`/${locale}/productlist/account/create`}
              next={false}
            >
              {t('product:addToList.createAList')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

const GiftListModal: FC<GiftListModalProps> = ({
  isOpen,
  onClose,
  selectedSize,
  product,
  eventName,
}) => (
  <Modal open={isOpen} onClose={onClose} className={s.modal}>
    <GiftListModalContent
      selectedSize={selectedSize}
      product={product}
      eventName={eventName}
    />
  </Modal>
)

export default GiftListModal
