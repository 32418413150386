const Loupe = ({ stroke = '#121212', ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5746 2C5.83896 2 2 5.83896 2 10.5746C2 15.3102 5.83896 19.1491 10.5746 19.1491C12.7624 19.1491 14.7589 18.3297 16.2739 16.981L21.1454 21.8525C21.3406 22.0478 21.6572 22.0478 21.8525 21.8525C22.0477 21.6573 22.0477 21.3407 21.8525 21.1454L16.981 16.2739C18.3297 14.759 19.1491 12.7625 19.1491 10.5746C19.1491 5.83896 15.3102 2 10.5746 2ZM16.2475 15.5939C17.4308 14.2575 18.1491 12.4999 18.1491 10.5746C18.1491 6.39125 14.7579 3 10.5746 3C6.39125 3 3 6.39125 3 10.5746C3 14.7579 6.39125 18.1491 10.5746 18.1491C12.4999 18.1491 14.2575 17.4308 15.5939 16.2475L16.2475 15.5939Z"
      fill="#1F1F1F"
    />
  </svg>
)

export default Loupe
