import { FC } from 'react'
import cn from 'classnames'

import s from './Separator.module.css'

interface Props {
  className?: string
  type?: string
}

const Separator: FC<Props> = ({ className, type = '' }) => (
  <div
    className={cn(className, s.separator, { [s.separatorBig]: type === 'big' })}
  />
)

export default Separator
