import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { ProductStocks, getAvailabilityLabel } from '@utils/stocks'

import cn from 'classnames'
import s from './ProductAvailabilityLabel.module.css'

export interface ProductAvailabilityLabelProps {
  stocks: ProductStocks
  sku: string
  className?: string
}

const ProductAvailabilityLabel: FC<ProductAvailabilityLabelProps> = ({
  stocks,
  sku,
  className,
}) => {
  const { t } = useTranslation()

  const quantity = stocks?.declinations[sku]?.quantity
  const availabilityLabel = getAvailabilityLabel(stocks, sku)

  if (!availabilityLabel) return null

  return (
    <span className={cn(s.label, className)}>
      {quantity > 0 && quantity <= 3
        ? quantity === 1
          ? t('product:quickBuy.declination.lastChance')
          : t('product:quickBuy.declination.lastChances')
        : t(availabilityLabel.message, availabilityLabel.params)}
    </span>
  )
}

export default ProductAvailabilityLabel
