export const addItemToCustomerProductList = async ({
  id,
  sku,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale,
}: {
  id: number
  sku: string
  apiUrl?: string
  locale: string
}): Promise<CustomerProductList> => {
  const res = await fetch(`${apiUrl}/v1/customer-product-lists/${id}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, locale }),
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}
