import React, { useEffect, useState, FC } from 'react'
import { useRouter } from 'next/router'

import { Blocks } from '@components/blocks-cms'
import { fetchPageLegacy } from '@mw/page'

interface PropsMirror {
  endpoint: {
    fr: string
    en: string
    it: string
    es: string
    de: string
  }
  offset?: number
  limit?: number
}

export const MirrorPageContent: FC<PropsMirror> = ({
  endpoint,
  offset,
  limit,
}) => {
  const { locale } = useRouter()

  const [blockToDisplay, setBlockToDisplay] = useState<PageBlockTypes[]>([])

  if (!endpoint?.[locale]) return null

  useEffect(() => {
    ;(async () => {
      try {
        const blockToDisplayData = await fetchPageLegacy({
          locale,
          path: endpoint[locale],
        })
        if (blockToDisplayData?.blocks?.length) {
          let selectedBlock = null
          if (limit || offset)
            selectedBlock = blockToDisplayData.blocks.slice(
              offset || 0,
              limit || 6
            )
          setBlockToDisplay(selectedBlock || blockToDisplayData?.blocks)
        }
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  if (!blockToDisplay?.length) return null

  return <Blocks blocks={blockToDisplay} />
}

export default MirrorPageContent
