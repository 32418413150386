import { FC } from 'react'

export interface UnMuteProps {
  className?: string
  color?: string
}

const UnMute: FC<UnMuteProps> = ({
  className,
  color = 'rgb(3,4,4)',
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 50 50"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    {...props}
  >
    <g transform="matrix(1,0,0,1,-248.092,-1043.46)">
      <g transform="matrix(0.100774,0,0,0.100774,273.092,1068.46)">
        <g transform="matrix(1,0,0,1,-248.079,-248.079)">
          <clipPath id="_clip1">
            <rect x="0" y="0" width="496.159" height="496.159" />
          </clipPath>
          <g clipPath="url(#_clip1)">
            <path
              d="M496.159,248.086C496.159,111.064 385.089,0.004 248.083,0.004C111.071,0.004 0,111.063 0,248.086C0,385.087 111.07,496.156 248.083,496.156C385.089,496.155 496.159,385.086 496.159,248.086Z"
              fillRule="nonzero"
              fill={color}
            />
            <g transform="matrix(1.29937,0,0,1.29937,-3.38716,-77.6054)">
              <path
                d="M259.432,128.917C256.023,127.066 251.873,127.229 248.619,129.342L153.482,191.133L118.318,191.133C112.473,191.133 107.735,195.871 107.735,201.715L107.735,294.443C107.735,300.288 112.473,305.026 118.318,305.026L153.482,305.026L248.619,366.816C250.367,367.951 252.373,368.524 254.384,368.524C256.117,368.524 257.855,368.098 259.433,367.241C262.843,365.389 264.967,361.821 264.967,357.94L264.967,138.219C264.966,134.339 262.842,130.768 259.432,128.917Z"
                fillRule="nonzero"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default UnMute
