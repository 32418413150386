export const fetchProduct = async ({
  id,
  locale,
  query,
  apiUrl = process.env.SMALLABLE_PRODUCT_INTERNAL_URL ||
    process.env.NEXT_PUBLIC_SMALLABLE_API_URL ||
    '',
  headers,
}: {
  id: string | number | number[]
  locale: string
  query?: string
  apiUrl?: string
  headers?: Headers
}): Promise<Product> => {
  const idString = typeof id === 'object' ? id.toString() : id
  const res = await fetch(
    `${apiUrl}/v1/products/${idString}?locale=${locale}${
      query ? `&${query}` : ''
    }`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers,
    }
  )
  if (!res?.ok) throw { status: res.status }
  const { data } = await res.json()
  return data
}

export const fetchProductsList = async ({
  query,
  locale,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  headers,
}: {
  query: string
  locale: string
  apiUrl?: string
  headers?: Headers
}): Promise<ListingFilters> => {
  const res = await fetch(
    `${apiUrl}/v1/products?locale=${locale}${query ? `&${query}` : ''}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers,
    }
  )
  if (!res?.ok) throw { status: res.status }
  const { data } = await res.json()
  return data
}
