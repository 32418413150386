import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import {
  BrandsHeader,
  BrandsListMedia,
} from '@components/blocks-cms/BrandsListing'

import { fetchBrands } from '@mw/query/brand'
import { MODALS } from '@constants'
import {
  filterFormatedBrands,
  filterQueryParams,
  initFormatedBrands,
  objIsEqual,
} from '@utils/brands'
import qs from 'query-string'

import s from './BrandsListing.module.css'
import { useUserSettings } from '@context/session'

export interface BrandsListingProps {
  title: string
  subtitle: string
  brands: Array<Brand>
  filters: Array<BrandFilter>
}

const BrandsListing: FC<BrandsListingProps> = ({
  title,
  subtitle,
  brands,
  filters,
}) => {
  const { t } = useTranslation()
  const { locale, query } = useRouter()
  const { setModalView, openModal } = useUI()

  const [listingMedia, setListingMedia] = useState<boolean>(true)
  const [formatedBrands, setFormatedBrands] = useState<FormatedBrands>(
    initFormatedBrands(brands)
  )
  const { deliveryCountryCode: countryCode } = useUserSettings()
  const [search, setSearch] = useState<string>('')
  const [searchResults, setSearchResults] =
    useState<FormatedBrands>(formatedBrands)
  const [selectedFilter, setSelectedFilter] = useState<string>(
    filters?.filter((filter) => filter.selected)[0]?.title ||
      t('block:brands.allBrands')
  )

  const getFilterTitle = (queryParams: { [key: string]: string }): string => {
    const selectedFilter = filters.filter((v) =>
      objIsEqual(v.parameters, queryParams)
    )
    if (selectedFilter.length) return selectedFilter[0].title
    return t('block:brands.allBrands')
  }

  useEffect(() => {
    setSearchResults(
      search === ''
        ? formatedBrands
        : filterFormatedBrands(formatedBrands, search)
    )
  }, [search])

  useEffect(() => {
    if (search === '') setSearchResults(formatedBrands)
    else setSearch('')
  }, [formatedBrands])

  useEffect(() => {
    ;(async () => {
      const { pages, ...queryParams } = query
      const filteredQueryParams = filterQueryParams(
        queryParams as { [key: string]: string }
      )
      if (
        Object.keys(filteredQueryParams).length ||
        'type' in filteredQueryParams ||
        'section' in filteredQueryParams ||
        'person' in filteredQueryParams
      ) {
        try {
          const newBrands = await fetchBrands({
            queryParams: qs.stringify(filteredQueryParams, {
              arrayFormat: 'bracket',
            }),
            locale: locale,
            countryCode,
          })
          if (!newBrands.length) throw Error
          setFormatedBrands(initFormatedBrands(newBrands))
          setSelectedFilter(getFilterTitle(filteredQueryParams))
        } catch (err) {
          setModalView(MODALS.NOTIFICATION, {
            message: t('block:error.defaultMessage'),
          })
          openModal()
        }
      }
    })()
  }, [query, countryCode])

  return (
    <div className={s.container}>
      <section>
        <BrandsHeader
          setSearch={setSearch}
          search={search}
          filters={filters}
          title={title}
          subtitle={subtitle}
          selectedFilter={selectedFilter}
          listingMedia={listingMedia}
          setListingMedia={setListingMedia}
        />
      </section>
      <section className="notranslate">
        <BrandsListMedia
          searchResults={searchResults}
          searchEnable={
            !!search?.length || selectedFilter !== t('block:brands.allBrands')
          }
          listingMedia={listingMedia}
        />
      </section>
    </div>
  )
}

export default BrandsListing
