import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'

import { collector, clearWindowForPartners } from '@lib/eulerian'

interface TagProductProps {
  name?: string
  lowestPrice?: string
  hasStocks?: boolean
  productId?: number
  prices?: ApiPrices
  stocks?: Stocks
  brand?: string
  color?: string
  size?: string
  image?: string
  reference?: string
  genre?: string
  sections?: string
  sexs?: string
  typologies?: Array<string>
  breadcrumb?: Array<{
    title: string
    href: string
  }>
}

const TagProduct: FC<TagProductProps> = ({
  name,
  lowestPrice,
  hasStocks,
  productId,
  prices,
  stocks,
  brand,
  color,
  size,
  image,
  reference,
  genre,
  sections,
  sexs,
  typologies,
  breadcrumb,
}) => {
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
  const { asPath, locale } = useRouter() || { asPath: null, locale: null }
  const { isEALoaded } = useUI()
  const {
    isFetched,
    emailMd5,
    emailSha256,
    uid,
    clientType,
    currencyCode,
    logged,
    subscribedToLoyalty,
  } = useUserSettings()

  if (!asPath || !locale) return null

  const _urlp = breadcrumb
    ? locale +
      `/page produit${`/${breadcrumb
        .map((e) => e.title.replace(' / ', '-').replace('/ ', '/'))
        .join('')}`}`
    : null

  const productTags = [
    ...(_urlp ? ['urlp', _urlp] : []),
    ...(emailSha256 ? ['email', emailSha256] : []),
    ...(emailMd5 ? ['email_md5', emailMd5] : []),
    ...(locale ? ['lang', locale] : []),
    ...(uid ? ['uid', uid] : []),
    ...(currencyCode ? ['currency', currencyCode] : []),
    ...(currencyCode ? ['devise', currencyCode] : []),
    ...(clientType ? ['type_client', clientType] : []),
    ...['is_logged', `${logged ? 'true' : 'false'}`],
    ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
    ...(appEnv ? ['env', appEnv] : []),
    ...(appVersion ? ['version', appVersion] : []),
    ...(productId ? ['prdref', `${productId}`] : []),
    ...(lowestPrice && prices?.declinations[lowestPrice]?.priceTaxIncluded
      ? ['prdamount', `${prices.declinations[lowestPrice].priceTaxIncluded}`]
      : []),
    ...(lowestPrice &&
    prices?.declinations[lowestPrice]?.discountInfo?.priceTaxIncluded
      ? [
          'prdamount_ttc_avantreduc',
          `${prices.declinations[lowestPrice].discountInfo?.priceTaxIncluded}`,
        ]
      : []),
    ...(name ? ['prdname', name + ' ' + brand] : []),
    ...(stocks && lowestPrice
      ? ['prdquantity', `${stocks.declinations[lowestPrice].quantity}`]
      : []),
    ...(brand ? ['prdparam-brand', brand] : []),
    ...(hasStocks !== undefined
      ? hasStocks
        ? ['prdparam-stock', 'dispo']
        : ['prdparam-stock', 'not_dispo']
      : []),
    ...(productId ? ['prdparam-refproduit', `${productId}`] : []),
    ...(sections ? ['prdparam-category', sections] : []),
    ...(reference ? ['prdparam-refco', reference] : []),
    ...(color ? ['prdparam-color', color] : []),
    ...(size ? ['prdparam-size', size] : []),
    ...(image ? ['prdparam-photo', image] : []),
    ...(genre ? ['prdparam-genre', genre] : []),
    ...(sexs ? ['prdparam-sexe', sexs] : []),
    ...(typologies?.length ? ['prdgroup', typologies[0]] : []),
    ...(typologies?.length ? ['prdparam-type-n1', typologies[0]] : []),
    ...(typologies && typologies[1] ? ['prdparam-type-n2', typologies[1]] : []),
    ...(typologies && typologies[2] ? ['prdparam-type-n3', typologies[2]] : []),
    ...(typologies && typologies[3] ? ['prdparam-type-n4', typologies[3]] : []),
  ]

  useEffect(() => {
    if (isFetched && isEALoaded && productTags?.length) {
      clearWindowForPartners(productTags)
      collector({ tags: window.data_layer, urlp: _urlp, collectType: 'print' })
    }
  }, [asPath, isFetched, isEALoaded])

  return null
}

export default TagProduct
