const BUSINESS_UNIT_ID = '4f560fdd0000640005136c1a'

export const fetchProductsListingReviewsSummary = async ({
  apiUrl = process.env.NEXT_PUBLIC_TRUSTPILOT_API_URL || '',
  apikey = process.env.NEXT_PUBLIC_TRUSTPILOT_API_KEY || '',
  headers,
  skus,
}: {
  apiUrl?: string
  apikey?: string
  headers?: Headers
  skus: string[]
}): Promise<ReviewSummary> => {
  if (!skus.length) throw { status: 404 }

  const commaSeparatedSkus = skus.join(',')
  const res = await fetch(
    `${apiUrl}/v1/product-reviews/business-units/${BUSINESS_UNIT_ID}?sku=${commaSeparatedSkus}&apikey=${apikey}`,
    {
      method: 'GET',
      mode: 'cors',
      headers,
    }
  )
  if (!res?.ok) throw { status: res.status }
  return res.json()
}

export const fetchProductReviewSummary = async ({
  locale,
  apiUrl = process.env.NEXT_PUBLIC_TRUSTPILOT_API_URL || '',
  apikey = process.env.NEXT_PUBLIC_TRUSTPILOT_API_KEY || '',
  headers,
  sku,
  stars,
}: {
  locale?: string
  apiUrl?: string
  apikey?: string
  headers?: Headers
  sku: string
  stars?: string
}): Promise<ReviewSummary> => {
  if (!sku) throw { status: 404 }
  const res = await fetch(
    `${apiUrl}/v1/product-reviews/business-units/${BUSINESS_UNIT_ID}?apikey=${apikey}${
      locale ? `&language=${locale}` : ''
    }&sku=${sku}${stars ? `&stars=${stars}` : ''}`,
    {
      method: 'GET',
      mode: 'cors',
      headers,
    }
  )
  if (!res?.ok) throw { status: res.status }
  return res.json()
}

export const fetchProductReviewComments = async ({
  locale,
  apiUrl = process.env.NEXT_PUBLIC_TRUSTPILOT_API_URL || '',
  apikey = process.env.NEXT_PUBLIC_TRUSTPILOT_API_KEY || '',
  headers,
  sku,
  stars,
}: {
  locale?: string
  apiUrl?: string
  apikey?: string
  headers?: Headers
  sku: string
  stars?: string
}): Promise<ReviewComments> => {
  if (!sku) throw { status: 404 }
  const res = await fetch(
    `${apiUrl}/v1/product-reviews/business-units/${BUSINESS_UNIT_ID}/reviews?apikey=${apikey}${
      locale ? `&language=${locale}` : ''
    }&sku=${sku}${stars ? `&stars=${stars}` : ''}`,
    {
      method: 'GET',
      mode: 'cors',
      headers,
    }
  )
  if (!res?.ok) throw { status: res.status }
  return res.json()
}

export const fetchBusinessUnitRating = async ({
  apiUrl = process.env.NEXT_PUBLIC_TRUSTPILOT_API_URL || '',
  apikey = process.env.NEXT_PUBLIC_TRUSTPILOT_API_KEY || '',
}: {
  apiUrl?: string
  apikey?: string
}): Promise<ReviewRating> => {
  const res = await fetch(
    `${apiUrl}/v1/business-units/${BUSINESS_UNIT_ID}?apikey=${apikey}`,
    {
      method: 'GET',
      mode: 'cors',
    }
  )
  if (!res?.ok) throw { status: res.status }
  return res.json()
}

export const fetchBusinessUnitReviews = async ({
  apiUrl = process.env.NEXT_PUBLIC_TRUSTPILOT_API_URL || '',
  apikey = process.env.NEXT_PUBLIC_TRUSTPILOT_API_KEY || '',
  page = 1,
  perPage = 20,
  orderBy,
}: {
  apiUrl?: string
  apikey?: string
  page?: number
  perPage?: number
  orderBy?: 'createdat.asc' | 'createdat.desc' | 'stars.asc' | 'stars.desc'
}): Promise<ReviewComments> => {
  const res = await fetch(
    `${apiUrl}/v1/business-units/${BUSINESS_UNIT_ID}/reviews?apikey=${apikey}&page=${page}&perPage=${perPage}${
      orderBy ? `orderBy=${orderBy}` : ''
    }`,
    {
      method: 'GET',
      mode: 'cors',
    }
  )
  if (!res?.ok) throw { status: res.status }
  return res.json()
}
