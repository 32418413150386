import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import cn from 'classnames'
import s from './ScrollToTop.module.css'

export interface ScrollToTopProps {
  className?: string
}

const ScrollToTop: FC<ScrollToTopProps> = ({ className }) => {
  const { t } = useTranslation()

  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return () => {
      window.removeEventListener('scroll', checkScrollTop)
    }
  })

  return (
    <button
      aria-label={t('layout:scrollToTop.ariaScroll')}
      className={cn(className, s.scrollTop, { [s.showScroll]: showScroll })}
      onClick={scrollTop}
    >
      <span className={cn(s.arrowUp, s.arrowThin)} />
    </button>
  )
}

export default ScrollToTop
