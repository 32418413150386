const InstagramCircle = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <g clipPath="url(#clip0_270_3193)">
      <circle cx="15" cy="15" r="15" fill="#292929" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM11.7021 7.04839C12.5555 7.00956 12.8281 7.00006 15.0008 7.00006H14.9983C17.1717 7.00006 17.4434 7.00956 18.2967 7.04839C19.1484 7.08739 19.73 7.22223 20.2401 7.42006C20.7667 7.62423 21.2117 7.89757 21.6567 8.34257C22.1017 8.78724 22.3751 9.23358 22.5801 9.75975C22.7767 10.2684 22.9117 10.8498 22.9517 11.7014C22.9901 12.5548 23.0001 12.8274 23.0001 15.0001C23.0001 17.1728 22.9901 17.4448 22.9517 18.2982C22.9117 19.1495 22.7767 19.731 22.5801 20.2399C22.3751 20.7659 22.1017 21.2122 21.6567 21.6569C21.2122 22.1019 20.7666 22.3759 20.2406 22.5802C19.7315 22.7781 19.1495 22.9129 18.2979 22.9519C17.4445 22.9907 17.1727 23.0002 14.9998 23.0002C12.8273 23.0002 12.5548 22.9907 11.7015 22.9519C10.85 22.9129 10.2684 22.7781 9.75944 22.5802C9.23361 22.3759 8.78727 22.1019 8.34276 21.6569C7.89792 21.2122 7.62459 20.7659 7.42009 20.2397C7.22242 19.731 7.08758 19.1497 7.04842 18.298C7.00975 17.4447 7.00008 17.1728 7.00008 15.0001C7.00008 12.8274 7.01008 12.5546 7.04825 11.7013C7.08658 10.8499 7.22158 10.2684 7.41992 9.75959C7.62492 9.23358 7.89826 8.78724 8.34326 8.34257C8.78793 7.89773 9.23427 7.6244 9.76044 7.42006C10.2691 7.22223 10.8505 7.08739 11.7021 7.04839Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2831 8.44145C14.4224 8.44123 14.5724 8.4413 14.7341 8.44137L15.0008 8.44145C17.1368 8.44145 17.39 8.44911 18.2335 8.48745C19.0135 8.52311 19.4368 8.65345 19.7188 8.76295C20.0921 8.90795 20.3583 9.08129 20.6382 9.36129C20.9182 9.64129 21.0915 9.90796 21.2368 10.2813C21.3463 10.563 21.4768 10.9864 21.5123 11.7664C21.5507 12.6097 21.559 12.863 21.559 14.9981C21.559 17.1331 21.5507 17.3864 21.5123 18.2298C21.4767 19.0098 21.3463 19.4331 21.2368 19.7148C21.0918 20.0881 20.9182 20.354 20.6382 20.6338C20.3582 20.9138 20.0923 21.0871 19.7188 21.2321C19.4371 21.3421 19.0135 21.4721 18.2335 21.5078C17.3901 21.5461 17.1368 21.5545 15.0008 21.5545C12.8646 21.5545 12.6114 21.5461 11.7681 21.5078C10.9881 21.4718 10.5647 21.3415 10.2825 21.232C9.90923 21.087 9.64256 20.9136 9.36256 20.6336C9.08255 20.3536 8.90922 20.0876 8.76388 19.7141C8.65438 19.4324 8.52388 19.0091 8.48838 18.2291C8.45005 17.3858 8.44238 17.1324 8.44238 14.9961C8.44238 12.8597 8.45005 12.6077 8.48838 11.7644C8.52405 10.9844 8.65438 10.561 8.76388 10.279C8.90889 9.90563 9.08255 9.63896 9.36256 9.35896C9.64256 9.07895 9.90923 8.90562 10.2825 8.76028C10.5645 8.65028 10.9881 8.52028 11.7681 8.48445C12.5061 8.45111 12.7921 8.44111 14.2831 8.43945V8.44145ZM19.2711 9.7698C18.7411 9.7698 18.3111 10.1994 18.3111 10.7295C18.3111 11.2595 18.7411 11.6895 19.2711 11.6895C19.8011 11.6895 20.2311 11.2595 20.2311 10.7295C20.2311 10.1995 19.8011 9.76947 19.2711 9.76947V9.7698ZM10.8925 14.9999C10.8925 12.7311 12.7319 10.8916 15.0007 10.8915C17.2695 10.8915 19.1085 12.731 19.1085 14.9999C19.1085 17.2688 17.2697 19.1074 15.0008 19.1074C12.732 19.1074 10.8925 17.2688 10.8925 14.9999Z"
        fill="white"
      />
      <path
        d="M15.0007 12.334C16.4734 12.334 17.6674 13.5278 17.6674 15.0007C17.6674 16.4734 16.4734 17.6674 15.0007 17.6674C13.5279 17.6674 12.334 16.4734 12.334 15.0007C12.334 13.5278 13.5279 12.334 15.0007 12.334Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_270_3193">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default InstagramCircle
