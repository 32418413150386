import cn from 'classnames'
import s from './LogoGreenable.module.css'

export interface LogoProps {
  className: string
}

const LogoGreenable: React.FC<LogoProps> = ({ className = '', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cn(className, s.greenable)}
    {...props}
    x="0px"
    y="0px"
    viewBox="0 0 34.48 9.11"
  >
    <g>
      <polygon
        points="34.48,0.45 34.48,0 32.59,0 32.59,3.47 34.48,3.47 34.48,3.02 33.09,3.02 33.09,1.95 34.34,1.95 
  34.34,1.5 33.09,1.5 33.09,0.45 	"
      />
      <polygon
        points="7.13,3.47 7.63,3.47 7.63,0 7.13,0 5.93,2.68 4.73,0 4.24,0 4.24,3.47 4.74,3.47 4.74,1.2 5.76,3.47 
  6.11,3.47 7.13,1.19 	"
      />
      <polygon points="15.65,3.03 14.47,3.03 14.47,0 13.97,0 13.97,3.47 15.65,3.47 	" />
      <polygon points="18.99,3.03 17.81,3.03 17.81,0 17.31,0 17.31,3.47 18.99,3.47 	" />
      <path
        d="M21.13,2.89c0.09-0.23,0.33-0.5,0.67-0.57c0.1-0.02,0.39-0.1,0.59-0.26l0.05,0.14l0.11,0.29l0.16,0.44
  l0.2,0.54h0.53L22.16,0h-0.49l-1.24,3.37l-0.04,0.1h0.53L21.13,2.89z M21.91,0.76l0.29,0.79c-0.01,0.23-0.31,0.29-0.52,0.34
  c-0.08,0.02-0.15,0.04-0.21,0.08L21.91,0.76z"
      />
      <path
        d="M11.05,0h-0.49L9.31,3.37l-0.04,0.1h0.53l0.22-0.58c0.09-0.23,0.33-0.5,0.67-0.57c0.1-0.02,0.39-0.1,0.59-0.26
  l0.05,0.14l0.11,0.29l0.16,0.44l0.2,0.54h0.53L11.05,0z M10.57,1.88c-0.08,0.02-0.15,0.04-0.21,0.08l0.44-1.2l0.29,0.79
  C11.08,1.78,10.78,1.84,10.57,1.88z"
      />
      <polygon points="29.75,0.01 29.24,0.01 29.24,3.47 30.93,3.47 30.93,3.03 29.75,3.03 	" />
      <path
        d="M2.3,1.84c-0.1-0.08-0.21-0.14-0.33-0.2C1.84,1.59,1.72,1.55,1.59,1.51C1.46,1.47,1.34,1.43,1.22,1.4
  C1.1,1.36,0.99,1.32,0.9,1.28c-0.09-0.04-0.16-0.1-0.21-0.16C0.64,1.06,0.63,0.99,0.63,0.9c0-0.36,0.37-0.44,0.46-0.45
  c0.43-0.07,0.81,0.33,0.81,0.33l0.33-0.29C2.05,0.25,1.44-0.08,0.95,0.02C0.79,0.05,0.66,0.1,0.55,0.17
  c-0.12,0.07-0.22,0.17-0.3,0.29C0.17,0.58,0.12,0.73,0.11,0.9C0.1,1.05,0.12,1.19,0.18,1.3c0.03,0.06,0.18,0.29,0.54,0.44
  c0.24,0.1,0.46,0.15,0.89,0.28C1.84,2.1,1.97,2.2,2.04,2.3C2.1,2.41,2.1,2.53,2.08,2.6C2.03,2.8,1.86,2.95,1.63,3.01
  c-0.79,0.21-1.27-0.5-1.27-0.5L0,2.81c0,0,0.55,0.81,1.67,0.64c0.16-0.02,0.3-0.07,0.43-0.14c0.13-0.07,0.24-0.17,0.33-0.29
  c0.09-0.12,0.14-0.28,0.15-0.46C2.6,2.39,2.58,2.25,2.52,2.13C2.47,2.02,2.39,1.92,2.3,1.84z"
      />
      <path
        d="M26.74,3.33l0.04-0.09c0.05-0.11,0.08-0.18,0.1-0.23l0,0l0,0c0.1-0.19,0.18-0.32,0.24-0.4l0,0l0,0
  c0.08-0.1,0.17-0.16,0.25-0.2c0-0.23-0.08-0.45-0.26-0.64c-0.09-0.1-0.3-0.27-0.47-0.31C26.69,1.43,27,1.21,27,0.74
  c0-0.27-0.29-0.73-0.96-0.73h-1v1.62v0.02v1.83h1.03C26.33,3.47,26.55,3.42,26.74,3.33z M25.55,0.46h0.5
  c0.66,0,0.52,0.56,0.38,0.66c-0.19,0.14-0.32,0.13-0.59,0.16c-0.1,0.01-0.22,0.06-0.29,0.12V0.46z M25.55,2.16v-0.3
  c0.13-0.11,0.4-0.25,0.86-0.1c0.23,0.08,0.42,0.27,0.47,0.4c0.14,0.36-0.09,0.86-0.79,0.86h-0.54V2.16z"
      />
      <path
        d="M6.93,5.09L6.93,5.09l-0.01,0l0,0l0,0C6.88,5.13,6.87,5.16,6.86,5.2L6.86,5.23c0,0.03,0.01,0.05,0.02,0.06
  c0.02,0.02,0.05,0.04,0.1,0.04c0,0,0,0,0,0c0.04,0,0.07-0.01,0.09-0.03c0.02-0.02,0.03-0.04,0.03-0.07c0-0.06-0.01-0.1-0.03-0.13
  C7.04,5.09,7.02,5.08,6.98,5.08C6.97,5.08,6.95,5.08,6.93,5.09z"
      />
      <path
        d="M10.65,5.65c-0.02-0.01-0.05-0.04-0.09-0.04c-0.01,0-0.03,0-0.04,0.01c-0.31,0.16-0.71,0.41-1.19,0.76
  c0,0,0,0,0,0C9.31,6.31,9.26,6.24,9.21,6.16C9.07,5.98,9,5.87,8.98,5.83C8.95,5.77,8.94,5.72,8.95,5.66
  c0.01-0.04,0.04-0.09,0.09-0.14c0.09-0.09,0.19-0.17,0.3-0.24C9.37,5.27,9.4,5.26,9.42,5.25c0,0,0.01,0,0.02-0.01l0.01,0
  c0,0.01-0.01,0.02-0.01,0.05C9.42,5.35,9.36,5.49,9.27,5.69c0,0.02-0.01,0.05,0.03,0.08C9.33,5.8,9.35,5.81,9.38,5.81
  c0,0,0.01,0,0.01,0c0.02,0,0.04-0.01,0.05-0.03c0.08-0.11,0.14-0.23,0.18-0.36c0.05-0.15,0.05-0.24,0.02-0.3
  C9.61,5.07,9.56,5.04,9.49,5.04c-0.05,0-0.12,0.02-0.2,0.05c-0.08,0.03-0.18,0.09-0.3,0.18c-0.13,0.1-0.22,0.18-0.26,0.25
  C8.72,5.55,8.71,5.59,8.7,5.64C8.37,5.85,8,6.05,7.6,6.24c-0.02,0.01-0.06,0.02-0.1,0.05C7.41,6.32,7.35,6.35,7.3,6.37
  C7.24,6.4,7.16,6.41,7.08,6.42C6.99,6.41,6.94,6.3,6.93,6.09c0-0.12,0.02-0.3,0.07-0.54C7,5.5,6.94,5.49,6.93,5.48
  c-0.02,0-0.04-0.01-0.05-0.01c-0.07,0-0.1,0.03-0.1,0.06C6.73,5.72,6.7,5.89,6.7,6.06l0,0.04c0,0.27,0.08,0.44,0.23,0.51
  c0.05,0.02,0.11,0.04,0.18,0.04c0.09-0.01,0.23-0.06,0.45-0.16c0.01-0.01,0.02-0.01,0.04-0.02c0.52-0.27,0.9-0.48,1.13-0.62
  c0,0.02,0.01,0.03,0.02,0.05c0.03,0.06,0.07,0.12,0.12,0.2c0.16,0.21,0.21,0.29,0.22,0.33c0.02,0.04,0.03,0.08,0.04,0.1
  c-0.14,0.1-0.28,0.21-0.4,0.33C8.59,6.99,8.51,7.07,8.47,7.11L8.47,7.12c0,0.01-0.01,0.02-0.05,0.06C8.39,7.22,8.38,7.24,8.37,7.24
  c-0.06,0.07-0.09,0.13-0.1,0.18c0,0.03,0,0.07,0.01,0.1c0.04,0.09,0.11,0.13,0.21,0.13c0.08,0,0.18-0.03,0.28-0.1
  c0.01,0,0.03-0.01,0.04-0.02c0.25-0.16,0.42-0.31,0.5-0.46c0.08-0.16,0.12-0.32,0.1-0.47c0.17-0.14,0.36-0.28,0.55-0.41
  c0.09-0.06,0.24-0.16,0.44-0.28c0.12-0.07,0.21-0.12,0.26-0.16c0.02-0.02,0.04-0.05,0.01-0.09L10.65,5.65z M9.03,7.03
  c-0.07,0.1-0.15,0.18-0.24,0.25C8.69,7.36,8.6,7.41,8.53,7.44c0-0.01,0.01-0.02,0.03-0.04C8.56,7.4,8.58,7.38,8.58,7.37
  C8.65,7.28,8.72,7.2,8.79,7.13C8.89,7.02,9,6.92,9.12,6.82C9.1,6.88,9.07,6.95,9.03,7.03z"
      />
      <path
        d="M29.53,5.64L29.53,5.64l-0.01-0.01C29.5,5.61,29.47,5.6,29.45,5.6c-0.02,0-0.05,0.01-0.07,0.02
  c-0.05,0.03-0.11,0.07-0.19,0.12c-0.47,0.3-0.83,0.45-1.07,0.45c-0.03,0-0.06,0-0.09-0.01c-0.07-0.02-0.1-0.09-0.1-0.22
  c0.12-0.07,0.23-0.16,0.33-0.26c0.08-0.1,0.11-0.16,0.11-0.21c0-0.07-0.05-0.12-0.13-0.15c-0.03-0.01-0.05-0.01-0.08-0.01
  c-0.11,0-0.21,0.07-0.31,0.21c-0.02,0.03-0.04,0.06-0.05,0.09c0,0-0.01,0.01-0.01,0.01c-0.16,0.2-0.36,0.39-0.59,0.57
  c-0.14,0.11-0.26,0.18-0.35,0.21c-0.02,0.01-0.05,0.01-0.07,0.01c-0.06,0-0.12-0.03-0.17-0.08c-0.04-0.03-0.07-0.08-0.09-0.13
  c-0.06-0.12-0.07-0.33-0.03-0.62c0-0.03,0.01-0.09,0.02-0.16c0.01-0.07,0.02-0.12,0.02-0.15c0.16-0.18,0.34-0.43,0.56-0.74
  c0.16-0.25,0.3-0.52,0.42-0.81c0.12-0.29,0.19-0.59,0.22-0.88c0-0.01,0-0.02,0.01-0.05c0-0.01,0-0.02,0-0.02l0-0.01
  c0.01-0.18-0.04-0.27-0.16-0.3c-0.01,0-0.02,0-0.03,0c-0.06,0-0.12,0.02-0.19,0.07c-0.05,0.03-0.1,0.08-0.15,0.14
  c-0.06,0.08-0.14,0.21-0.23,0.39c-0.02,0.04-0.04,0.1-0.08,0.17c-0.01,0.02-0.02,0.04-0.02,0.05l-0.1,0.23
  c-0.12,0.3-0.24,0.62-0.33,0.95c-0.09,0.31-0.16,0.65-0.22,0.99c-0.01,0.03-0.01,0.06-0.02,0.08c-0.01,0.03-0.01,0.06-0.01,0.1
  c0,0-0.01,0-0.01,0c-0.12,0.04-0.25,0.08-0.37,0.13c-0.17,0.06-0.33,0.14-0.49,0.22c0.02-0.09,0.03-0.1,0.03-0.11l0-0.01
  c0.04-0.2,0.04-0.33,0-0.42L25.34,5.4c-0.07-0.11-0.18-0.16-0.31-0.16c-0.07,0-0.15,0.02-0.24,0.05c-0.22,0.08-0.41,0.26-0.58,0.56
  l-0.02,0.04c0.07-0.66,0.18-1.45,0.31-2.36C24.52,3.3,24.57,3,24.63,2.61c0-0.02-0.01-0.05-0.06-0.08
  c-0.02-0.01-0.04-0.01-0.06-0.01c-0.02,0-0.04,0-0.05,0.01c-0.01,0.01-0.03,0.02-0.03,0.05c-0.2,1.07-0.37,2.1-0.49,3.06
  c-0.06,0.06-0.12,0.12-0.2,0.18C23.5,6,23.31,6.09,23.15,6.09c-0.02,0-0.03,0-0.05,0c-0.01,0-0.03-0.01-0.04-0.01
  c-0.1-0.03-0.15-0.09-0.18-0.18c-0.03-0.1-0.03-0.28,0.02-0.52c0-0.04-0.02-0.07-0.08-0.1c-0.03-0.01-0.06-0.02-0.08-0.02
  c-0.04,0-0.07,0.02-0.09,0.05c-0.02,0.05-0.04,0.08-0.05,0.11c-0.04-0.01-0.08-0.02-0.12-0.02c-0.04,0-0.08,0.01-0.12,0.02
  c-0.15,0.05-0.28,0.12-0.4,0.2c-0.01,0-0.03,0.01-0.04,0.02c-0.01,0-0.03,0.01-0.05,0.02c-0.32,0.18-0.53,0.29-0.59,0.32
  c-0.03,0.01-0.06,0.02-0.1,0.03c-0.14,0.05-0.27,0.07-0.37,0.07c-0.05,0-0.1-0.01-0.14-0.02c-0.1-0.03-0.18-0.16-0.23-0.39
  c-0.03-0.13-0.11-0.21-0.22-0.23c-0.02,0-0.03,0-0.05,0c-0.04,0-0.08,0.01-0.12,0.02c-0.03,0.02-0.1,0.07-0.21,0.17l-0.46,0.42
  c0-0.02,0.01-0.03,0.01-0.05c0.01-0.06,0.03-0.11,0.03-0.14l0.12-0.58c0-0.04-0.03-0.07-0.08-0.1C19.45,5.2,19.43,5.2,19.42,5.2
  c-0.02,0-0.04,0.01-0.05,0.02c-0.01,0.01-0.03,0.03-0.03,0.06c-0.01,0.06-0.04,0.15-0.07,0.28l-0.02,0.09
  c-0.05,0.03-0.1,0.06-0.16,0.11C18.6,6.04,18.24,6.19,18,6.19c-0.03,0-0.06,0-0.09-0.01c-0.07-0.02-0.1-0.09-0.1-0.22
  c0.12-0.07,0.23-0.16,0.33-0.26c0.08-0.1,0.11-0.16,0.11-0.21c0-0.07-0.05-0.12-0.13-0.15c-0.03-0.01-0.05-0.01-0.08-0.01
  c-0.11,0-0.21,0.07-0.32,0.21c-0.02,0.03-0.04,0.06-0.06,0.1c-0.05,0.03-0.1,0.07-0.16,0.11c-0.47,0.3-0.83,0.45-1.07,0.45
  c-0.03,0-0.06,0-0.09-0.01c-0.07-0.02-0.1-0.09-0.1-0.22c0.12-0.07,0.24-0.16,0.33-0.26c0.07-0.09,0.11-0.16,0.11-0.21
  c0-0.07-0.05-0.12-0.13-0.15c-0.03-0.01-0.05-0.01-0.08-0.01c-0.11,0-0.21,0.07-0.32,0.21c-0.02,0.03-0.04,0.06-0.05,0.09
  c-0.1,0.05-0.2,0.07-0.28,0.07c-0.05,0-0.09-0.01-0.13-0.02c-0.05-0.03-0.1-0.06-0.13-0.1c-0.01-0.02-0.03-0.05-0.04-0.09
  c-0.01-0.03-0.02-0.07-0.04-0.09c-0.05-0.09-0.12-0.14-0.22-0.15c-0.08,0-0.17,0.05-0.24,0.13c-0.04,0.05-0.08,0.1-0.12,0.16
  c0-0.12-0.01-0.21-0.03-0.27c0-0.01-0.01-0.02-0.03-0.04c-0.03-0.02-0.05-0.02-0.07-0.02c-0.01,0-0.01,0-0.02,0
  c-0.01,0-0.03,0.01-0.05,0.04c-0.03,0.04-0.05,0.1-0.05,0.17c0,0.01,0,0.04-0.01,0.08l0,0.07c0,0.02,0,0.04-0.01,0.07
  c-0.1,0.05-0.2,0.11-0.31,0.19c-0.13,0.1-0.26,0.19-0.38,0.28c-0.03,0.02-0.07,0.06-0.13,0.11c0-0.01,0-0.02,0-0.03
  c0-0.06,0.01-0.1,0.01-0.13c0-0.04,0-0.1,0.01-0.19l0-0.18c0-0.14-0.06-0.24-0.16-0.28c-0.03-0.01-0.07-0.02-0.1-0.02
  c-0.06,0-0.11,0.02-0.17,0.05c-0.01,0-0.02,0.01-0.03,0.02l0-0.01c0-0.06-0.04-0.13-0.17-0.14c-0.01,0-0.02,0-0.04,0
  c-0.07,0-0.14,0.02-0.21,0.06c-0.17,0.12-0.32,0.28-0.44,0.47C12.51,5.9,12.46,6,12.42,6.12c0,0.01-0.01,0.02-0.01,0.03
  c-0.06,0.14-0.06,0.23-0.01,0.29l0.03,0.03c0.04,0.03,0.08,0.05,0.13,0.05c0.05,0,0.11-0.02,0.17-0.05
  c0.01-0.01,0.03-0.02,0.12-0.11l0.13-0.13c0.16-0.16,0.25-0.25,0.26-0.26c0.03-0.03,0.07-0.08,0.12-0.14
  c0.05-0.06,0.09-0.11,0.11-0.14c0.03-0.03,0.07-0.06,0.1-0.09c0.01,0,0.02-0.01,0.03-0.01c0,0.01,0.01,0.04,0.01,0.12V5.9
  c0,0.07-0.01,0.18-0.02,0.34l-0.02,0.2c-0.13,0.12-0.27,0.26-0.44,0.43c-0.33,0.36-0.57,0.64-0.7,0.85
  c-0.01,0.03-0.03,0.05-0.04,0.07c-0.22,0.35-0.31,0.65-0.27,0.89c0.01,0.02,0.01,0.05,0.02,0.07c0.06,0.18,0.18,0.29,0.35,0.34
  c0.01,0,0.02,0.01,0.03,0.01c0.05,0.01,0.1,0.02,0.15,0.02c0.15,0,0.29-0.05,0.41-0.16c0.1-0.08,0.2-0.24,0.31-0.48
  c0.06-0.13,0.12-0.31,0.18-0.52c0.1-0.35,0.17-0.76,0.22-1.2c0-0.03,0.01-0.08,0.01-0.13c0.01-0.05,0.01-0.09,0.01-0.12
  c0.26-0.24,0.53-0.45,0.82-0.64c-0.01,0.08-0.05,0.28-0.12,0.6c0,0.03,0,0.08,0.09,0.11c0.02,0.01,0.04,0.01,0.06,0.01
  c0.02,0,0.04-0.01,0.06-0.02c0.01-0.01,0.03-0.03,0.04-0.06c0.09-0.35,0.24-0.67,0.45-0.96c0.02-0.02,0.03-0.04,0.05-0.06
  c0,0.01,0.01,0.03,0.02,0.04c0.02,0.05,0.03,0.08,0.04,0.11c0.08,0.13,0.21,0.22,0.39,0.25c0.04,0.01,0.07,0.01,0.11,0.01
  c0.07,0,0.14-0.01,0.21-0.03c-0.01,0.05-0.02,0.1-0.02,0.14c0,0.06,0,0.12,0.02,0.18c0.05,0.15,0.17,0.22,0.35,0.22
  c0.07,0,0.14-0.01,0.22-0.03c0.09-0.03,0.2-0.06,0.31-0.11c0.24-0.1,0.46-0.22,0.67-0.35C17.57,5.95,17.57,5.97,17.57,6
  c0,0.06,0,0.12,0.02,0.18c0.01,0.04,0.03,0.07,0.05,0.1c0.06,0.08,0.16,0.12,0.29,0.12c0.01,0,0.02,0,0.03,0
  c0.06,0,0.12-0.01,0.2-0.03c0.09-0.03,0.2-0.06,0.31-0.11c0.25-0.1,0.49-0.23,0.71-0.38c-0.01,0.02-0.01,0.05-0.02,0.08
  c-0.01,0.06-0.03,0.1-0.03,0.14c0,0.02-0.01,0.05-0.03,0.1c-0.02,0.08-0.04,0.14-0.04,0.19c0,0.03,0,0.07,0.06,0.1
  c0.03,0.02,0.06,0.02,0.09,0.02c0.03,0,0.06-0.01,0.08-0.03c0.07-0.07,0.19-0.17,0.34-0.31l0.34-0.3l0.08-0.08
  c0.03-0.03,0.06-0.05,0.08-0.07c0.01,0,0.02-0.01,0.02-0.02l0.01,0c0.01-0.01,0.02-0.01,0.02-0.01c0,0,0,0,0,0
  c0.02,0.03,0.04,0.08,0.05,0.13c0.04,0.19,0.14,0.33,0.3,0.41c0.09,0.04,0.19,0.06,0.29,0.06c0.15,0,0.31-0.04,0.48-0.12
  c0.11-0.05,0.22-0.1,0.34-0.17c-0.07,0.19-0.04,0.33,0.11,0.42c0.04,0.02,0.09,0.04,0.13,0.04c0.07,0,0.15-0.03,0.23-0.09
  c0.01-0.01,0.01-0.01,0.03-0.02c0.02-0.02,0.1-0.1,0.24-0.25c0.1-0.11,0.18-0.22,0.24-0.3c0.02,0.16,0.09,0.29,0.2,0.38
  c0.09,0.07,0.2,0.11,0.32,0.11c0.17,0,0.37-0.07,0.59-0.22c0.07-0.04,0.13-0.09,0.19-0.14l-0.02,0.24
  c-0.01,0.19-0.03,0.34-0.04,0.45c0,0.05,0.03,0.09,0.09,0.11c0.03,0.01,0.05,0.01,0.07,0.01c0.02,0,0.03,0,0.05-0.01
  c0.01-0.01,0.03-0.02,0.04-0.06c0.04-0.12,0.06-0.2,0.08-0.24c0.14-0.42,0.31-0.72,0.51-0.89c0.08-0.06,0.16-0.1,0.22-0.11
  c0.03-0.01,0.06-0.01,0.09-0.01c0.04,0,0.07,0.01,0.09,0.02c0.01,0,0.02,0.01,0.02,0.02c0.01,0.01,0.03,0.06,0.01,0.23
  c-0.01,0.07-0.04,0.18-0.08,0.33c0,0.02-0.01,0.05-0.03,0.1c-0.08,0.05-0.14,0.09-0.18,0.12c-0.19,0.14-0.38,0.29-0.54,0.45
  c-0.12,0.12-0.2,0.21-0.23,0.28c-0.03,0.07-0.04,0.14-0.02,0.21c0.04,0.07,0.1,0.12,0.17,0.14c0.02,0,0.03,0,0.05,0
  c0.1,0,0.21-0.05,0.32-0.14c0.1-0.08,0.19-0.18,0.28-0.28c0.15-0.18,0.28-0.41,0.38-0.68c0.21-0.12,0.52-0.25,0.94-0.41
  c-0.01,0.25,0.04,0.45,0.14,0.57c0.09,0.12,0.2,0.19,0.34,0.23c0.02,0.01,0.05,0.01,0.07,0.01c0.2,0,0.46-0.15,0.82-0.47
  c0.05-0.04,0.09-0.09,0.14-0.13c0,0.05,0.01,0.09,0.02,0.13c0.05,0.15,0.17,0.22,0.35,0.22c0.07,0,0.14-0.01,0.22-0.03
  c0.09-0.03,0.2-0.06,0.31-0.11c0.34-0.14,0.66-0.32,0.93-0.54c0.02-0.02,0.03-0.05,0.02-0.08L29.53,5.64L29.53,5.64z M28.06,5.59
  L28.06,5.59l0.01-0.01v0c0.06-0.09,0.1-0.11,0.12-0.11c0,0,0.01,0.03-0.06,0.13c-0.04,0.05-0.09,0.1-0.15,0.15
  C28,5.69,28.03,5.64,28.06,5.59z M26.66,4.71c0.16-0.61,0.36-1.18,0.6-1.7c0.08-0.15,0.15-0.26,0.21-0.32
  c0.01-0.01,0.03-0.03,0.06-0.05c0,0,0,0,0,0c0,0.02,0,0.05,0,0.11l0,0.07c-0.01,0.22-0.07,0.48-0.18,0.78
  c-0.1,0.27-0.24,0.55-0.42,0.83c-0.12,0.17-0.22,0.32-0.3,0.43L26.66,4.71z M17.95,5.59L17.95,5.59l0.01-0.01v0
  c0.07-0.09,0.1-0.11,0.12-0.11c0,0.01,0,0.04-0.06,0.12c-0.04,0.05-0.09,0.1-0.15,0.15C17.89,5.69,17.92,5.64,17.95,5.59z
   M16.39,5.59L16.39,5.59l0.01-0.01v0c0.07-0.09,0.1-0.11,0.12-0.11c0,0.01,0,0.04-0.06,0.12c-0.04,0.05-0.09,0.1-0.15,0.15
  C16.33,5.69,16.36,5.64,16.39,5.59z M13.15,5.69c-0.23,0.25-0.4,0.43-0.53,0.55c0,0,0,0,0,0c0.03-0.08,0.06-0.17,0.11-0.28
  c0.11-0.18,0.24-0.34,0.4-0.47c0.02-0.02,0.04-0.03,0.07-0.04c0.01,0.02,0.03,0.04,0.06,0.06c0.02,0.01,0.03,0.01,0.04,0.01
  C13.27,5.57,13.22,5.62,13.15,5.69z M13.51,7.21c-0.08,0.45-0.18,0.82-0.29,1.09c-0.07,0.2-0.16,0.36-0.26,0.47
  c-0.02,0.02-0.04,0.04-0.07,0.06c-0.08,0.06-0.16,0.08-0.23,0.08c-0.05,0-0.09-0.01-0.13-0.04c-0.11-0.07-0.15-0.2-0.14-0.39
  c0.01-0.13,0.07-0.28,0.17-0.46c0.15-0.26,0.35-0.54,0.59-0.82c0.09-0.1,0.23-0.25,0.42-0.44C13.55,6.91,13.53,7.06,13.51,7.21z
   M21.98,6.2c-0.01,0.01-0.02,0.02-0.04,0.03c-0.03,0.03-0.04,0.04-0.05,0.04c-0.01,0.01-0.02,0.01-0.02,0.01c0,0-0.01,0-0.01,0
  c-0.02-0.05-0.02-0.12,0.03-0.2c0.08-0.14,0.19-0.26,0.33-0.37c0.09-0.06,0.17-0.1,0.24-0.13c0.02-0.01,0.04-0.01,0.05-0.01
  C22.37,5.78,22.19,5.99,21.98,6.2z M24.75,6.65c-0.08,0.11-0.15,0.2-0.21,0.27c-0.09,0.11-0.18,0.18-0.24,0.21
  c-0.01,0-0.01,0.01-0.02,0.01c0.01-0.03,0.02-0.06,0.04-0.1c0.06-0.08,0.13-0.16,0.19-0.23c0.09-0.09,0.19-0.18,0.31-0.28
  C24.8,6.58,24.78,6.62,24.75,6.65z"
      />
    </g>
  </svg>
)

export default LogoGreenable
