import { FC } from 'react'

interface Props {
  className?: string
}

const CheckConnected: FC<Props> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <circle cx="8" cy="8" r="8" fill="#1F1F1F" />
    <path
      d="M12 5L6.5 11L4 8.27273"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckConnected
