import { FC, FocusEvent, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import useIfLogged from '@lib/hooks/useIfLogged'

import { CardSlider, QuickBuy } from '@components/product'
import ProductTags from '@components/product/ProductTags'

import GiftListModal from '@components/product/ProductDetails/AddToListButtons/GiftListModal'
import { Status } from '@components/common/Alert/Alert'
import { Cross, Gift, Heart } from '@components/icons'
import Link from '@components/ui/Link'
import DropdownQuickBuy from '@components/product/ProductCard/DropdownQuickBuy'
import { tagEventList } from '@components/common/Eulerian/EulerianEvents'
import {
  ACTION,
  FROM,
  tagAlgoliaClick,
  tagAlgoliaConvertion,
} from '@lib/lib-algolia/algolia'
import Portal from '@reach/portal'

import { MODALS } from '@constants'
import productUrl from '@utils/url/product'
import { cardPriceFormat, formattedObjPrices, isDiffPrice } from '@utils/prices'
import { hasQuantity, productIsUnavailable } from '@utils/stocks'
import { getItemsName } from '@utils/basket/items'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import {
  disableHtml100vh,
  disableTouchAction,
  enableHtml100vh,
  enableTouchAction,
} from '@utils/html100vh'
import { isIOS13, isMobileSafari, isTablet } from 'react-device-detect'

import cn from 'classnames'
import s from './ProductCard.module.css'

const ProductCard: FC<ProductCardProps> = ({
  product,
  productsPerLine = 1,
  disableSlider = false,
  disableSwipe = false,
  priority = false,
  layout,
  hit,
  handleBlockScroll = true,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { deliveryCountryCode } = useUserSettings()
  const screen = useScreen()
  const sessionData = useUserSettings()
  const { addToWishlist, removeFromWishlist, wishlist } = sessionData
  const { setModalView, openModal } = useUI()

  const [isATwoTagsCard, setIsATwoTagsCard] = useState<boolean>(true)
  const [quickBuyOpen, setQuickBuyOpen] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [isGiftlistOpen, setIsGiftListOpen] = useState<boolean>(false)

  const {
    attachColor,
    brand,
    color,
    declinations,
    id,
    tags,
    medias,
    name,
    prices,
    stocks,
    siblings,
    reference,
    sections,
    persons,
    sexs,
    typologies,
    alertAvailability,
  } = product

  const productName = getItemsName({ attachColor, name, color })
  const urlProduct = productUrl({
    attachColor,
    brand,
    color,
    id,
    name,
    locale,
  })
  const pricesFormatted = cardPriceFormat(prices, locale) as DeclinationPrice
  const diffPrice = isDiffPrice(prices)
  const displaySlider = !disableSlider && medias?.length > 1
  const isInWishList = wishlist?.products?.includes(Number(id))
  const isUnavailable = productIsUnavailable(stocks, alertAvailability)
  const isOnDemand =
    stocks?.availability?.onDemand &&
    stocks?.isSalableOutOfStock &&
    !hasQuantity(stocks?.declinations)

  const handleWishList = useIfLogged(async () => {
    try {
      if (isInWishList) await removeFromWishlist(id, locale)
      else await addToWishlist(id, locale)
      const wishlistTagPath = isInWishList
        ? '/wishlist/remove/page_listing'
        : '/wishlist/add/page_listing'
      tagEventList({
        urlp: wishlistTagPath,
        locale,
        sessionData,
        productData: {
          brand,
          name,
          id,
          color,
          reference,
          sections,
          persons,
          sexs,
          typologies,
        },
        selectedSize: `${id}`,
      })
      if (!isInWishList) {
        tagAlgoliaConvertion({
          deliveryCountryCode,
          from: FROM.LISTING_PRODUCT,
          locale,
          objectId: product.reference,
          queryID: hit.__queryID,
          typeAction: ACTION.ADD_TO_WISHLIST,
        })
      }
    } catch {
      console.error(Status.Error)
    }
  })

  const handleGiftList = useIfLogged(() => {
    setIsGiftListOpen(true)
  })

  const handleCardEvents = (e) => {
    if (!isTablet && declinations?.length)
      setQuickBuyOpen(
        ![Screen.xs, Screen.sm, Screen.md].includes(screen) &&
          (e.type === 'mouseenter' || e.type === 'focus')
      )
  }

  const displayAddToBasketMsg = () => {
    if (![Screen.xs, Screen.sm, Screen.md, Screen.lg].includes(screen))
      return null
    setModalView(MODALS.ADD_TO_BASKET)
    openModal()
  }

  const onBlur = useCallback(
    (e: FocusEvent<HTMLAnchorElement>) => {
      if (
        !e.relatedTarget ||
        !e.currentTarget.contains(e.relatedTarget as Element)
      ) {
        setQuickBuyOpen(false)
      }
    },
    [setQuickBuyOpen]
  )

  const closeGiftListModal = useCallback(() => {
    setIsGiftListOpen(false)
  }, [setIsGiftListOpen])

  useEffect(() => {
    if (!isIOS13 && handleBlockScroll) {
      if (isDropdownOpen && screen && [Screen.xs, Screen.sm].includes(screen)) {
        blockScroll(isMobileSafari)
      } else {
        enableScroll(isMobileSafari)
      }
      if (isMobileSafari) {
        if (isDropdownOpen) {
          enableHtml100vh()
          disableTouchAction()
        } else {
          disableHtml100vh()
          enableTouchAction()
        }
      }
    }
  }, [isDropdownOpen])

  useEffect(() => {
    setIsATwoTagsCard(
      ![Screen.xs, Screen.sm, Screen.md].includes(screen) ||
        productsPerLine !== 2
    )
    setIsDropDownOpen(false)
  }, [screen, productsPerLine])

  return (
    <div
      className={cn(s.container, { [s.half]: productsPerLine === 2 })}
      data-testid="ProductCard_container"
    >
      {isUnavailable && (
        <div className={s.unavailable}>
          <p>{t('product:productIsUnavailable')}</p>
        </div>
      )}
      {screen && ![Screen.xs, Screen.sm, Screen.md].includes(screen) && (
        <div className={s.tagLine}>
          {tags && (
            <ProductTags
              tags={tags}
              isATwoTagsCard={isATwoTagsCard}
              discountType={pricesFormatted?.discountType}
              layout="card"
            />
          )}
        </div>
      )}
      <Link
        href={`${urlProduct}${
          hit?.__queryID ? `?algsearch=${hit.__queryID}` : ''
        }`}
        next
        aria-label={`${t('layout:common.ariaNavigate')} ${productName}`}
        onMouseEnter={(e) => handleCardEvents(e)}
        onMouseLeave={(e) => handleCardEvents(e)}
        onFocus={
          ![Screen.xs, Screen.sm, Screen.md].includes(screen) && !isTablet
            ? () => setQuickBuyOpen(true)
            : null
        }
        onBlur={
          ![Screen.xs, Screen.sm, Screen.md].includes(screen) ? onBlur : null
        }
        onClick={(e) => {
          tagAlgoliaClick({
            hit: hit,
            deliveryCountryCode,
            locale,
            from: FROM.LISTING_PRODUCT,
          })
        }}
      >
        <div className={s.content}>
          <div className={s.img}>
            {displaySlider ? (
              <CardSlider
                medias={medias}
                productName={productName}
                productsPerLine={productsPerLine}
                priority={priority}
                quickBuyOpen={quickBuyOpen}
                disableSwipe={disableSwipe}
              />
            ) : (
              <div
                className={cn(s.slide, {
                  [s.hoverEffect]:
                    medias?.length > 1 &&
                    ![Screen.xs, Screen.sm, Screen.md].includes(screen),
                })}
              >
                {medias.map(
                  (img, index) =>
                    index < 2 && (
                      <Image
                        key={`ProductCard-img-${img['648x648q80']}`}
                        src={
                          img['648x648q80']
                            ? `https:${img['648x648q80']}`
                            : '/img/apple-touch-icon.png'
                        }
                        alt={productName}
                        aria-label={productName}
                        priority={priority}
                        layout="responsive"
                        width="100"
                        height="100"
                        unoptimized
                      />
                    )
                )}
              </div>
            )}
            {(!screen ||
              [Screen.xs, Screen.sm, Screen.md].includes(screen)) && (
              <div className={s.tagLine}>
                {tags && (
                  <ProductTags
                    tags={tags}
                    isATwoTagsCard={isATwoTagsCard}
                    discountType={pricesFormatted?.discountType}
                  />
                )}
              </div>
            )}
            {declinations?.length &&
            quickBuyOpen &&
            ![Screen.xs, Screen.sm, Screen.md].includes(screen) ? (
              <QuickBuy
                declinations={declinations}
                stocks={stocks}
                queryID={hit?.__queryID || null}
                layout={layout}
              />
            ) : null}
          </div>
          <div className={cn(s.desc, { [s.slidedown]: quickBuyOpen })}>
            <ul className={s.attr}>
              <li className={cn(s.brand, 'notranslate')}>
                <strong>{brand?.name}</strong>
              </li>
              <li
                className={s.title}
                data-testid="ProductCard_title"
                title={productName}
                aria-label={productName}
              >
                {productName}
              </li>
              {isOnDemand && productsPerLine !== 2 ? (
                <li className={s.onDemand}>
                  {t('product:delivery.isSalableOutOfStock', {
                    from: Math.floor(stocks?.availability?.from / 7),
                    to: Math.ceil(stocks?.availability?.to / 7),
                  })}
                </li>
              ) : null}
              <li className={cn(s.prices, 'notranslate')}>
                {pricesFormatted && (
                  <p className={s.priceInfo}>
                    {diffPrice && (
                      <span className={s.labelFrom}>
                        {t('product:price.from')}&nbsp;
                      </span>
                    )}
                    {pricesFormatted.baseAmount &&
                    pricesFormatted.baseAmount !== pricesFormatted.amount ? (
                      <>
                        <span className={s.salePrice}>
                          {pricesFormatted.baseAmount}
                        </span>
                        <span className={s.initialPrice}>
                          {pricesFormatted.amount}
                        </span>
                        <span
                          className={s.discountAmount}
                        >{`-${pricesFormatted.discount}`}</span>
                      </>
                    ) : (
                      pricesFormatted.amount
                    )}
                  </p>
                )}
                {siblings?.length >= 1 && productsPerLine !== 2 ? (
                  <p className={s.siblings}>
                    {t('product:siblingSelector.colors', {
                      nbr: siblings.length + 1,
                    })}
                  </p>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
      </Link>
      {productsPerLine !== 2 ? (
        <button
          className={s.addToGiftList}
          aria-label={t('product:addToList.addToAList')}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleGiftList()
          }}
        >
          <Gift className={s.listIcon} size="1rem" fill="#FFF" aria-hidden />
        </button>
      ) : null}
      <button
        className={cn(s.addToWishList, {
          [s.enabled]: isInWishList && isUnavailable,
        })}
        aria-label={t('product:addToList.addToWishList')}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleWishList()
        }}
      >
        {layout === 'wishlist' && isInWishList ? (
          <>
            <Cross
              className={s.listIcon}
              width="1rem"
              height="1rem"
              stroke="#000"
              aria-hidden
            />
          </>
        ) : (
          <Heart
            className={s.listIcon}
            size="1rem"
            fill={isInWishList ? '#000' : '#FFF'}
            aria-hidden
          />
        )}
      </button>
      {([Screen.xs, Screen.sm, Screen.md].includes(screen) || isTablet) && (
        <div className={s.ctaAddContainer}>
          <button
            className={s.ctaAddToBasket}
            data-testid="ProductCard_ctaAddToBasket"
            aria-label={t('product:addToShoppingBag')}
            onClick={(e) => {
              e.preventDefault()
              setIsDropDownOpen(true)
            }}
          >
            {t('product:add')}
          </button>
        </div>
      )}
      {siblings?.length > 1 && productsPerLine === 2 ? (
        <p className={s.siblings}>
          +
          {t('product:siblingSelector.colors', {
            nbr: siblings.length,
          })}
        </p>
      ) : null}
      {isOnDemand && productsPerLine === 2 ? (
        <p className={s.onDemand}>
          {t('product:delivery.isSalableOutOfStock', {
            from: Math.floor(stocks?.availability?.from / 7),
            to: Math.ceil(stocks?.availability?.to / 7),
          })}
        </p>
      ) : null}
      {isDropdownOpen && (
        <Portal>
          <DropdownQuickBuy
            declinations={declinations}
            stocks={stocks}
            prices={formattedObjPrices(prices, locale)}
            setIsDropDownOpen={setIsDropDownOpen}
            displayAddToBasketMsg={displayAddToBasketMsg}
            layout={layout}
          />
        </Portal>
      )}
      <GiftListModal
        isOpen={isGiftlistOpen}
        onClose={closeGiftListModal}
        product={product}
        selectedSize={declinations?.length === 1 ? declinations?.[0]?.sku : ''}
        eventName="/giftlist/add/page_listing"
      />
    </div>
  )
}

export default ProductCard
