import { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Arrow } from 'components/ui'

import { AVAILABLE_LANGUAGES, LOCALES } from '@constants'

import cn from 'classnames'
import s from './LocaleSelector.module.css'

export interface LocaleSelectorProps {
  className?: string
  alternates: Array<Alternate>
}

const hasAnAlternate = ({
  locale,
  alternates,
}: {
  locale: string
  alternates?: Array<Alternate>
}) => {
  return alternates?.filter((alternate) => alternate.hrefLang === locale)?.[0]
}

const handleSelectValue = ({
  e,
  asPath,
  alternates,
}: {
  e
  asPath?: string
  alternates?: Array<Alternate>
}) => {
  if (typeof window === 'undefined') return null

  const alternateTarget = hasAnAlternate({ locale: e.target.value, alternates })
  const pathname = asPath || ''
  window.location.href =
    alternateTarget?.href || '/' + e.target.value + pathname
}

const LocaleSelector: FC<LocaleSelectorProps> = ({ className, alternates }) => {
  const { t } = useTranslation()
  const { locale, asPath } = useRouter()

  const isTranslatableLoaded =
    (typeof window !== 'undefined' && window.Translatable?.initialized) || false

  return (
    <div className={cn(s.selectorContainer, className)}>
      <div
        className={s.valueSelect}
        aria-label={t('layout:header.language.ariaLanguage', {
          language: AVAILABLE_LANGUAGES[locale],
        })}
      >
        <span className={s.value}>{AVAILABLE_LANGUAGES[locale]}</span>
        <Arrow
          width="8"
          height="8"
          stroke="white"
          className={s.arrowDown}
          aria-hidden
        />
      </div>
      <select
        className={s.selectField}
        onChange={(e) => handleSelectValue({ e, asPath, alternates })}
        value={
          isTranslatableLoaded ? `${window.Translatable.currentLang}` : locale
        }
        aria-label={t('layout:header.language.ariaLanguage', {
          language: AVAILABLE_LANGUAGES[locale],
        })}
      >
        {LOCALES?.map((locale) => (
          <option key={locale.id} value={locale.locale}>
            {AVAILABLE_LANGUAGES[locale.locale]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default LocaleSelector
