import React, { FC, FocusEvent, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import Link from '@components/ui/Link'

import ProductCard from '@components/product/ProductCard'

import productUrl from '@utils/url/product'

import cn from 'classnames'
import s from './ShopTheLook1.module.css'

export interface ShopTheLookProps {
  lookPoint: {
    positionX: number
    positionY: number
    productId: number
  }
  product: ProductListItem
  linkOnly: boolean
}

const ShopTheLook: FC<ShopTheLookProps> = ({
  lookPoint,
  product,
  linkOnly,
}) => {
  const { locale } = useRouter()
  const [isExpand, setIsExpand] = useState<boolean>(false)

  const onBlur = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      if (
        !e.relatedTarget ||
        !e.currentTarget.contains(e.relatedTarget as Element)
      ) {
        setIsExpand(false)
      }
    },
    [setIsExpand]
  )

  return (
    <div className={s.content}>
      {linkOnly ? (
        <div
          className={s.icon}
          style={{
            top: lookPoint.positionY + '%',
            left: lookPoint.positionX + '%',
          }}
        >
          <Link
            href={
              productUrl({
                attachColor: product.attachColor,
                brand: product.brand,
                color: product.color,
                id: product.id,
                name: product.name,
                locale: locale,
              }) || ''
            }
            next
          >
            <span>+</span>
          </Link>
        </div>
      ) : (
        <>
          <div
            className={s.icon}
            style={{
              top: lookPoint.positionY + '%',
              left: lookPoint.positionX + '%',
            }}
          >
            <span>+</span>
          </div>
          <div
            className={cn(s.lookPoint, { [s.expand]: isExpand })}
            style={{
              top: lookPoint.positionY + '%',
              left: lookPoint.positionX + '%',
            }}
            onMouseEnter={() => setIsExpand(true)}
            onMouseLeave={() => setIsExpand(false)}
            onFocus={() => setIsExpand(true)}
            onBlur={onBlur}
            aria-describedby={`shoTheLookCard-${lookPoint.productId}`}
            tabIndex={0}
          >
            <div
              role="tooltip"
              id={`shoTheLookCard-${lookPoint.productId}`}
              className={s.card}
            >
              <ProductCard product={product} layout="shopTheLook" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ShopTheLook
