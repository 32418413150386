import qs from 'query-string'
import { normalizeString } from '../string'

export const initFormatedBrands = (brands: Array<Brand>): FormatedBrands => {
  const compareFormatedBrands = (a: string, b: string): number => {
    const isLetterRegex = /[A-Z]/i

    if (a.match(isLetterRegex) && b.match(isLetterRegex)) {
      if (a > b) return 1
      return -1
    }
    if (a.match(isLetterRegex) || b.match(isLetterRegex)) {
      return a.match(isLetterRegex) ? -1 : 1
    }
    if (a === '...') return 1
    if (b === '...') return -1
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }

  const sortFormatedBrands = (
    formatedBrands: FormatedBrands
  ): FormatedBrands => {
    return Object.keys(formatedBrands)
      .sort(compareFormatedBrands)
      .reduce((result: FormatedBrands, key: string) => {
        result[key] = formatedBrands[key].sort((a: Brand, b: Brand) => {
          const titleA = normalizeString(
            a.name.toLowerCase(),
            key === '...' ? false : true,
            true
          )
          const titleB = normalizeString(
            b.name.toLowerCase(),
            key === '...' ? false : true,
            true
          )
          if (titleA < titleB) return -1
          if (titleA > titleB) return 1
          return 0
        })

        return result
      }, {})
  }

  const formatedBrands: FormatedBrands = {}
  const isSpecialChar = /[!@#$%^&*(),.?":{}|<>]/g
  for (let i = 0; i < brands?.length; i++) {
    let letter = normalizeString(brands[i].name, false, true)[0].toUpperCase()

    if (letter.match(isSpecialChar) || !!Number(letter)) {
      formatedBrands['...']?.length
        ? formatedBrands['...'].push(brands[i])
        : (formatedBrands['...'] = [brands[i]])
    } else {
      formatedBrands[letter]?.length
        ? formatedBrands[letter].push(brands[i])
        : (formatedBrands[letter] = [brands[i]])
    }
  }
  return sortFormatedBrands(formatedBrands)
}

export const filterFormatedBrands = (
  formatedBrands: FormatedBrands,
  search: string
): FormatedBrands => {
  const searchBrandResults: FormatedBrands = { ...formatedBrands }
  for (let letter in searchBrandResults) {
    searchBrandResults[letter] = searchBrandResults[letter].filter((brand) => {
      const title = brand.name.toLowerCase()
      const upperSearch = search.toLowerCase()
      return (
        title.includes(upperSearch) ||
        title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(upperSearch)
      )
    })
  }
  return searchBrandResults
}

export const filterQueryParams = (queryParams: {
  [key: string]: string
}): { [key: string]: string } => {
  const allowedKeys = ['section', 'person', 'type']
  return Object.keys(queryParams)
    .filter((key) => allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = queryParams[key]
      return obj
    }, {})
}

export const objIsEqual = (
  obj1: { [key: string]: string },
  obj2: { [key: string]: string }
): boolean => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) return false
  for (let i = 0; i < keys1.length; i++) {
    if (!keys2.includes(keys1[i])) return false
    if (obj1[keys1[i]] !== obj2[keys1[i]]) return false
  }
  return true
}

export const getQueryFilter = (filter: BrandFilter | null): string => {
  if (filter?.parameters) {
    return qs.stringify(filter?.parameters, {
      arrayFormat: 'bracket',
    })
  }
  return ''
}

export const getRowCount = (brands: Brand[], screen: string) => {
  let rowCount = brands.length
  if (screen === 'xl' || screen === '2xl') {
    if (brands.length > 30) rowCount = Math.ceil(brands.length / 5)
    else if (brands.length > 6) rowCount = 6
  } else if (screen === 'lg' || screen === 'md') {
    if (brands.length > 18) rowCount = Math.ceil(brands.length / 3)
    else if (brands.length > 6) rowCount = 6
  }
  return rowCount
}
