export const getDidomiConsent = ({ vendor }: { vendor: string }) => {
  if (typeof window === 'undefined' || !vendor?.length) return false
  try {
    return window.Didomi?.getUserStatusForVendorAndLinkedPurposes(vendor)
  } catch (err) {
    console.error(
      'Error getUserStatusForVendorAndLinkedPurposes, details: ',
      err
    )
  }
  return false
}

export const getPageTypeCatalog = ({ urlp, brandPage }) => {
  if (typeof window === 'undefined') return ''
  const _urlp = urlp?.substring(2)
  if (_urlp === '/error') return 'erreur'
  const path = window.location.pathname
  if (path.length === 3) return 'accueil'
  if (path.indexOf('/product') !== -1) return 'page produit'
  if (path.indexOf('/search') !== -1) return 'recherche'
  if (
    path.indexOf('/magazine') !== -1 ||
    path.indexOf('/page/inspiration') !== -1
  )
    return 'journal'
  if (path.indexOf('/wishlist') !== -1) return 'wishlist'
  if (path.indexOf('/personal-shopping') !== -1) return 'personal shopper'
  if (
    path.indexOf('/career') !== -1 ||
    path.indexOf('/smallable-and-me') !== -1
  )
    return 'landing'
  if (brandPage.some((b) => path.indexOf(b) !== -1)) return 'marque'
  if (window._oEa?.app.includes('env')) return 'catalogue'
  return ''
}

export const getPageTypeTransac = ({ urlp }) => {
  if (typeof window === 'undefined') return ''
  const _urlp = urlp?.substring(2)
  if (_urlp === '/error') return 'erreur'
  const path = window.location.pathname
  if (path.indexOf('/productlist') !== -1) return 'productlist'
  if (path.indexOf('/basket') !== -1) return 'panier'
  if (path.indexOf('/checkout/information') !== -1) return 'adresse'
  if (path.indexOf('/checkout/shipping') !== -1) return 'livraison'
  if (path.indexOf('/checkout/payment') !== -1) return 'paiement'
  if (path.indexOf('/payment-checkout') !== -1) return 'paiement'
  if (path.indexOf('/payment-confirm') !== -1) return 'confirmation'
  if (path.indexOf('/payment') !== -1) return 'livraison'
  if (path.indexOf('/loyalty') !== -1) return 'fid'
  if (path.indexOf('/account') !== -1) return 'compte'
  if (path.indexOf('/login') !== -1) return 'login'
  if (path.indexOf('/register') !== -1) return 'register'
  return ''
}

export const getEventName = (urlp: string) => {
  if (!urlp?.length) return null
  const _urlp = urlp.substring(2)
  switch (_urlp) {
    case '/basket/add/page_listing':
      return '?cs-popin-achat-rapide'
    case '/basket/add/page_product':
      return '?cs-ajout-panier'
    case '/wishlist/add/page_listing':
      return '?cs-quick-add-to-wishlist'
    case '/wishlist/add/page_product':
      return '?cs-add-to-wishlist'
    case '/giftlist/add/page_listing':
      return '?cs-quick-add-to-list'
    case '/giftlist/add/page_product':
      return '?cs-add-to-list'
    case '/basket/add/shop_the_look':
      return 'cs-quick-add-shop-the-look'
    default:
      return null
  }
}
