import React, { FC, useState, useRef, useEffect } from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useSwipeable } from 'react-swipeable'

import { ArrowRight } from '@components/icons'

import { isMobileOnly, isTablet, isChrome, isIOS } from 'react-device-detect'

import cn from 'classnames'
import s from './CardSlider.module.css'

export interface CardSliderProps {
  medias: Array<MediaCard>
  productName: string
  productsPerLine: number
  quickBuyOpen?: boolean
  priority?: boolean
  disableSwipe?: boolean
}

const loopSliderIndex = (current = 0, total = 1) => (current + total) % total

const CardSlider: FC<CardSliderProps> = ({
  medias,
  productName,
  productsPerLine,
  priority = false,
  quickBuyOpen = false,
  disableSwipe = false,
}) => {
  const { t } = useTranslation()

  const hasBeenHovered = useRef<boolean>(false)
  const previousSlide = useRef<number>(null)
  const direction = useRef<string>('init')
  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const [altAnim, setAltAnim] = useState<boolean>(false)
  const [forceControls, setForceControls] = useState<boolean>(disableSwipe)

  const handleSliderIndex = (
    current = 0,
    total = 1,
    inc = 1,
    dir = 'forward'
  ) => {
    previousSlide.current = current
    direction.current = dir
    setSliderIndex(loopSliderIndex(current + inc, total))
  }

  const swipeHandlers =
    isMobileOnly && !forceControls
      ? useSwipeable({
          onSwipedLeft: () =>
            handleSliderIndex(sliderIndex, medias?.length, 1, 'forward'),
          onSwipedRight: () =>
            handleSliderIndex(sliderIndex, medias?.length, -1, 'backward'),
          swipeDuration: 500,
          preventScrollOnSwipe: true,
          trackMouse: true,
        })
      : null

  useEffect(() => {
    if (quickBuyOpen && !hasBeenHovered.current) {
      hasBeenHovered.current = true
      let timer = setTimeout(() => {
        handleSliderIndex(sliderIndex, medias?.length, 1, 'forward')
      }, 500)

      return () => timer && clearTimeout(timer)
    }
  }, [quickBuyOpen])

  useEffect(() => {
    if (isIOS && isChrome) setAltAnim(true)
    if (isTablet || disableSwipe) setForceControls(true)
  }, [disableSwipe])

  return (
    <>
      <div className={s.container}>
        <div
          className={cn(
            s.slider,
            { [s.half]: productsPerLine === 2 },
            { [s.altAnim]: altAnim }
          )}
          {...swipeHandlers}
        >
          {sliderIndex !== undefined &&
            medias?.map((img, i) => (
              <div
                key={`ProductCard-img-${img?.['648x648q80']}-${i}`}
                className={cn(
                  s.slide,
                  { [s.current]: i === sliderIndex },
                  {
                    [s.currentForward]:
                      i === sliderIndex && direction.current === 'forward',
                  },
                  {
                    [s.currentBackward]:
                      i === sliderIndex && direction.current === 'backward',
                  },
                  {
                    [s.previousForward]:
                      i === previousSlide.current &&
                      direction.current === 'forward',
                  },
                  {
                    [s.previousBackward]:
                      i === previousSlide.current &&
                      direction.current === 'backward',
                  }
                )}
              >
                <Image
                  src={`https:${img?.['648x648q80']}`}
                  className={s.img}
                  alt={productName}
                  aria-label={productName}
                  priority={priority && i === 0}
                  layout="responsive"
                  width="100"
                  height="100"
                  unoptimized
                />
              </div>
            ))}
        </div>
        {!isMobileOnly || forceControls ? (
          <div
            className={cn({ [s.visible]: forceControls || quickBuyOpen })}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <button
              className={cn(s.controls, s.left)}
              onClick={() =>
                handleSliderIndex(sliderIndex, medias?.length, -1, 'backward')
              }
              onMouseDown={(e) => e.preventDefault()}
              aria-label={t('block:slideBlock.ariaSlidePrev')}
            >
              <div className={cn(s.arrow, s.arrowLeft)}>
                <ArrowRight strokeW="1" />
              </div>
            </button>
            <button
              className={cn(s.controls, s.right)}
              onClick={() =>
                handleSliderIndex(sliderIndex, medias?.length, 1, 'forward')
              }
              onMouseDown={(e) => e.preventDefault()}
              aria-label={t('block:slideBlock.ariaSlideNext')}
            >
              <div className={cn(s.arrow, s.arrowRight)}>
                <ArrowRight strokeW="1" />
              </div>
            </button>
          </div>
        ) : null}
      </div>
      <div className={s.dots}>
        {medias?.map((img, index) => (
          <span
            key={`dots-key-${img['648x648q80']}-${index}`}
            className={cn(s.dot, { [s.enable]: index === sliderIndex })}
          />
        ))}
      </div>
    </>
  )
}

export default CardSlider
