import { gql } from '@apollo/client'
import client from '@lib/apolloClient'

export const fetchBrands = async ({
  locale,
  countryCode,
  queryParams,
  headers = null,
}: {
  countryCode: string
  queryParams: string
  locale: string
  headers?: Headers
}): Promise<Brand[]> => {
  console.log(`Get Brands`)
  const { data, errors } = await client.query<{ brands: Brand[] }>({
    query: gql`
      query Brands(
        $locale: Locale
        $countryCode: CountryCode
        $queryParams: String
      ) {
        brands(
          locale: $locale
          countryCode: $countryCode
          queryParams: $queryParams
        ) {
          name
          description
          isNew
          slug
          media {
            id
            alt
            formats {
              origin
              large
              medium
              small
              thumbnail
            }
          }
        }
      }
    `,
    variables: {
      countryCode: countryCode?.toUpperCase(),
      locale,
      queryParams,
    },
    context: {
      headers: {
        ...headers,
      },
    },
  })

  if (errors?.length || !data?.brands)
    throw { status: 400, message: errors?.[0]?.message || 'Page not found' }

  return data.brands
}
