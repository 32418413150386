import { FC } from 'react'
import Script from 'next/script'
import { useUI } from '@components/ui/context'

declare global {
  interface Window {
    EA_collector: (data_layer: (string | number)[]) => void
    _oEa: {
      app: string[]
    }
    data_layer: (string | number)[]
    CS_CONF: {
      integrations: Array<string | number>
    }
  }
}

const Eulerian: FC = () => {
  const EULERIAN_URL = process.env.NEXT_PUBLIC_EULERIAN_URL || ''
  const { setisEALoaded } = useUI()

  if (!EULERIAN_URL?.length) return null

  return (
    <Script
      src={`${EULERIAN_URL}/ea.js`}
      strategy="lazyOnload"
      onLoad={() => {
        setisEALoaded()
      }}
    />
  )
}

export default Eulerian
