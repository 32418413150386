import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import { Link, Arrow, Container } from '@components/ui'
import NavbarColumn from '../NavbarColumn'

import ClickOutside from '@lib/click-outside'

import cn from 'classnames'
import s from './NavbarItem.module.css'

interface Props {
  collapse?: () => void
  expand?: () => void
  isExpanded: boolean
  item: NavigationItem
  withStickyTop: boolean
}

const NavbarItem: FC<Props> = ({
  collapse = () => {},
  expand = () => {},
  isExpanded,
  item,
  withStickyTop,
}) => {
  const { t } = useTranslation()
  const { closeNavigation } = useUI()

  const [isOpened, setIsOpened] = useState(false)
  const [expandedItem, setExpandedItem] = useState<string | undefined>(
    undefined
  )

  const isExpandable = item.children?.length && !item?.attribute

  useEffect(() => {
    if (!isExpanded) setExpandedItem(undefined)
  }, [isExpanded, setExpandedItem])

  useEffect(() => {
    if (isOpened) setIsOpened(false)
  }, [closeNavigation])

  if (!item) return null

  return (
    <li
      className={cn(s.item, { [s.opened]: isOpened, [s.expanded]: isExpanded })}
      data-cs-override-id={`navbarItem-${item.title}`}
      onMouseEnter={() => setIsOpened(true)}
      onMouseLeave={() => setIsOpened(false)}
    >
      <div className={s.buttonContainer}>
        <Link
          next
          className={s.link}
          href={item.url || ''}
          rel={item.rel || ''}
          style={item.color ? { color: item.color } : {}}
          onTouchEnd={(e) => {
            if (isExpandable) {
              e.preventDefault()
              setIsOpened(!isOpened)
            }
          }}
        >
          <strong className={s.title}>{item.title || ''}</strong>
          {isExpandable ? <Arrow className={s.arrow} /> : null}
        </Link>

        {isExpandable ? (
          <button
            className={s.button}
            aria-label={t(
              isExpanded
                ? 'layout:navigation.ariaCollapseMenu'
                : 'layout:navigation.ariaExpandMenu',
              { title: item.title || '' }
            )}
            onClick={() => {
              isExpanded ? collapse() : expand()
            }}
          />
        ) : null}
      </div>

      {item.children?.length ? (
        <ClickOutside active={isOpened} onClick={() => setIsOpened(false)}>
          <div
            className={cn(s.container, {
              [s.stickyTop]: withStickyTop,
              [s.isExpandable]: isExpandable,
            })}
          >
            <Container className={s.cols}>
              {item.children?.map((navItem: NavigationItem, index: number) => (
                <NavbarColumn
                  key={index}
                  expandParent={() => {
                    if (!isExpanded && isExpandable) {
                      expand()
                    }
                  }}
                  expandedItem={expandedItem}
                  imgClassName={s.image}
                  isParentItemExpanded={isExpanded}
                  item={navItem}
                  setExpandedItem={setExpandedItem}
                />
              ))}
            </Container>
          </div>
        </ClickOutside>
      ) : null}
    </li>
  )
}

export default NavbarItem
