import { FC } from 'react'

import cn from 'classnames'
import s from './RichText.module.css'
export interface RichTextProps {
  content: string
  className?: string
  tag?: keyof JSX.IntrinsicElements
}

const RichText: FC<RichTextProps> = ({
  content,
  className,
  tag: Wrapper = 'div',
}) => (
  <Wrapper
    className={cn('rich-text', s.container, className)}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export default RichText
