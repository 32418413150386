import { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'

const scrollRestoration = () => {
  const router = useRouter()
  const shouldRestoreScroll = useRef<boolean>(false)

  const saveScrollPosition = () => {
    if (shouldRestoreScroll.current) return null

    let idx = history.state.idx
    let scrollHistory =
      JSON.parse(sessionStorage.getItem('_next_scroll_position')) || []

    scrollHistory = scrollHistory.filter((i) => i.id !== idx)
    scrollHistory.push({ id: idx, position: window.scrollY })

    sessionStorage.setItem(
      '_next_scroll_position',
      JSON.stringify(scrollHistory)
    )
  }

  const restoreScrollPosition = () => {
    if (!shouldRestoreScroll.current) return null

    restoreScrollManually()

    shouldRestoreScroll.current = false
  }

  useEffect(() => {
    sessionStorage.removeItem('_next_scroll_position')
  }, [])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'

    router.events.on('routeChangeStart', saveScrollPosition)
    router.events.on('routeChangeComplete', restoreScrollPosition)
    router.beforePopState(() => {
      shouldRestoreScroll.current = true
      return true
    })

    return () => {
      router.events.off('routeChangeStart', saveScrollPosition)
      router.events.off('routeChangeComplete', restoreScrollPosition)
      router.beforePopState(() => true)
    }
  }, [router])
}

export default scrollRestoration

export const restoreScrollManually = () => {
  let idx = history.state.idx
  let scrollHistory = JSON.parse(
    sessionStorage.getItem('_next_scroll_position')
  )

  if (scrollHistory?.length) {
    const currentIdInHistory = scrollHistory.filter((item) => item.id === idx)
    window.scrollTo({
      top: currentIdInHistory[0]?.position,
    })
  }
}
