export const PlayBar = ({ width = '368', height = '40', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 368 40"
    fill="none"
    {...props}
  >
    <rect opacity="0.65" width="368" height="40" fill="#1F1F1F" />
    <path
      d="M344 14L340.111 17.4286H337V22.5714H340.111L344 26V14Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      opacity="0.4"
      x="0.5"
      y="0.5"
      width="367"
      height="39"
      stroke="#CBCBCB"
    />
    <path
      d="M15.334 13.3335L26.0007 20.0002L15.334 26.6668V13.3335Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="50" cy="20" r="6" fill="white" />
    <path
      opacity="0.5"
      d="M60 20L320 20"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
