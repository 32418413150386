import React, { FC, ReactChild } from 'react'

import { motion, Variant } from 'framer-motion'
import cn from 'classnames'

import s from './AnimateMotion.module.css'

interface AnimateMotionProps {
  children: ReactChild
  className?: string
  initial?: boolean
  from?: Variant
  to?: Variant
  duration?: number
}

const AnimateMotion: FC<AnimateMotionProps> = ({
  children,
  className,
  initial = true,
  from = { height: 'auto' },
  to = { height: 0 },
  duration = 0.4,
}) => {
  return (
    <motion.div
      className={cn(s.container, className)}
      initial={initial ? 'from' : 'to'}
      animate={initial ? 'from' : 'to'}
      inherit={false}
      variants={{ from, to }}
      transition={{ ease: 'easeOut', duration }}
    >
      {children}
    </motion.div>
  )
}

export default AnimateMotion
