import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'

import { getDidomiConsent } from '@utils/data'

interface TagPageProductProps {
  name: string
  productId: number
  price: number
  brand: string
  color: string
  category: string
}

const TagPageProduct: FC<TagPageProductProps> = ({
  name,
  productId,
  price,
  brand,
  color,
  category,
}) => {
  const GTM_ACCOUNT = process.env.NEXT_PUBLIC_GTM_ACCOUNT || ''
  const { asPath } = useRouter() || { asPath: null, locale: null }

  useEffect(() => {
    if (
      window?.dataLayer &&
      GTM_ACCOUNT?.length &&
      getDidomiConsent({ vendor: 'google' })
    ) {
      window.dataLayer.push({
        page: 'page produit',
      })
      if (productId) {
        const productTag = {
          event: 'productDetailView',
          ecommerce: {
            detail: {
              products: [
                {
                  id: `${productId}`,
                  brand,
                  name,
                  price: `${price}`,
                  color,
                  category,
                },
              ],
            },
          },
        }
        window.dataLayer.push(productTag)
      }
    }
  }, [asPath])

  return null
}

export default TagPageProduct
