import React from 'react'

import { normalize } from 'path'
import { Check } from '@components/icons'

import {
  useToggleRefinement,
  UseToggleRefinementProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './Refinement.module.css'

interface RefinementToggleCheckboxProps {
  title: string
}

export function RefinementToggleCheckbox(
  props: UseToggleRefinementProps & RefinementToggleCheckboxProps
) {
  const { title } = props
  const { refine, value } = useToggleRefinement(props)

  const titleNormalize = normalize(value.name)

  return (
    <ul className={cn(s.filtersList, s.buttonItemContainer)}>
      <React.Fragment key={`Filters-item`}>
        <li
          className={cn(s.containerCheckbox, {
            [s.disabled]: !value.count,
          })}
        >
          <div className={s.contentCheckbox}>
            <input
              id={`checkbox_${titleNormalize}`}
              className={cn(s.boxCheckbox, {
                [s.checked]: value.isRefined,
              })}
              type="checkbox"
              disabled={!value.count && !value.isRefined}
              value={String(value.isRefined)}
              checked={value.isRefined}
              onChange={() => refine({ isRefined: value.isRefined })}
            />
            <label
              htmlFor={`checkbox_${titleNormalize}`}
              className={s.titleCheckbox}
            >
              {value.isRefined && <Check aria-hidden />}
              {title}
              {value.count && !value.isRefined ? (
                <span className={s.totalCheckbox}>{value.count}</span>
              ) : null}
            </label>
          </div>
        </li>
      </React.Fragment>
    </ul>
  )
}
export default RefinementToggleCheckbox
