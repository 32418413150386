import React, { FC, useRef, useEffect } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

import { NotFound } from '@components/common'
import { TagGeneric } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'

import { fetchProduct } from '@mw/products'

export interface ProductNotFoundProps {
  productId?: string
}

const ProductNotFound: FC<ProductNotFoundProps> = ({ productId }) => {
  const { locale } = useRouter()
  const { deliveryCountryCode } = useUserSettings()
  const formerDeliveryCountryCode = useRef(deliveryCountryCode)

  useEffect(() => {
    ;(async () => {
      if (!productId) return null
      if (deliveryCountryCode !== formerDeliveryCountryCode.current) {
        formerDeliveryCountryCode.current = deliveryCountryCode
        try {
          const apiProduct = await fetchProduct({
            id: productId,
            locale: locale as string,
          })
          if (apiProduct.prices && window) window.location.reload()
        } catch (err) {
          console.error(err)
        }
      }
    })()
  }, [deliveryCountryCode])

  return (
    <>
      <NextSeo noindex nofollow />
      <TagGeneric isError />
      <TagPageCategory pageCategory="erreur" />
      <NotFound />
    </>
  )
}

export default ProductNotFound
