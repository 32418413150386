const CoffeeTalk = ({ ...props }) => (
  <svg width="55" height="47" viewBox="0 0 55 47" fill="none" {...props}>
    <mask id="path-1-inside-1_180_577" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21529 41.67C2.93909 39.8953 0.000310898 36.2099 0.000310898 31.9513C0.000310898 25.9613 5.81442 21.1055 12.9865 21.1055C20.1585 21.1055 25.9727 25.9613 25.9727 31.9513C25.9727 37.9413 20.1585 42.7972 12.9865 42.7972C12.6711 42.7972 12.3583 42.7878 12.0485 42.7693L8.07318 46.844C7.76003 47.165 7.21529 46.9433 7.21529 46.4948V41.67Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21529 41.67C2.93909 39.8953 0.000310898 36.2099 0.000310898 31.9513C0.000310898 25.9613 5.81442 21.1055 12.9865 21.1055C20.1585 21.1055 25.9727 25.9613 25.9727 31.9513C25.9727 37.9413 20.1585 42.7972 12.9865 42.7972C12.6711 42.7972 12.3583 42.7878 12.0485 42.7693L8.07318 46.844C7.76003 47.165 7.21529 46.9433 7.21529 46.4948V41.67Z"
      fill="#292929"
    />
    <path
      d="M7.21529 41.67L7.5986 40.7463C7.97194 40.9013 8.21529 41.2657 8.21529 41.67H7.21529ZM12.0485 42.7693L11.3327 42.071C11.5354 41.8633 11.8183 41.7538 12.108 41.7711L12.0485 42.7693ZM8.07318 46.844L8.78896 47.5423H8.78896L8.07318 46.844ZM1.00031 31.9513C1.00031 35.7145 3.60362 39.0884 7.5986 40.7463L6.83198 42.5936C2.27455 40.7022 -0.999689 36.7054 -0.999689 31.9513H1.00031ZM12.9865 22.1055C6.19048 22.1055 1.00031 26.6747 1.00031 31.9513H-0.999689C-0.999689 25.2479 5.43836 20.1055 12.9865 20.1055V22.1055ZM24.9727 31.9513C24.9727 26.6747 19.7825 22.1055 12.9865 22.1055V20.1055C20.5346 20.1055 26.9727 25.2479 26.9727 31.9513H24.9727ZM12.9865 41.7972C19.7825 41.7972 24.9727 37.2279 24.9727 31.9513H26.9727C26.9727 38.6547 20.5346 43.7972 12.9865 43.7972V41.7972ZM12.108 41.7711C12.398 41.7884 12.6909 41.7972 12.9865 41.7972V43.7972C12.6512 43.7972 12.3186 43.7872 11.989 43.7675L12.108 41.7711ZM7.3574 46.1457L11.3327 42.071L12.7642 43.4676L8.78896 47.5423L7.3574 46.1457ZM8.21529 46.4948C8.21529 46.0464 7.67055 45.8247 7.3574 46.1457L8.78896 47.5423C7.84951 48.5052 6.21529 47.8401 6.21529 46.4948H8.21529ZM8.21529 41.67V46.4948H6.21529V41.67H8.21529Z"
      fill="#292929"
      mask="url(#path-1-inside-1_180_577)"
    />
    <mask id="path-3-inside-2_180_577" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5413 32.6612C50.3328 29.8425 55.0002 23.9894 55.0002 17.2258C55.0002 7.71224 45.766 0 34.3751 0C22.9842 0 13.75 7.71224 13.75 17.2258C13.75 26.7393 22.9842 34.4515 34.3751 34.4515C34.8741 34.4515 35.3689 34.4367 35.859 34.4076L41.8256 40.5234C42.4519 41.1654 43.5413 40.7219 43.5413 39.8251V32.6612Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5413 32.6612C50.3328 29.8425 55.0002 23.9894 55.0002 17.2258C55.0002 7.71224 45.766 0 34.3751 0C22.9842 0 13.75 7.71224 13.75 17.2258C13.75 26.7393 22.9842 34.4515 34.3751 34.4515C34.8741 34.4515 35.3689 34.4367 35.859 34.4076L41.8256 40.5234C42.4519 41.1654 43.5413 40.7219 43.5413 39.8251V32.6612Z"
      fill="white"
    />
    <path
      d="M43.5413 32.6612L43.158 31.7375C42.7847 31.8925 42.5413 32.257 42.5413 32.6612H43.5413ZM35.859 34.4076L36.5747 33.7093C36.3721 33.5016 36.0893 33.3922 35.7997 33.4094L35.859 34.4076ZM41.8256 40.5234L42.5413 39.8251L41.8256 40.5234ZM54.0002 17.2258C54.0002 23.4939 49.6683 29.0356 43.158 31.7375L43.9247 33.5848C50.9974 30.6494 56.0002 24.4848 56.0002 17.2258H54.0002ZM34.3751 1C45.39 1 54.0002 8.42563 54.0002 17.2258H56.0002C56.0002 6.99885 46.1421 -1 34.3751 -1V1ZM14.75 17.2258C14.75 8.42563 23.3602 1 34.3751 1V-1C22.6081 -1 12.75 6.99885 12.75 17.2258H14.75ZM34.3751 33.4515C23.3602 33.4515 14.75 26.0259 14.75 17.2258H12.75C12.75 27.4527 22.6081 35.4515 34.3751 35.4515V33.4515ZM35.7997 33.4094C35.3293 33.4373 34.8543 33.4515 34.3751 33.4515V35.4515C34.8938 35.4515 35.4084 35.4361 35.9182 35.4059L35.7997 33.4094ZM42.5413 39.8251L36.5747 33.7093L35.1432 35.106L41.1098 41.2217L42.5413 39.8251ZM42.5413 39.8251V39.8251L41.1098 41.2217C42.3624 42.5057 44.5413 41.6188 44.5413 39.8251H42.5413ZM42.5413 32.6612V39.8251H44.5413V32.6612H42.5413Z"
      fill="#292929"
      mask="url(#path-3-inside-2_180_577)"
    />
    <rect
      x="26.1562"
      y="11.0146"
      width="16.383"
      height="1.17192"
      rx="0.585961"
      fill="#292929"
    />
    <rect
      x="26.1572"
      y="22.0234"
      width="16.383"
      height="1.17192"
      rx="0.585961"
      fill="#292929"
    />
    <rect
      x="20.958"
      y="16.5186"
      width="25.7448"
      height="1.17192"
      rx="0.585961"
      fill="#292929"
    />
  </svg>
)

export default CoffeeTalk
