export const removeTags = (str: string) => {
  if (str === null || str === '') return ''
  else str = str.toString()
  return str.replace(/(<([^>]+)>)/gi, '')
}

export const shortenDescription = (str: string, maxLength: number) => {
  if (!str.length) return null
  let plainText = removeTags(str)
  let shortentText = plainText?.substr(0, maxLength)
  shortentText = shortentText?.substr(
    0,
    Math.min(shortentText.length, shortentText.lastIndexOf(' '))
  )
  return shortentText + '...' || str
}

export const subStringIsRepeat = (str: string, subStr: string) => {
  if (!str?.length || !subStr?.length) return null
  let firstIndex = str.indexOf(subStr)
  let result = firstIndex != str.lastIndexOf(subStr) && firstIndex != -1
  return result
}

export const subStringArray = (src, separator) => {
  if (!src?.length || !separator.length) return null
  return src?.split(separator).reduce((a, c) => {
    if (c.length) a.push(separator + c)
    return a
  }, [])
}

export const getValueFromHtmlText = (str: string, selector: string) => {
  if (!str?.length || !selector?.length) return ''
  const _html = str.replace(/(\r\n|\n|\r)/gm, '')
  const regex = new RegExp(
    `<(\\S+).*?class="(?:\\b${selector}\\b.*?|.*?\\b${selector}\\b)".*?>(.+?)<\\/\\1>`,
    'gm'
  )
  let m, r
  while ((m = regex.exec(_html)) !== null) {
    if (m.index === regex.lastIndex) regex.lastIndex++
    r = m[m.length - 1].replace(/<[^>]+>/g, '')
  }
  return r || ''
}

export const getTrendCategory = (name: string) => {
  if (!name?.length || name.indexOf('- ') === -1) return null
  return name.substring(name.indexOf('- ') + 1)?.toUpperCase()
}

export const replaceAt = (string, index, replacement) => {
  return (
    string.substr(0, index) +
    replacement +
    string.substr(index + replacement.length)
  )
}

export const normalizeString = (
  value: string,
  noSpecialChar?: boolean,
  noSpace?: boolean
) => {
  let result = value?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || ''
  if (noSpecialChar) {
    result = result.replace(/[^a-zA-Z0-9 ]/g, '')
  }
  if (noSpace) {
    result = result.replace(/ /g, '')
  }
  return result
}
