import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'

import { getItemsName } from '@utils/basket/items'
import PriceLine from './PriceLine'

import cn from 'classnames'
import s from './ProductModalBlock.module.css'
export interface ProductModalBlockProps {
  className?: string
  product: Product
  selectedSize?: string
  withProductSize?: boolean
}

const ProductModalBlock: FC<ProductModalBlockProps> = ({
  className,
  product,
  selectedSize,
  withProductSize = false,
}) => {
  const { t } = useTranslation()

  const imgSrc =
    product?.medias[0]?.thickbox || product?.medias[0]?.['324x324q80']
  const sibling = (product?.siblings || []).find(
    (sibling) => sibling?.id === product?.id
  )
  const productName = getItemsName({
    attachColor: sibling?.attachColor,
    name: product?.name,
    color: sibling?.color,
  })
  const selectedDeclination =
    withProductSize &&
    (product?.declinations || []).find(({ sku }) => sku === selectedSize)

  return (
    <div className={cn(s.container, className)}>
      {imgSrc && (
        <div className={s.imgContainer}>
          <Image
            className={s.img}
            src={imgSrc}
            alt={t('product:productMediaSlider.altProductImage', {
              productName: productName,
              id: product?.id,
            })}
            aria-label={t('product:productMediaSlider.ariaProductImage', {
              productName: productName,
              id: product?.id,
            })}
            width={94}
            height={94}
            quality="85"
            unoptimized
          />
        </div>
      )}
      <div>
        {product?.tags?.isNew && (
          <p className={s.isNew}>{t('product:new.title')}</p>
        )}
        <p className={s.productBrand}>{product?.brand?.name}</p>
        <p className={s.productName}>{productName}</p>
        <PriceLine
          className={s.productPrice}
          product={product}
          selectedSize={selectedSize}
        />
        {selectedDeclination && (
          <p className={s.productSize}>{selectedDeclination?.size}</p>
        )}
      </div>
    </div>
  )
}

export default ProductModalBlock
