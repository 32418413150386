export const fetchLogin = async ({
  formData,
  anonymousToken,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_AUTH_API_URL || '',
  country,
}: {
  formData: {
    username?: string
    password?: string
    type?: string
    accessToken?: string
  }
  anonymousToken?: string
  apiUrl?: string
  country?: string
}) => {
  const dataToFetch = new FormData()
  for (let key in formData) {
    dataToFetch.append(key, formData[key])
  }
  const loginHeaders = new Headers()
  if (country?.length) loginHeaders.set('X-Frontend-Viewer-Country', country)
  if (anonymousToken?.length > 0)
    loginHeaders.set('X-ANONYMOUS-TOKEN', anonymousToken)
  const res = await fetch(`${apiUrl}/smallable/login`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: loginHeaders,
    body: dataToFetch,
  })
  const { data } = await res.json()
  return { data, status: res.status }
}

export const fetchResetPassword = async ({
  email,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_AUTH_API_URL || '',
}: {
  email: string
  apiUrl?: string
}) => {
  const res = await fetch(`${apiUrl}/smallable/reset-password`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
  const { data } = await res.json()
  return { data, status: res.status }
}
