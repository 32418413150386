import React, { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Star } from '@components/icons'

import s from './TrustComment.module.css'

interface TrustCommentProps {
  comments: DetailReviewComments
}

const TrustComment: FC<TrustCommentProps> = ({ comments }) => {
  const { locale } = useRouter()
  const { t } = useTranslation()

  if (!comments) return null

  return (
    <div className={s.comments}>
      <div className={s.row}>
        <p className={s.name}>{comments.consumer.displayName}</p>
        <div className={s.stars}>
          {Array.from(Array(5).keys()).map((i) => (
            <React.Fragment key={`comment-stars-key-${i}`}>
              <Star
                height="12"
                fill={
                  i + 1 <= Math.round(comments.stars) ? '#000' : 'transparent'
                }
              />
            </React.Fragment>
          ))}
        </div>
      </div>
      <p className={s.evaluated}>
        {t('product:review.evaluated')}&nbsp;
        {new Date(comments.createdAt)?.toLocaleDateString(locale)}
      </p>
      <p className={s.content}>{comments.content}</p>
    </div>
  )
}

export default TrustComment
