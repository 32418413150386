const Star = ({ ...props }) => (
  <svg viewBox="0 0 16 15" {...props}>
    <path
      d="M7.9987 1.3335L10.0587 5.50683L14.6654 6.18016L11.332 9.42683L12.1187 14.0135L7.9987 11.8468L3.8787 14.0135L4.66536 9.42683L1.33203 6.18016L5.9387 5.50683L7.9987 1.3335Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Star
