import React from 'react'
import useTranslation from 'next-translate/useTranslation'

import { ClearSearchRefinements } from '@components/common/Header/Search/Algolia/SearchBox'

import cn from 'classnames'
import s from './StatsResults.module.css'

interface NoResultsProps {
  query: string
  layout?: string
  focusInputSearch?: () => void
}

const NoResults = (props: NoResultsProps) => {
  const { t } = useTranslation()
  const { query, layout, focusInputSearch } = props

  return (
    <div className={cn(s.no_results, { [s.listing]: layout === 'listing' })}>
      <h3 className={s.oups}>{t('layout:notFound.title')}</h3>

      <p className={s.noResultFor}>
        {' '}
        {t('layout:header.search.noResultFor')}
        {'.'}
      </p>

      {query && <p className={s.query}>« {query} »</p>}

      <p className={s.notToWorry}>{t('layout:header.search.notToWorry')}</p>

      {focusInputSearch && (
        <ClearSearchRefinements focusInputSearch={focusInputSearch} />
      )}
    </div>
  )
}

export default NoResults
