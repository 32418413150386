import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import ProductCard from '@components/product/ProductCard'

import s from './ProductsList.module.css'

export interface ProductsListProps {
  products: ProductListItem[]
}

const ProductsList: FC<ProductsListProps> = ({ products }) => {
  const { t } = useTranslation()

  if (!products?.length) return null

  return (
    <section
      className={s.container}
      id="filterList-SimilarProduct"
      data-cs-override-id="filterList-SimilarProduct"
    >
      <div className={s.content}>
        <h2 className={s.title}>{t('product:relatedProducts.title')}</h2>
        <div className={s.row}>
          {products?.map((product, i) => (
            <div key={`productsList-${i}`} className={s.col}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ProductsList
