export const EU_COUNTRY_CODES = [
  'en',
  'it',
  'de',
  'be',
  'bg',
  'cy',
  'hr',
  'dk',
  'es',
  'ee',
  'fi',
  'fr',
  'gr',
  'hu',
  'ie',
  'lv',
  'lt',
  'lu',
  'mt',
  'nl',
  'pl',
  'pt',
  'cz',
  'ro',
  'sk',
  'si',
  'se',
]

export const TOKENS_TO_REFRESH = ['X-AUTH-TOKEN', 'X-AUTH-SALER-TOKEN']

export const USED_COUPON_STATUS = 'USED'

export enum SMALLABLE2_EVENTS {
  LAYOUT_REFRESH = '/layout/refresh',
  LAYOUT_BUTTON_REFRESH = '/layout/modifyBtnRefresh',
  BASKET_ADD = '/basket/add',
  BASKET_REMOVE = '/basket/remove',
  MODAL_CLOSE = '/modal/close',
}

export enum BASKET_ERRORS {
  DEFAULT = 'default',
  NO_STOCK = 'stock',
}

export const HONGKONG_COUNTRY_ID = 65

export const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
export const PASSWORD_LENGTH_REGEXP = /^.{8,}$/
export const PASSWORD_LOWERCASE_REGEXP = /[a-z]+/
export const PASSWORD_UPPERCASE_REGEXP = /[A-Z]+/
export const PASSWORD_NUMBER_REGEXP = /\d+/
