type DeclicationStock = {
  quantity: number
}

export type ProductStocks = {
  availability?: {
    from: number
    to: number
    onDemand: boolean
  }
  declinations: {
    [sku: string]: DeclicationStock
  }
  isSalableOutOfStock: boolean
}

export const fetchProductStocks = async ({
  id,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  id: number
  apiUrl?: string
}): Promise<ProductStocks> => {
  const res = await fetch(`${apiUrl}/v1/stocks/${id}`, {
    method: 'GET',
    mode: 'cors',
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const hasQuantity = (declinations: {
  [sku: string]: DeclicationStock
}) => Object.values(declinations).some((d) => d.quantity >= 1)

export const hasProductStocks = ({
  isSalableOutOfStock,
  declinations,
}: ProductStocks) => isSalableOutOfStock || hasQuantity(declinations)

export const hasDeclinationStocks = (
  { isSalableOutOfStock, declinations }: ProductStocks,
  sku: string
) => isSalableOutOfStock || declinations[sku]?.quantity > 0

export const productIsUnavailable = (
  stocks: ProductStocks,
  alertAvailability: boolean = true
) => !hasProductStocks(stocks) && !alertAvailability

export const getAvailabilityLabel = (
  stocks: ProductStocks,
  sku: string
): { message: string; params?: {}; status: string } => {
  const quantity = stocks?.declinations[sku]?.quantity
  if (quantity > 0) {
    if (quantity === 1) {
      return {
        message: `product:availability.available_1`,
        status: 'info',
      }
    } else if (quantity <= 3) {
      return {
        message: `product:availability.available`,
        params: { count: quantity },
        status: 'info',
      }
    } else {
      return {
        message: `product:availability.available_other`,
        status: 'info',
      }
    }
  } else {
    if (stocks?.isSalableOutOfStock && stocks?.availability) {
      const { from, to } = stocks?.availability
      return {
        message: `product:delivery.isSalableOutOfStock`,
        params: {
          from: Math.floor(from / 7),
          to: Math.ceil(to / 7),
        },
        status: 'info',
      }
    } else {
      return {
        message: `product:availability.notAvailable`,
        status: 'error',
      }
    }
  }
}
