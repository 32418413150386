export const PlayCircle = ({ width = '16', height = '16', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3.33398 3.13774C3.33398 2.35231 4.19794 1.87347 4.86398 2.28975L12.6439 7.15216C13.2705 7.54383 13.2705 8.45649 12.6439 8.84816L4.86398 13.7106C4.19794 14.1269 3.33398 13.648 3.33398 12.8626V3.13774Z"
      stroke="#1F1F1F"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
