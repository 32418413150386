export const formattedPrice = (
  amount: number,
  currencyCode: string,
  currentLocale: string
) => {
  return new Intl.NumberFormat(currentLocale, {
    style: 'currency',
    currency: currencyCode,
  }).format(amount)
}

export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()

export const formattedObjPrices = (
  apiPrices: ApiPrices,
  locale: string,
  euroApiPrices?: ApiPrices
) => {
  if (!apiPrices?.declinations || !Object.keys(apiPrices.declinations).length) {
    return null
  }
  const { declinations, currencyCode } = apiPrices
  const pricesDeclinations = {} as Prices
  for (const sku in declinations) {
    const declination = declinations[sku]
    const euroDeclination = euroApiPrices?.declinations?.[sku] || null

    const amountFormat = formattedPrice(
      declination.priceTaxIncluded,
      currencyCode,
      locale
    )
    const baseAmountFormat = declination.discountInfo
      ? formattedPrice(
          declination.discountInfo.priceTaxIncluded,
          currencyCode,
          locale
        )
      : undefined
    pricesDeclinations[sku] = {
      euroValue: euroDeclination?.priceTaxIncluded || null,
      value: declination.priceTaxIncluded,
      baseValue: declination.discountInfo
        ? declination.discountInfo.priceTaxIncluded
        : undefined,
      currencyCode: currencyCode,
      amount: amountFormat,
      baseAmount: baseAmountFormat,
      discount: declination.discountInfo?.discount,
      discountName: declination.discountInfo?.discountName,
      discountType: declination.discountInfo?.discountType,
    }
  }
  return pricesDeclinations
}

export const getLowestPriceDeclination = (declinations?: Prices) => {
  if (declinations) {
    return Object.keys(declinations).reduce((key, v) =>
      declinations[v].value < declinations[key].value ? v : key
    )
  }
}

export const isDiffPrice = (prices?: ApiPrices) => {
  const declinations = prices?.declinations
  if (!declinations) return null
  const priceKey = Object.keys(declinations)
  const firstPrice = declinations[priceKey[0]]?.priceTaxIncluded || null
  if (!firstPrice) return null
  return priceKey.some(
    (key) => firstPrice !== declinations[key].priceTaxIncluded
  )
}

export const isSinglePrice = (prices: Prices) => {
  if (prices) {
    const declinationsValues = Object.values(prices)
    if (declinationsValues.length) {
      return !declinationsValues.some(
        (d) => d.value !== declinationsValues[0].value
      )
    }
    return false
  }
}

export const cardPriceFormat = (prices: ApiPrices, locales: string) => {
  const priceFormat = formattedObjPrices(prices, locales)
  if (priceFormat) {
    const lowestPrice = getLowestPriceDeclination(priceFormat)
    if (lowestPrice) return priceFormat[lowestPrice] as DeclinationPrice
  }
  return null
}

export const fetchProductPrices = async ({
  ids,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  ids: number | number[]
  apiUrl?: string
}): Promise<ApiPrices> => {
  const idString = typeof ids === 'object' ? ids.toString() : ids
  const res = await fetch(`${apiUrl}/v1/prices?ids=${idString}`, {
    method: 'GET',
    mode: 'cors',
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const getPrices = async (ids: number | number[], locale: string) => {
  try {
    const repApiPrices = await fetchProductPrices({ ids })
    if (Object.keys(repApiPrices).length !== 0) {
      return formattedObjPrices(repApiPrices, locale)
    }
    return null
  } catch (error) {
    throw error
  }
}

export const getDiscountName = (discountType) => {
  switch (discountType) {
    case 0:
      return 'promotion'
    case 1:
      return 'sale'
    case 2:
      return 'outlet'
    default:
      return ''
  }
}

export const getFloatPrice = (price: string): string => {
  const regexp = /[^\d-,-.]/g
  return price.replace(regexp, '')
}

export const getMinAndMaxProductsListingPrices = (
  products: ProductListItem[]
): { minPrice: number; maxPrice: number } => {
  if (!products.length) return { minPrice: 0, maxPrice: 0 }
  try {
    const prices = products
      .map((product) => product.prices)
      .map((price) => price.declinations)
      .map((declination) => Object.values(declination)[0].priceTaxIncluded)
    return { minPrice: Math.min(...prices), maxPrice: Math.max(...prices) }
  } catch (error) {
    console.error('Error getting min max product listing prices:', error)
    return { minPrice: 0, maxPrice: 0 }
  }
}
