import React, { FC, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { ProductCard } from '@components/product'
import { ArrowLineLeft } from '@components/icons'

import { fetchProductsList } from '@mw/products'
import qs from 'query-string'

import type { ShopTheLookProps } from '../ShopTheLook'

import { blockScroll, enableScroll } from '@utils/scrollLock'
import { isMobileSafari } from 'react-device-detect'

import cn from 'classnames'
import s from './ShopTheLook2.module.css'

const ShopTheLookV2: FC<ShopTheLookProps> = ({
  title,
  media,
  lookPoints,
  isInListing = false,
}) => {
  const { locale } = useRouter()
  const { t } = useTranslation()
  const screen = useScreen()

  const [productsData, setProductsData] = useState<ProductListItem[]>([])
  const alreadyFetch = useRef<boolean>(false)

  const [choosenProduct, setChoosenProduct] = useState<ProductListItem>(null)
  const [sortedProducts, setSortedProducts] = useState<ProductListItem[]>([])
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  const handlechoosenProduct = (product: ProductListItem) => {
    setChoosenProduct(product)
    setIsDropDownOpen(!isDesktop)
  }

  const handleCloseListing = () => {
    setIsDropDownOpen(false)
  }

  useEffect(() => {
    const isDesktop = ![Screen.xs, Screen.sm, Screen.md].includes(screen)
    setIsDesktop(isDesktop)
  }, [screen])

  useEffect(() => {
    if (isDropDownOpen) blockScroll(isMobileSafari)
    else enableScroll(isMobileSafari)
  }, [isDropDownOpen])

  useEffect(() => {
    ;(async () => {
      if (alreadyFetch.current) return null
      try {
        const productIdList = lookPoints.map((lookPoint) => lookPoint.productId)
        const idsString = qs.stringify(
          { id: productIdList },
          {
            arrayFormat: 'bracket',
          }
        )
        if (idsString?.length) {
          const repProducts = await fetchProductsList({
            locale: locale as string,
            query: idsString,
          })
          if (repProducts?.products?.length) {
            setProductsData(repProducts.products)
            setChoosenProduct(repProducts.products[0])
            alreadyFetch.current = true
          }
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [screen])

  useEffect(() => {
    if (choosenProduct && productsData?.length) {
      const choosenProductFirst = [
        choosenProduct,
        ...productsData.filter((product) => product.id !== choosenProduct?.id),
      ]
      setSortedProducts(choosenProductFirst)
    }
  }, [choosenProduct, productsData])

  return (
    <section
      className={cn('shop_the_look', s.container, {
        [s.isInListing]: isInListing,
      })}
    >
      {!title?.length ? <h2 className={s.title}>{title}</h2> : null}
      <div className={s.lookBlock}>
        <div className={s.pictureAndPoints}>
          <picture className={s.mediaContainer}>
            <source
              media="(min-width: 768px)"
              srcSet={`
                      ${media.formats.large} 2x,
                      ${media.formats.large}
                    `}
            />
            <source srcSet={media.formats.large} />
            <img src={media.formats.large} alt={title} />
            {lookPoints.map((point, i) => {
              const productAssociate =
                productsData.find(
                  (product) => String(product.id) === String(point.productId)
                ) || null
              if (!productAssociate) return null
              return (
                <React.Fragment key={`Look-point-${point.productId}-${i}`}>
                  <div className={s.content}>
                    <div
                      className={s.icon}
                      style={{
                        top: point.positionY + '%',
                        left: point.positionX + '%',
                      }}
                    >
                      +
                    </div>
                    <div
                      className={s.lookPoint}
                      style={{
                        top: point.positionY + '%',
                        left: point.positionX + '%',
                      }}
                      onClick={() => handlechoosenProduct(productAssociate)}
                      aria-describedby={`shoTheLookCard-${point.productId}`}
                      tabIndex={0}
                    ></div>
                  </div>
                </React.Fragment>
              )
            })}
          </picture>
        </div>
        {isDesktop && (
          <div className={s.card}>
            {choosenProduct ? (
              <ProductCard product={choosenProduct} layout="shopTheLook" />
            ) : null}
          </div>
        )}
        {isDropDownOpen ? (
          <div className={cn(s.dropdown)}>
            <div className={s.close} onClick={handleCloseListing}>
              <ArrowLineLeft
                className={s.iconClose}
                aria-label={t('layout:dropdown.ariaClose')}
              />
              <p>{t('layout:dropdown.ariaGoBack')}</p>
            </div>
            <div
              className={cn(s.row, {
                [s.isEven]: sortedProducts?.length % 2 === 0,
              })}
            >
              {sortedProducts?.map((product, i) => (
                <div className={s.col} key={`shopTheLook2_cards_${i}`}>
                  <ProductCard
                    product={product}
                    layout="shopTheLook"
                    handleBlockScroll={false}
                    productsPerLine={
                      i === 0 ||
                      (i === sortedProducts?.length - 1 && (i + 1) % 2 == 0)
                        ? 1
                        : 2
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default ShopTheLookV2
