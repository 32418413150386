import React, { FC } from 'react'

import Arrow from '@components/ui/Arrow'

import cn from 'classnames'
import s from './Selector.module.css'

interface SelectorProps {
  className?: string
  label?: string
  defaultSelected?: string | number
  isDarkTheme?: boolean
  options?: { option: string; value: string | number }[]
  onChange: (...args: any) => any
}

const Selector: FC<SelectorProps> = ({
  className,
  label,
  isDarkTheme = false,
  defaultSelected,
  options,
  onChange,
}) => (
  <div className={cn(s.container, className)}>
    <div className={s.formItem}>
      <Arrow
        width="12"
        height="12"
        stroke="grey"
        className={s.arrowDown}
        aria-hidden
      />
      <select
        className={s.select}
        defaultValue={defaultSelected}
        onChange={(e) => onChange(e)}
      >
        {options?.map((option, index) => (
          <option value={option.value} key={index}>
            {option.option}
          </option>
        ))}
      </select>
      {label?.length ? (
        <label className={cn(s.formLabel, { [s.darkTheme]: isDarkTheme })}>
          {label}
        </label>
      ) : null}
    </div>
  </div>
)

export default Selector
