export const More = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 4.16699V15.8337"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16675 10H15.8334"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Less = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M5.83325 10H14.1666"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
