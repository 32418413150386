import { FC, MutableRefObject } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { DisplayMulti, DisplaySolo } from '@components/icons'
import { useUserSettings } from '@context/session'
import { scrollToTop } from '@utils/scrollTo'
import { useRouter } from 'next/router'
import { SortByAlgolia } from '../SortMenuAlgolia'

import cn from 'classnames'
import s from './SortMenuMobileAlgolia.module.css'

export interface SortMenuMobileProps {
  openFilters: boolean
  setOpenFilters: (toOpen: boolean) => void
  productsPerLine: number
  setProductsPerLine: (prdPerLine: number) => void
  navigationBar: MutableRefObject<HTMLDivElement>
  isVP?: boolean
}

const SortMenuMobileAlgolia: FC<SortMenuMobileProps> = ({
  openFilters,
  setOpenFilters,
  productsPerLine,
  setProductsPerLine,
  navigationBar,
  isVP = false,
}) => {
  const { t } = useTranslation()
  const { deliveryCountryCode } = useUserSettings()
  const { locale } = useRouter()

  const handleProductPerLine = (productsPerLine: number) => {
    scrollToTop(navigationBar.current, -62)
    window?.localStorage?.setItem(
      'display_listing',
      productsPerLine === 2 ? '2' : '1'
    )
    setProductsPerLine(productsPerLine)
  }

  return (
    <div className={s.container}>
      <button className={s.button} onClick={() => setOpenFilters(!openFilters)}>
        {t('block:filter.showFilter')}
      </button>
      <div className={s.separator} aria-hidden></div>
      <div className={s.button}>
        {t('block:sort.ariaSortBy')}
        <SortByAlgolia
          deliveryCountryCode={deliveryCountryCode}
          locale={locale}
          isVP={isVP}
          items={[
            {
              label: t('block:sort.pertinence'),
              value: `pertinence`,
            },
            {
              label: t('block:sort.newArrivals'),
              value: `activationDate:desc`,
            },
            {
              label: t('block:sort.stock'),
              value: `stock:desc`,
            },
            {
              label: t('block:sort.priceAsc'),
              value: `price:asc`,
            },
            {
              label: t('block:sort.priceDesc'),
              value: `price:desc`,
            },
          ]}
        />
      </div>
      <div className={s.separator} aria-hidden></div>
      <div className={s.button}>
        <button
          className={cn(s.cta, { [s.selected]: productsPerLine !== 2 })}
          onClick={() => handleProductPerLine(1)}
          aria-label={t('product:displayGridSelector.single')}
        >
          <DisplaySolo width={24} height={24} fill="#000" aria-hidden />
        </button>
        <button
          className={cn(s.cta, { [s.selected]: productsPerLine === 2 })}
          onClick={() => handleProductPerLine(2)}
          aria-label={t('product:displayGridSelector.multi', { nbr: 2 })}
        >
          <DisplayMulti width={24} height={24} fill="#000" aria-hidden />
        </button>
      </div>
    </div>
  )
}

export default SortMenuMobileAlgolia
