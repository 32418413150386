import { FC } from 'react'
import useScreen from '@lib/hooks/useScreen'

import Sticky from 'react-stickynode'

const StickyComponent: FC<{
  children: any
  bottomBoundary: string
  className?: string
  disabled?: boolean
}> = ({ children, bottomBoundary = '', className = '', disabled = false }) => {
  const screen = useScreen()

  if (disabled || screen === 'xs' || screen === 'sm') return children

  return (
    <Sticky
      top={0}
      bottomBoundary={bottomBoundary}
      innerZ={0}
      innerActiveClass={className}
    >
      {children}
    </Sticky>
  )
}

export default StickyComponent
