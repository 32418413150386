import React, { FC, LegacyRef } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '@components/ui'

import { shortenDescription } from '@utils/string'

import cn from 'classnames'
import s from './BrandsLetterBlockMedia.module.css'

export interface BrandsLetterBlockMediaProps {
  letter: string
  brands: Brand[]
  brandsContainerRef: LegacyRef<HTMLOListElement> | null
  searchEnable: boolean
}

const BrandsLetterBlockMedia: FC<BrandsLetterBlockMediaProps> = ({
  letter,
  brands,
  brandsContainerRef,
  searchEnable,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <div className={cn(s.letterBlock, { [s.displayNone]: !brands.length })}>
      <div
        className={s.anchor}
        id={letter === '#' ? 'letter-num' : `letter-${letter.toLowerCase()}`}
      />
      <ol
        ref={brandsContainerRef}
        className={cn(s.brandsBlock, { [s.searchDisabled]: !searchEnable })}
      >
        <li className={s.letter}>{letter}</li>
        {brands.map((brand, index) => (
          <li key={`brand-${index}`} className={s.brand}>
            <Link
              next
              className={s.brandLink}
              aria-label={`${t('layout:common.ariaNavigate')} brands ${
                brand.name
              }`}
              href={`/${locale}/${t('block:brand.brandsUrl')}/${brand.slug}`}
            >
              {brand.media?.formats?.origin && (
                <Image
                  src={brand.media?.formats?.origin}
                  className={s.brandMedia}
                  alt={brand.name}
                  layout="fill"
                  objectFit="cover"
                  unoptimized
                />
              )}
              <p className={s.brandName}>
                {brand.name}
                {brand.isNew && (
                  <span className={cn(s.brandSuffix, s.brandIsNew)}>New</span>
                )}
                {brand.discount ? (
                  <span className={cn(s.brandSuffix, s.brandDiscount)}>
                    -{brand.discount}%
                  </span>
                ) : null}
              </p>
              {brand.description?.length ? (
                <div className={s.brandDesc}>
                  {brand.isNew && (
                    <span
                      className={cn(
                        s.brandSuffix,
                        s.brandIsNew,
                        s.brandIsNewDesc
                      )}
                    >
                      New
                    </span>
                  )}
                  <h3 className={s.brandDescName}> {brand.name}</h3>
                  <p
                    className={s.brandDescText}
                    dangerouslySetInnerHTML={{
                      __html: shortenDescription(brand.description, 250),
                    }}
                  />
                  <button className={s.brandDescCta} tabIndex={-1}>
                    {t('block:brand.see')}
                  </button>
                </div>
              ) : null}
            </Link>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default BrandsLetterBlockMedia
