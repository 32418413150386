import { FC, KeyboardEvent, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import normalize from '@lib/normalize'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import cn from 'classnames'
import s from './ToggleItem.module.css'

interface ToggleItemProps {
  filter: FilterValue
  parent: string
  changeFiltersSelected: changeFiltersSelectedProps
}

const ToggleItem: FC<ToggleItemProps> = ({
  filter,
  parent,
  changeFiltersSelected,
}) => {
  const { t } = useTranslation()
  const [isBoxChecked, setIsBoxChecked] = useState(filter.selected)

  const titleNormalize = normalize(filter.title)

  const onClickHandler = (filter: FilterValue) => {
    setIsBoxChecked(!isBoxChecked)
    if (filter.count || filter.selected) {
      changeFiltersSelected({
        selectedValue: filter.key,
        parent,
        isSelected: filter.selected,
        parentSlug: filter.levelDepth
          ? filter.levelDepth > 1
            ? filter.parentSlug
            : filter.key
          : undefined,
        levelDepth: filter.levelDepth || 0,
      })
    }
  }

  const onKeyDownHandler = (
    e: KeyboardEvent<HTMLInputElement>,
    filter: FilterValue
  ) => {
    if (e.key === 'Enter') onClickHandler(filter)
  }

  useEffect(() => {
    setIsBoxChecked(filter.selected)
  }, [filter.selected])

  return (
    <li
      className={cn(s.container, { [s.disabled]: !filter.count })}
      data-cs-override-id={`ToggleItem-${filter.key}`}
    >
      <div
        className={s.content}
        style={{
          marginLeft: filter.levelDepth ? 20 * (filter.levelDepth - 1) : 0,
        }}
      >
        <input
          type="checkbox"
          id={`checkbox_${titleNormalize}`}
          className={cn(
            s.box,
            { [s.checked]: isBoxChecked },
            { [s.disabled]: !filter.count }
          )}
          value={filter.key}
          disabled={!filter.count && !isBoxChecked}
          checked={isBoxChecked}
          aria-checked={isBoxChecked}
          aria-label={t('block:filter.ariaFilterItem', {
            filterText: filter.title || filter.key,
          })}
          onChange={() => onClickHandler(filter)}
          onKeyDown={(e) => onKeyDownHandler(e, filter)}
        />
        <label htmlFor={`checkbox_${titleNormalize}`} className={s.label}>
          <span className={s.title}>{filter.title || filter.key}</span>
          <span
            className={cn(
              s.switch,
              { [s.checked]: isBoxChecked },
              { [s.disabled]: !filter.count }
            )}
            aria-hidden
          />
        </label>
      </div>
    </li>
  )
}
export default ToggleItem
