import { useConnector } from 'react-instantsearch'

import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'

import type {
  StatsConnectorParams,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'

export type UseStatsProps = StatsConnectorParams & StatsResultsProps

const useStats = (props?: UseStatsProps) =>
  useConnector<StatsConnectorParams, StatsWidgetDescription>(
    connectStats,
    props
  )

export default useStats
