import React, { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Arrow, Input } from '@components/ui'
import { Burger, DisplayMulti, Loupe } from '@components/icons'

import { getQueryFilter } from '@utils/brands'
import { changeLocaleIfTranslatable } from '@utils/locale'

import cn from 'classnames'
import s from './BrandsHeader.module.css'

export interface BrandsHeaderProps {
  title: string
  subtitle: string
  search: string
  setSearch: (param: string) => void
  filters: Array<BrandFilter>
  selectedFilter: string
  listingMedia: boolean
  setListingMedia: (listingMedia: boolean) => void
}

const BrandsHeader: FC<BrandsHeaderProps> = ({
  setSearch,
  search,
  filters,
  title,
  subtitle,
  selectedFilter,
  listingMedia,
  setListingMedia,
}) => {
  const { t } = useTranslation()
  const { locale, query, ...router } = useRouter()

  const handleOnChange = (filterTitle: string) => {
    const filter = filters.filter((filter) => filter.title === filterTitle)[0]
    const queryParam = getQueryFilter(filter)
    router.push(`/${query.pages[0]}?${queryParam}`, undefined, {
      locale: changeLocaleIfTranslatable(locale),
    })
  }

  return (
    <div className={s.container}>
      <h1 className={s.title}>{title}</h1>
      <p className={s.subtitle}>
        <strong>{subtitle}</strong>
      </p>
      <div className={cn(s.filters, 'notranslate')}>
        <div className={s.listContainer}>
          <button
            className={cn(s.listContent, { [s.enable]: !listingMedia })}
            onClick={() => setListingMedia(false)}
          >
            <Burger width={24} height={24} />
            <p className={s.listCta}>{t('block:brands.listDisplay')}</p>
          </button>
          <button
            className={cn(s.listContent, { [s.enable]: listingMedia })}
            onClick={() => setListingMedia(true)}
          >
            <DisplayMulti width={24} height={24} fill="#000" />
            <p className={s.listCta}>{t('block:brands.galerieDisplay')}</p>
          </button>
        </div>
        <div className={s.filterContainer}>
          <Loupe
            className={s.icon}
            stroke="black"
            height="0.875rem"
            aria-hidden
          />
          <Input
            className={s.searchBar}
            type="text"
            placeholder={t('block:brands.search')}
            value={search}
            onChange={setSearch}
            aria-label={t('block:brands.searchBrands')}
          />
        </div>
        <div className={cn(s.filterContainer, s.filterBar)}>
          <select
            className={s.selectField}
            aria-label={t('block:brands.selectCategory')}
            value={selectedFilter}
            onChange={(e) => handleOnChange(e?.target?.value)}
          >
            <option value={t('block:brands.allBrands')}>
              {t('block:brands.allBrands')}
            </option>
            {filters?.map((filter, index) => (
              <option
                key={`filter-option-${index}`}
                value={filter.title}
                aria-label={filter.title}
              >
                {filter.title}
              </option>
            ))}
          </select>
          <Arrow
            width="14"
            height="14"
            stroke="black"
            className={s.arrowDown}
            aria-hidden
          />
        </div>
      </div>
    </div>
  )
}

export default BrandsHeader
