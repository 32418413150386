import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  DefaultOptions,
  concat,
} from '@apollo/client'

const SERVER_GRAPH_URL =
  process.env.SMALLABLE_GRAPHABLE_URL_SERVER ||
  process.env.NEXT_PUBLIC_SMALLABLE_GRAPHABLE_URL_CLIENT ||
  ''

const httpLink = createHttpLink({
  uri: SERVER_GRAPH_URL,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
    },
  }))
  return forward(operation)
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
  defaultOptions: defaultOptions,
})

export default client
