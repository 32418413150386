import { FC, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import useIntersection from '@lib/hooks/useIntersection'

import { getItemsName } from '@utils/basket/items'
import productUrl from '@utils/url/product'

import { LazyLoadComponent, Link } from '@components/ui'

import cn from 'classnames'
import s from './ProductInspiration.module.css'

const ProductInspiration: FC<InspirationBlock> = ({
  htmlTitle,
  title,
  media,
  url,
  products,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  const [imgIsInView, setImgIsInView] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>()

  useIntersection(containerRef, () => {
    setImgIsInView(true)
  })

  const strippedTitle = htmlTitle
    ? `${t('product:inspiration')} : ${htmlTitle.replace(/(<([^>]+)>)/gi, '')}`
    : title

  return (
    <section
      className={s.container}
      data-cs-override-id="filterList-InspirationProduct"
    >
      <div className={s.content}>
        <h2
          className={s.title}
          dangerouslySetInnerHTML={{
            __html: strippedTitle,
          }}
        />
        <div ref={containerRef} className={s.inspiration}>
          {media?.length ? (
            <div className={s.leftContainer}>
              <Link
                href={url}
                next
                aria-label={`${t('layout:common.ariaNavigate')} ${title}`}
              >
                <div className={s.link}>
                  <div
                    role="img"
                    className={s.inspirationImage}
                    aria-label={title}
                    style={{
                      backgroundImage: imgIsInView ? `url(${media})` : '',
                    }}
                  />
                </div>
              </Link>
            </div>
          ) : null}
          <div className={cn(s.rightContainer, { [s.full]: !media?.length })}>
            <ul>
              {products?.map((product) => {
                if (!product) return null

                const productName = getItemsName({
                  attachColor: product.attachColor,
                  color: product.color,
                  name: product.name,
                })

                const urlProduct = productUrl({
                  attachColor: product.attachColor,
                  brand: product.brand,
                  color: product.color,
                  id: product.id,
                  name: product.name,
                  locale,
                })

                return (
                  <li key={`product-inspiration-${product.id}`}>
                    <Link
                      href={urlProduct}
                      next
                      aria-label={`${t(
                        'layout:common.ariaNavigate'
                      )} ${productName}`}
                    >
                      <div className={s.link}>
                        <LazyLoadComponent seoReadable>
                          <img
                            src={product.medias[0]?.['648x648q80']}
                            alt={productName}
                            aria-label={productName}
                          />
                        </LazyLoadComponent>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductInspiration
