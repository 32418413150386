import { FC, Fragment } from 'react'
import dynamic from 'next/dynamic'
import { InstantSearchServerState } from 'react-instantsearch'

const SeparatorBlock = dynamic(
  () => import('@components/blocks-cms/SeparatorBlock')
)
const GuideBlock = dynamic(() => import('@components/blocks-cms/GuideBlock'))
const HeaderPage = dynamic(() => import('@components/blocks-cms/HeaderPage'))
const HomeItemBlock = dynamic(
  () => import('@components/blocks-cms/HomeItemBlock')
)
const HomeSaleBlock = dynamic(
  () => import('@components/blocks-cms/HomeSaleBlock')
)
const HomeLargeBlock = dynamic(
  () => import('@components/blocks-cms/HomeLargeBlock')
)
const HomeMediumBlock = dynamic(
  () => import('@components/blocks-cms/HomeMediumBlock')
)
const HomeSmallBlock = dynamic(
  () => import('@components/blocks-cms/HomeSmallBlock')
)
const LandingItem = dynamic(() => import('@components/blocks-cms/LandingItem'))
const Brand = dynamic(() => import('@components/blocks-cms/Brand'))
const RichText = dynamic(() => import('@components/blocks-cms/RichText'))
const BrandsListing = dynamic(
  () => import('@components/blocks-cms/BrandsListing')
)
const MagazinesSlider = dynamic(
  () => import('@components/blocks-cms/MagazinesSlider')
)
const ProductsListing = dynamic(
  () => import('@components/blocks-cms/ProductsListing')
)
const ProductListingAlgolia = dynamic(
  () =>
    import('@components/blocks-cms/ProductListingAlgolia/ProductListingAlgolia')
)
const DescListingHeader = dynamic(
  () => import('@components/blocks-cms/DescriptionListingHeader')
)
const BrandsSlider = dynamic(
  () => import('@components/blocks-cms/BrandsSlider')
)
const SlideBlock = dynamic(() => import('@components/blocks-cms/SlideBlock'))
const MagazineListing = dynamic(
  () => import('@components/blocks-cms/MagazineListing')
)
const Media = dynamic(() => import('@components/blocks-cms/Media'))
const VideoPlayer = dynamic(() => import('@components/blocks-cms/VideoPlayer'))
const ShopTheLook = dynamic(() => import('@components/blocks-cms/ShopTheLook'))
const ButtonsSlider = dynamic(
  () => import('@components/blocks-cms/ButtonsSlider')
)

export const BLOCK_TYPE = {
  SEPARATOR_BLOCK: 'blocks.separator-block',
  GUIDE_BLOCK: 'blocks.guide-block',
  HEADER_PAGE: 'blocks.header-page',
  HOME_ITEM_BLOCK: 'blocks.home-item-block',
  HOME_SALE_BLOCK: 'blocks.home-sale-block',
  HOME_LARGE_BLOCK: 'blocks.home-large-block',
  HOME_MEDIUM_BLOCK: 'blocks.home-medium-block',
  HOME_SMALL_BLOCK: 'blocks.home-small-block',
  LANDING_ITEM: 'blocks.landing-item',
  RICH_TEXT: 'blocks.rich-text',
  BRAND: 'brand.brand',
  BRANDS_LISTING: 'listing.brands-listing',
  BRANDS_SLIDER: 'sliders.brands-slider',
  MAGAZINES_SLIDER: 'sliders.magazine-slider',
  SLIDE_BLOCK: 'sliders.home-slider-full',
  MEDIA: 'blocks.media',
  MAGAZINE_LISTING: 'listing.magazine', //missing - renamed by graphable for v2 fallback - dont exist in strapi
  VIDEO_BLOCK: 'blocks.video-player',
  SHOP_THE_LOOK_BLOCK: 'blocks.shop-the-look',
  BUTTONS_SLIDER: 'blocks.slide-buttons',
  DESCRIPTION_LISTING: 'listing.description-listing',
  LIST_PRODUCTS: 'listing.products-listing',
  ALGOLIA_LIST_PRODUCTS: 'listing.algolia-products-listing',
}

export const Block: FC<{
  block: PageBlockTypes
  algoliaServerInfos?: AlgoliaServerInfos
}> = ({ block, algoliaServerInfos }) => {
  if (block.enabled === false || (block.data as any)?.inListing) return null
  const type =
    block.blockType ||
    console.warn(`block.blockType not found for `, block) ||
    block.type
  switch (type) {
    case BLOCK_TYPE.SEPARATOR_BLOCK:
      return <SeparatorBlock {...(block.data as SeparatorTypes)} />
    case BLOCK_TYPE.GUIDE_BLOCK:
      return <GuideBlock {...(block.data as GuideBlockTypes)} />
    case BLOCK_TYPE.HEADER_PAGE:
      return <HeaderPage {...(block.data as HeaderPageTypes)} />
    case BLOCK_TYPE.HOME_ITEM_BLOCK:
      return <HomeItemBlock {...(block.data as HomeItemBlockTypes)} />
    case BLOCK_TYPE.HOME_SALE_BLOCK:
      return <HomeSaleBlock {...(block.data as HomeSaleBlockTypes)} />
    case BLOCK_TYPE.HOME_LARGE_BLOCK:
      return <HomeLargeBlock {...(block.data as HomeLargeBlockTypes)} />
    case BLOCK_TYPE.HOME_MEDIUM_BLOCK:
      return <HomeMediumBlock {...(block.data as HomeMediumBlockTypes)} />
    case BLOCK_TYPE.HOME_SMALL_BLOCK:
      return <HomeSmallBlock {...(block.data as HomeSmallBlockTypes)} />
    case BLOCK_TYPE.LANDING_ITEM:
      return <LandingItem {...(block.data as LandingItemTypes)} />
    case BLOCK_TYPE.BRAND:
      return <Brand {...(block.data as BrandTypes)} />
    case BLOCK_TYPE.RICH_TEXT:
      return <RichText {...(block.data as RichTextTypes)} />
    case BLOCK_TYPE.BRANDS_LISTING:
      return <BrandsListing {...(block.data as BrandsListingTypes)} />
    case BLOCK_TYPE.BRANDS_SLIDER:
      return <BrandsSlider {...(block.data as BrandsSliderTypes)} />
    case BLOCK_TYPE.MAGAZINES_SLIDER:
      return <MagazinesSlider {...(block.data as MagazinesSliderTypes)} />
    case BLOCK_TYPE.DESCRIPTION_LISTING:
      return <DescListingHeader {...(block.data as DescriptionListingTypes)} />
    case BLOCK_TYPE.LIST_PRODUCTS:
      return <ProductsListing {...(block.data as ProductsListingTypes)} />
    case BLOCK_TYPE.ALGOLIA_LIST_PRODUCTS:
      return (
        <ProductListingAlgolia
          algoliaListingProps={block.data as AlgoliaProductsListingTypes}
          algoliaServerInfos={algoliaServerInfos}
        />
      )
    case BLOCK_TYPE.SLIDE_BLOCK:
      return <SlideBlock {...(block.data as SlideBlockTypes)} />
    case BLOCK_TYPE.MEDIA:
      return <Media {...(block.data as MediaTypes)} />
    case BLOCK_TYPE.MAGAZINE_LISTING:
      return <MagazineListing {...(block.data as MagazineListingTypes)} />
    case BLOCK_TYPE.VIDEO_BLOCK:
      return <VideoPlayer {...(block.data as VideoBlockTypes)} />
    case BLOCK_TYPE.SHOP_THE_LOOK_BLOCK:
      return <ShopTheLook {...(block.data as ShopTheLookTypes)} />
    case BLOCK_TYPE.BUTTONS_SLIDER:
      return <ButtonsSlider {...(block.data as ButtonsSliderTypes)} />
    default:
      return null
  }
}

interface BlocksProps {
  blocks?: PageBlockTypes[]
  className?: string
  algoliaServerInfos?: AlgoliaServerInfos
}

const Blocks: FC<BlocksProps> = ({
  blocks = [],
  className,
  algoliaServerInfos,
}) => (
  <div className={className}>
    {blocks?.map((block: PageBlockTypes, index: number) => (
      <Fragment key={`block_page_${index}`}>
        <Block block={block} algoliaServerInfos={algoliaServerInfos} />
      </Fragment>
    ))}
  </div>
)

export default Blocks
