const Tower = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_64_2134)">
      <path
        d="M7.99967 1.33301L1.33301 4.66634L7.99967 7.99967L14.6663 4.66634L7.99967 1.33301Z"
        stroke="#555555"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33301 11.333L7.99967 14.6663L14.6663 11.333"
        stroke="#555555"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33301 8L7.99967 11.3333L14.6663 8"
        stroke="#555555"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_64_2134">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Tower
