import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'
import RichText from '@components/blocks-cms/RichText'

import cn from 'classnames'
import s from './HeaderPage.module.css'

export interface HeaderPageProps {
  url?: string
  link?: string
  linkTitle?: string
  targetBlank: boolean
  isPopin?: boolean
  title?: string
  titleShort?: string
  section?: string
  description?: string
  descriptionShort?: string
  media?: BlocksMedia
  isShortVersion?: boolean
}

const HeaderPage: FC<HeaderPageProps> = ({
  link,
  linkTitle,
  title,
  titleShort,
  section,
  description,
  descriptionShort,
  media,
  targetBlank,
  isPopin,
  isShortVersion = false,
}) => {
  const { t } = useTranslation()

  return (
    <section className={cn('header_page', s.container)}>
      <BlockLinkWrapper
        title={titleShort}
        url={link}
        targetBlank={targetBlank}
        isPopin={isPopin}
      >
        {media?.formats?.origin && (
          <div className={s.mediaContainer}>
            <div
              className={s.media}
              style={{
                backgroundImage: `url(${
                  media?.formats?.large || media?.formats?.origin
                })`,
              }}
            />
          </div>
        )}
        {section && <p className={s.section}>{section}</p>}
        {isShortVersion ? (
          <>
            {titleShort && <RichText content={titleShort} />}
            {descriptionShort && (
              <RichText content={descriptionShort} className={s.desc} />
            )}
          </>
        ) : (
          <>
            {title && <RichText content={title} />}
            {description && (
              <RichText content={description} className={s.desc} />
            )}
          </>
        )}
        {link && linkTitle && (
          <p
            className={s.cta}
            aria-label={`${t('layout:common.ariaNavigate')} ${titleShort}`}
          >
            {linkTitle}
          </p>
        )}
      </BlockLinkWrapper>
    </section>
  )
}

export default HeaderPage
