import { FC } from 'react'
import { useUI } from '@components/ui/context'
import { useUserSettings } from 'context/session'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { FROM, tagAlgoliaConvertion } from '@lib/lib-algolia/algolia'

import { tagEventCS } from '@components/common/ContentSquare/ContentSquare'
import { tagEventAddToBasket } from '@components/common/Eulerian/EulerianEvents'

import { MODALS, TOOLTIPS } from '@constants'
import { hasDeclinationStocks } from '@utils/stocks'

import cn from 'classnames'
import s from './QuickBuy.module.css'

export interface QuickBuyProps {
  declinations: Declination[]
  stocks: Stocks
  queryID: string
  layout?: string
}

const setSizeCharactersLong = (declinations: Declination[]) => {
  const isLong = declinations?.some(
    (declination) => declination.sizeListing?.length > 5
  )
  return isLong ? 3 : 7
}

const QuickBuy: FC<QuickBuyProps> = ({
  declinations,
  stocks,
  queryID,
  layout,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const sessionData = useUserSettings()
  const { deliveryCountryCode } = useUserSettings()
  const { addToBasket } = sessionData
  const { setModalView, openModal, openTooltip } = useUI()

  const isSizeCharactersLong = setSizeCharactersLong(declinations)

  const handleAddToBasket = async (sku?: string) => {
    let newBasket
    try {
      newBasket = await addToBasket(sku, locale)
      openTooltip(TOOLTIPS.BASKET, { withTimer: true })
    } catch (err) {
      const errMsg =
        typeof err === 'string'
          ? t(`${'product:error.' + err}`)
          : t(`${'product:error.defaultMessage'}`)
      setModalView(MODALS.NOTIFICATION, {
        message: errMsg,
      })
      openModal()
    }
    if (newBasket) {
      tagEventAddToBasket({
        urlp:
          layout === 'shopTheLook'
            ? '/basket/add/shop_the_look'
            : '/basket/add/page_listing',
        locale,
        sessionData,
        productData: newBasket,
      })

      if (newBasket.items?.length) {
        tagAlgoliaConvertion({
          deliveryCountryCode,
          from: FROM.LISTING_PRODUCT,
          locale,
          objectId: newBasket.items[newBasket.items.length - 1].reference,
          queryID,
        })
      }
    }
  }

  const handleAlertDisponibility = async (declination?: string) => {
    if (declination) {
      setModalView(MODALS.NOTIFY_ME, {
        sku: declination,
      })
      openModal()
    }
  }

  return (
    <div className={s.container} onClick={(e) => e.preventDefault()}>
      <div className={s.content}>
        <p
          className={s.title}
          onClick={() => tagEventCS({ eventName: 'quickbuyTitleClick' })}
        >
          {t('product:addToShoppingBag')}
        </p>
        {declinations?.length ? (
          <div
            className={cn(
              s.sizes,
              { [s.long]: isSizeCharactersLong === 3 },
              {
                [s.scrollable]:
                  (isSizeCharactersLong === 3 && declinations.length >= 8) ||
                  declinations.length >= 14,
              }
            )}
          >
            {declinations.map((declination) => (
              <div key={`sizekey-${declination?.sku}`} className={s.size}>
                <button
                  id={declination.sku}
                  data-testid={
                    hasDeclinationStocks(stocks, declination?.sku)
                      ? 'QuickBuy_cta_available'
                      : 'QuickBuy_cta_unavailable'
                  }
                  className={cn(s.cta, {
                    [s.empty]: !hasDeclinationStocks(stocks, declination?.sku),
                    [s.long]: isSizeCharactersLong === 3,
                    [s.lowStock]:
                      stocks?.declinations[declination.sku]?.quantity > 0 &&
                      stocks?.declinations[declination.sku]?.quantity <= 3,
                  })}
                  onClick={() => {
                    hasDeclinationStocks(stocks, declination?.sku)
                      ? handleAddToBasket(declination?.sku)
                      : handleAlertDisponibility(declination?.sku)
                  }}
                >
                  {declination?.sizeListing || declination?.size}
                </button>
                {stocks?.declinations[declination.sku]?.quantity > 0 &&
                  stocks?.declinations[declination.sku]?.quantity <= 3 &&
                  !(
                    (isSizeCharactersLong === 3 && declinations.length >= 8) ||
                    declinations.length >= 14
                  ) && (
                    <div className={s.lastChance}>
                      {stocks.declinations[declination.sku].quantity === 0
                        ? t('product:quickBuy.declination.lastChance')
                        : t('product:quickBuy.declination.lastChances')}
                    </div>
                  )}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default QuickBuy
