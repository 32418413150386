import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { Link } from '@components/ui'
import s from './NotFound.module.css'

const NotFound: FC<{}> = () => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <>
      <div className={s.container}>
        <p className={s.title}>{t('layout:notFound.title')}</p>
        <p className={s.subTitle}>{t('layout:notFound.subTitle')}</p>
        <Link next href={`/${locale}`} className={s.link}>
          {t('layout:notFound.goHome')}
        </Link>
      </div>
    </>
  )
}

export default NotFound
