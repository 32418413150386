import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import FiltersSelected from '@components/blocks-cms/ProductsListing/SortMenu/FiltersSelected'
import { Filters } from '@components/icons'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import cn from 'classnames'
import s from './SortMenu.module.css'

export interface SortMenuProps {
  filters: ProductsListFilters
  resetFilters: () => void
  openFilters: boolean
  setOpenFilters: (openFilters: boolean) => void
  sort: string[]
  currentSort?: string
  total: number
  chunk: number
  layout?: string
  changeFiltersSelected: changeFiltersSelectedProps
}

const SortMenu: FC<SortMenuProps> = ({
  filters,
  resetFilters,
  openFilters,
  setOpenFilters,
  sort,
  currentSort,
  total,
  layout,
  changeFiltersSelected,
}) => {
  const { t } = useTranslation()

  return (
    <div className={cn(s.container, { [s.layoutBottom]: layout === 'bottom' })}>
      <div className={s.colFilters}>
        <button
          className={s.toggleFilters}
          onClick={() => setOpenFilters(!openFilters)}
        >
          <Filters
            width="1.25rem"
            height="1.25rem"
            fill="#000"
            className={s.icon}
            aria-hidden
          />
          {!openFilters
            ? t('block:filter.ariaShowFilter')
            : t('block:filter.ariaCloseFilter')}
        </button>
        <div className={s.separator}>|</div>
        <p className={s.total}>
          {t('block:nbProductsResults', {
            nbProducts: total,
          })}
        </p>
      </div>

      <FiltersSelected
        filters={filters}
        changeFiltersSelected={changeFiltersSelected}
        resetFilters={resetFilters}
      />

      <div className={s.colSort}>
        {sort && (
          <div className={s.selectorContainer}>
            <select
              className={s.selector}
              value={currentSort}
              onChange={(e) => {
                changeFiltersSelected({
                  selectedValue: e.target.value,
                  parent: 'sort',
                  isSelected: false,
                  toReplace: true,
                })
              }}
            >
              {sort.includes('rank:asc') && (
                <option value="rank:asc" aria-labelledby="sort_selector">
                  {t('block:sort.bestSellers')}
                </option>
              )}
              {sort.includes('brand:asc') && (
                <option value="brand:asc" aria-labelledby="sort_selector">
                  {t('block:sort.brands')}
                </option>
              )}
              <option
                value={'activationDate:desc'}
                aria-labelledby="sort_selector"
              >
                {t('block:sort.newArrivals')}
              </option>
              {sort.includes('stock:desc') && (
                <option value="stock:desc" aria-labelledby="sort_selector">
                  {t('block:sort.stock')}
                </option>
              )}
              {sort.includes('price:asc') && (
                <option value="price:asc" aria-labelledby="sort_selector">
                  {t('block:sort.priceAsc')}
                </option>
              )}
              {sort.includes('price:desc') && (
                <option value="price:desc" aria-labelledby="sort_selector">
                  {t('block:sort.priceDesc')}
                </option>
              )}
            </select>
          </div>
        )}
      </div>
    </div>
  )
}

export default SortMenu
