export const getItemsCount = (basket: Basket) => {
  if (basket.items?.length)
    return basket.items.reduce((t, c) => t + c.quantity, 0)
  return 0
}

export const getItemsName = ({
  attachColor = true,
  name,
  color,
}: {
  attachColor?: boolean
  name: string
  color?: string
}) => {
  return attachColor && color
    ? `${name?.endsWith('-') ? name.slice(0, -1) : name} | ${color}`
    : name
}
