export const scrollToTop = (
  element: HTMLElement,
  yOffset: number,
  smooth = true
) => {
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: smooth ? 'smooth' : 'auto' })
  }
}

export const scrollToId = (
  id,
  smooth: boolean = true,
  position: ScrollLogicalPosition = 'center'
) => {
  if (id) {
    const el = document?.getElementById(id)
    el?.scrollIntoView({
      behavior: smooth ? 'smooth' : 'auto',
      block: position,
    })
  }
}
