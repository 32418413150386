import { tagPrintCS, tagFakePrintCS } from '@components/common/ContentSquare'
import { arrayToObject } from '@utils/array'

declare global {
  interface Window {
    smlTags: { [key: string]: string }
  }
}

export const clearWindowForPartners = (newTags) => {
  if (typeof window !== 'undefined') {
    window.data_layer = newTags
    if (window._oEa?.app) window._oEa.app = []
  }
}

export const collector = ({
  urlp,
  tags,
  collectType = 'event',
  deliveryCountryCode,
  basket,
}: {
  urlp: string
  tags: Array<string | number>
  collectType?: 'print' | 'event' | 'sale'
  deliveryCountryCode?: string
  basket?: Basket
}) => {
  if (!tags?.length) return
  try {
    if (typeof window?.EA_collector !== 'undefined') window.EA_collector(tags)
    if (collectType === 'print' || collectType === 'sale') {
      tagPrintCS({ urlp, tags, deliveryCountryCode, basket })
    }
    if (collectType === 'event') tagFakePrintCS({ urlp })
    if (typeof window !== 'undefined') window.smlTags = arrayToObject(tags)
  } catch (error) {
    console.error(error)
  }
}
