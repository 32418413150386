import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { ProductDescriptionTab } from '@components/product/ProductDescription'

import { subStringIsRepeat, subStringArray } from '@utils/string'

import cn from 'classnames'
import s from './ProductDescription.module.css'

export type ProductDescriptionProps = {
  description: string
  careIcons?: CareIcons[]
  className?: string
}

const ProductDescription: FC<ProductDescriptionProps> = ({
  description,
  careIcons,
  className,
}) => {
  const { t } = useTranslation()

  const isOldDescription = !subStringIsRepeat(description, 'class="title"')

  const careText = [],
    careImg = []
  careIcons?.map((icon) => {
    if (icon.display === 'icon' || icon.display === 'icon_text')
      careImg.push(icon)
    else careText.push(icon)
  })

  let descriptionBlock,
    hasShowMore = false
  if (!isOldDescription) {
    const descriptionArray = subStringArray(description, '<div class="title">')
    const lastTab = descriptionArray
      ? descriptionArray[descriptionArray.length - 1]
      : null
    const hasAdditionalDetail = !!lastTab && subStringIsRepeat(lastTab, '<ul>')
    hasShowMore = !!careText?.length || !!careImg?.length || hasAdditionalDetail
    if (hasShowMore) {
      const delimiter = hasAdditionalDetail
        ? lastTab.indexOf('</ul><ul>') + 5
        : undefined
      const additionalDetail = [
        lastTab.substring(0, delimiter),
        lastTab.substring(delimiter),
      ]
      descriptionArray.splice(-1, 1)
      descriptionArray.push(
        additionalDetail[0],
        `<div class="title"><strong>${t(
          'block:brand.showMore'
        )}</strong></div>` +
          (delimiter ? additionalDetail[1] : '') +
          (careText?.length
            ? `
        <ul>
        ${careText
          .map(
            (icon) => `
            <li>
            ${icon?.name}
          </li>
          `
          )
          .join('')}
      </ul>
      `
            : '') +
          (careImg?.length
            ? `
            <ul class="iconCareTabList">
              ${careImg
                .map(
                  (icon) => `
                <li>
                  <img
                  class="iconCareTabImg"
                  src="${icon?.path}"
                  title="${icon?.name}"
                  alt="${icon?.name}"
                  />
                </li>
              `
                )
                .join('')}
            </ul>
        `
            : '')
      )
    }
    descriptionBlock = [...descriptionArray]
  }

  return (
    <div className={cn(className, s.container)}>
      {isOldDescription ? (
        <>
          <div
            className={cn(s.content, s.oldDescription)}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {careText?.length ? (
            <ul className={s.careText}>
              {careText.map((icon, index) => (
                <li key={`CareText-Key-${index}`} className={s.legend}>
                  {icon?.name}
                </li>
              ))}
            </ul>
          ) : null}
          {careImg?.length ? (
            <ul className={s.careImg}>
              {careImg?.map((icon, index) => (
                <li key={`CareImg-Key-${index}`}>
                  <img
                    className={s.picto}
                    src={icon?.path}
                    title={icon?.name}
                    alt={icon?.name}
                  />
                </li>
              ))}
            </ul>
          ) : (
            ''
          )}
        </>
      ) : (
        <ProductDescriptionTab descriptionBlock={descriptionBlock} />
      )}
    </div>
  )
}

export default React.memo(ProductDescription)
