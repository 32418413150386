import React, { createContext, useCallback, useEffect, useState } from 'react'

import { fetchSession, updateSession } from '@mw/session'
import { deleteItemFromBasket, addItemToBasket } from '@mw/basket'
import { addItemToWishlist, deleteItemFromWishlist } from '@mw/wishlist'
import { addItemToCustomerProductList } from '@mw/customerProductList'

import { DEFAULT_COUNTRY } from '@constants'

export interface UserSettingsContextTypes extends Session {
  selectCurrency: (currencyCode: string, locale: string) => void
  selectDeliveryCountry: (countryCode: string, locale: string) => void
  addToBasket: (sku: string, locale: string) => Promise<Basket | null>
  removeFromBasket: (
    sku: string,
    locale: string,
    quantity?: number
  ) => Promise<Basket | null>
  addToWishlist: (productId: number, locale: string) => Promise<void>
  removeFromWishlist: (productId: number, locale: string) => Promise<void>
  addToCustomerProductList: (
    id: number,
    sku: string,
    locale: string
  ) => Promise<void>
  refreshSession: () => void
}

const defaultUserSettingsContext: UserSettingsContextTypes = {
  isFetched: false,
  logged: false,
  uid: null,
  email: null,
  emailMd5: null,
  emailSha256: null,
  firstname: null,
  lastname: null,
  clientType: null,
  roles: [],
  currencyCode: 'EUR',
  currencyRate: 1,
  deliveryCountryCode: DEFAULT_COUNTRY,
  basket: {
    id: 0,
    totalPrice: '',
    totalPriceTaxIncluded: 0,
    totalPriceTaxExcluded: 0,
    items: [],
  },
  wishlist: null,
  customerProductLists: [],
  sales: [],
  discountCampaigns: [],
  tokens: null,
  navigationId: '',
  newsletterSubscription: true,
  subscribedToLoyalty: false,
  selectCurrency: () => {},
  selectDeliveryCountry: () => {},
  refreshSession: () => {},
  addToBasket: async () => null,
  removeFromBasket: async () => null,
  addToWishlist: async () => {},
  removeFromWishlist: async () => {},
  addToCustomerProductList: async () => {},
}

export const UserSettingsContext = createContext<UserSettingsContextTypes>(
  defaultUserSettingsContext
)

export const UserSettingsProvider = ({
  sessionData,
  children,
}: {
  sessionData: Session
  children: any | undefined
}) => {
  const [userProfile, setUserProfile] = useState<Session>(
    sessionData
      ? {
          isFetched: true,
          ...sessionData,
        }
      : {
          isFetched: defaultUserSettingsContext.isFetched,
          logged: defaultUserSettingsContext.logged,
          uid: defaultUserSettingsContext.uid,
          email: defaultUserSettingsContext.email,
          emailMd5: defaultUserSettingsContext.emailMd5,
          emailSha256: defaultUserSettingsContext.emailSha256,
          firstname: defaultUserSettingsContext.firstname,
          lastname: defaultUserSettingsContext.lastname,
          clientType: defaultUserSettingsContext.clientType,
          roles: defaultUserSettingsContext.roles,
          currencyCode: defaultUserSettingsContext.currencyCode,
          currencyRate: defaultUserSettingsContext.currencyRate,
          deliveryCountryCode: defaultUserSettingsContext.deliveryCountryCode,
          basket: defaultUserSettingsContext.basket,
          wishlist: defaultUserSettingsContext.wishlist,
          customerProductLists: defaultUserSettingsContext.customerProductLists,
          sales: defaultUserSettingsContext.sales,
          discountCampaigns: defaultUserSettingsContext.discountCampaigns,
          tokens: defaultUserSettingsContext.tokens,
          navigationId: defaultUserSettingsContext.navigationId,
          newsletterSubscription:
            defaultUserSettingsContext.newsletterSubscription,
          subscribedToLoyalty: defaultUserSettingsContext.subscribedToLoyalty,
        }
  )

  const selectCurrency = useCallback(
    async (_currencyCode: string, locale: string): Promise<void> => {
      const sessionData = await updateSession({
        currencyCode: _currencyCode,
        locale,
      })
      if (sessionData?.data)
        setUserProfile({ ...userProfile, ...sessionData.data })
    },
    [userProfile]
  )

  const selectDeliveryCountry = useCallback(
    async (_countryCode: string, locale: string): Promise<void> => {
      const sessionData = await updateSession({
        deliveryCountryCode: _countryCode,
        locale,
      })
      if (sessionData?.data)
        setUserProfile({ ...userProfile, ...sessionData.data })
    },
    [userProfile]
  )

  const addToBasket = useCallback(
    async (sku: string, locale: string): Promise<Basket | null> => {
      const _newBasket = await addItemToBasket({ sku, locale })
      setUserProfile({ ...userProfile, basket: _newBasket })
      return _newBasket
    },
    [userProfile]
  )

  const removeFromBasket = useCallback(
    async (
      sku: string,
      locale: string,
      quantity?: number
    ): Promise<Basket | null> => {
      try {
        const _newBasket = await deleteItemFromBasket({ sku, locale, quantity })
        setUserProfile({ ...userProfile, basket: _newBasket })
        return _newBasket
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [userProfile]
  )

  const addToWishlist = useCallback(
    async (productId: number, locale: string): Promise<void> => {
      const _newWishlist = await addItemToWishlist({ productId, locale })
      setUserProfile({ ...userProfile, wishlist: _newWishlist })
    },
    [userProfile]
  )

  const removeFromWishlist = useCallback(
    async (productId: number, locale: string): Promise<void> => {
      const _newWishlist = await deleteItemFromWishlist({
        productId,
        locale,
      })
      setUserProfile({ ...userProfile, wishlist: _newWishlist })
    },
    [userProfile]
  )

  const addToCustomerProductList = useCallback(
    async (id: number, sku: string, locale: string): Promise<void> => {
      const list = await addItemToCustomerProductList({ id, sku, locale })

      const customerProductLists = [...(userProfile.customerProductLists || [])]
      const index = customerProductLists.findIndex(
        ({ id }: CustomerProductList) => id === list.id
      )
      customerProductLists[index !== -1 ? index : customerProductLists.length] =
        list

      setUserProfile({ ...userProfile, customerProductLists })
    },
    [userProfile]
  )

  const refreshSession = useCallback(async (): Promise<void> => {
    const sessionData = await fetchSession()
    if (sessionData?.data)
      setUserProfile({ ...userProfile, ...sessionData.data })
  }, [userProfile])

  useEffect(() => {
    ;(async () => {
      try {
        if (!sessionData) {
          const sessionDataCSR = await fetchSession()
          if (sessionDataCSR?.data)
            setUserProfile({ isFetched: true, ...sessionDataCSR.data })
        }
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  return (
    <UserSettingsContext.Provider
      value={{
        isFetched: userProfile.isFetched,
        logged: userProfile.logged,
        uid: userProfile.uid,
        email: userProfile.email,
        emailMd5: userProfile.emailMd5,
        emailSha256: userProfile.emailSha256,
        firstname: userProfile.firstname,
        lastname: userProfile.lastname,
        clientType: userProfile.clientType,
        roles: userProfile.roles,
        currencyCode: userProfile.currencyCode,
        currencyRate: userProfile.currencyRate,
        deliveryCountryCode: userProfile.deliveryCountryCode,
        basket: userProfile.basket,
        wishlist: userProfile.wishlist,
        customerProductLists: userProfile.customerProductLists,
        sales: userProfile.sales,
        discountCampaigns: userProfile.discountCampaigns,
        tokens: userProfile.tokens,
        navigationId: userProfile.navigationId,
        newsletterSubscription: userProfile.newsletterSubscription,
        subscribedToLoyalty: userProfile.subscribedToLoyalty,
        selectCurrency,
        selectDeliveryCountry,
        addToBasket,
        removeFromBasket,
        addToWishlist,
        removeFromWishlist,
        addToCustomerProductList,
        refreshSession,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}

export const useUserSettings = () => {
  const context = React.useContext(UserSettingsContext)
  if (context === undefined) throw new Error(`User Profil Context is undefined`)
  return context
}
