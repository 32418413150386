import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { DropdownSelect } from '@components/ui'
import { ProductAvailabilityLabel } from '@components/product'
import { Mail } from '@components/icons'

import {
  ProductStocks,
  hasProductStocks,
  hasDeclinationStocks,
} from '@utils/stocks'

import cn from 'classnames'
import s from './ProductSizeSelector.module.css'

export interface ProductSizeSelectorProps {
  sizes: Array<Declination>
  className?: string
  expanded: boolean
  onExpand: (expanded: boolean) => void
  onClickOutside?: () => void
  selectedSize: string | undefined
  onSizeSelected: (value: string) => void
  stocks: ProductStocks
  isOnMobileQuickBuy?: boolean
  setIsDropDownOpen?: (value: boolean) => void
}

const SizeContent: FC<{
  size: Declination
  stocks: ProductStocks
  isOnMobileQuickBuy: boolean
}> = ({ size, stocks, isOnMobileQuickBuy }) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn(s.sizeContainer, {
        [s.empty]: !hasDeclinationStocks(stocks, size?.sku),
        [s.lowStock]:
          stocks?.declinations[size.sku]?.quantity > 0 &&
          stocks?.declinations[size.sku]?.quantity <= 3,
      })}
    >
      <div className={cn(s.sizeValue)}>{size?.size} </div>
      {!hasDeclinationStocks(stocks, size.sku) && isOnMobileQuickBuy ? (
        <div className={s.noStock}>
          <div>{t('product:isUnavailable')}</div>
          <Mail width={16} />
        </div>
      ) : (
        <ProductAvailabilityLabel
          className={s.label}
          stocks={stocks}
          sku={size?.sku}
        />
      )}
    </div>
  )
}

const ProductSizeSelector: FC<ProductSizeSelectorProps> = ({
  className,
  expanded,
  onExpand,
  onClickOutside,
  onSizeSelected,
  selectedSize,
  sizes = [],
  stocks,
  isOnMobileQuickBuy = false,
  setIsDropDownOpen,
}) => {
  const { t } = useTranslation()

  const getOptionValue = (size: Declination) => size.sku

  if (sizes?.length === 1) {
    return (
      <div
        className={cn(s.uniqueSizeContainer, className, {
          [s.empty]: !hasDeclinationStocks(stocks, sizes[0]?.sku),
          [s.lowStock]:
            stocks?.declinations[sizes[0]?.sku]?.quantity > 0 &&
            stocks?.declinations[sizes[0]?.sku]?.quantity <= 3,
        })}
        onClick={
          isOnMobileQuickBuy ? () => onSizeSelected(sizes[0]?.sku) : null
        }
      >
        <span className={s.uniqueSize}>{sizes[0]?.size} </span>
        {!hasProductStocks(stocks) && isOnMobileQuickBuy ? (
          <div className={s.noStock}>
            <div>{t('product:isUnavailable')}</div>
            <Mail width={18} />
          </div>
        ) : (
          <ProductAvailabilityLabel
            className={s.label}
            stocks={stocks}
            sku={sizes[0]?.sku}
          />
        )}
      </div>
    )
  }

  return (
    <DropdownSelect
      id="productSize"
      className={cn(s.customSelectContainer, className)}
      expanded={expanded}
      getOptionValue={getOptionValue}
      onChange={onSizeSelected}
      options={sizes}
      onClickOutside={onClickOutside}
      placeholder={t('product:sizeSelector.placeholder')}
      renderOption={(size: Declination) => (
        <SizeContent
          size={size}
          stocks={stocks}
          isOnMobileQuickBuy={isOnMobileQuickBuy}
        />
      )}
      setExpanded={onExpand}
      title={t('product:quickBuy.declination.size')}
      value={selectedSize}
      setIsDropDownOpen={setIsDropDownOpen}
      isOnMobileQuickBuy={isOnMobileQuickBuy}
    />
  )
}

export default ProductSizeSelector
