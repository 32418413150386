const SearchIconLight = ({ size = '14', color = '#121212', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 2C5.69365 2 2 5.69365 2 10.25C2 14.8063 5.69365 18.5 10.25 18.5C12.3464 18.5 14.2603 17.718 15.7157 16.4299L21.1394 21.8536C21.3346 22.0488 21.6512 22.0488 21.8465 21.8536C22.0417 21.6583 22.0417 21.3417 21.8465 21.1464L16.4232 15.7232C17.7153 14.2668 18.5 12.3501 18.5 10.25C18.5 5.69365 14.8063 2 10.25 2ZM17.5 10.25C17.5 14.2541 14.2541 17.5 10.25 17.5C6.24594 17.5 3 14.2541 3 10.25C3 6.24594 6.24594 3 10.25 3C14.2541 3 17.5 6.24594 17.5 10.25Z"
      fill={color}
    />
  </svg>
)

export default SearchIconLight
