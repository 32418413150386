import React, { FC, LegacyRef, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import useScreen from '@lib/hooks/useScreen'
import { useKeenSlider } from 'keen-slider/react'

import BlockLinkWrapper from '@components/blocks-cms/BlockLinkWrapper'

import { removeTags, shortenDescription } from '@utils/string'

import 'keen-slider/keen-slider.min.css'
import cn from 'classnames'
import s from './MagazinesSlider.module.css'
import { useUI } from '@components/ui/context'

export interface MagazinesSliderProps {
  title: string
  slidesPerView?: number
  magazines: Array<{
    magazineId: number
    name: string
    url: string
    description: string
    descriptionShort: string
    section: string
    title: string
    titleShort: string
    media: BlocksMedia
  }>
}

const MagazinesSlider: FC<MagazinesSliderProps> = ({
  title,
  slidesPerView,
  magazines,
}) => {
  const { isSiteReverted } = useUI()
  const { t } = useTranslation()
  const screen = useScreen()

  const arrowLeftRef = useRef<HTMLButtonElement>()
  const arrowRightRef = useRef<HTMLButtonElement>()
  const [isMounted, setIsMounted] = useState(false)

  const _slidesPerView =
    screen === 'xs' || screen === 'sm' ? 1.1 : slidesPerView || 3.25
  const toLoop = magazines?.length > _slidesPerView

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    rtl: isSiteReverted,
    loop: toLoop,
    defaultAnimation: {
      duration: 600,
    },
    slides: {
      perView: _slidesPerView,
    },
    created: () => setIsMounted(true),
  })

  if (!magazines?.length) return null

  const buttons = [
    <button
      className={s.arrowLeft}
      onClick={() => instanceRef.current?.prev()}
      ref={arrowLeftRef as LegacyRef<HTMLButtonElement>}
      aria-label={t('block:slideBlock.ariaSlideNext')}
    />,
    <button
      className={s.arrowRight}
      onClick={() => instanceRef.current?.next()}
      ref={arrowRightRef as LegacyRef<HTMLButtonElement>}
      aria-label={t('block:slideBlock.ariaSlidePrev')}
    />,
  ]

  if (isSiteReverted) {
    buttons.reverse()
  }

  return (
    <section className={cn('magazines_slider', s.container)}>
      <div className={cn(s.head, { 'flex-row': !toLoop })}>
        {instanceRef && !(screen === 'xs' || screen === 'sm') && toLoop ? (
          <div className={s.control}>{buttons}</div>
        ) : null}
        {title && <h2 className={s.title}>{title}</h2>}
      </div>
      <div
        ref={sliderRef}
        className={cn('keen-slider', { [s.jsDisabled]: !isMounted })}
      >
        {magazines?.map((magazine, i) => {
          if (
            !magazine?.media?.formats?.origin?.length ||
            !magazine?.title?.length ||
            !magazine?.url?.length
          )
            return null
          return (
            <div
              key={`magazine_slide_${magazine?.magazineId}_${i}`}
              className={cn(
                'keen-slider__slide',
                s.slide,
                { [s.jsDisabledBlocks]: !isMounted },
                {
                  [s.floating]: !Number.isInteger(_slidesPerView),
                }
              )}
            >
              <BlockLinkWrapper
                className={s.link}
                title={magazine.title}
                url={magazine.url}
                targetBlank={false}
                isPopin={false}
              >
                <div
                  className={cn(s.mediaContainer, {
                    [s.large]: _slidesPerView < 3,
                  })}
                  style={{
                    backgroundImage: `url(${magazine.media.formats.origin})`,
                  }}
                />
                <h3
                  className={s.name}
                  dangerouslySetInnerHTML={{
                    __html: removeTags(magazine?.titleShort || magazine.title),
                  }}
                />
                {magazine?.description?.length ? (
                  <p
                    className={s.desc}
                    dangerouslySetInnerHTML={{
                      __html: shortenDescription(
                        magazine.description,
                        screen === 'md' ? 85 : 160
                      ),
                    }}
                  />
                ) : null}
                <p className={s.cta}>{t('block:loadMore.seeSelection')}</p>
              </BlockLinkWrapper>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default MagazinesSlider
