import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import FiltersList from '@components/blocks-cms/ProductsListing/FiltersMenu/FiltersList'

import { sortFilters } from '@utils/filters'

import type { changeFiltersSelectedProps } from '@components/blocks-cms/ProductsListing/ProductsListing'

import s from './FiltersMenu.module.css'

export interface FiltersMenuProps {
  filters: ProductsListFilters
  total: number
  setOpenFilters: (toOpen: boolean) => void
  changeFiltersSelected: changeFiltersSelectedProps
  resetFilters: () => void
}

const FiltersMenu: FC<FiltersMenuProps> = ({
  filters,
  total,
  setOpenFilters,
  changeFiltersSelected,
  resetFilters,
}) => {
  const { t } = useTranslation()

  const sortedFilters = sortFilters(filters)

  return (
    <div className={s.container}>
      <div className={s.list}>
        {sortedFilters?.map((filter, index) => (
          <FiltersList
            key={`ListCategory-${index}`}
            filter={filter}
            changeFiltersSelected={changeFiltersSelected}
          />
        ))}
      </div>
      <div className={s.action}>
        <button className={s.button} onClick={() => resetFilters()}>
          {t('block:filter.deleteFilters')}
        </button>
        <div className={s.separator} aria-hidden></div>
        <button className={s.button} onClick={() => setOpenFilters(false)}>
          {t('block:filter.applyFilters', { filterText: total })}
        </button>
      </div>
    </div>
  )
}

export default FiltersMenu
