import { UserSettingsContextTypes } from 'context/session'
import { collector } from '@lib/eulerian'

export const tagEventAddToBasket = ({
  urlp,
  locale,
  sessionData,
  productData,
}: {
  urlp: string
  locale: string
  sessionData: UserSettingsContextTypes
  productData: Basket
}) => {
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
  if (urlp?.length) {
    const {
      emailMd5,
      emailSha256,
      uid,
      clientType,
      currencyCode,
      logged,
      subscribedToLoyalty,
    } = sessionData
    const { totalPrice, items } = productData
    const {
      brand,
      color,
      name,
      price,
      id,
      quantity,
      size,
      priceTaxIncluded,
      reference,
      sections,
      persons,
      sexs,
      typologies,
    } = items[items.length - 1]

    const _urlp = locale && urlp ? locale + urlp : null

    const eventTags = [
      ...(_urlp ? ['path', _urlp] : []),
      ...(emailSha256 ? ['email', emailSha256] : []),
      ...(emailMd5 ? ['email_md5', emailMd5] : []),
      ...(locale ? ['lang', locale] : []),
      ...(uid ? ['uid', uid] : []),
      ...(currencyCode ? ['currency', currencyCode] : []),
      ...(currencyCode ? ['devise', currencyCode] : []),
      ...(clientType ? ['type_client', clientType] : []),
      ...['is_logged', `${logged ? 'true' : 'false'}`],
      ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
      ...(clientType ? ['type_client', clientType] : []),
      ...(appEnv ? ['env', appEnv] : []),
      ...(appVersion ? ['version', appVersion] : []),
      ...(totalPrice ? ['montant_panier', totalPrice] : []),
      'sccumul',
      '1',
      ...(id ? ['prdparam-refproduit', `${id}`] : []),
      ...(id ? ['prdref', `${id}`] : []),
      ...(price ? ['prdamount', price] : []),
      ...(priceTaxIncluded
        ? ['prdamount_ttc_avantreduc', `${priceTaxIncluded}`]
        : []),
      ...(name && brand?.name ? ['prdname', name + ' ' + brand.name] : []),
      ...(brand?.name ? ['prdparam-brand', brand.name] : []),
      ...(color ? ['prdparam-color', color] : []),
      ...(size ? ['prdparam-size', size] : []),
      ...(quantity ? ['prdquantity', `${quantity}`] : []),
      ...(sections?.length ? ['prdparam-category', sections[0]] : []),
      ...(reference ? ['prdparam-refco', reference] : []),
      ...(persons?.length ? ['prdparam-genre', persons[0]] : []),
      ...(sexs?.length ? ['prdparam-sexe', sexs[0]] : []),
      ...(typologies?.length ? ['prdgroup', typologies[1]] : []),
      ...(typologies?.length ? ['prdparam-type-n1', typologies[0]] : []),
      ...(typologies && typologies[1]
        ? ['prdparam-type-n2', typologies[1]]
        : []),
      ...(typologies && typologies[2]
        ? ['prdparam-type-n3', typologies[2]]
        : []),
      ...(typologies && typologies[3]
        ? ['prdparam-type-n4', typologies[3]]
        : []),
    ]

    collector({ tags: eventTags, urlp: _urlp })
  }
}

export const tagEventRemoveFromBasket = ({
  urlp,
  locale,
  sessionData,
  productData,
  skuRemove,
}: {
  urlp: string
  locale: string
  sessionData: UserSettingsContextTypes
  productData: Basket
  skuRemove: string
}) => {
  if (urlp?.length) {
    const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
    const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
    const {
      emailMd5,
      emailSha256,
      uid,
      clientType,
      currencyCode,
      logged,
      subscribedToLoyalty,
    } = sessionData
    const { totalPrice } = productData
    const itemRemove = productData.items.find((item) => item.sku === skuRemove)
    const {
      brand,
      color,
      name,
      price,
      id,
      quantity,
      size,
      priceTaxIncluded,
      reference,
      sections,
      persons,
      sexs,
      typologies,
    } = itemRemove

    const _urlp = locale && urlp ? locale + urlp : null

    const eventTags = [
      ...(_urlp ? ['path', _urlp] : []),
      ...(emailSha256 ? ['email', emailSha256] : []),
      ...(emailMd5 ? ['email_md5', emailMd5] : []),
      ...(locale ? ['lang', locale] : []),
      ...(uid ? ['uid', uid] : []),
      ...(currencyCode ? ['currency', currencyCode] : []),
      ...(currencyCode ? ['devise', currencyCode] : []),
      ...(clientType ? ['type_client', clientType] : []),
      ...['is_logged', `${logged ? 'true' : 'false'}`],
      ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
      ...(appEnv ? ['env', appEnv] : []),
      ...(appVersion ? ['version', appVersion] : []),
      ...(totalPrice ? ['montant_panier', totalPrice] : []),
      'sccumul',
      '1',
      ...(id ? ['prdparam-refproduit', `${id}`] : []),
      ...(id ? ['prdref', `${id}`] : []),
      ...(price ? ['prdamount', price] : []),
      ...(priceTaxIncluded
        ? ['prdamount_ttc_avantreduc', `${priceTaxIncluded}`]
        : []),
      ...(name && brand?.name ? ['prdname', name + ' ' + brand.name] : []),
      ...(brand?.name ? ['prdparam-brand', brand.name] : []),
      ...(color ? ['prdparam-color', color] : []),
      ...(size ? ['prdparam-size', size] : []),
      ...(quantity ? ['prdquantity', `-${quantity}`] : []),
      ...(sections?.length ? ['prdparam-category', sections[0]] : []),
      ...(reference ? ['prdparam-refco', reference] : []),
      ...(persons?.length ? ['prdparam-genre', persons[0]] : []),
      ...(sexs?.length ? ['prdparam-sexe', sexs[0]] : []),
      ...(typologies?.length ? ['prdgroup', typologies[1]] : []),
      ...(typologies?.length ? ['prdparam-type-n1', typologies[0]] : []),
      ...(typologies && typologies[1]
        ? ['prdparam-type-n2', typologies[1]]
        : []),
      ...(typologies && typologies[2]
        ? ['prdparam-type-n3', typologies[2]]
        : []),
      ...(typologies && typologies[3]
        ? ['prdparam-type-n4', typologies[3]]
        : []),
    ]

    collector({ tags: eventTags, urlp: _urlp })
  }
}

export const tagEventNewsletterInscription = ({
  urlp,
  locale,
  sessionData,
  emailNewsletter,
  emailMd5Newsletter,
}: {
  urlp: string
  locale: string
  sessionData: UserSettingsContextTypes
  emailNewsletter?: string
  emailMd5Newsletter?: string
}) => {
  if (urlp?.length) {
    const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
    const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
    const {
      emailMd5,
      emailSha256,
      uid,
      clientType,
      logged,
      subscribedToLoyalty,
    } = sessionData

    const _urlp = locale && urlp ? locale + urlp : null

    const eventTags = [
      'type',
      'INSCRIPTION_NEWSLETTER',
      ...(_urlp ? ['path', _urlp] : []),
      ...(emailSha256 ? ['email', emailSha256] : []),
      ...(emailMd5 ? ['email_md5', emailMd5] : []),
      ...(locale ? ['lang', locale] : []),
      ...(uid ? ['uid', uid] : []),
      ...(clientType ? ['type_client', clientType] : []),
      ...['is_logged', `${logged ? 'true' : 'false'}`],
      ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
      ...(appEnv ? ['env', appEnv] : []),
      ...(appVersion ? ['version', appVersion] : []),
      ...(emailNewsletter ? ['email_newsletter', emailNewsletter] : []),
      ...(emailMd5Newsletter
        ? ['email_md5_newsletter', emailMd5Newsletter]
        : []),
    ]

    collector({ tags: eventTags, urlp: _urlp })
  }
}

export const tagEventList = ({
  urlp,
  locale,
  sessionData,
  productData,
  selectedSize,
  type,
}: {
  urlp: string
  locale: string
  sessionData: UserSettingsContextTypes
  productData: {
    brand: ProductBrand
    name: string
    id: number
    color?: string
    declinations?: Array<Declination>
    reference?: string
    persons?: Array<string>
    sections?: Array<string>
    sexs?: Array<string>
    typologies?: Array<string>
  }
  selectedSize?: string
  type?: string
}) => {
  if (urlp?.length) {
    const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
    const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
    const {
      emailMd5,
      emailSha256,
      uid,
      clientType,
      logged,
      currencyCode,
      subscribedToLoyalty,
    } = sessionData
    const _size = productData?.declinations?.find(
      (declination) => declination.sku === selectedSize
    )
    const size = _size?.size || null

    const {
      brand,
      name,
      id,
      color,
      reference,
      sections,
      persons,
      sexs,
      typologies,
    } = productData

    const _urlp = locale && urlp ? locale + urlp : null

    const eventTags = [
      ...(_urlp ? ['path', _urlp] : []),
      ...(type ? ['type', type] : []),
      ...(emailSha256 ? ['email', emailSha256] : []),
      ...(emailMd5 ? ['email_md5', emailMd5] : []),
      ...(locale ? ['lang', locale] : []),
      ...(uid ? ['uid', uid] : []),
      ...(currencyCode ? ['currency', currencyCode] : []),
      ...(currencyCode ? ['devise', currencyCode] : []),
      ...(clientType ? ['type_client', clientType] : []),
      ...['is_logged', `${logged ? 'true' : 'false'}`],
      ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
      ...(appEnv ? ['env', appEnv] : []),
      ...(appVersion ? ['version', appVersion] : []),
      ...(id ? ['prdparam-refproduit', `${id}`] : []),
      ...(id ? ['prdref', `${id}`] : []),
      ...(name && brand?.name ? ['prdname', name + ' ' + brand.name] : []),
      ...(brand?.name ? ['prdparam-brand', brand.name] : []),
      ...(color ? ['prdparam-color', color] : []),
      ...(size ? ['prdparam-size', size] : []),
      ...(sections?.length ? ['prdparam-category', sections[0]] : []),
      ...(reference ? ['prdparam-refco', reference] : []),
      ...(persons?.length ? ['prdparam-genre', persons[0]] : []),
      ...(sexs?.length ? ['prdparam-sexe', sexs[0]] : []),
      ...(typologies?.length ? ['prdgroup', typologies[1]] : []),
      ...(typologies?.length ? ['prdparam-type-n1', typologies[0]] : []),
      ...(typologies && typologies[1]
        ? ['prdparam-type-n2', typologies[1]]
        : []),
      ...(typologies && typologies[2]
        ? ['prdparam-type-n3', typologies[2]]
        : []),
      ...(typologies && typologies[3]
        ? ['prdparam-type-n4', typologies[3]]
        : []),
    ]

    collector({ tags: eventTags, urlp: _urlp })
  }
}
