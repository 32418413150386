import { FC } from 'react'
import { useUserSettings } from '@context/session'
import { useRouter } from 'next/router'

import ProductCard from '@components/product/ProductCard/ProductCard'

import { mediaSrcCard } from '@utils/algolia'
import {
  getApiPrices,
  getDeclinations,
  getStocks,
} from '@lib/lib-algolia/algolia'
import toSlug from '@lib/toSlug'

const ProductCardAlgolia: FC<ProductCardAlgolia> = ({
  hit,
  productsPerLine = 3,
  layout = null,
  disableSlider = false,
  priority = false,
}) => {
  const { locale } = useRouter()
  const { currencyCode, currencyRate, deliveryCountryCode } = useUserSettings()

  if (!hit?.declinations) return null
  return (
    <ProductCard
      productsPerLine={productsPerLine}
      layout={layout}
      disableSlider={disableSlider}
      priority={priority}
      hit={hit}
      product={{
        prices: getApiPrices({
          hit,
          currencyCode,
          currencyRate,
          deliveryCountryCode,
          locale,
        }),
        stocks: getStocks(hit),
        attachColor: hit.attachColor,
        alertAvailability: hit.availabilityAlert,
        brand: hit.brand,
        color: hit.color,
        declinations: getDeclinations(hit),
        id: hit.id,
        medias: mediaSrcCard(hit),
        name: hit.name,
        persons: hit.persons,
        reference: hit.reference,
        sexs: hit.sexs,
        // "thematics" (dans Elasticsearch et dans Akeneo) est donc nommé "thematics" dans Algolia, c'est l'api produit qui appelle ça "sections" à tort
        sections: hit.thematics,
        tags: {
          isNew: hit.isNew,
          isExclusive: hit.isExclusive,
          isGreenable: hit.isGreenable,
          isCharitable: hit.isCharitable,
        },
        // Types & typo : remplir le tableau et slugify les données
        // Typologies : La façon dont on range les produits (exemple : TShirt, Polo > TShirt > TShirt Manche courte > Débardeur)
        typologies:
          hit.typologies && hit.typologies[0]
            ? Object.values(hit.typologies[0]).map((typ) =>
                toSlug(typ as string)
              )
            : [],
        // Types : miroir des pages de Smallable.com, exemple : Streetwear > Snikers
        types:
          hit.types && hit.types[hit.types.length - 1]
            ? Object.values(hit.types[hit.types.length - 1]).map(
                (typ) => typ && toSlug(typ as string)
              )
            : [],
        // Siblings : couleurs alternatives
        siblings: hit.siblings,
      }}
    />
  )
}

export default ProductCardAlgolia
