import cn from 'classnames'
import s from './LoadingDots.module.css'

export interface LoadingDotsProps {
  className?: string
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ className }) => {
  return (
    <span className={cn(s.root, className)}>
      <span />
      <span />
      <span />
    </span>
  )
}

export default LoadingDots
