import { getDidomiConsent, getPageTypeCatalog, getEventName } from '@utils/data'
import { BRAND_PAGES } from '@constants'

declare global {
  interface Window {
    _uxa: {
      push: (tags: Array<string | number | { [key: string]: string }>) => void
    }
  }
}

export const tagEventCS = ({
  eventName,
  eventType = 'trackPageEvent',
}: {
  eventName: string
  eventType?: 'trackPageEvent' | 'trackPageview'
}) => {
  if (
    typeof window === 'undefined' ||
    !getDidomiConsent({ vendor: 'c:contentsquare' }) ||
    !eventName
  ) {
    return null
  }
  window._uxa = window._uxa || []
  window._uxa.push([eventType, eventName])
}

export const tagPrintCS = ({
  urlp,
  tags,
  deliveryCountryCode,
  basket,
}: {
  urlp: string
  tags: (string | number)[]
  deliveryCountryCode: string
  basket: Basket
}) => {
  if (
    typeof window === 'undefined' ||
    !getDidomiConsent({ vendor: 'c:contentsquare' }) ||
    urlp?.includes('basket/add' || '/giftlist/add' || '/wishlist/add')
  ) {
    return null
  }
  window._uxa = window._uxa || []
  let arrayBrand = []
  let arrayCategory = []
  let arrayGenre = []
  let arraySexe = []
  let arrayType1 = []
  let arrayType2 = []
  let arrayType3 = []
  const basketID = basket?.items?.length >= 1 ? basket.id : 'none'
  try {
    if (tags?.length) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i] === 'urlp' || tags[i] === 'path') {
          window._uxa.push(['setCustomVariable', 1, 'path', tags[i + 1], 3])
        }
        if (tags[i] === 'devise') {
          window._uxa.push(['setCustomVariable', 2, 'devise', tags[i + 1], 3])
        }
        if (tags[i] === 'amount') {
          window._uxa.push(['setCustomVariable', 3, 'amount', tags[i + 1], 3])
        }
        if (deliveryCountryCode) {
          window._uxa.push([
            'setCustomVariable',
            4,
            'deliveryCountryCode',
            deliveryCountryCode,
            3,
          ])
        }
        if (tags[i] === 'payment') {
          window._uxa.push(['setCustomVariable', 5, 'payment', tags[i + 1], 3])
        }
        if (tags[i] === 'prdparam-brand') {
          arrayBrand.push(tags[i + 1])
        }
        if (tags[i] === 'prdparam-category') {
          arrayCategory.push(tags[i + 1])
        }
        if (tags[i] === 'prdparam-genre') {
          arrayGenre.push(tags[i + 1])
        }
        if (tags[i] === 'prdparam-sexe') {
          arraySexe.push(tags[i + 1])
        }
        if (tags[i] === 'prdparam-type-n1') {
          arrayType1.push(tags[i + 1])
        }
        if (tags[i] === 'prdparam-type-n2') {
          arrayType2.push(tags[i + 1])
        }
        if (tags[i] === 'prdparam-type-n3') {
          arrayType3.push(tags[i + 1])
        }
        if (basketID) {
          window._uxa.push(['setCustomVariable', 13, 'basketID', basketID, 3])
        }
        if (tags[i] === 'type_client') {
          window._uxa.push([
            'setCustomVariable',
            14,
            'type_client',
            tags[i + 1],
            3,
          ])
        }
        if (tags[i] === 'nc') {
          window._uxa.push(['setCustomVariable', 15, 'nc', tags[i + 1], 3])
        }
        if (tags[i] === 'env') {
          window._uxa.push(['setCustomVariable', 16, 'env', tags[i + 1], 3])
        }
        if (tags[i] === 'version') {
          window._uxa.push(['setCustomVariable', 17, 'version', tags[i + 1], 3])
        }
        if (tags[i] === 'loyaltySubscribed') {
          window._uxa.push([
            'setCustomVariable',
            19,
            'loyaltySubscribed',
            tags[i + 1],
            3,
          ])
        }
        if (tags[i] === 'is_logged') {
          window._uxa.push([
            'setCustomVariable',
            20,
            'userLogged',
            tags[i + 1],
            3,
          ])
        }
        let pageType = getPageTypeCatalog({ urlp, brandPage: BRAND_PAGES })
        if (pageType?.length) {
          window._uxa.push(['setCustomVariable', 18, 'pagetype', pageType, 3])
        }
      }
      window._uxa.push([
        'setCustomVariable',
        6,
        'prdparam-brand',
        arrayBrand.join('|'),
        3,
      ])
      window._uxa.push([
        'setCustomVariable',
        7,
        'prdparam-category',
        arrayCategory.join('|'),
        3,
      ])
      window._uxa.push([
        'setCustomVariable',
        8,
        'prdparam-genre',
        arrayCategory.join('|'),
        3,
      ])
      window._uxa.push([
        'setCustomVariable',
        9,
        'prdparam-sexe',
        arraySexe.join('|'),
        3,
      ])
      window._uxa.push([
        'setCustomVariable',
        10,
        'prdparam-type-n1',
        arrayType1.join('|'),
        3,
      ])
      window._uxa.push([
        'setCustomVariable',
        11,
        'prdparam-type-n2',
        arrayType2.join('|'),
        3,
      ])
      window._uxa.push([
        'setCustomVariable',
        12,
        'prdparam-type-n3',
        arrayType3.join('|'),
        3,
      ])
    }
  } catch (err) {
    console.error(err)
  }
  if (
    window.location.pathname.indexOf('basket/') > 0 ||
    window.location.pathname.indexOf('payment/confirm') > 0
  ) {
    window._uxa.push([
      'trackDynamicVariable',
      { key: 'prdparam-brand_group', value: arrayBrand.join(' | ') },
    ])
  }
  try {
    if (typeof window.CS_CONF === 'undefined') {
      window._uxa.push([
        'setPath',
        window.location.pathname + window.location.hash.replace('#', '?__'),
      ])
      let mt = document.createElement('script')
      mt.type = 'text/javascript'
      mt.async = true
      mt.src = '//t.contentsquare.net/uxa/4f721bb5a353e.js'
      mt.onload = () => {
        if (typeof window.CS_CONF !== 'undefined')
          window.CS_CONF.integrations = window.CS_CONF.integrations || []
      }
      document.getElementsByTagName('head')[0].appendChild(mt)
    } else {
      window._uxa.push([
        'trackPageview',
        window.location.pathname + window.location.hash.replace('#', '?__'),
      ])
    }
  } catch (err) {
    console.error(err)
  }
}

export const tagFakePrintCS = ({ urlp }: { urlp: string }) => {
  try {
    if (
      typeof window === 'undefined' ||
      !getDidomiConsent({ vendor: 'c:contentsquare' })
    ) {
      return null
    }
    const eventName = getEventName(urlp)
    if (eventName) {
      window._uxa = window._uxa || []
      window._uxa.push([
        'trackPageview',
        window.location.pathname +
          window.location.hash.replace('#', '?__') +
          eventName,
      ])
    }
  } catch (err) {
    console.error(err)
  }
}
