const CrossedOutEye = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 12C3 12 6.27273 7 12 7C17.7273 7 21 12 21 12C21 12 17.7273 17 12 17C6.27273 17 3 12 3 12Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15Z"
      fill="#121212"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11C10 9.89543 10.8954 9 12 9"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 3L21 21"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CrossedOutEye
