import React from 'react'

export default function SearchEmpty({ stroke = '#121212', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.5C5.52944 1.5 1.5 5.52944 1.5 10.5C1.5 15.4706 5.52944 19.5 10.5 19.5C12.8054 19.5 14.9083 18.6332 16.5006 17.2077L21.6464 22.3536C21.8417 22.5488 22.1583 22.5488 22.3536 22.3536C22.5488 22.1583 22.5488 21.8417 22.3536 21.6464L17.2077 16.5006C18.6332 14.9083 19.5 12.8054 19.5 10.5C19.5 5.52944 15.4706 1.5 10.5 1.5ZM16.4905 15.8024C17.7409 14.3908 18.5 12.5341 18.5 10.5C18.5 6.08172 14.9183 2.5 10.5 2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5C12.5341 18.5 14.3908 17.7409 15.8024 16.4905L16.4905 15.8024Z"
        fill={stroke}
      />
    </svg>
  )
}
