import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Check } from '@components/icons'
import { Arrow } from '@components/ui'
import normalize from '@lib/normalize'

import { RefinementListWidgetParams } from 'instantsearch.js/es/widgets/refinement-list/refinement-list'
import {
  useCurrentRefinements,
  UseCurrentRefinementsProps,
  useRefinementList,
  UseRefinementListProps,
} from 'react-instantsearch'

import cn from 'classnames'
import s from './Refinement.module.css'

interface RefinementProps {
  title?: string
  unity?: string
}

export type RefinementListProps = UseRefinementListProps &
  UseCurrentRefinementsProps &
  Pick<RefinementListWidgetParams, 'searchable' | 'searchablePlaceholder'> &
  RefinementProps

export function RefinementCheckbox(props: RefinementListProps) {
  const { t } = useTranslation()
  const { title, unity, attribute } = props
  const { items, refine } = useRefinementList(props)
  const { items: itemsRefinement } = useCurrentRefinements(props)

  const [openFilter, setOpenFilter] = useState(false)
  const [query, setQuery] = useState('')

  const [searchField, setSearchField] = useState('')
  const [itemsFiltered, setItemsFiltered] = useState([...items])

  useEffect(() => {
    if (searchField?.length <= 0) {
      setItemsFiltered([...items])
    } else {
      setItemsFiltered(
        [...items].filter((item) =>
          normalize(item.label.toLowerCase()).includes(
            normalize(searchField.toLowerCase())
          )
        )
      )
    }
  }, [searchField, items])

  useEffect(() => {
    if (
      itemsRefinement.some((item) => item.attribute === attribute) &&
      !openFilter
    ) {
      setOpenFilter(true)
    }
  }, [itemsRefinement])

  return (
    <>
      {(items?.length > 0 ||
        (props.searchable && items?.length === 0 && !!query)) && (
        <div
          className={cn(s.container, {
            [s.disabled]: !openFilter,
          })}
        >
          <button className={s.cta} onClick={() => setOpenFilter(!openFilter)}>
            {title}
            <Arrow
              width="10"
              height="10"
              stroke="white"
              className={s.arrowDown}
              aria-hidden
            />
          </button>

          {props.searchable && (
            <input
              className={s.inputSearch}
              aria-label={t('block:filter.ariaSearchBrand')}
              type="text"
              placeholder={t('block:filter.searchBrand')}
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            />
          )}
          <ul className={cn(s.filtersList, s.buttonItemContainer)}>
            {itemsFiltered.map((item, index) => (
              <React.Fragment key={`Filters-item-${index}`}>
                <li
                  key={item.value}
                  className={cn(s.containerCheckbox, {
                    [s.disabled]: !item.count,
                  })}
                >
                  <div className={s.contentCheckbox}>
                    <input
                      id={`checkbox_${normalize(item.label)}`}
                      className={cn(s.boxCheckbox, {
                        [s.checked]: item.isRefined,
                      })}
                      type="checkbox"
                      disabled={!item.count && !item.isRefined}
                      value={item.value}
                      checked={item.isRefined}
                      onChange={() => {
                        refine(item.value)
                        setQuery('')
                      }}
                    />
                    <label
                      htmlFor={`checkbox_${normalize(item.label)}`}
                      className={s.titleCheckbox}
                    >
                      {item.isRefined && <Check aria-hidden />}
                      {item.label || item.value}
                      {unity}
                      {item.count ? (
                        <span className={s.totalCheckbox}>{item.count}</span>
                      ) : null}
                    </label>
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default RefinementCheckbox
