import { useEffect, useState } from 'react'
import hasWindow from '../hasWindow'

export interface State {
  x: number
  y: number
}

const useWindowScroll = (): State => {
  const [state, setState] = useState<State>({
    x: hasWindow() ? window.pageXOffset : 0,
    y: hasWindow() ? window.pageYOffset : 0,
  })

  useEffect(() => {
    const handler = () => {
      setState({
        x: window.pageXOffset,
        y: window.pageYOffset,
      })
    }

    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    })

    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [])

  return state
}

export default useWindowScroll
