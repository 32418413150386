export const getCookie = (name: string, cookieString: string) => {
  let v = cookieString?.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const setCookie = (name: string, value: string, days: number) => {
  let date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + '; ' + expires + '; path=/'
}

export const deleteCookie = (name: string) => {
  setCookie(name, '', -1)
}

export const writeServerCookie = ({
  name,
  value,
  days = 1,
  path = '/',
  domain = process.env.SMALLABLE_COOKIE_DOMAIN,
  secure = true,
  httpOnly = true,
  sameSite = 'Lax',
}: {
  name: string
  value: string
  days?: number
  path?: string
  domain?: string
  secure?: boolean
  httpOnly?: boolean
  sameSite?: string
}): string => {
  const resMaxAge = days ? ` Max-Age=${86400 * days};` : ''
  const resPath = path ? ` path=${path};` : ''
  const resDomain = domain ? ` domain=${domain};` : ''
  const resSecure = secure ? ` secure;` : ''
  const resHttpOnly = httpOnly ? ` HttpOnly;` : ''
  const resSameSite = ` sameSite=${sameSite};`

  return `${name}=${value};${resMaxAge}${resPath}${resDomain}${resSecure}${resHttpOnly}${resSameSite}`
}

export const deleteServerCookie = ({
  name,
  path = '/',
  domain = process.env.SMALLABLE_COOKIE_DOMAIN,
  secure = true,
  httpOnly = true,
  sameSite = 'Lax',
}: {
  name: string
  path?: string
  domain?: string
  secure?: boolean
  httpOnly?: boolean
  sameSite?: string
}): string => {
  const resPath = path ? ` path=${path};` : ''
  const resDomain = domain ? ` domain=${domain};` : ''
  const resSecure = secure ? ` secure;` : ''
  const resHttpOnly = httpOnly ? ` HttpOnly;` : ''
  const resSameSite = ` sameSite=${sameSite};`

  return `${name}=; Max-Age=0;${resPath}${resDomain}${resSecure}${resHttpOnly}${resSameSite}`
}

export const getServerCookie = (
  name: string,
  cookies: string
): string | null => {
  const line = cookies.split(';').find((s) => s.includes(name))
  const res = line?.split('=')

  return res?.length === 2 ? res[1] : null
}
